import React, { ReactNode } from 'react'
import * as S from './Grid.styled'

interface GridProps {
  id: string
  columns?: number
  rowGap?: number
  columnGap?: number
  children: ReactNode
}

const Grid = (props: GridProps) => {
  const { id, columns, rowGap, columnGap, children } = props

  return (
    <S.Grid
      id={id}
      data-testid={id}
      columns={columns}
      rowGap={rowGap}
      columnGap={columnGap}
    >
      {children}
    </S.Grid>
  )
}

export default Grid
