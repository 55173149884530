import generateRoutes from './generateRoutes'
import getAdminRoutes from './routes/getAdminRoutes'
import getAppsRoutes from './routes/getAppsRoutes'
import getAuthenticatedRoutes from './routes/getAuthenticatedRoutes'
import { UniverseModules } from 'redux/universeModules/types'

interface GetRoutesParams {
  user: User
  account: Account
  dashboards: Dashboard[]
  universeModules: UniverseModules
}

const getRoutes = (params: GetRoutesParams) => {
  const { user, account, dashboards, universeModules } = params

  return {
    adminRoutes: generateRoutes({
      routes: getAdminRoutes({ account }),
    }),
    appsRoutes: getAppsRoutes({ user, dashboards }),
    authenticatedRoutes: generateRoutes({
      routes: getAuthenticatedRoutes({ user, universeModules }),
    }),
  }
}

export default getRoutes
