import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Amplify } from 'aws-amplify'
import awsconfig from './aws-config'
import Root from 'components/Root/Root'
import ReactGA from 'react-ga'
import 'tracking/Sentry'
import TagManager from 'react-gtm-module'
import { LicenseInfo } from '@mui/x-license-pro'

const { REACT_APP_GTM_ID, REACT_APP_GA_ID, REACT_APP_MUI_LICENSE } = process.env

Amplify.configure(awsconfig)

ReactGA.initialize(String(REACT_APP_GA_ID))
ReactGA.pageview(window.location.pathname)

const tagManagerArgs = {
  gtmId: String(REACT_APP_GTM_ID),
}

TagManager.initialize(tagManagerArgs)

LicenseInfo.setLicenseKey(REACT_APP_MUI_LICENSE || '')

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
)
