import { GridRowsProp } from '@mui/x-data-grid-pro'
import { MappingObject } from 'lib/reports/types'
import { isNullOrUndefined } from 'lib/variableType'
import { DATA_FIELD_TYPES } from 'lib/reports/types'
import dayjs from 'dayjs'

const { DATE_FIELD, INT_FIELD } = DATA_FIELD_TYPES

interface GenerateTableRowsParams {
  dataItems: Data[]
  rowsMapping: MappingObject
  CSV?: boolean
}

const generateTableRows = (params: GenerateTableRowsParams): GridRowsProp => {
  const { dataItems, rowsMapping, CSV } = params

  const tableRows = dataItems.map((dataItem) =>
    Object.fromEntries(
      Object.entries(rowsMapping).map(([key, defaultValue]) => {
        const value = dataItem[defaultValue as keyof Data]

        if (isNullOrUndefined(value)) {
          return Boolean(CSV) ? [key, ''] : [key, null]
        }

        if (defaultValue.startsWith(DATE_FIELD)) {
          return Boolean(CSV)
            ? [key, dayjs(value).format('D/M/YYYY')]
            : [key, new Date(value as string)]
        }

        if (defaultValue.startsWith(INT_FIELD)) {
          return Boolean(CSV)
            ? [key, Number(value).toLocaleString('es-ES')]
            : [key, value]
        }

        return [key, value]
      })
    )
  )

  return tableRows
}

export default generateTableRows
