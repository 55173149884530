import { BASE_COLORS, GREY_PALLETE } from 'theme/common/colors'
import { Text } from 'theme/types'

const TEXT: Text = {
  primary: BASE_COLORS.black,
  secondary: GREY_PALLETE[700],
  disabled: GREY_PALLETE[500],
  hint: GREY_PALLETE[600],
  icon: BASE_COLORS.black,
}

export default TEXT
