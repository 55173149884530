interface GenerateColsParams {
  columns?: number
  paddingRight: number
  paddingLeft: number
  gridColumns: number
}

const generateCols = (params: GenerateColsParams) => {
  const {
    columns: defaultColumns,
    paddingRight,
    paddingLeft,
    gridColumns,
  } = params

  const columns = defaultColumns || gridColumns

  const cols = (columns as number) + paddingRight + paddingLeft

  if (cols > gridColumns) {
    const maxCols = gridColumns - (paddingRight + paddingLeft)

    return maxCols
  }

  return columns
}

export default generateCols
