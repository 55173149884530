import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'redux/store/types'
import reducers from './reducers'

const initialState = {} as Account

const ACCOUNT = 'account'

const accountSlice = createSlice({
  name: ACCOUNT,
  initialState,
  reducers,
})

export const selectAccount = (state: RootState) => state.account

export const { setAccount } = accountSlice.actions

export default accountSlice.reducer
