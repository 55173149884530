import accountFragment from '../fragments/account'

const getAccount = /* GraphQL */ `
  ${accountFragment}
  query getAccount($id: ID!) {
    getAccount(id: $id) {
      ...AccountFields
    }
  }
`

export default getAccount
