import React from 'react'
import { SelectChangeEvent, FormControl, MenuItem } from '@mui/material'
import { SelectOption } from 'containers/Multifilter/types'
import { SelectInput } from 'components/Input'
import styles from './styles.module.css'

interface MultiFilterParams {
  selectOptions: SelectOption[]
  selectedOption: { [s: string]: string }
  handleChange: (e: SelectChangeEvent<string>, selectOption: string) => void
  selectWidth?: number | string
  isDesktop?: boolean
}

const MultiFilter = (params: MultiFilterParams) => {
  const {
    selectOptions,
    selectedOption,
    handleChange,
    selectWidth = '100%',
    isDesktop,
  } = params
  return (
    <div
      className={styles.container_multifilter}
      style={{ flexWrap: isDesktop ? 'nowrap' : 'wrap' }}
    >
      {selectOptions.map((selectOption: SelectOption, i: number) => (
        <FormControl
          key={`${selectOption.label}-${i}`}
          variant="filled"
          className={styles.option_multifilter}
          style={{ width: isDesktop ? selectWidth : '100%' }}
          data-testid={`formControl-${selectOption.label}`}
        >
          <SelectInput
            id={`selectMultifilter-${selectOption.label}-${i}`}
            labelId={`selectMultifilter-${selectOption.label}-${i}`}
            label={selectOption.label}
            value={selectedOption[selectOption.label]}
            onChange={(e: any) => handleChange(e, selectOption.label)}
            variant="filled"
          >
            {selectOption.options.map((option: string, i) => (
              <MenuItem key={`${option}-${i}`} value={option}>
                {option}
              </MenuItem>
            ))}
          </SelectInput>
        </FormControl>
      ))}
    </div>
  )
}

export default MultiFilter
