import { keyframes } from '@emotion/react'
import { styled } from '@mui/material/styles'

const keyframe = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0,0);
  }

  15% {
    opacity: 0.3;
  }

  85% {
    opacity: 0.3;
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1,1);
  }
`

export const CircleContainer = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
`

const circleCommonProperties = `
  aspect-ratio: 1 / 1;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
`

export const Circle = styled('div')`
  width: 75%;
  background-color: rgba(0, 0, 0, 0.2);
  ${circleCommonProperties}
  animation: ${keyframe} 2000ms linear infinite normal forwards;

  &:before {
    content: '';
    width: 70%;
    background-color: rgba(0, 0, 0, 0.4);
    ${circleCommonProperties}
    animation: ${keyframe} 2000ms linear infinite normal forwards;
  }

  &:after {
    content: '';
    width: 30%;
    background-color: rgba(0, 0, 0, 0.6);
    ${circleCommonProperties}
    animation: ${keyframe} 2000ms linear infinite normal forwards;
  }
`
