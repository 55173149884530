import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import Logo from 'components/Logo'
import * as S from './Menu.styled'

interface DrawerMenuProps {
  open: boolean
  onClose: () => void
  variant: 'permanent' | 'persistent' | 'temporary'
  ModalProps: {
    keepMounted: boolean
  }
  children: ReactNode
  menuHeader?: boolean
}

const DrawerMenu: React.FC<DrawerMenuProps> = ({
  open,
  onClose,
  variant,
  ModalProps,
  children,
  menuHeader = false,
}) => {
  return (
    <S.Drawer
      open={open}
      onClose={onClose}
      variant={variant}
      ModalProps={ModalProps}
      PaperProps={{ sx: S.PaperStyles }}
      menuHeader={menuHeader}
      data-testid="side-menu"
    >
      <S.LogoContainer menuHeader={menuHeader}>
        <Link to="/">
          <Logo width="auto" height="1.75rem" />
        </Link>
      </S.LogoContainer>
      {children}
    </S.Drawer>
  )
}

export default DrawerMenu
