import React, { useEffect } from 'react'
import { useDashboardParams } from 'hooks/useDashboardParams'
import { useRealTime } from 'contexts/realTime'
import SharedRoutes from 'containers/SharedRoutes'
import Routes from 'components/Routes'
import { RealTimeEventsReducerActionType } from 'contexts/realTime/realTimeEventsReducer/types'
import useRealTimeEventsGeneral from 'contexts/realTime/realTimeEvents/useRealTimeEventsGeneral'

const AppRoutes = () => {
  const { NOTIFICATION } = RealTimeEventsReducerActionType
  const { shared } = useDashboardParams()
  const { handleGeneralEvent } = useRealTimeEventsGeneral()
  const { registerRealTimeComponent, unregisterRealTimeComponent } =
    useRealTime()

  const generalEventsToHandle = [NOTIFICATION]

  useEffect(() => {
    generalEventsToHandle.forEach((event) => {
      registerRealTimeComponent({
        eventType: event,
        callback: handleGeneralEvent,
      })
    })

    return () => {
      generalEventsToHandle.forEach((event) => {
        unregisterRealTimeComponent({ eventType: event })
      })
    }
  }, []) // eslint-disable-line

  return shared ? <SharedRoutes /> : <Routes />
}

export default AppRoutes
