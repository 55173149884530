import pLimit from 'p-limit'
import {
  GraphQLOperationParams,
  GraphQLOperationTypeEnum,
} from 'hooks/useGraphQLOperation/types'
import { API } from 'aws-amplify'
import { ReportDataSetProperties } from 'lib/reports/types'
import { PublicPermissions } from '..'
import { RawReportDataSet } from 'contexts/ReportDataSets/types'

const { QUERY } = GraphQLOperationTypeEnum
const LIMIT_CONCURRENCY = 20

interface GetReportDataSetsQueriesParams {
  graphQLOperation: (params: GraphQLOperationParams) => Promise<any>
  reportDataSets: RawReportDataSet[]
  query: string
  limit: number
  publicPermissions?: PublicPermissions
  shared?: boolean
}

const getReportDataSetsQueries = (params: GetReportDataSetsQueriesParams) => {
  const {
    graphQLOperation,
    reportDataSets,
    query,
    limit,
    publicPermissions,
    shared = false,
  } = params
  const limitConcurrency = pLimit(LIMIT_CONCURRENCY)

  const reportDataSetsQueries = reportDataSets.flatMap((reportDataSet) => {
    const {
      properties,
      dataSet: { id: dataSetId, filter = null, totalQueries = 1 },
    } = reportDataSet
    const reportDataSetProperties: ReportDataSetProperties = properties
      ? JSON.parse(properties)
      : {}
    const { sort = {} } = reportDataSetProperties
    return Array.from({ length: totalQueries }, (_, i) => {
      const from = i * limit
      const variables = { dataSetId, sort, filter, from, limit }
      if (shared && publicPermissions) {
        const { token, dashboardId } = publicPermissions
        return limitConcurrency(
          () =>
            API.graphql({
              query,
              variables: {
                ...variables,
                publicPermissions: {
                  resourceId: dashboardId,
                  token,
                },
              },
              authMode: 'API_KEY',
            }) as Promise<any>
        )
      }

      const options = { variables }
      return limitConcurrency(() =>
        graphQLOperation({
          operationType: QUERY,
          operation: query,
          options,
        })
      )
    })
  })
  return reportDataSetsQueries
}

export default getReportDataSetsQueries
