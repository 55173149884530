import { SingleAxisComponentOption } from 'echarts'
import { Option, DefaultData, DataElement } from '../../types'

interface DefaultOptionModificationsSingleAxis {
  data: DefaultData
  paddingSingleAxis: boolean
  axisName: string
}

const defaultOptionModifications = (
  params: DefaultOptionModificationsSingleAxis
): Option => {
  const { data, paddingSingleAxis, axisName } = params

  return {
    tooltip: {
      trigger: 'item',
    },
    toolbox: {
      feature: {
        restore: { show: false },
        dataZoom: { show: false },
        magicType: { show: false },
      },
    },
    singleAxis: {
      height: paddingSingleAxis ? '90%' : '100%',
      bottom: 24,
      data: data.map((value: DataElement) => value?.[axisName]),
    } as SingleAxisComponentOption,
    axisPointer: {
      show: false,
    },
    grid: {
      show: false,
    },
    xAxis: {
      show: false,
    },
    yAxis: {
      show: false,
    },
    dataZoom: {
      show: false,
    },
    visualMap: {
      show: false,
      inRange: {},
      outOfRange: {},
    },
    calendar: {
      range: 2000,
      cellSize: 0,
      splitLine: {
        show: false,
      },
      itemStyle: {
        opacity: 0,
      },
      dayLabel: {
        show: false,
      },
      monthLabel: {
        show: false,
      },
      yearLabel: {
        show: false,
      },
    },
    animation: false,
  }
}

export default defaultOptionModifications
