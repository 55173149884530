import React, { SVGProps } from 'react'

export const Settings = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
    >
      <title>settings-icon</title>
      <path
        d="M10.4011 5.1035C11.0708 3.80365 12.9292 3.80365 13.5989 5.1035V5.1035C14.0013 5.88456 14.9091 6.26058 15.746 5.99283V5.99283C17.1386 5.54723 18.4528 6.86138 18.0072 8.25404V8.25404C17.7394 9.09087 18.1154 9.99868 18.8965 10.4011V10.4011C20.1963 11.0708 20.1963 12.9292 18.8965 13.5989V13.5989C18.1154 14.0013 17.7394 14.9091 18.0072 15.746V15.746C18.4528 17.1386 17.1386 18.4528 15.746 18.0072V18.0072C14.9091 17.7394 14.0013 18.1154 13.5989 18.8965V18.8965C12.9292 20.1963 11.0708 20.1963 10.4011 18.8965V18.8965C9.99868 18.1154 9.09087 17.7394 8.25404 18.0072V18.0072C6.86138 18.4528 5.54723 17.1386 5.99283 15.746V15.746C6.26058 14.9091 5.88456 14.0013 5.1035 13.5989V13.5989C3.80365 12.9292 3.80365 11.0708 5.1035 10.4011V10.4011C5.88456 9.99868 6.26058 9.09087 5.99283 8.25404V8.25404C5.54723 6.86138 6.86138 5.54723 8.25404 5.99283V5.99283C9.09087 6.26058 9.99868 5.88456 10.4011 5.1035V5.1035Z"
        stroke={color}
        stroke-width="2"
        stroke-linejoin="round"
      />
      <circle cx="12" cy="12" r="3" stroke={color} stroke-width="2" />
    </svg>
  )
}
