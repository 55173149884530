import { styled } from '@mui/material/styles'
import { Chip } from '@mui/material'

export const Header = styled('header')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.appBar,
  width: '100%',
  height: 'var(--header-height)',
  padding: '0.5rem 1.5rem',
  backgroundColor: 'var(--background-default)',
  borderBottom: '1px solid var(--color-grey-200)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1rem',

  '& > a': {
    display: 'inline-flex',
  },
}))

export const PlaygroundChip = styled(Chip)(() => ({
  backgroundColor: 'var(--complementary-orange)',
  fontSize: '0.875rem',
}))

export const ContainerUserMenu = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',

  [theme.breakpoints.down('md')]: {
    flexGrow: 0,
  },
}))

export const ContainerButton = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))
