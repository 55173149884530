import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { setItem } from 'lib/localStorage'
import { PlaygroundState } from '../../types'
import { PLAYGROUND_PORT } from 'redux/playground'

type State = PlaygroundState
type Action = Partial<PlaygroundState>

const setPlayground: CaseReducer<State, PayloadAction<Action>> = (
  state,
  action
) => {
  setItem(PLAYGROUND_PORT, action.payload.playgroundPort)

  return {
    ...state,
    ...action.payload,
  }
}

export default setPlayground
