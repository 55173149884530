import React, { ReactNode } from 'react'
import { Typography } from '@mui/material'
import SearchInput from 'components/Input/SearchInput'
import * as S from 'components/UserMenu/UserMenu.styled'

interface MenuGroupProps {
  children: ReactNode
  title: string
  icon?: JSX.Element
  accountNames?: string[]
  onClickIcon?: () => void
  handleSearch: (value: string) => void
}

const MenuGroup = (props: MenuGroupProps) => {
  const { handleSearch, children, title, icon, onClickIcon } = props
  return (
    <>
      {(title || icon) && (
        <S.MenuGroupContainer data-testid="menu-group">
          {icon && (
            <S.MenuButton onClick={onClickIcon}>
              <S.MenuIcon position="left">{icon}</S.MenuIcon>
            </S.MenuButton>
          )}
          {title && (
            <Typography variant="bodyS400" component="h6">
              {title}
            </Typography>
          )}
        </S.MenuGroupContainer>
      )}
      <S.MenuDivider />
      <SearchInput
        labelId="SearchAccount"
        iconPosition="end"
        handleSearch={handleSearch}
        withoutPadding
      />
      {children}
    </>
  )
}

export default MenuGroup
