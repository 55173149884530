import React from 'react'
import { SelectChangeEvent, InputAdornment } from '@mui/material'
import { formatMessage } from 'i18n/ShimokuIntl'
import Search from 'icons/Line/search'
import debounce from 'lib/debounce'
import * as S from '../Select/Select.styled'

interface SearchInputProps {
  fullWidth?: boolean
  labelId: string
  handleSearch: (value: string) => void
  delay?: number
  position?: 'top' | 'bottom'
  iconPosition?: 'start' | 'end'
  withoutPadding?: boolean
}

const SearchInput: React.FC<SearchInputProps> = ({
  fullWidth,
  labelId,
  delay = 200,
  handleSearch,
  position = 'top',
  iconPosition = 'start',
  withoutPadding,
}) => {
  const searchIconAdornment = (
    <InputAdornment position={iconPosition}>
      <Search width="24px" height="24px" />
    </InputAdornment>
  )

  return (
    <S.InputWithoutBorders
      id={`search-${labelId}`}
      top={position === 'top'}
      placeholder={`${formatMessage('generic.search')}...`}
      fullWidth={!!fullWidth}
      onChange={debounce(
        (e: SelectChangeEvent) => handleSearch(e.target.value),
        delay
      )}
      onKeyDown={(e: React.KeyboardEvent) => {
        if (e.key === 'ArrowDown') {
          const nextSibling = e.currentTarget.nextElementSibling
          nextSibling &&
            setTimeout(() => (nextSibling as HTMLElement).focus(), 0)
        } else {
          e.stopPropagation()
        }
      }}
      type="search"
      InputProps={{
        ...(iconPosition === 'start' && {
          startAdornment: searchIconAdornment,
        }),
        ...(iconPosition === 'end' && { endAdornment: searchIconAdornment }),
      }}
      withoutPadding={withoutPadding}
    />
  )
}

export default SearchInput
