import React, { useState } from 'react'
import { Alert } from '@mui/material'
import { formatMessage } from 'i18n/ShimokuIntl'
import withErrorWrapper from 'hocs/withErrorWrapper'
import StockLineChart from '../StockLineChart'

export interface StockLineChartData {
  [Value: string]: string
}

const ReportStockLineChart = (props: WithReport) => {
  const { report } = props
  const [chartData] = useState<StockLineChartData[] | null>(null)
  const [chartDataItem] = useState<StockLineChartData | null>(null)

  return (
    <StockLineChart
      id={report.id}
      initialChartdata={report.chartData && JSON.parse(report.chartData)}
      initialChartdataItem={
        report.chartDataItem && JSON.parse(report.chartDataItem)
      }
      chartData={chartData}
      chartDataItem={chartDataItem}
      fields={report.dataFields && JSON.parse(report.dataFields)}
    />
  )
}

const Placeholder = () => (
  <Alert severity="error">
    {formatMessage('errors.inconsistentReportData')}
  </Alert>
)

export default withErrorWrapper<WithReport>(ReportStockLineChart, Placeholder)
