import React, { SVGProps } from 'react'
import styles from './styles.module.css'

interface customSVG extends SVGProps<SVGSVGElementEventMap> {
  primaryColor?: string
  secondaryColor?: string
}

export const Loading = (props: customSVG) => {
  const {
    width = '500px',
    height = '500px',
    primaryColor,
    secondaryColor,
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      style={{
        width,
        height,
      }}
      fill="none"
      role="progressbar"
    >
      <title>loading-icon</title>
      <g>
        <g className={styles.dot_1}>
          <circle r="20" transform="translate(0,0)" fill={primaryColor} />
        </g>
      </g>
      <g transform="translate(-1.531151 0)">
        <g
          className={styles.dot_2}
          transform="translate(170.535611,250.000001)"
        >
          <circle r="20" transform="translate(0,0)" fill={secondaryColor} />
        </g>
      </g>
      <g transform="translate(20 0)">
        <g className={styles.dot_3} transform="translate(230,250.000001)">
          <circle r="20" transform="translate(0,0)" fill={primaryColor} />
        </g>
      </g>
      <g transform="translate(100.995537 0)">
        <g className={styles.dot_4} transform="translate(230,250.000001)">
          <circle r="20" transform="translate(0,0)" fill={secondaryColor} />
        </g>
      </g>
      <g transform="translate(181.991074 0)">
        <g className={styles.dot_5} transform="translate(230,250.000001)">
          <circle r="20" transform="translate(0,0)" fill={primaryColor} />
        </g>
      </g>
    </svg>
  )
}
