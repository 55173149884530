import useGetActionEvent from 'hooks/useRunEvents/useGetActionEvent'
import { Events, RunEvents } from 'hooks/useRunEvents/types'

export interface UseRunEventsParams {
  events: Events
  appId?: string
}

const useRunEvents = (params: UseRunEventsParams) => {
  const { events, appId = '' } = params
  const { getActionEvent } = useGetActionEvent({ appId })

  return Object.fromEntries(
    Object.entries(events).map(([eventType, actions = []]) => [
      eventType,
      (eventParams) =>
        actions.forEach(({ action, params: actionParams }) =>
          getActionEvent({
            action,
            actionParams,
            eventParams,
          })
        ),
    ])
  ) as RunEvents
}

export default useRunEvents
