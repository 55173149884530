import doOperation from '../operations/doOperation'
import { OperationType } from '../types'

const { SUM } = OperationType

interface GenerateAggregationWithOperationsParams {
  reportDataSets: ReportDataSet[]
}

const generateAggregationWithOperation = (
  params: GenerateAggregationWithOperationsParams
) => {
  const { reportDataSets } = params

  return reportDataSets.map((reportDataSet) => {
    const { aggregationProperty = [], aggregation: defaultAggregation = [] } =
      reportDataSet

    const aggregationOperations = aggregationProperty.filter(
      (aggregation) => aggregation.operation
    )

    const aggregation = defaultAggregation.map((aggregation) => {
      const operations = aggregationOperations.reduce(
        (operation, aggregationOperation) => {
          const { count } = aggregation
          const { name, operation: operationType = SUM } = aggregationOperation

          const data = aggregation.items.map(
            (aggregationItem) => aggregationItem[name]
          ) as number[]
          const operationResult = doOperation({ operationType, data, count })

          return {
            ...operation,
            [name]: operationResult,
          }
        },
        {}
      )

      return {
        ...aggregation,
        ...operations,
      }
    }) as Aggregation[]

    return {
      ...reportDataSet,
      aggregation,
    }
  }) as ReportDataSet[]
}

export default generateAggregationWithOperation
