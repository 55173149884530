import React, { memo, FC } from 'react'
import globalStyles from 'material/global.module.css'
import classNames from 'classnames'
import Loading from 'icons/loading'

interface LoadingSectionProps {
  className?: string
  minHeight?: number
}

const LoadingSection: FC<LoadingSectionProps> = memo(
  ({ className, minHeight = '32' }) => (
    <div
      className={classNames(globalStyles.centrendContainer, className)}
      style={{ minHeight: `${minHeight}px` }}
    >
      <Loading
        className={globalStyles.centred}
        width="200px"
        height="200px"
        primaryColor="var(--color-primary)"
        secondaryColor="var(--color-secondary)"
      />
    </div>
  )
)

export default LoadingSection
