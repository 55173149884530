import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { formatMessage } from 'i18n/ShimokuIntl'
import { generateRoutes } from 'components/Routes/generateRoutes'
import { NOT_ALLOWED } from 'components/Routes/routes/getAuthenticatedRoutes'
import Route, { RouteProps } from 'components/Routes/Route'

/**
 * Middleware for <Route /> component, it verifies the user has alread the permissions to access to this route,
 * otherwise, it will redirecto to /subscriptions page.
 */

interface RestrictedRouteProps extends RouteProps {
  user: User
  app: App
}

const RestrictedRoute = (props: RestrictedRouteProps) => {
  const { user, app } = props
  const [notAllow] = generateRoutes({
    routes: [NOT_ALLOWED],
  })
  const { enqueueSnackbar } = useSnackbar()

  if (user.isAdmin || app) {
    return <Route {...props} />
  } else {
    enqueueSnackbar(formatMessage('component.restrictedRoute.redirection'), {
      variant: 'warning',
    })
    return <Redirect to={notAllow.path} />
  }
}

export default RestrictedRoute
