import { HTTP_METHODS } from 'lib/HTTP_Methods'

const { PUT } = HTTP_METHODS

interface UploadFileParams {
  file: File
  url: string
}

const uploadFile = async (params: UploadFileParams) => {
  const { file, url } = params

  const res = await fetch(url, {
    method: PUT,
    body: file,
  })

  return res
}

export default uploadFile
