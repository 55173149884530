import { TableProperties } from 'lib/reports/types'
import { TableState } from 'containers/Table/types'

interface GetDefaultTableStateParams {
  tableProperties: TableProperties
}

const getDefaultTableState = (params: GetDefaultTableStateParams) => {
  const { tableProperties } = params
  const { sort, pagination = {} } = tableProperties
  const { pageSize = 10, pageSizeOptions = [] } = pagination

  return {
    columns: [],
    rowIdName: '',
    rows: [],
    sort,
    filter: null,
    total: 0,
    queryTokens: [],
    paginationModel: {
      pageSize,
      page: 0,
    },
    pageSizeOptions,
    lastPage: 0,
    loading: true,
  } as TableState
}

export default getDefaultTableState
