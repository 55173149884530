import React from 'react'
import ButtonComponent from 'components/Button'
import { ButtonProperties } from 'lib/reports/types'
import useRunEvents from 'hooks/useRunEvents'
import * as S from './Button.styled'

interface ButtonProps {
  properties: ButtonProperties
}

const Button = (props: ButtonProps) => {
  const { properties } = props
  const { text, align = 'stretch', events = {}, ...restProps } = properties
  const { onClick } = useRunEvents({ events })

  const handleClick = () => {
    onClick?.()
  }

  return (
    <S.ContainerButton align={align}>
      <ButtonComponent
        {...restProps}
        fullWidth={align === 'stretch'}
        onClick={handleClick}
      >
        {text}
      </ButtonComponent>
    </S.ContainerButton>
  )
}

export default Button
