import React, { SVGProps } from 'react'

export const Youtube = ({
  width = '32',
  height = '32',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>youtube-icon</title>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.94447 6.31345C3.96395 6.41157 2.37304 7.96233 2.24451 9.94111C2.12136 11.8369 2 14.1697 2 16C2 17.8303 2.12136 20.1631 2.24451 22.0589C2.37304 24.0377 3.96395 25.5884 5.94447 25.6866C8.81239 25.8286 12.9115 26 16 26C19.0885 26 23.1876 25.8286 26.0555 25.6866C28.0361 25.5884 29.627 24.0377 29.7555 22.0589C29.8786 20.1631 30 17.8303 30 16C30 14.1697 29.8786 11.8369 29.7555 9.94111C29.627 7.96233 28.0361 6.41157 26.0555 6.31345C23.1876 6.17136 19.0885 6 16 6C12.9115 6 8.81239 6.17136 5.94447 6.31345ZM21 16L13 20V12L21 16Z"
        fill={color}
      />
    </svg>
  )
}
