import reportFragment from '../fragments/report'

const getReportById = /* GraphQL */ `
  ${reportFragment}
  query getReport(
    $id: ID!
    $publicPermissions: DashboardPublicPermissionInputExposed
  ) {
    getReport(id: $id, publicPermissions: $publicPermissions) {
      ...ReportFields
    }
  }
`

export default getReportById
