import { Events } from 'hooks/useRunEvents/types'
import { Align } from 'containers/ReportForm/types'
import { FieldGroup } from 'components/ReportForm/types'
import { EChartsOption } from 'echarts'
import { FilterInput, FilterMapping } from 'containers/FilterDataSet/types'
import { CustomButtonProps } from 'components/Button'

type ORDER_DIRECTION = 'asc' | 'desc'

export interface Sort {
  field: customField | dateField | intField | stringField
  direction: ORDER_DIRECTION
}

export interface TablePagination {
  pageSize?: number
  pageSizeOptions?: number[]
}

interface TableFilter {
  [field: string]: { [operator: string]: string }
}

export interface ReportDataSetProperties {
  mapping: Mapping
  sort: Sort
}

export type MappingObject = {
  [k: string]: any
}
export type MappingArray = string[] | MappingObject[]

export type Mapping =
  | string
  | number
  | MappingObject
  | MappingArray
  | FieldGroup[]

export interface ReportDataSetWithMapping {
  [idReportDataSet: string]: Mapping[]
}

export interface DataState {
  value: boolean
  message?: string
}

export interface FreeEchartsProperties {
  option?: EChartsOption
}

type MarkProperties = {
  label: string
  value: number
}

export type SliderProperties = {
  max?: number
  defaultValue?: number
  marks?: MarkProperties[]
}

export interface HistoryChartProperties {
  option?: EChartsOption
  slider?: SliderProperties
}

export interface HistoryChartProperties extends FreeEchartsProperties {
  slider?: SliderProperties
}

export interface FilterDataSetProperties {
  filter: FilterInput[]
  mapping: FilterMapping[]
}

export type IndicatorColor =
  | 'success'
  | 'warning'
  | 'error'
  | 'neutral'
  | 'caution'
  | 'default'
  | 'error-background'
  | 'success-background'
  | 'warning-background'

export type IndicatorVariant = 'default' | 'outlined' | 'contained' | 'topColor'

export interface IndicatorProperties {
  title: string
  value: string
  description?: string
  color?: IndicatorColor
  align?: 'left' | 'center' | 'right'
  targetPath?: string
  icon?: string
  bigIcon?: string
  variant?: IndicatorVariant
  info?: string
  hideIcons?: boolean
  backgroundImage?: string
  elipsis?: boolean
}

export interface TabReports {
  order: number
  reportIds: string[]
}

export type TabsVariant = 'solidRounded' | 'enclosedSolidRounded'

export interface TabsProperties {
  tabs: { [tabName: string]: TabReports }
  sticky?: boolean
  variant?: TabsVariant
}

interface TablePropertiesRows {
  mapping: MappingObject
}

type TableColumnType = 'singleSelect' | 'button'

export interface TableColumnLink {
  url: string
  openNewTab?: boolean
}

export interface TableColumnIcon {
  name: string
  color?: string
  width?: number
  height?: number
}

export type TableChipVariant = 'filled' | 'outlined'

export interface TableChipOption {
  value: string
  color: string
  backgroundColor: string
}

export interface TableChip {
  field: string
  variant?: TableChipVariant
  options: TableChipOption[]
}

export interface TablePropertiesColumn {
  field: string
  headerName: string
  order: number
  hideColumn?: boolean
  type?: TableColumnType
  options?: string[]
  link?: TableColumnLink
  icon?: TableColumnIcon
  width?: number
  chips?: TableChip
}

export interface OldTableProperties {
  rowsPerPage?: number
  downloadable?: boolean
}

export interface TableProperties {
  rows: TablePropertiesRows
  columns: TablePropertiesColumn[]
  sort?: Sort
  pagination?: TablePagination
  filter?: TableFilter
  columnsButton?: boolean
  filtersButton?: boolean
  exportButton?: boolean
  search?: boolean
  footer?: boolean
  info?: string
}

export interface ButtonProperties extends CustomButtonProps {
  text: string
  align: Align
  events?: Events
}

export interface ModalProperties {
  open?: boolean
  reportIds?: string[]
  width?: number
  height?: number
}

export interface FormProperties {
  events?: Events
}

export type ReportProperties =
  | FreeEchartsProperties
  | FilterDataSetProperties
  | IndicatorProperties
  | TabsProperties
  | TableProperties
  | ButtonProperties
  | ModalProperties
  | FormProperties

export enum DATA_FIELD_TYPES {
  STRING_FIELD = 'stringField',
  INT_FIELD = 'intField',
  DATE_FIELD = 'dateField',
  CUSTOM_FIELD = 'customField',
}
