import React, { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import Layout from 'containers/Layout'
import RestrictedRoute from 'components/RestrictedRoute'
import { reloadPageOnChunkError } from 'lib/lazy'
import getAppRoutes from 'lib/apps/getAppRoutes'

const Reports = lazy(() =>
  import('pages/Reports').catch(reloadPageOnChunkError)
)

interface GetAppsRoutesParams {
  user: User
  dashboards: Dashboard[]
  prefix?: string
}

const getAppsRoutes = (params: GetAppsRoutesParams) => {
  const { user, dashboards, prefix = '' } = params
  const paths = dashboards.map((dashboard) => `${prefix}/${dashboard.id}/`)

  return (
    <Route path={paths}>
      <Layout>
        <Switch>
          {dashboards.flatMap((dashboard) =>
            dashboard.appDashboards.items.flatMap(
              (appDashboard: AppDashboard) => {
                const { allRoutes } = getAppRoutes({
                  app: appDashboard.app,
                  dashboard,
                  prefix,
                })

                return allRoutes.map((appRoute) => {
                  const { path, breadcrumb } = appRoute
                  const { id: appId } = appDashboard.app

                  return (
                    <RestrictedRoute
                      key={`${appId}|${path}`}
                      user={user}
                      app={appDashboard.app}
                      exact
                      path={path}
                      breadcrumb={breadcrumb}
                      render={(routeProps) => (
                        <Reports
                          {...routeProps}
                          dashboard={dashboard}
                          appRoute={appRoute}
                          app={appDashboard.app}
                        />
                      )}
                    />
                  )
                })
              }
            )
          )}
        </Switch>
      </Layout>
    </Route>
  )
}

export default getAppsRoutes
