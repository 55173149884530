const debounce = (callback: any, wait: number) => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null
  return (...args: any) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      callback.apply(null, args)
    }, wait)
  }
}

export default debounce
