import { CHART_OPTIONS } from 'theme/defaultTheme/defaultThemes/dark'
import { DOMAIN_OPTIONS, ChartOptions } from 'theme/types'

const { GASOLINOW } = DOMAIN_OPTIONS

const overrides: any = {
  MuiButton: {
    containedPrimary: {
      color: 'var(--text-primary)',
      boxShadow: 'none',
    },
  },
  MuiAppBar: {
    colorPrimary: {
      color: 'var(--color-white)',
      backgroundColor: 'var(--background-paper)',
    },
  },
}

const BRAND_PALLETE = {
  50: '#FEEFCC',
  100: '#FEEFCC',
  200: '#FDDB9A',
  300: '#F9C167',
  400: '#F4A741',
  500: '#EE7E05',
  600: '#CC6203',
  700: '#AB4902',
  800: '#8A3401',
  900: '#722500',
}

const chartOptions: ChartOptions = {
  ...CHART_OPTIONS,
  axisline: 'rgba(255,255,255, .4)',
  axislabel: 'rgba(255,255,255, .4)',
}

export const gasolinowOptions = {
  domain: GASOLINOW,
  brandPallete: BRAND_PALLETE,
  overrides,
  chartOptions,
}
