import { ItemFilter } from 'components/ReportsFilter'
import { REPORT_TYPES } from 'lib/reports'

const getFilters = (reports: Report[]) => {
  const { FILTER } = REPORT_TYPES
  return reports.filter((report: Report) => report.reportType === FILTER)
}

const getSubReports = (reportFilters: Report[]) => {
  if (reportFilters.length) {
    const subReports: string[][] = reportFilters.flatMap((report: Report) =>
      report.chartData
        ? JSON.parse(report.chartData).map(
            (itemFilter: ItemFilter) => itemFilter.id
          )
        : ([] as string[])
    )
    return new Set(subReports.flat())
  }

  return new Set([]) as Set<string>
}

const removeFilters = (reports: Report[], reportFilters: Report[]) => {
  const idFilters = new Set(reportFilters.map((report: Report) => report.id))
  return reports.filter((report: Report) => !idFilters.has(report.id))
}

const removeSubReports = (reports: Report[], idSubReports: Set<string>) =>
  reports.filter((report: Report) => !idSubReports.has(report.id))

const setSupReports = (
  reports: Report[],
  idSubReports: Set<string>,
  reportFilters: Report[]
) => {
  return reportFilters.map((report: Report) => {
    const subReports = reports.filter((report: Report) =>
      idSubReports.has(report.id)
    )

    return {
      ...report,
      subReports,
    }
  })
}

const createFilters = (reports: Report[] = []) => {
  const reportFilters = getFilters(reports)
  const subReports = getSubReports(reportFilters)
  const reportsWithoutFilters = removeFilters(reports, reportFilters)
  const reportsWithoutSubReports = removeSubReports(
    reportsWithoutFilters,
    subReports
  )
  const filtersWithSubReports = setSupReports(
    reports,
    subReports,
    reportFilters
  )

  return [...reportsWithoutSubReports, ...filtersWithSubReports]
}

const organizeApps = (apps: App[]) => {
  const organizedApps = apps.map((app: App) => {
    const { reports } = app
    const organizedReports: Report[] = createFilters(reports?.items)

    return {
      ...app,
      reports: {
        items: organizedReports,
      },
    } as App
  })

  return organizedApps
}

export default organizeApps
