import React from 'react'
import styles from './styles.module.css'

export interface HtmlContent {
  value: string
  box: boolean
}

export interface HtmlProps {
  data: HtmlContent[]
}

export const Html = (props: HtmlProps) => {
  const { data } = props

  return (
    <div className={styles.container__html}>
      {data.map((html: HtmlContent, i) => (
        <div
          key={i}
          className={html.box ? styles.container__html__box : ''}
          dangerouslySetInnerHTML={{ __html: html.value }}
        />
      ))}
    </div>
  )
}
