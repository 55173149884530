import { FilterValue } from './types'

interface GenerateEqualParams {
  value: FilterValue
}

const generateEqual = (params: GenerateEqualParams) => {
  const { value } = params
  return { eq: value }
}

export default generateEqual
