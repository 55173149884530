interface RolePermissionsFieldsParams {
  name: string
}

const rolePermissionsFragment = ({
  name,
}: RolePermissionsFieldsParams) => /* GraphQL */ `
  fragment RolePermissionsFields${name} on RolePermissionExposed {
      role
      permission
      resource
      target
  }
`
export default rolePermissionsFragment
