import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectPlan } from 'redux/plan'
import { Tooltip } from '@mui/material'
import ConditionalWrapper from 'hocs/conditionalWrapper'
import Download from 'icons/Line/download'
import { downloadCSV } from 'lib/files'
import { sanitizeString } from 'lib/strings'
import { formatMessage } from 'i18n/ShimokuIntl'
import * as S from '../ReportTable.styled'
import { FetchAllDataParams } from '..'

interface DownloadDataTableProps {
  reportTitle?: string
  fetchAllData: ({ rows, customLimit }: FetchAllDataParams) => Promise<any>
}

const DownloadDataTable = (props: DownloadDataTableProps) => {
  const { reportTitle, fetchAllData } = props
  const [downloadLoading, setDownloadLoading] = useState(false)
  const {
    limits: { enabledReportDataDownload = false },
  } = useSelector(selectPlan)

  const handleDownload = async () => {
    setDownloadLoading(true)
    const allRows = await fetchAllData({ customLimit: 1000 })
    const headers = Object.keys(allRows[0])
    const title = reportTitle && sanitizeString(reportTitle)
    downloadCSV({ headers, rows: allRows, title })
    setDownloadLoading(false)
  }

  return (
    <S.DownloadContainer>
      <ConditionalWrapper
        condition={!enabledReportDataDownload}
        wrapper={(children) => (
          <Tooltip title={formatMessage('info.featureNotAvaibleInPlan')} arrow>
            <span>{children}</span>
          </Tooltip>
        )}
      >
        <S.IconButton
          disabled={!enabledReportDataDownload}
          onClick={handleDownload}
          loading={downloadLoading}
        >
          <Download width={18} height={18} />
        </S.IconButton>
      </ConditionalWrapper>
    </S.DownloadContainer>
  )
}

export default DownloadDataTable
