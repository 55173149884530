import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentRoles } from 'redux/roles'
import { selectDashboards } from 'redux/dashboards'
import useReportDataSetsByReportDataSets from 'contexts/ReportDataSets/hooks/useReportDataSetsByReportDataSets'
import { useDashboardParams } from 'hooks/useDashboardParams'
import ReportFormComponent from 'components/ReportForm'
import ReportFormLoading from './ReportFormLoading'
import getComponentData from './helpers/getComponentData'
import { generateFormReportDataSetsWithMapping } from 'lib/reports/reportDataSetsWithMapping'
import { ReportDataSetWithMapping, FormProperties } from 'lib/reports/types'

interface ReportFormProps {
  report: Report
  reportProperties: FormProperties
  rows: number
  columns: number
  isBentoBox?: boolean
}

const ReportForm = (props: ReportFormProps) => {
  const { report, reportProperties, rows, columns, isBentoBox } = props
  const { appId } = report
  const { events = {} } = reportProperties
  const rolePermissions = useSelector(selectCurrentRoles)
  const { shared } = useDashboardParams()
  const [dashboard] = useSelector(selectDashboards)
  const hasEditPermission =
    (rolePermissions.DATA === 'WRITE' && !shared) ||
    (shared && dashboard.publicPermission?.permission === 'WRITE')
  const [reportDataSets, setReportDataSets] =
    useState<ReportDataSetWithMapping | null>(null)
  const { reportDataSets: defaultReportDataSets } =
    useReportDataSetsByReportDataSets({
      reportDataSets: report.reportDataSets?.items || [],
    })

  const updateReportDataSets = (reportdataSets: ReportDataSet[]) => {
    const reportSataSetsWithMapping: ReportDataSetWithMapping =
      generateFormReportDataSetsWithMapping(reportdataSets)
    setReportDataSets(reportSataSetsWithMapping)
  }

  const loading = defaultReportDataSets.some(
    (reportDataSet) => reportDataSet.dataSet.loading === true
  )
  const hasData = defaultReportDataSets.some(
    (reportDataSet) =>
      reportDataSet.dataSet?.data && reportDataSet.dataSet?.data.length
  )

  const stringDefaultReportDataSets = JSON.stringify(defaultReportDataSets)
  useEffect(() => {
    if (!loading && hasData) {
      updateReportDataSets(defaultReportDataSets)
    }
  }, [loading, hasData, stringDefaultReportDataSets]) // eslint-disable-line

  return !loading && reportDataSets ? (
    <>
      {Object.keys(reportDataSets).map((key) => {
        const { currentFieldGroups, defaultFormValues } = getComponentData(
          //@ts-ignore
          reportDataSets[key].fields
        )

        return (
          <ReportFormComponent
            currentFieldGroups={currentFieldGroups}
            hasEditPermission={hasEditPermission}
            //@ts-ignore
            dataSetId={reportDataSets[key].dataSetId}
            //@ts-ignore
            dataId={reportDataSets[key].dataId}
            defaultFormValues={defaultFormValues}
            columns={columns}
            //@ts-ignore
            sendButton={reportDataSets[key].sendButton}
            //@ts-ignore
            variant={reportDataSets[key].variant}
            appId={appId}
            defaultReportDataSets={defaultReportDataSets}
            updateReportDataSets={updateReportDataSets}
            events={events}
          />
        )
      })}
    </>
  ) : (
    <ReportFormLoading gridRows={rows} isBentoBox={isBentoBox} />
  )
}

export default ReportForm
