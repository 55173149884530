import { styled } from '@mui/material/styles'
import { GridPagination as MUIGridPagination } from '@mui/x-data-grid-pro'

interface GridFooterContainerProps {
  info: boolean
}

export const GridFooterContainer = styled('div')<GridFooterContainerProps>(
  ({ info }) => ({
    display: 'flex',
    justifyContent: info ? 'space-between' : 'flex-end',
    alignItems: 'center',
    padding: '1rem',
  })
)

export const ContatinerPaginationActions = styled('div')(() => ({
  display: 'flex',
}))

export const GridPagination = styled(MUIGridPagination)(() => ({
  '& p': {
    margin: 0,
  },
  '& .MuiInputBase-root': {
    margin: '0 0 0 0.5rem',

    '& .MuiTablePagination-select': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      color: 'var(--color-black)',
    },
  },
  '& .MuiTablePagination-displayedRows': {
    margin: '0 1rem',
  },
}))
