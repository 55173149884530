import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'redux/store/types'

const initialState: AppType[] = []

const appTypeSlice = createSlice({
  name: 'appTypes',
  initialState,
  reducers: {
    setAppTypes: (state, action) => action.payload,
  },
})

export const selectAppTypes = (state: RootState) => state.appType

export const { setAppTypes } = appTypeSlice.actions

export default appTypeSlice.reducer
