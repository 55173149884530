import { ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { getMainDefinition } from '@apollo/client/utilities'

const LOCALHOST = 'localhost'

interface GetApolloClientParams {
  port: number
}

const getApolloClient = (params: GetApolloClientParams) => {
  const { port } = params

  const httpLink = new HttpLink({
    uri: `http://${LOCALHOST}:${port}/graphql`,
  })

  const wsLink = new WebSocketLink(
    new SubscriptionClient(`ws://${LOCALHOST}:${port}/graphql`)
  )

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)

      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )

  const apolloClient = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  })

  return apolloClient
}

export default getApolloClient
