import React from 'react'
import styles from './styles.module.css'

export interface IframeFields {
  url: string
  height?: number
}

interface IframeProps {
  fields: IframeFields
}

const Iframe = (props: IframeProps) => {
  const { fields } = props
  const { url, height } = fields

  return (
    <iframe
      title={`report-iframe-${url}`}
      className={styles.container}
      style={{ height: height ? `${height}px` : '100%' }}
      src={url}
    ></iframe>
  )
}

export default Iframe
