import { Option } from '../../types'

const defaultOptionModifications = (): Option => ({
  toolbox: {
    feature: {
      restore: { show: false },
      dataZoom: { show: false },
      magicType: { show: false },
    },
  },
  legend: {
    show: false,
  },
  axisPointer: {
    show: false,
  },
  grid: {
    show: false,
  },
  xAxis: {
    show: false,
  },
  yAxis: {
    show: false,
  },
  dataZoom: {
    show: false,
  },
  visualMap: {
    show: false,
    inRange: {},
    outOfRange: {},
  },
  singleAxis: {
    show: false,
    axisLine: {
      show: false,
    },
  },
  calendar: {
    range: 2000,
    cellSize: 0,
    splitLine: {
      show: false,
    },
    itemStyle: {
      opacity: 0,
    },
    dayLabel: {
      show: false,
    },
    monthLabel: {
      show: false,
    },
    yearLabel: {
      show: false,
    },
  },
  animation: false,
})

export default defaultOptionModifications
