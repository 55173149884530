import getAllAppsFromDashboards from 'lib/apps/getApps/getAllAppsFromDashboards'
import addIncrementToDuplicatedAppNames from 'lib/apps/getApps/addIncrementToDuplicatedAppNames'
import sortApps from 'lib/apps/sortApps'
import organizeApps from 'lib/apps/organizeApps'

interface GetAppsParams {
  dashboards: Dashboard[]
  haveInstallRemoveApps: boolean
}

const excludeAppsWithoutReports = (apps: App[]) =>
  apps.filter((app) => app?.reports?.items.length)

export const getApps = (params: GetAppsParams) => {
  const { dashboards, haveInstallRemoveApps } = params
  const apps = getAllAppsFromDashboards({ dashboards })
  let organizedApps = apps ? organizeApps(apps) : []
  if (!haveInstallRemoveApps && Boolean(organizedApps.length)) {
    organizedApps = excludeAppsWithoutReports(organizedApps)
  }
  const enabledApps = organizedApps.filter((app: App) => !app.isDisabled)
  const sortedApps = sortApps(enabledApps)
  const whithoutDuplicatedAppNames = addIncrementToDuplicatedAppNames({
    apps: sortedApps,
  })

  return whithoutDuplicatedAppNames
}

export default getApps
