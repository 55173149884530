import { GaugeSeriesOption } from 'echarts'
import { SHADOW_STYLE, BORDER_STYLE, TEXT_STYLE } from '../../defaults/general'
import { TypeSeriesParams } from '../../types'

const gaugeSerie = (
  params: TypeSeriesParams<GaugeSeriesOption>
): GaugeSeriesOption => {
  const {
    typeSeriesModifications: gaugeSeriesModifications = {},
    paddingGauge = false,
    theme,
  } = params
  const PALLETE = theme.palette

  return {
    type: 'gauge',
    center: ['50%', `${paddingGauge ? 55 : 50}%`],
    radius: `${paddingGauge ? 85 : 95}%`,
    startAngle: 90,
    endAngle: -270,
    clockwise: true,
    splitNumber: 10,
    ...gaugeSeriesModifications,
    axisLine: {
      show: true,
      roundCap: false,
      ...gaugeSeriesModifications?.axisLine,
      //@ts-ignore
      lineStyle: {
        color: [[1, PALLETE.grey['600']]],
        width: 32,
        ...SHADOW_STYLE,
        opacity: 0.2,
        ...gaugeSeriesModifications?.axisLine?.lineStyle,
      },
    },
    progress: {
      show: true,
      overlap: false,
      width: 8,
      roundCap: true,
      clip: false,
      ...gaugeSeriesModifications?.progress,
      itemStyle: {
        ...BORDER_STYLE,
        ...SHADOW_STYLE,
        ...gaugeSeriesModifications?.progress?.itemStyle,
      },
    },
    splitLine: {
      show: false,
      length: 8,
      distance: 8,
      ...gaugeSeriesModifications?.splitLine,
      lineStyle: {
        color: PALLETE.grey['600'],
        width: 3,
        type: 'solid',
        ...SHADOW_STYLE,
        ...gaugeSeriesModifications?.splitLine?.lineStyle,
      },
    },
    axisTick: {
      show: false,
      splitNumber: 5,
      length: 4,
      distance: 8,
      ...gaugeSeriesModifications?.axisTick,
      lineStyle: {
        color: PALLETE.grey['400'],
        width: 1,
        type: 'solid',
        ...SHADOW_STYLE,
        ...gaugeSeriesModifications?.axisTick?.lineStyle,
      },
    },
    axisLabel: {
      show: false,
      distance: 40,
      ...TEXT_STYLE(theme),
      fontSize: 12,
      backgroundColor: 'transparent',
      ...BORDER_STYLE,
      padding: 0,
      ...SHADOW_STYLE,
      ...gaugeSeriesModifications?.axisLabel,
    },
    pointer: {
      show: false,
      offsetCenter: [0, 0],
      length: '40%',
      width: 8,
      ...gaugeSeriesModifications?.pointer,
      itemStyle: {
        ...BORDER_STYLE,
        ...SHADOW_STYLE,
        ...gaugeSeriesModifications?.pointer?.itemStyle,
      },
    },
    anchor: {
      show: false,
      ...gaugeSeriesModifications?.anchor,
    },
    itemStyle: {
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      ...gaugeSeriesModifications?.itemStyle,
    },
    title: {
      show: false,
      offsetCenter: [0, 0],
      ...TEXT_STYLE(theme),
      fontWeight: 500,
      fontSize: 14,
      backgroundColor: 'transparent',
      ...BORDER_STYLE,
      padding: 0,
      ...SHADOW_STYLE,
      ...gaugeSeriesModifications?.title,
    },
    detail: {
      show: true,
      ...TEXT_STYLE(theme),
      fontWeight: 400,
      fontSize: 14,
      backgroundColor: 'transparent',
      ...BORDER_STYLE,
      padding: 0,
      ...SHADOW_STYLE,
      ...gaugeSeriesModifications?.detail,
      rich: {
        ...gaugeSeriesModifications?.detail?.rich,
        detail: {
          color: '#fff',
          backgroundColor: 'inherit',
          padding: 4,
          fontWeight: 600,
          borderRadius: 4,
          ...gaugeSeriesModifications?.detail?.rich?.detail,
        },
      },
    },
  }
}

export default gaugeSerie
