import { FieldGroup, ReportFormDataset } from 'components/ReportForm/types'

const fillDataSet = (dataSet: FieldGroup[], formData: any): FieldGroup[] =>
  dataSet.map(({ fields, title }: FieldGroup) => {
    const overridedFields = fields.map((field: ReportFormDataset) => {
      const { fieldName } = field
      if (formData.hasOwnProperty(fieldName)) {
        return {
          ...field,
          mapping: formData[fieldName],
        }
      }
      return field
    })
    return { title, fields: overridedFields }
  })

export default fillDataSet
