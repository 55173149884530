interface GetGatewayDomainThemeParams {
  account: Account
}

const getGatewayDomainTheme = (
  params: GetGatewayDomainThemeParams
): string | null => {
  const { account } = params
  const { business } = account
  const gatewayItems = business?.universe?.gateways?.items
  if (!gatewayItems) {
    return null
  }
  if (
    (process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'test') &&
    gatewayItems[0]
  ) {
    return gatewayItems[0].domain.theme
  }
  const currentGateway = gatewayItems.find(
    (gatewayItem) => gatewayItem.domain.name === window.location.hostname
  )
  if (!currentGateway || !currentGateway.domain.theme) {
    return null
  }
  return currentGateway.domain.theme
}

export default getGatewayDomainTheme
