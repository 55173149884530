import { deepmerge } from '@mui/utils'
import { Theme } from '@mui/material/styles'
import defaultTheme from 'theme/defaultTheme'

interface GetThemeParams {
  themes: (string | null)[]
}

const getTheme = (params: GetThemeParams) => {
  const { themes: defaultThemes } = params

  const filteredThemes = defaultThemes.filter(
    (theme) => theme !== null
  ) as string[]
  const themes = filteredThemes.map((theme) => JSON.parse(theme) as Theme)
  const parsedThemes = [defaultTheme, ...themes]
  const mergedThemes = parsedThemes.reduce((acc, theme) =>
    deepmerge(acc, theme)
  )

  return mergedThemes
}

export default getTheme
