import React, {
  useState,
  ChangeEvent,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import { ListItemIcon, IconButton, Pagination } from '@mui/material'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { groupByNumber } from 'lib/arrays'
import Delete from 'icons/Line/delete'
import { FileData, handleChangeParams } from '../types'
import * as S from '../input.styled'

type FileListProps = {
  fieldName: string
  paginateBy?: number
  disabled?: boolean
  handleChange: ({ files, eventName }: handleChangeParams) => void
  listFiles?: (File | FileData)[]
  setListFiles: Dispatch<SetStateAction<(File | FileData)[]>>
}

type HandleDeleteParams = FileListProps & {
  fileName: string
}

const handleDelete = ({
  fileName,
  fieldName,
  setListFiles,
  handleChange,
}: HandleDeleteParams) => {
  setListFiles((prevListFiles) => {
    const files = prevListFiles?.filter((file: any) => file.name !== fileName)
    handleChange({ files, eventName: fieldName })

    return files
  })
}

const FileList = ({
  fieldName,
  handleChange,
  listFiles = [],
  setListFiles,
  paginateBy = 10,
  disabled = false,
}: FileListProps) => {
  const groupedFiles = groupByNumber(listFiles, paginateBy)
  const [page, setPage] = useState(1)
  const [filesPerPage, setFilesPerPage] = useState([])

  useEffect(() => {
    if (!Boolean(groupedFiles[page - 1]) && groupedFiles.length) {
      setFilesPerPage(groupedFiles[page - 2] || [])
      setPage(page - 1)
    } else {
      setFilesPerPage(groupedFiles[page - 1] || [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listFiles])

  const handlePagination = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
    setFilesPerPage(groupedFiles[value - 1] || [])
  }
  return (
    <>
      <TransitionGroup
        component="ul"
        style={{
          padding: 0,
          maxHeight: `${S.ITEM_HEIGHT * paginateBy}px`,
          overflow: 'hidden',
        }}
      >
        {filesPerPage.map(({ name }: any) => (
          <CSSTransition key={name} timeout={500} classNames="item">
            <S.ListItem>
              <ListItemIcon>
                <IconButton
                  disabled={disabled}
                  onClick={() =>
                    handleDelete({
                      fileName: name,
                      fieldName,
                      handleChange,
                      setListFiles,
                    })
                  }
                >
                  <Delete
                    color={
                      disabled
                        ? 'var(--color-grey-500)'
                        : 'var(--complementary-red)'
                    }
                  />
                </IconButton>
              </ListItemIcon>
              <S.ListItemText primary={name} />
            </S.ListItem>
          </CSSTransition>
        ))}
      </TransitionGroup>
      {Boolean(groupedFiles.length > 1) && (
        <Pagination
          count={groupedFiles.length}
          page={page}
          onChange={handlePagination}
        />
      )}
    </>
  )
}

export default FileList
