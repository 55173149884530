import dayjs from 'dayjs'
import { Mapping } from 'lib/reports/types'

const transformStringsToDates = (value: Mapping) => {
  const stringValue = value.toString()
  const [startDate, endDate] = stringValue.split(',')

  if (
    !dayjs(startDate, 'YYYY-MM-DD').isValid() ||
    !dayjs(endDate, 'YYYY-MM-DD').isValid()
  ) {
    throw new Error(
      'Invalid date format, dates must be expressed in YYYY-MM-DD format'
    )
  }
  return [new Date(startDate), new Date(endDate)]
}

export default transformStringsToDates
