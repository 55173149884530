import { styled } from '@mui/material/styles'
import { keyframes } from '@emotion/react'
import { Fab as MaterialFab } from '@mui/material'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

type FabProps = {
  isFabVisible: boolean
}

export const Fab = styled(MaterialFab)<FabProps>(({ isFabVisible }) => ({
  position: 'fixed',
  bottom: '1.5rem',
  left: '1.5rem',
  width: '52px',
  height: '52px',
  boxShadow: 'var(--box-shadow-m)',
  borderRadius: 'var(--border-radius-m)',
  opacity: isFabVisible ? 1 : 0,
  animation: `${isFabVisible ? fadeIn : fadeOut} 0.3s forwards`,
}))
