import actionFragment from 'gql/fragments/action'

const getAction = /* GraphQL */ `
  ${actionFragment}
  query getAction($actionId: ID!) {
    getAction(actionId: $actionId) {
      ...ActionFields
    }
  }
`

export default getAction
