import sum from './sum'

interface AvgParams {
  data: number[]
  count: number
}

const avg = (params: AvgParams) => {
  const { data, count } = params

  return sum({ data }) / count
}

export default avg
