import React, { useState } from 'react'
import FilterDataSetComponent from 'components/FilterDataSet'
import Button from 'components/Button'
import useUpdateReportDataSets from 'contexts/ReportDataSets/hooks/useUpdateReportDataSets'
import generateFilters from './generateFilters'
import { formatMessage } from 'i18n/ShimokuIntl'
import { FilterValues } from './types'
import { FilterDataSetProperties } from 'lib/reports/types'
import * as S from './FilterDataSet.styled'
import {
  NUMERIC_OPERATORS,
  NumericFilterOperatorType,
} from 'containers/Table/types'

interface FilterDataSetProps {
  report: Report
  reportProperties: FilterDataSetProperties
}

const FilterDataSet = ({
  reportProperties: { filter, mapping },
}: FilterDataSetProps) => {
  if (!filter) {
    throw new Error(
      formatMessage('errors.filterDataSetProperties.filter.undefined')
    )
  }

  if (!mapping) {
    throw new Error(
      formatMessage('errors.filterDataSetProperties.mapping.undefined')
    )
  }

  const defaultFilterValues: FilterValues = filter.reduce(
    (filterValues, filterItem) => ({
      ...filterValues,
      [filterItem.field]: undefined,
    }),
    {}
  )
  const numericOperators = Object.values(NUMERIC_OPERATORS)
  const [defaultOperator] = numericOperators
  const [filterValues, setFilterValues] = useState(defaultFilterValues)
  const [operator, setOperator] =
    useState<NumericFilterOperatorType>(defaultOperator)

  const { updateReportsDataSetsByDataSetIds } = useUpdateReportDataSets()
  const filters = generateFilters({ filter, mapping, filterValues, operator })

  const handleClick = () => {
    updateReportsDataSetsByDataSetIds({ filters })
  }

  return (
    <S.Container>
      {filter.map((filterItem, i) => (
        <FilterDataSetComponent
          key={`${filterItem.field}-${i}`}
          filter={filterItem}
          setFilterValues={setFilterValues}
          setOperator={setOperator}
        />
      ))}
      <Button onClick={handleClick}>{formatMessage('generic.filter')}</Button>
    </S.Container>
  )
}

export default FilterDataSet
