import React, { FC, useState, useEffect } from 'react'
import { Grow, ButtonProps } from '@mui/material'
import { TIMEOUTS } from 'lib/app-timeouts'
import CloudDownload from 'icons/Line/cloud-download'
import { REPORT_TYPES, getDownloadFileURL, getReportType } from 'lib/reports'
import styles from './DownloadReportButton.module.css'
import globalStyles from 'material/global.module.css'
import { formatMessage } from 'i18n/ShimokuIntl'
import { useSelector } from 'react-redux'
import { selectAccount } from '../../redux/account'
import Button from 'components/Button'
import classNames from 'classnames'

const DownloadReportButton: FC<WithReport & ButtonProps> = ({
  report,
  className,
}) => {
  const [downloadURL, setDownloadURL] = useState<string | undefined>()
  const account = useSelector(selectAccount)
  const generateDownloadURL = async () =>
    setDownloadURL(await getDownloadFileURL(account, report))

  useEffect(() => {
    getReportType(report) === REPORT_TYPES.TABLE &&
      setTimeout(generateDownloadURL, TIMEOUTS.DOWNLOAD_REPORT_BUTTON)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (getReportType(report) !== REPORT_TYPES.TABLE || !downloadURL) return null

  return (
    <Grow in={true}>
      <Button
        size="medium"
        variant="outlined"
        href={downloadURL}
        classes={{
          root: classNames(styles.button, globalStyles.ellipsisText, className),
        }}
      >
        <CloudDownload />
        {formatMessage('generic.download')}
      </Button>
    </Grow>
  )
}

export default DownloadReportButton
