import { RadarSeriesOption } from 'echarts'
import { TEXT_STYLE, BORDER_STYLE, SHADOW_STYLE } from '../../defaults/general'
import { TypeSeriesParams } from '../../types'

const radarSerie = (
  params: TypeSeriesParams<RadarSeriesOption>
): RadarSeriesOption => {
  const { typeSeriesModifications: radarSeriesModifications = {}, theme } =
    params

  return {
    type: 'radar',
    symbol: 'circle',
    symbolSize: 4,
    symbolOffset: [0, 0],
    selectedMode: false,
    ...radarSeriesModifications,
    label: {
      show: false,
      position: 'top',
      distance: 4,
      rotate: 0,
      offset: [0, 0],
      ...TEXT_STYLE(theme),
      fontSize: 12,
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      ...radarSeriesModifications?.label,
    },
    labelLayout: {
      hideOverlap: false,
      ...radarSeriesModifications?.labelLayout,
    },
    itemStyle: {
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      opacity: 1,
      ...radarSeriesModifications?.itemStyle,
    },
    lineStyle: {
      width: 2,
      type: 'solid',
      ...SHADOW_STYLE,
      opacity: 1,
      ...radarSeriesModifications?.lineStyle,
    },
    areaStyle: {
      ...SHADOW_STYLE,
      opacity: 0,
      ...radarSeriesModifications?.areaStyle,
    },
    emphasis: {
      focus: 'none',
      ...radarSeriesModifications?.emphasis,
    },
  }
}

export default radarSerie
