import { ElementType } from 'react'
import { styled } from '@mui/material/styles'
import { Menu as MenuMUI, Typography } from '@mui/material'

const commonIconStyles = {
  width: 100,
  height: '100%',
  maxWidth: '1.5rem',
  maxHeight: '1.5rem',
  color: 'inherit',
}

export const Menu = styled(MenuMUI)(
  ({
    theme: {
      palette: { mode },
    },
  }) => ({
    '& .MuiPaper-root': {
      width: '20rem',
      maxWidth: 'calc(100vw - 2rem)',
      padding: '0',
      marginTop: '1rem',
      borderRadius: 'var(--border-radius-m)',
      zIndex: 'var(--zIndex-l)',
      overflow: 'hidden',
      ...(mode === 'dark' && {
        border: '1px solid var(--divider)',
      }),
    },

    '& .MuiList-root': {
      padding: '0',
    },
  })
)

export const MenuButton = styled('button')(() => ({
  animation: 'none',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  fontSize: '1rem',
  width: 'auto',
  height: 'auto',
  margin: '0',
  padding: '0',
  outline: '0',
  transition: 'none',
  color: 'inherit',
  display: 'flex',
}))

export const ContainerUserIcon = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'var(--color-primary)',
  padding: '0.5rem',
  borderRadius: '50%',
}))

export const MenuGroupContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  textAlign: 'left',
  transition: 'var(--transition)',
  padding: '12px 32px 0 24px',
  backgroundColor: 'var(--color-white)',
  color: 'var(--color-black)',
  borderRadius: `var(--border-radius-m) var(--border-radius-m) 0 0`,
}))

type ListMenuItemsProps = {
  maxheight?: string
}

export const MenuDivider = styled('hr')(() => ({
  borderTop: 'none',
  borderRight: 'none',
  borderBottom: '1px solid var(--divider)',
  borderLeft: 'none',
  marginBottom: 0,
  opacity: 0.6,
}))

export const ListMenuItems = styled('div')<ListMenuItemsProps>(
  ({ maxheight = ' none' }) => ({
    maxHeight: maxheight,
    overflowY: 'auto',
  })
)

type MenuItemProps = {
  haveIcon?: boolean
  position?: 'first' | 'last'
  highlighted?: boolean
  active?: boolean
}

export const MenuItem = styled('button')<MenuItemProps>(
  ({
    theme: {
      palette: { mode },
    },
    haveIcon,
    position,
    highlighted = false,
    active = false,
  }) => ({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '16px 24px',
    textAlign: 'left',
    transition: 'var(--transition)',
    color: 'inherit',
    backgroundColor: 'var(--color-white)',
    border: 'none',

    ...(mode === 'dark' && {
      backgroundColor: 'var(--color-grey-800)',
    }),
    ...(haveIcon && {
      padding: '12px 24px',
    }),
    ...(position === 'first' && {
      borderRadius: `var(--border-radius-m) var(--border-radius-m) 0 0`,
    }),
    ...(position === 'last' && {
      borderRadius: `0 0 var(--border-radius-m) var(--border-radius-m)`,
    }),
    '&:hover': {
      backgroundColor: 'var(--color-grey-200)',
      ...(mode === 'dark' && {
        backgroundColor: 'var(--color-grey-700)',
      }),
    },
    ...(highlighted && {
      width: 'calc(100% - 2rem)',
      background: 'var(--stripe-highlight-gradient)',
      color: 'var(--color-white)',
      justifyContent: 'center',
      margin: '0 auto',
      borderRadius: 'var(--border-radius-m)',
    }),
    ...(active && {
      width: 'calc(100% - 2rem)',
      backgroundColor: 'var(--color-primary)',
      borderRadius: 'var(--border-radius-m)',
      color: 'var(--color-white)',
      margin: '0 auto',
      '&: hover': {
        backgroundColor: 'var(--color-primary-dark)',
      },
      '&:last-child': {
        marginBottom: '1rem',
      },
    }),
  })
)

type MenIconProps = {
  position?: 'left' | 'right'
  highlighted?: boolean
}

export const MenuIcon = styled('span')<MenIconProps>(
  ({ position, highlighted = false }) => ({
    lineHeight: 0,
    color: 'inherit',
    ...(position === 'left' && {
      '> svg': {
        marginRight: highlighted ? '0.5rem' : '1.5rem',
        ...commonIconStyles,
      },
    }),
    ...(position === 'right' && {
      '> svg': {
        marginLeft: highlighted ? '0.5rem' : '1.5rem',
        ...commonIconStyles,
      },
    }),
  })
)

type TextProps = {
  component?: ElementType
}

export const Text = styled(Typography)<TextProps>(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  margin: 0,
}))
