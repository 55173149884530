import { EChartsOption, SeriesOption } from 'echarts'
import addOptionModifications from '../../addOptionModifications'
import defaultOptionModifications from './defaultOptionModifications'
import titleOption from '../../defaults/title'
import tooltipOption from '../../defaults/tooltip'
import toolboxOption, { getDefaultToolbox } from '../../defaults/toolbox'
import gridOption from '../../defaults/grid'
import generatePaddingGrid from '../generatePaddingGrid'
import {
  PADDING_GRID_TOP,
  PADDING_GRID_RIGHT,
  PADDING_GRID_BOTTOM,
  PADDING_GRID_LEFT,
} from './grid'
import xAxisOption, { getDefaultXAxis } from '../../defaults/xAxis'
import yAxisOption, { getDefaultYAxis } from '../../defaults/yAxis'
import visualMapOption from '../../defaults/visualMap'
import seriesOption from './series'
import { Option, ChartParams, VisualMapType } from '../../types'
import validObject from '../validObject'

const heatmapOption = (params: ChartParams): EChartsOption => {
  const {
    theme,
    title = '',
    subtitle = '',
    tooltip = true,
    toolbox = getDefaultToolbox(theme),
    xAxis = getDefaultXAxis(theme),
    yAxis = { ...getDefaultYAxis(theme), type: 'category' },
    visualMap = 'piecewise',
    data = [{}],
    optionModifications = {},
  } = params

  const paddingGrid = generatePaddingGrid({
    allChartProperties: {
      title: Boolean(title),
      subtitle: Boolean(subtitle),
      toolbox: validObject({ object: toolbox }),
      yAxisName: validObject({ object: xAxis, ignoreKeys: ['type'] }),
      xAxisName: validObject({ object: yAxis, ignoreKeys: ['type'] }),
    },
    gridSizes: {
      top: PADDING_GRID_TOP,
      left: PADDING_GRID_LEFT,
      bottom: PADDING_GRID_BOTTOM,
      right: PADDING_GRID_RIGHT,
    },
  })

  const seriesModifications = optionModifications?.series as SeriesOption

  const defaultOption: Option = {
    title: titleOption({ title, subtitle, theme }),
    tooltip: tooltipOption({ tooltip, theme }),
    toolbox: toolboxOption({ ...toolbox, theme }),
    grid: gridOption({ paddingGrid }),
    xAxis: xAxisOption({ ...xAxis, theme }),
    yAxis: yAxisOption({ ...yAxis, theme }),
    visualMap: visualMapOption({ type: visualMap as VisualMapType, theme }),
    series: seriesOption({ data, seriesModifications, theme }),
  }

  const heatmapOption = addOptionModifications({
    defaultOption,
    defaultOptionModifications: defaultOptionModifications({ data }),
    optionModifications,
  })

  return heatmapOption
}

export default heatmapOption
