import React, { useState, Dispatch, SetStateAction, FC } from 'react'

import Select from 'components/Input/Select'
import { FilterInput, FilterValues } from 'containers/FilterDataSet/types'
import {
  NUMERIC_OPERATORS,
  NumericFilterOperatorType,
} from 'containers/Table/types'
import { HandleInputValues } from 'components/Input/Select'
import getOperator from 'containers/Table/utils/getFilter/getOperator'
import { handleNumericFilter } from './filterHandlers'
import * as S from './NumericFilter.styled'

interface NumericFilterProps {
  filter: FilterInput
  setFilterValues: Dispatch<SetStateAction<FilterValues>>
  setOperator: Dispatch<SetStateAction<NumericFilterOperatorType>>
}

export const NumericFilter: FC<NumericFilterProps> = ({
  filter,
  setFilterValues,
  setOperator,
}) => {
  const numericOperators = Object.values(NUMERIC_OPERATORS)
  const [defaultOperator] = numericOperators
  const initialOperator = getOperator(defaultOperator)

  const [filterOperator, setFilterOperator] =
    useState<NumericFilterOperatorType>(initialOperator)
  const [filterValue, setFilterValue] = useState<number | null>(null)

  const applyFilter = (value: number, operator: NumericFilterOperatorType) => {
    handleNumericFilter({
      value,
      filter,
      operator,
      setFilterValues,
      setOperator,
    })
  }

  const handleOperatorChange = ({ value }: HandleInputValues) => {
    if (!value) return

    const operator = getOperator(value)
    setFilterOperator(operator)

    if (filterValue) {
      applyFilter(filterValue, operator)
    }
  }

  const handleValueChange = (event: any) => {
    const value = Number(event.target.value)
    setFilterValue(value)
    applyFilter(value, filterOperator)
  }

  return (
    <S.FilterWrapper>
      <Select
        options={numericOperators}
        labelId={filter.field}
        label=""
        id={`operator-${filter.field}`}
        defaultValue={defaultOperator}
        value={[defaultOperator]}
        textAlign="center"
        minWidth="48px"
        hideArrow
        name={`operator-${filter.field}`}
        handleInputChange={handleOperatorChange}
      />
      <S.ValueInput type="number" onChange={handleValueChange} />
    </S.FilterWrapper>
  )
}
