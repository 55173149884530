import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store/types'
import { Modal } from './types'

const initialState: Modal = {
  open: false,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalState: (state, action: PayloadAction<Modal>) => ({
      ...state,
      ...action.payload,
    }),
  },
})

export const selectModalState = (state: RootState) => state.modal

export const { setModalState } = modalSlice.actions

export default modalSlice.reducer
