import { DateRange } from '@mui/x-date-pickers-pro'
import dayjs from 'dayjs'

const getFormatedDates = (value: DateRange<any>) => {
  const startDate: Date = value[0]['$d']
  const endDate: Date = value[1]['$d']
  return [
    dayjs(startDate).format('YYYY-MM-DD'),
    dayjs(endDate).format('YYYY-MM-DD'),
  ]
}

export default getFormatedDates
