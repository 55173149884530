const alignIndicator = (align: 'left' | 'center' | 'right') => {
  switch (align) {
    case 'left':
      return 'flex-start'
    case 'center':
      return 'center'
    case 'right':
      return 'flex-end'
    default:
      return 'flex-start'
  }
}

export default alignIndicator
