import React, { FC, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import useQueryUrlParams from '../../hooks/useQueryUrlParams'
import { INVITATION_TOKEN_PARAM } from 'lib/domains'
import { useSnackbar } from 'notistack'
import Sentry from 'tracking/Sentry'
import { getErrorMessage } from 'lib/errors'
import { sanitizeString } from 'lib/strings'
import { SignUpStepOne, SignUpStepTwo } from 'components/SignUp'
import { usePostHog } from 'posthog-js/react'
import { USER_EVENTS } from 'lib/postHogEvents'

export const UNSETTLED = 'unsettled'

export interface SignUpFormValues {
  email: string
  password: string
  repassword: string
  termsAndConditions: boolean
}

const SignUp: FC<RouteComponentProps> = ({ history }) => {
  const { REACT_APP_AUTH_CONFIRMATION_TYPE } = process.env
  const { enqueueSnackbar } = useSnackbar()
  const posthog = usePostHog()
  const invitationToken = useQueryUrlParams().get(INVITATION_TOKEN_PARAM)
  const [sendedLink, setSendedLink] = useState(false)
  const [step, setStep] = useState(1)
  const [formValues, setFormValues] = useState<SignUpFormValues>({
    email: '',
    password: '',
    repassword: '',
    termsAndConditions: true,
  })

  const onSubmitSuccess = (email: string) => {
    if (REACT_APP_AUTH_CONFIRMATION_TYPE === 'CODE') {
      return history.replace(`/confirm-account-code?email=${email}&success=1`)
    }
    setSendedLink(true)
  }

  const handleFirstStepSubmit = (values: Partial<SignUpFormValues>) => {
    setFormValues({ ...formValues, ...values })
    posthog?.capture(USER_EVENTS.SIGN_UP_FIRST_STEP)
    setStep(2)
  }

  const goToStepOne = () => setStep(1)

  const handleSecondStepSubmit = async (values: Partial<SignUpFormValues>) => {
    try {
      const updatedValues = { ...formValues, ...values }
      const [localPartOfEmail] = updatedValues.email.split('@')
      const sanitizedLocalPart = sanitizeString(localPartOfEmail)
      const signUpVariables = {
        username: updatedValues.email,
        password: updatedValues.password,
        attributes: {
          name: `${sanitizedLocalPart}-${UNSETTLED}`,
          'custom:company': sanitizedLocalPart,
          'custom:domainUrl': window.location.host,
          ...(invitationToken && { 'custom:invitationToken': invitationToken }),
        },
      }

      await Auth.signUp(signUpVariables)
      onSubmitSuccess(updatedValues.email)
      posthog?.capture(USER_EVENTS.SIGN_UP)
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error, error.message), {
        variant: 'error',
      })
      Sentry.captureException(error)
    }
  }

  return step === 1 ? (
    <SignUpStepOne handleSubmit={handleFirstStepSubmit} />
  ) : (
    <SignUpStepTwo
      handleSubmit={handleSecondStepSubmit}
      goToStepOne={goToStepOne}
      sendedLink={sendedLink}
    />
  )
}

export default SignUp
