export const sortBy =
  <T extends AnyObject>(key: string | number) =>
  (prev: T, next: T): -1 | 1 | 0 => {
    if (prev[key] < next[key]) {
      return -1
    } else if (prev[key] > next[key]) {
      return 1
    } else {
      return 0
    }
  }

export const groupByNumber = (array: any[], size: number) => {
  const itemsGrouped = array.reduce((acc: any, item, index) => {
    const groupIndex = Math.floor(index / size)
    if (!acc[groupIndex]) {
      acc[groupIndex] = []
    }
    acc[groupIndex].push(item)
    return acc
  }, [])
  return itemsGrouped
}
