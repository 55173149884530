import { Theme } from '@mui/material/styles'
import { ToolboxComponentOption } from 'echarts'
import { ICON_STYLE } from './general'
import { ToolboxParams } from '../types'

export const getDefaultToolbox = (theme: Theme): ToolboxParams => ({
  theme,
  saveAsImage: false,
  restore: false,
  dataView: false,
  dataZoom: false,
  magicType: false,
})

const toolbox = (params: ToolboxParams): ToolboxComponentOption => {
  const {
    theme,
    saveAsImage: showSaveAsImage = false,
    restore: showRestore = false,
    dataView: showDataView = false,
    dataZoom: showDataZoom = false,
    magicType: showMagicType = false,
  } = params
  const CHART_OPTIONS = theme.custom.chart.options

  return {
    id: '',
    show: true,
    orient: 'horizontal',
    itemSize: 16,
    itemGap: 8,
    showTitle: true,
    left: 'auto',
    right: 0,
    top: 0,
    feature: {
      saveAsImage: {
        show: showSaveAsImage,
        type: 'png',
        name: 'chart-image',
        backgroundColor: 'auto',
        connectedBackgroundColor: '#fff',
        excludeComponents: ['toolbox'],
        title: 'Save as image',
      },
      restore: {
        show: showRestore,
        title: 'Restore',
      },
      dataView: {
        show: showDataView,
        title: 'Data View',
        readOnly: true,
        textareaColor: '#fff',
        textareaBorderColor: '#fff',
        textColor: CHART_OPTIONS.text,
        lang: ['Data view', 'Turn off', 'Refresh'],
        backgroundColor: 'var(--color-white)',
        buttonColor: 'var(--color-primary)',
        buttonTextColor: 'var(--color-white)',
      },
      dataZoom: {
        show: showDataZoom,
        title: {
          zoom: 'Area zooming',
          back: 'Restore area zooming',
        },
      },
      magicType: {
        show: showMagicType,
        type: ['line', 'bar', 'stack'],
        title: {
          line: 'Switch to Line Chart',
          bar: 'Switch to Bar Chart',
          stack: 'Stack',
          tiled: 'Tile',
        },
      },
    },
    iconStyle: {
      ...ICON_STYLE(theme),
    },
    emphasis: {
      iconStyle: {
        ...ICON_STYLE(theme),
      },
    },
  }
}

export default toolbox
