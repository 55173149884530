import { NumericFilterOperatorType } from 'containers/Table/types'
import generateOperations from './generateOperations'
import { FilterInput, FilterMapping, FilterValues, Filter } from './types'

interface GenerateFiltersParams {
  filter: FilterInput[]
  mapping: FilterMapping[]
  filterValues: FilterValues
  operator: NumericFilterOperatorType
}

const GenerateFilters = (params: GenerateFiltersParams) => {
  const { filter: defaultFilter, mapping, filterValues, operator } = params
  return mapping.map((mappingItem) => {
    const { id: dataSetId } = mappingItem
    const filter = defaultFilter.reduce((filter, filterItem) => {
      return {
        ...filter,
        [mappingItem[filterItem.field]]: generateOperations({
          filter: filterItem,
          filterValues,
          operator,
        }),
      }
    }, {})

    return {
      dataSetId,
      filter,
    } as Filter
  })
}

export default GenerateFilters
