import React, { memo, FC } from 'react'
import { FormControl } from '@mui/material'
import classNames from 'classnames'
import DownloadDataTable from './DownloadDataTable'
import SelectFilter from './SelectFilter'
import SearchFilter from './SearchFilter'
import styles from './ReportTable.module.css'
import { FetchAllDataParams } from '.'

interface ReportTableFiltersProps extends React.HTMLAttributes<HTMLDivElement> {
  reportId: string
  dataFields: ReportTableDataFields
  filter: EqualityFilter
  loading?: boolean
  onFilter: (field: string) => (event: any) => void
  fetchAllData: ({ rows, customLimit }: FetchAllDataParams) => Promise<any>
  downloadable?: boolean
  reportTitle?: string
}

const ReportTableFilters: FC<ReportTableFiltersProps> = memo(
  ({
    reportId,
    dataFields,
    filter,
    onFilter,
    className,
    loading,
    downloadable,
    reportTitle,
    fetchAllData,
  }) => {
    return (
      <div className={classNames(styles.filters, className)}>
        {Object.keys(dataFields).map((cell: string) => {
          const field = dataFields[cell]
          return field?.field && (field?.filterBy || field?.type) ? (
            <FormControl
              key={field.field}
              variant="standard"
              size="small"
              className={styles.formControl}
            >
              {field.type === 'search' && (
                <SearchFilter
                  cell={cell}
                  field={field}
                  type={field.type}
                  loading={loading}
                  onFilter={onFilter}
                  reportId={reportId}
                />
              )}
              {field.filterBy && (
                <SelectFilter
                  cell={cell}
                  field={field}
                  filter={filter}
                  loading={loading}
                  onFilter={onFilter}
                  reportId={reportId}
                />
              )}
            </FormControl>
          ) : null
        })}
        {downloadable && (
          <DownloadDataTable
            reportTitle={reportTitle}
            fetchAllData={fetchAllData}
          />
        )}
      </div>
    )
  }
)

export default ReportTableFilters
