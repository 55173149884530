import React, { memo, FunctionComponent, useEffect, useCallback } from 'react'
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  IconButton,
  LinearProgress,
  Alert,
} from '@mui/material'
import { API, graphqlOperation } from 'aws-amplify'
import { useComponentState, useLayoutState } from 'hooks/components'
import ChevronRight from 'icons/Line/chevron-right'
import ChevronLeft from 'icons/Line/chevron-left'
import ReportTableFilters from './ReportTableFilters'
import DownloadReportButton from 'components/DownloadReportButton/DownloadReportButton'
import { getColumnFixedClassName } from 'lib/reports'
import ReportTableHead from './ReportTableHead'
import globalStyles from 'material/global.module.css'
import { formatMessage } from 'i18n/ShimokuIntl'
import styles from './ReportTable.module.css'
import classNames from 'classnames'
import Sentry from 'tracking/Sentry'
import withErrorWrapper from 'hocs/withErrorWrapper'
import getOrder from './getOrder'
import { useSnackbar } from 'notistack'
import Chip from 'components/Chip'
import { setCompatibleLabelProperties } from './helpers/label'
import ConditionalWrapper from 'hocs/conditionalWrapper'
import { OldTableProperties } from 'lib/reports/types'

const ROWS_PER_PAGE = 10
const ROWS_PER_QUERY = 100

export const searchReportEntrysQuery = `
query searchReportEntrys($reportId: ID!, $filter: SearchableReportEntryFilterInput, $sort: SearchableReportEntrySortInput, $limit: Int, $from: Int) {
  listReportEntries(reportId: $reportId, filter: $filter, sort: $sort, limit: $limit, from: $from) {
    total
    items {
      id
      data
      description
    }
  }
}`

interface ReportTableProps {
  report: Report
  reportProperties: OldTableProperties
}

export interface FetchAllDataParams {
  rows?: any[]
  customLimit?: number
}

/**
 * TODO: REFACTOR this component is too complex
 */
const ReportTable: FunctionComponent<ReportTableProps> = memo(
  ({ report, reportProperties }) => {
    const { dataFields: defaultDataFields } = report
    const { matches: isTablet } = useLayoutState('sm')
    const { enqueueSnackbar } = useSnackbar()
    const reportEntryMapper = ({ id, data, description = '' }: any) => ({
      ...(JSON.parse(data) || {}),
      description,
      id,
    })

    if (!defaultDataFields) {
      throw new Error(formatMessage('errors.dataFieldsUndefined'))
    }

    const dataFields = JSON.parse(defaultDataFields)
    const rowsPerPage = reportProperties?.rowsPerPage || ROWS_PER_PAGE
    const { order, orderBy } = getOrder({ dataFields })
    const defaultFilter = { and: [{ or: [{ reportId: { eq: report.id } }] }] }

    const defaultState: any = {
      order,
      orderBy,
      loading: true,
      filter: { ...defaultFilter },
      isLastPage: false,
      page: 0,
      rows: [],
      total: 0,
    }

    const [state, setState] = useComponentState(defaultState)
    const handleRequestSort = useCallback(
      (property: string) => {
        setState({
          order:
            state.orderBy === property && state.order === 'asc'
              ? 'desc'
              : 'asc',
          orderBy: property,
          loading: true,
        })
      },
      [state.orderBy, state.order, setState]
    )

    const filterSelect = (field: string, eventValue: string[]) => {
      const currentFilter = { ...state.filter }
      const formattedValues = eventValue.map((value: string) => ({
        [field]: { eq: value },
      }))
      const otherValues = currentFilter.and.filter(
        (orItem: any) => !orItem.or[0].hasOwnProperty(field)
      )
      const allValues = formattedValues.length
        ? [...otherValues, { or: [...formattedValues] }]
        : [...otherValues]
      if (!Boolean(allValues.length)) {
        return setState({ filter: defaultFilter, loading: true })
      }
      const newFilter = Object.assign({}, defaultFilter)
      const withoutDuplicates = allValues.filter(
        (item: any, index: number) =>
          allValues.findIndex(
            (item2: any) => JSON.stringify(item) === JSON.stringify(item2)
          ) === index
      )
      newFilter.and = withoutDuplicates
      return setState({ filter: newFilter, loading: false, page: 0 })
    }

    const filterSearch = (field: string, eventValue: string[]) => {
      const currentFilter = { ...state.filter }
      const unifiedValues = eventValue.join('')
      if (Boolean(!unifiedValues.length)) {
        return setState({ filter: defaultFilter, loading: true })
      }
      const formattedValues = {
        [field]: { matchPhrasePrefix: unifiedValues },
      }
      const otherValues = currentFilter.and.filter(
        (orItem: any) => !orItem.or[0].hasOwnProperty(field)
      )
      const newFilter = Object.assign({}, defaultFilter)
      const allValues = [...otherValues, { or: [formattedValues] }]
      newFilter.and = allValues
      return setState({ filter: newFilter, loading: false, page: 0 })
    }

    const handleFilter = (field: string, type?: string) => (event: any) => {
      const eventValue: string[] = Array.from(event.target.value)

      if (type === 'search') {
        return filterSearch(field, eventValue)
      }

      return filterSelect(field, eventValue)
    }

    const isLastPage = () => {
      const { rows } = state
      return rows.length < rowsPerPage
    }

    let request: any
    let unmounted: any

    const fetchData = useCallback(async () => {
      const { page } = state
      setState({ loading: true })
      try {
        const variables = {
          reportId: report.id,
          filter: state.filter,
          sort: Boolean(state.orderBy)
            ? { field: state.orderBy, direction: state.order }
            : null,
          limit: rowsPerPage,
          from: page * rowsPerPage,
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        request = API.graphql(
          graphqlOperation(searchReportEntrysQuery, variables)
        ) // eslint-disable-line
        const {
          data: {
            listReportEntries: { items, total },
          },
        }: any = await request

        if (items && !unmounted) {
          setState({
            total,
            loading: false,
            rows: items.map(reportEntryMapper),
          })
        }
      } catch (error) {
        Sentry.captureException(error)
        enqueueSnackbar(formatMessage('errors.fetchData'))
        setState({ loading: false })
      }
    }, [setState, state.filter, state.orderBy, state.order, report.id])

    const fetchAllData = async ({
      rows,
      customLimit,
    }: FetchAllDataParams): Promise<any> => {
      let allRows = rows || []
      try {
        const variables = {
          reportId: report.id,
          filter: state.filter,
          sort: Boolean(state.orderBy)
            ? { field: state.orderBy, direction: state.order }
            : null,
          from: allRows.length,
          limit: customLimit || ROWS_PER_QUERY,
        }
        const {
          // @ts-ignore
          data: { listReportEntries },
        } = await API.graphql(
          graphqlOperation(searchReportEntrysQuery, variables)
        )
        const newRows = listReportEntries.items.map(reportEntryMapper)
        allRows = [...allRows, ...newRows]
        if (allRows.length < listReportEntries.total) {
          return fetchAllData({ rows: allRows })
        }
      } catch (error) {
        Sentry.captureException(error)
        enqueueSnackbar(formatMessage('errors.fetchData'))
        return error
      }
      return allRows
    }

    const onPrevPage = () => {
      const { page } = state
      setState({ loading: true, page: page - 1 })
    }

    const onNextPage = () => {
      const { page } = state
      setState({ loading: true, page: page + 1 })
    }

    useEffect(() => {
      fetchData()
      return () => {
        API.cancel(request)
        unmounted = true // eslint-disable-line
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.order, state.orderBy, state.filter, state.page])
    return (
      <>
        <div className={classNames(styles.reportActions)}>
          <DownloadReportButton
            report={report}
            className={styles.downloadButton}
          />
        </div>
        <ReportTableFilters
          reportId={report.id}
          reportTitle={report.title}
          loading={state.loading}
          dataFields={dataFields}
          filter={state.filter}
          onFilter={handleFilter}
          fetchAllData={fetchAllData}
          className={state.loading ? globalStyles.disabled : ''}
          downloadable={reportProperties?.downloadable}
        />
        <TableContainer component={Paper} className={styles.tableContainer}>
          {state.loading ? (
            <LinearProgress className={styles.loader} />
          ) : (
            <div className={styles.loader} />
          )}
          <Table
            aria-label="report table"
            size={isTablet ? 'medium' : 'small'}
            className={state.loading ? globalStyles.disabled : ''}
          >
            <ReportTableHead
              dataFields={dataFields}
              order={state.order}
              orderBy={state.orderBy}
              onSort={handleRequestSort}
            />
            <TableBody>
              {!Boolean(state.rows.length) ? (
                <TableRow>
                  <TableCell colSpan={Object.keys(dataFields).length + 1}>
                    {!state.loading && (
                      <Alert severity="warning">
                        {!Boolean(isLastPage())
                          ? formatMessage('generic.noFilterResults')
                          : formatMessage('generic.noMoreResults')}
                      </Alert>
                    )}
                  </TableCell>
                </TableRow>
              ) : (
                state.rows.map((row: any) => (
                  <TableRow key={`row:${row.id}`} className={styles.tableRow}>
                    {Object.keys(dataFields).map(
                      (cell: string, index: number) => {
                        const label =
                          dataFields[cell]?.isLabel &&
                          setCompatibleLabelProperties(
                            dataFields[cell],
                            row[cell]
                          )
                        return (
                          <TableCell
                            key={`${cell}:${row.id}`}
                            align={index > 0 ? 'right' : 'left'}
                            className={classNames(
                              getColumnFixedClassName(row[cell])
                            )}
                          >
                            <ConditionalWrapper
                              condition={Boolean(label)}
                              wrapper={(children) => (
                                <Chip
                                  color={label[row[cell]].color}
                                  radius={label[row[cell]].radius}
                                  variant={label[row[cell]].variant}
                                >
                                  {children}
                                </Chip>
                              )}
                            >
                              {row[cell]}
                            </ConditionalWrapper>
                          </TableCell>
                        )
                      }
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell
                  colSpan={Object.keys(dataFields).length}
                  align="right"
                >
                  <IconButton
                    size="small"
                    disabled={state.page === 0}
                    onClick={onPrevPage}
                  >
                    <ChevronLeft
                      color={
                        state.page === 0
                          ? 'var(--color-grey-500)'
                          : 'var(--color-base-icon)'
                      }
                    />
                  </IconButton>
                  <IconButton
                    size="small"
                    disabled={isLastPage()}
                    onClick={onNextPage}
                  >
                    <ChevronRight
                      color={
                        isLastPage()
                          ? 'var(--color-grey-500)'
                          : 'var(--color-base-icon)'
                      }
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </>
    )
  }
)

const Placeholder = () => (
  <Alert severity="error">
    {formatMessage('errors.inconsistentReportData')}
  </Alert>
)

export default withErrorWrapper(ReportTable, Placeholder)
