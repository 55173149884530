import getFiles from '../getFiles'
import uploadFiles from '../uploadFiles'
import getDeletedFiles from '../getDeletedFiles'
import deleteFileEntities from '../../calls/deleteFileEntities'
import formatCheckBoxData from '../../helpers/formatCheckBoxData'
import keepOnlyDefaultValues from '../../helpers/keepOnlyDefaultValues'
import fillDataSet from '../../helpers/fillDataSet'
import getReferencedFields from '../../helpers/getReferencedFields'
import { FormData, UpdateReportDataSetDto } from '../../types'
import { FieldGroup } from 'components/ReportForm/types'

export interface GetDataToSendParams {
  appId: string
  dataId: string
  currentFieldGroups: FieldGroup[]
  defaultFormValues: FormData
  formData: FormData
}

const getDataToSend = async (params: GetDataToSendParams) => {
  const { appId, dataId, currentFieldGroups, defaultFormValues, formData } =
    params

  const formDataFiles = getFiles({ formData })
  const formDataWithFiles = await uploadFiles({ formDataFiles, appId })

  if (Boolean(formDataFiles.length)) {
    const deletedFiles = getDeletedFiles({
      formDataWithFiles,
      defaultFormValues,
    })

    await deleteFileEntities({ files: deletedFiles })
  }

  const overridedWithNewData = formDataWithFiles
    ? { ...formData, ...formDataWithFiles }
    : formData

  const dataWithFormattedCheckbox = formatCheckBoxData(
    overridedWithNewData,
    currentFieldGroups
  )
  const defaultValues = keepOnlyDefaultValues(
    defaultFormValues,
    dataWithFormattedCheckbox
  )
  const filledData = fillDataSet(currentFieldGroups, defaultValues)
  const referencedFields = getReferencedFields(filledData)
  const stringifyedData = JSON.stringify(referencedFields)
  const dataToSend: UpdateReportDataSetDto = {
    id: dataId,
    customField1: stringifyedData,
  }

  return {
    dataToSend,
    formDataWithFiles,
    stringifyedData,
  }
}

export default getDataToSend
