import React from 'react'
import { Modal as ModalMUI } from '@mui/material'
import GridReports from 'components/Reports/GridReports'
import { generateBentoBox } from 'lib/reports/bentoBox'
import * as S from './Modal.styled'

interface ModalProps {
  reports: Report[]
  open: boolean
  handleClose: () => void
  width?: number
  height?: number
}

const Modal = (props: ModalProps) => {
  const { reports, open, handleClose, width, height } = props
  const reportGroup = generateBentoBox(reports)

  return (
    <ModalMUI open={open} onClose={handleClose}>
      <S.ModalContainer width={width} height={height}>
        <GridReports reportGroup={reportGroup} />
      </S.ModalContainer>
    </ModalMUI>
  )
}

export default Modal
