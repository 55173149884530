import { PaddingGrid } from '../types'

interface GenerateDistanceParams {
  allChartProperties: { [s: string]: boolean | undefined }
  gridSizes: {
    top: PaddingGrid
    right: PaddingGrid
    bottom: PaddingGrid
    left: PaddingGrid
  }
}

const generatePaddingGrid = (params: GenerateDistanceParams) => {
  const { allChartProperties, gridSizes } = params
  const topProperties = new Set(Object.keys(gridSizes.top))
  const rightProperties = new Set(Object.keys(gridSizes.right))
  const bottomProperties = new Set(Object.keys(gridSizes.bottom))
  const leftProperties = new Set(Object.keys(gridSizes.left))
  const sidesProperties = [
    topProperties,
    rightProperties,
    bottomProperties,
    leftProperties,
  ]
  const sidePositions = ['top', 'right', 'bottom', 'left']
  let paddingGrid = {}

  sidesProperties.forEach((side: Set<string>, i) => {
    const sidePosition = sidePositions[i] as 'top' | 'right' | 'bottom' | 'left'
    let sidePadding: number = gridSizes[sidePosition]?.default

    const validChartProperties = Object.entries(allChartProperties).filter(
      ([key, value]) => side.has(key) && value
    )

    validChartProperties.forEach(([key]) => {
      if (gridSizes[sidePosition][key] > sidePadding) {
        sidePadding = gridSizes[sidePosition][key]
      }
    })

    paddingGrid = { ...paddingGrid, [sidePosition]: sidePadding }
  })

  return paddingGrid
}

export default generatePaddingGrid
