import generateContains from './generateContains'
import generateEqual from './generateEqual'
import { formatMessage } from 'i18n/ShimokuIntl'
import { FILTER_TYPES, FilterInput, FilterValues, OPERATIONS } from './types'
import { NumericFilterOperatorType } from 'containers/Table/types'

const { CONTAINS, EQ } = OPERATIONS

interface GenerateOperationsParams {
  filter: FilterInput
  filterValues: FilterValues
  operator: NumericFilterOperatorType
}

const generateOperations = (params: GenerateOperationsParams) => {
  const { NUMERIC } = FILTER_TYPES
  const {
    filter: { operations, inputType: filterType, field },
    filterValues,
    operator,
  } = params
  if (filterType === NUMERIC) {
    return { [operator]: filterValues[field] }
  }
  return operations
    .map((operation) => {
      switch (operation) {
        case CONTAINS:
          return generateContains({ filterType, value: filterValues[field] })
        case EQ:
          return generateEqual({ value: filterValues[field] })
        default:
          throw new Error(
            formatMessage('errors.invalidOperationType', {
              operationType: operation,
            })
          )
      }
    })
    .reduce((operations, operation) => {
      return {
        ...operations,
        ...operation,
      }
    }, {})
}

export default generateOperations
