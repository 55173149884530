import getSingleSelectColumn from 'containers/Table/utils/generateTableColumns/getColumn/getSingleSelectColumn'
import getStringColumn from 'containers/Table/utils/generateTableColumns/getColumn/getStringColumn'
import getNumberColumn from 'containers/Table/utils/generateTableColumns/getColumn/getNumberColumn'
import getDateColumn from 'containers/Table/utils/generateTableColumns/getColumn/getDateColumn'
import {
  TablePropertiesColumn,
  MappingObject,
  DATA_FIELD_TYPES,
} from 'lib/reports/types'
import { TABLE_COLUMN_TYPES } from '../../../types'
import getButtonColumn from './getButtonColumn'

const { SINGLE_SELECT } = TABLE_COLUMN_TYPES
const { STRING_FIELD, INT_FIELD, DATE_FIELD, CUSTOM_FIELD } = DATA_FIELD_TYPES
export const COLUMN_WIDTH = 100

interface GetColumnParams {
  column: TablePropertiesColumn
  rowsMapping: MappingObject
}

const getColumn = (params: GetColumnParams) => {
  const { column, rowsMapping } = params
  const { field, type } = column
  const dataFieltType: string = rowsMapping[field] || ''

  if (type === SINGLE_SELECT) {
    return getSingleSelectColumn({ column })
  }

  if (dataFieltType.startsWith(CUSTOM_FIELD) && column.type === 'button') {
    return getButtonColumn({ column })
  }

  if (dataFieltType.startsWith(STRING_FIELD)) {
    return getStringColumn({ column })
  }

  if (dataFieltType.startsWith(INT_FIELD)) {
    return getNumberColumn({ column })
  }

  if (dataFieltType.startsWith(DATE_FIELD)) {
    return getDateColumn({ column })
  }

  return getStringColumn({ column })
}

export default getColumn
