import dayjs from 'dayjs'

interface GetDateKeyParams {
  date: string
  dateSubType: AggregationDateSubType
}

const DATE_UNITS = {
  SECOND: 'SECOND',
  MINUTE: 'MINUTE',
  HOUR: 'HOUR',
  DAY: 'DAY',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
}

const { SECOND, MINUTE, HOUR, DAY, MONTH, YEAR } = DATE_UNITS

const getDateKey = (params: GetDateKeyParams) => {
  const { date, dateSubType } = params

  switch (dateSubType) {
    case SECOND:
      return dayjs(date).format('h:mm:ssa MMMM D, YYYY')
    case MINUTE:
      return dayjs(date).format('h:mma MMMM D, YYYY')
    case HOUR:
      return dayjs(date).format('ha MMMM D, YYYY')
    case DAY:
      return dayjs(date).format('MMMM D, YYYY')
    case MONTH:
      return dayjs(date).format('MMMM, YYYY')
    case YEAR:
      return dayjs(date).format('YYYY')
    default:
      return dayjs(date).format('h:mm:ssa MMMM D, YYYY')
  }
}

export default getDateKey
