import { createSlice } from '@reduxjs/toolkit'
import reducers from './reducers'
import { RootState } from 'redux/store/types'
import { Loading } from './types'

const initialState: Loading = {
  loadingAccount: false,
}

const LOADING = 'loading'

const userSlice = createSlice({
  name: LOADING,
  initialState,
  reducers,
})

export const selectLoading = (state: RootState) => state.loading

export const { setLoading, setLoadingAccount } = userSlice.actions

export default userSlice.reducer
