import React, { lazy, Suspense } from 'react'
import ErrorBoundaryIcon from './ErrorBoundaryIcon'

type IconProps = {
  name: string
  width?: number
  height?: number
  color?: string
  style?: React.CSSProperties
}

const Icon = (props: IconProps) => {
  const {
    name = 'default',
    width = 16,
    height = 16,
    color = 'var(--color-base-icon)',
    style,
  } = props
  const DynamicIcon = lazy(() => import(`icons/${name}`))
  return (
    <ErrorBoundaryIcon width={width} height={height}>
      <Suspense
        fallback={
          <span
            style={{ width, height, display: 'block', ...style }}
            data-testid="fallback-icon"
          />
        }
      >
        <DynamicIcon width={width} height={height} color={color} />
      </Suspense>
    </ErrorBoundaryIcon>
  )
}

export default Icon
