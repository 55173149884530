import {
  GridColDef,
  GridRowsProp,
  GridPaginationModel,
} from '@mui/x-data-grid-pro'
import { Sort } from 'lib/reports/types'

export interface TableState {
  columns: GridColDef[]
  rowIdName: string
  rows: GridRowsProp
  sort?: Sort
  total: number
  queryTokens: string[]
  paginationModel: GridPaginationModel
  pageSizeOptions: number[]
  lastPage: number
  filter: null | any
  loading: boolean
}

export enum TABLE_COLUMN_TYPES {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  SINGLE_SELECT = 'singleSelect',
}

export const NUMERIC_OPERATORS = {
  NUMBER_EQUAL: '=',
  NUMBER_DIFFERENT: '!=',
  NUMBER_GREATER_THAN: '>',
  NUMBER_GREATER_OR_EQUAL_THAN: '>=',
  NUMBER_LESS_THAN: '<',
  NUMBER_LESS_OR_EQUAL_THAN: '<=',
}

export const STRING_OPERATORS = {
  STRING_EQUALS: 'equals',
  STRING_STARTS_WITH: 'startsWith',
}

const DATE_OPERATORS = {
  DATE_IS: 'is',
  DATE_AFTER: 'after',
  DATE_ON_OR_AFTER: 'onOrAfter',
  DATE_BEFORE: 'before',
  DATE_ON_OR_BEFORE: 'onOrBefore',
}

export const TABLE_COLUMN_FILTER_OPERATORS = {
  ...NUMERIC_OPERATORS,
  ...STRING_OPERATORS,
  ...DATE_OPERATORS,
  SINGLE_SELECT_IS: 'is',
}

export type NumericFilterOperatorType =
  typeof NUMERIC_OPERATORS[keyof typeof NUMERIC_OPERATORS]

export type TableColumnFilterOperatorTypes =
  typeof TABLE_COLUMN_FILTER_OPERATORS[keyof typeof TABLE_COLUMN_FILTER_OPERATORS]
