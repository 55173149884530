import getOptionsWithAllLabels from './getOptionsWithAllLabels'
import getLabels from './getLabels'
import getOptionsForSelects from './getOptionsForSelects'
import { OptionsMultifilter, OptionMultifilter } from '../types'

interface FormatOptionsForSelectsParams {
  defaultOptions: OptionsMultifilter
}

const formatOptions = (params: FormatOptionsForSelectsParams) => {
  const { defaultOptions } = params

  const options = getOptionsWithAllLabels({ defaultOptions })

  const reportIds = new Set(
    defaultOptions.map((option: OptionMultifilter) => option?.reportId).flat()
  )

  const optionsWithoutReportIds = options.map((option: OptionMultifilter) => {
    const { reportId, ...rest } = option

    return rest
  })

  const labels = getLabels({ options: optionsWithoutReportIds })

  const optionsForSelects = getOptionsForSelects({
    labels,
    options: optionsWithoutReportIds,
  })

  return {
    options,
    reportIds,
    optionsWithoutReportIds,
    labels,
    optionsForSelects,
  }
}

export default formatOptions
