import getDefaultOrder from './getDefaultOrder'

interface GetOrderParams {
  dataFields: ReportTableDataFields
}

const getOrder = (params: GetOrderParams) => {
  const { dataFields } = params

  const defaultOrder = getDefaultOrder({ dataFields })

  if (Boolean(defaultOrder)) {
    const { defaultOrder: order, field: orderBy } =
      defaultOrder as ReportTableDataField

    return {
      order,
      orderBy,
    }
  }

  return {
    order: 'asc',
    orderBy: '',
  }
}

export default getOrder
