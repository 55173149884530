interface IsInputOperationEditableParams {
  reportDataSets: ReportDataSet[]
}

const isInputOperationEditable = (params: IsInputOperationEditableParams) => {
  const { reportDataSets } = params

  return reportDataSets.flatMap((reportDataSet) => {
    const { aggregationProperty = [] } = reportDataSet

    return aggregationProperty.filter((agg) => agg.inputOperationEditable)
  })
}

export default isInputOperationEditable
