import uploadFileEntities from '../../calls/uploadFileEntities'
import { PROMISE_STATUS } from 'lib/promises'
import { isArray } from 'lib/variableType'
import { isFile } from 'lib/variableType'
import { FileData } from 'components/Input/types'
import {
  FormDataArrayFiles,
  FormDataSubmittedFiles,
  FormDataArrayFilesAndFilesData,
} from '../../types'

const { FULFILLED } = PROMISE_STATUS

interface UploadFilesParams {
  formDataFiles: FormDataArrayFilesAndFilesData
  appId: string
}

const uploadFiles = async (params: UploadFilesParams) => {
  const { formDataFiles: defaultFormDataFiles, appId } = params

  if (!Boolean(defaultFormDataFiles.length)) {
    return {} as FormDataSubmittedFiles
  }

  const { formDataSubmittedFiles, formDataFiles } = defaultFormDataFiles.reduce(
    (
      {
        formDataSubmittedFiles: defaultFormDataSubmittedFiles,
        formDataFiles: defaultFormDataFiles,
      },
      [key, defaultFilesDataAndFiles]
    ) => {
      const filesDataAndFiles = isArray(defaultFilesDataAndFiles)
        ? defaultFilesDataAndFiles
        : []
      //@ts-ignore
      const filesData = filesDataAndFiles.filter(
        (fileData: FileData | File) => !isFile(fileData)
      )
      const formDataSubmittedFiles = {
        ...defaultFormDataSubmittedFiles,
        [key]: filesData,
      } as FormDataSubmittedFiles
      //@ts-ignore
      const files = filesDataAndFiles.filter((file: FileData | File) =>
        isFile(file)
      ) as File[]
      const formDataFiles = [
        ...defaultFormDataFiles,
        [key, files],
      ] as FormDataArrayFiles

      return {
        formDataSubmittedFiles,
        formDataFiles,
      }
    },
    {
      formDataSubmittedFiles: {} as FormDataSubmittedFiles,
      formDataFiles: [] as FormDataArrayFiles,
    }
  )

  const uploadedFiles = await Promise.all(
    formDataFiles.map(async ([key, files]) => {
      const res: any = await uploadFileEntities({ files, appId })
      return [key, res]
    })
  )

  const fullfilledFiles = uploadedFiles.reduce(
    (fullfilledFiles, [key, value]) => ({
      ...fullfilledFiles,
      [key]: [
        ...formDataSubmittedFiles[key],
        ...value
          .filter((file: any) => file.status === FULFILLED)
          .map((file: any) => ({
            id: file.value.id,
            name: file.value.name,
            fileName: file.value.fileName,
          })),
      ],
    }),
    {} as FormDataSubmittedFiles
  )

  return fullfilledFiles
}

export default uploadFiles
