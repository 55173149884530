import { ShadowSizes } from 'theme/types'

const COMMON_SHADOWS: ShadowSizes = {
  s: '0px 4px 8px 0px #00000026',
  m: '0px 4px 16px 0px #00000033',
  l: '0px 4px 24px 0px #00000026',
  xl: '0px 4px 48px 0px #0000001A',
}

export default COMMON_SHADOWS
