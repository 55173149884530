import { configureStore } from '@reduxjs/toolkit'
import account from 'redux/account'
import apps from 'redux/apps'
import appType from 'redux/appType'
import dashboards from 'redux/dashboards'
import loading from 'redux/loading'
import modules from 'redux/modules'
import plan from 'redux/plan'
import modal from 'redux/modal'
import resourcePermissions from 'redux/roles'
import user from 'redux/user'
import universeModules from 'redux/universeModules'
import playground from 'redux/playground'

export const reducer = {
  account,
  apps,
  appType,
  dashboards,
  loading,
  modules,
  plan,
  modal,
  resourcePermissions,
  user,
  universeModules,
  playground,
}

interface configureAppStoreParams {
  preloadedState?: any
}

const configureAppStore = (params: configureAppStoreParams) => {
  const { preloadedState = {} } = params

  return configureStore({
    reducer,
    preloadedState,
  })
}

export default configureAppStore
