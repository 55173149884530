import React, { SVGProps } from 'react'

type ArrowLeftProps = SVGProps<SVGSVGElement> & {
  testId?: string
}

export const ArrowLeft = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
  testId = 'arrow-left',
}: ArrowLeftProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
      data-testid={testId}
    >
      <title>arrow-left-icon</title>
      <path
        d="M6 12H9.25L12.5 12H15.75L19 12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 17L5 12L10 7"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
