import { Option, DefaultData, DataElement } from '../../../types'
import toolboxDataView from './toolboxDataView'

interface DefaultOptionModificationsHeatmap {
  data: DefaultData
}

const defaultOptionModifications = (
  params: DefaultOptionModificationsHeatmap
): Option => {
  const { data } = params

  const values = data.map((element: DataElement) => element?.value) as number[]

  return {
    tooltip: {
      trigger: 'item',
    },
    grid: {
      containLabel: true,
    },
    xAxis: {
      boundaryGap: true,
      data: Array.from(
        new Set(data.map((value: DataElement) => value?.xAxis))
      ) as [],
    },
    yAxis: {
      nameGap: 80,
      boundaryGap: true,
      axisLabel: {
        margin: 24,
      },
      data: Array.from(
        new Set(data.map((value: DataElement) => value?.yAxis))
      ) as [],
    },
    toolbox: {
      feature: { dataView: { ...toolboxDataView } },
    },
    visualMap: {
      min: Math.min(...values),
      max: Math.max(...values),
    },
    dataZoom: { show: false },
    singleAxis: {
      show: false,
      axisLine: {
        show: false,
      },
    },
    calendar: {
      range: 2000,
      cellSize: 0,
      splitLine: {
        show: false,
      },
      itemStyle: {
        opacity: 0,
      },
      dayLabel: {
        show: false,
      },
      monthLabel: {
        show: false,
      },
      yearLabel: {
        show: false,
      },
    },
    animation: false,
  }
}

export default defaultOptionModifications
