import React, { CSSProperties } from 'react'
import ReportWrapperComponent from 'components/ReportWrapper'
import useScrollGridChildren from 'hooks/useScrollGridChildren'
import * as S from './ReportWrapper.styled'

interface ReportWrapperProps {
  report: Report
  rows: number
  columns: number
  isBentoBox?: boolean
  oldGrid?: boolean
  style?: CSSProperties
}

const ReportWrapper = (props: ReportWrapperProps) => {
  const {
    report,
    rows,
    columns,
    oldGrid = false,
    style = {},
    isBentoBox,
  } = props
  const { ref, haveHorizontalScroll } = useScrollGridChildren({})

  return (
    <S.Wrapper
      ref={ref}
      type={report?.reportType}
      haveHorizontalScroll={haveHorizontalScroll}
      oldGrid={oldGrid}
      data-testid={`REPORT-${report?.reportType}-${report?.id}`}
      style={style}
    >
      <ReportWrapperComponent
        report={report}
        rows={rows}
        columns={columns}
        isBentoBox={isBentoBox}
      />
    </S.Wrapper>
  )
}

export default ReportWrapper
