import React, { SVGProps } from 'react'

export const ColumnsA = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width,
      height,
    }}
    fill="none"
  >
    <title>columns-a-icon</title>
    <path
      stroke={color}
      stroke-linecap="round"
      stroke-width={2}
      d="M12 6v12M6 6v12M18 6v12"
    />
  </svg>
)
