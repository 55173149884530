interface GetAllAppsFromDashboardsParams {
  dashboards?: Dashboard[]
}

const getAllAppsFromDashboards = (params: GetAllAppsFromDashboardsParams) => {
  const { dashboards } = params

  if (!dashboards) return []

  const allApps = dashboards.reduce((acc: App[], dashboard: Dashboard) => {
    const apps = dashboard.appDashboards.items.map(
      (appDashboard: AppDashboard) => appDashboard.app
    )
    return acc.concat(apps)
  }, [])

  return allApps
}

export default getAllAppsFromDashboards
