import React, { Fragment, useState, useEffect, MouseEvent } from 'react'
import { Collapse } from '@mui/material'
import {
  addOpenAndActiveStatus,
  checkOpenAndActiveStatus,
  toogleOpenedStatus,
} from './utils'
import OverflowTooltip from 'components/OverflowTooltip/OverflowTooltip'
import ConditionalLink from 'components/ConditionalLink'
import { PLAYGROUND_PATH } from 'components/Routes/routes/getDefaultRoutes'
import ChevronDown from 'icons/Line/chevron-down'
import { ChevronRight } from 'icons/Line/chevron-right/chevron-right'
import getAppRoutes from 'lib/apps/getAppRoutes'
import getAppName from 'lib/apps/getAppName'
import { usePostHog } from 'posthog-js/react'
import { NAVIGATION_EVENTS } from 'lib/postHogEvents'
import * as S from './Menu.styled'

interface MenuProps {
  items: App[]
  pathname: string
  dashboard: Dashboard
  playground?: boolean
}

const Menu = (props: MenuProps) => {
  const { items, pathname, dashboard, playground = false } = props
  const [menuItemList, setMenuItemList] = useState<MenuItem[]>([])
  const posthog = usePostHog()
  useEffect(() => {
    if (items.length) {
      menuItemList.length
        ? setMenuItemList(
            checkOpenAndActiveStatus({
              list: menuItemList,
              pathname,
              dashboard,
              prefix: playground ? PLAYGROUND_PATH : '',
            })
          )
        : setMenuItemList(
            addOpenAndActiveStatus(items, window.location.pathname, dashboard)
          ) // TODO Improve this. Pathname of react-roter-dom is setted after real location has loaded
    } else {
      setMenuItemList([])
    }
  }, [items, pathname]) // eslint-disable-line

  const toogleMenuOpened = (event: MouseEvent, index: number) => {
    event.preventDefault()
    const newMenuItemList: MenuItem[] = toogleOpenedStatus(menuItemList, index)
    setMenuItemList(newMenuItemList)
  }
  return (
    <div>
      {menuItemList.map((app: MenuItem, index: number) => {
        const { rootApp, subApps: defaultSubApps } = getAppRoutes({
          app,
          dashboard,
          prefix: playground ? PLAYGROUND_PATH : '',
        })
        const subApps = defaultSubApps.filter((subApp) => !subApp.hidePath)
        const appName = getAppName(app)
        return (
          <Fragment key={`${app.id}-fragment`}>
            {!app?.hidePath && (
              <ConditionalLink to={rootApp.path}>
                <S.MenuItem
                  key={app.id}
                  selected={app.active}
                  haveArrow={Boolean(subApps.length)}
                  data-testid={appName}
                  onClick={() =>
                    posthog?.capture(NAVIGATION_EVENTS.MENU_ITEM_CLICKED, {
                      menuItemName: appName,
                    })
                  }
                >
                  {Boolean(subApps.length) && (
                    <S.Icon
                      active={app.active}
                      onClick={(event) => toogleMenuOpened(event, index)}
                      size="large"
                    >
                      {app.opened ? <ChevronDown /> : <ChevronRight />}
                    </S.Icon>
                  )}
                  <OverflowTooltip
                    arrow
                    title={appName}
                    placement="top"
                    margin="0 0 0 5px"
                  >
                    {appName}
                  </OverflowTooltip>
                </S.MenuItem>
              </ConditionalLink>
            )}
            {Boolean(subApps.length) && (
              <S.SubMenuContainer>
                {subApps.map(
                  (subApp) =>
                    !app?.hidePath && (
                      <Collapse
                        key={`${app.id}|${subApp.name}`}
                        in={app.opened}
                        timeout="auto"
                        unmountOnExit
                      >
                        <ConditionalLink to={subApp.path}>
                          <S.SubMenuItem
                            onClick={() =>
                              posthog?.capture(
                                NAVIGATION_EVENTS.SUB_MENU_ITEM_CLICKED,
                                {
                                  menuItemName: appName,
                                }
                              )
                            }
                            selected={
                              pathname?.toUpperCase() ===
                              subApp.path.toUpperCase()
                            }
                            active={app.active}
                          >
                            <OverflowTooltip
                              arrow
                              title={subApp.name}
                              placement="top"
                            >
                              {subApp.name}
                            </OverflowTooltip>
                          </S.SubMenuItem>
                        </ConditionalLink>
                      </Collapse>
                    )
                )}
              </S.SubMenuContainer>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

export default Menu
