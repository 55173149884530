import { ApolloClient, NormalizedCacheObject, gql } from '@apollo/client'
import {
  GraphQLOperationParams,
  GraphQLOperationTypeEnum,
  FetchPolicyEnum,
} from '../types'

const { QUERY, MUTATION, SUBSCRIPTION } = GraphQLOperationTypeEnum
const { CACHE_FIRST } = FetchPolicyEnum

interface ExecuteAppSyncOperationParams extends GraphQLOperationParams {
  apolloClient: ApolloClient<NormalizedCacheObject>
}

const executeApolloOperation = async (
  params: ExecuteAppSyncOperationParams
) => {
  const {
    apolloClient,
    fetchPolicy = CACHE_FIRST,
    operationType,
    operation,
    options = {},
  } = params

  const { variables = {}, onData } = options

  switch (operationType) {
    case QUERY:
      return apolloClient.query({
        query: gql(operation),
        variables,
        fetchPolicy,
      })

    case MUTATION:
      return apolloClient.mutate({ mutation: gql(operation), variables })

    case SUBSCRIPTION:
      return apolloClient
        .subscribe({ query: gql(operation), variables })
        .subscribe({ next: onData })

    default:
      throw new Error(`Unsupported operation type for Apollo: ${operationType}`)
  }
}

export default executeApolloOperation
