import { VariantType as NotistackVariantType } from 'notistack'
import { RealTimeEventsReducerActionType } from './realTimeEventsReducer/types'

export enum AuthResourceType {
  UNIVERSE_ADMIN = 'UNIVERSE_ADMIN',
  BUSINESS_ADMIN = 'BUSINESS_ADMIN',
  UNIVERSE_USER = 'UNIVERSE_USER',
  BUSINESS_USER = 'BUSINESS_USER',
}

export interface RealTimeEvent {
  id: string
  type: string
  universeId: string
  authResourceType: string
  mainAuthResourceId: string
  resourceId: string
  content: string
}

export interface RegisterRealTimeComponentParams {
  eventType: RealTimeEventsReducerActionType
  id?: string
  callback: (event: RealTimeEvent) => void
}

export interface UnregisterRealTimeComponentParams {
  eventType: string
  id?: string
}

export interface NotificationContent {
  message: string
  variant: NotistackVariantType
}
