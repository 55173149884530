import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { useSelector } from 'react-redux'
import { selectAccount } from 'redux/account'
import { useSnackbar } from 'notistack'
import Plan from 'components/Plan'
import listUniversePlanTypes from 'gql/queries/listUniversePlanTypes'
import { getErrorMessage } from 'lib/errors'
import Sentry from 'tracking/Sentry'
import * as S from './PricingTable.styled'
import NotFound from 'pages/NotFound'
import PlansSkeleton from './Skeleton'

const PricingTable = () => {
  const account = useSelector(selectAccount)
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(true)
  const [plans, setPlans] = useState<Plan[]>([])
  const [allPlansDisabled, setAllPlansDisabled] = useState(false)
  const universeId = account.business.universe.id
  const activePlanType =
    account.business.universe.activeUniversePlan.planType.type

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response: any = await API.graphql(
          graphqlOperation(listUniversePlanTypes, { universeId })
        )
        const fetchedPlans = response.data.listUniversePlanTypes.items
        const sortedPlans = fetchedPlans.sort(
          (a: Plan, b: Plan) => a.order - b.order
        )
        setPlans(sortedPlans)
        setIsLoading(false)
      } catch (error) {
        enqueueSnackbar(getErrorMessage(error, 'errors.fetchPlansData'), {
          variant: 'error',
        })
        Sentry.captureException(error)
      }
    }
    fetchPlans()
  }, [enqueueSnackbar, universeId])

  return (
    <S.PlansContainer data-testid="pricing-table">
      {isLoading ? (
        <PlansSkeleton />
      ) : plans.length ? (
        plans.map(
          ({
            id,
            description,
            featured,
            features,
            images,
            limits,
            price,
            type,
          }) => (
            <Plan
              key={id}
              id={id}
              activePlanType={activePlanType}
              description={description}
              setAllPlansDisabled={setAllPlansDisabled}
              disabled={allPlansDisabled}
              featured={featured}
              features={features}
              image={images && images[0]}
              limits={limits}
              price={price}
              type={type}
            />
          )
        )
      ) : (
        <NotFound />
      )}
    </S.PlansContainer>
  )
}

export default PricingTable
