import { Mapping } from 'lib/reports/types'
import { FormVariant } from 'containers/ReportForm/types'

export enum InputType {
  button = 'button',
  checkbox = 'checkbox',
  color = 'color',
  date = 'date',
  datetimeLocal = 'datetime-local',
  email = 'email',
  month = 'month',
  number = 'number',
  password = 'password',
  select = 'select',
  range = 'range',
  radio = 'radio',
  tel = 'tel',
  time = 'time',
  url = 'url',
  week = 'week',
  dateRange = 'dateRange',
  file = 'file',
  audio = 'audio',
  multiSelect = 'multiSelect',
}

export type DependentOptions = { [option: string]: string[] }

export type Options = string[] | DependentOptions

export type ReportFormDataset = {
  inputType: InputType
  mapping: Mapping
  fieldName: string
  required?: boolean
  reference?: string
  options?: Options
  dependsOn?: string
  showSearch?: boolean
  dragAndDrop?: boolean
  sizeColumns?: number
  sizePadding?: string
  hide?: boolean
}

export const acceptedTypes = [
  InputType.button,
  InputType.checkbox,
  InputType.color,
  InputType.date,
  InputType.datetimeLocal,
  InputType.email,
  InputType.month,
  InputType.number,
  InputType.password,
  InputType.select,
  InputType.range,
  InputType.radio,
  InputType.tel,
  InputType.time,
  InputType.url,
  InputType.week,
  InputType.dateRange,
]

interface NextFormGroup {
  id: string
  label: string
}

export type FieldGroup = {
  id?: string
  nextFormGroup?: NextFormGroup
  title: string
  fields: ReportFormDataset[]
}

export type SubmitDataObj = {
  currentFieldGroups: FieldGroup[]
  dataId: string
  dataSetId: string
  defaultFormValues: any
  formVariant?: FormVariant
  submitData: any
}

export type FormValues = { [formField: string]: string }
