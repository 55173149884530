import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'redux/store/types'

const initialState: Dashboard[] = []

const dashboardSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {
    setDashboards: (state, action) => action.payload,
  },
})

const filterEnabledDashboards = (state: RootState) => {
  return state.dashboards.filter(
    (dashboard: Dashboard) => !dashboard.isDisabled
  )
}

const hasOrder = (dashboard: Dashboard): boolean => {
  return dashboard.order === 0 || Boolean(dashboard.order)
}

const sortDashboardsByOrder = (dashboards: Dashboard[]): Dashboard[] => {
  // @ts-ignore
  return dashboards.sort((a, b) => a.order - b.order)
}

const selectDashboards = (state: RootState) => {
  const enabledDashboards = filterEnabledDashboards(state)
  const sortedWithOrder = sortDashboardsByOrder(
    enabledDashboards.filter(hasOrder)
  )
  const dashboardsWithoutOrder = enabledDashboards.filter(
    (dashboard) => !hasOrder(dashboard)
  )

  return [...sortedWithOrder, ...dashboardsWithoutOrder]
}

export { selectDashboards }
export const { setDashboards } = dashboardSlice.actions
export default dashboardSlice.reducer
