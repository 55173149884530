export type AggregationItem = { [key: string]: string | number }

export interface AggregationByKeys {
  [key: string]: Aggregation
}

export enum OperationType {
  SUM = 'SUM',
  AVG = 'AVG',
  MIN = 'MIN',
  MAX = 'MAX',
}
