import { GRID_COLUMNS } from 'components/Grid/utils/defaultValues'
import { formatMessage } from 'i18n/ShimokuIntl'
import { REPORT_TYPES } from 'lib/reports'

const { INDICATORS } = REPORT_TYPES

export const DEFAULT_REPORT_SIZE_ROWS = {
  indicators: 8,
  others: 14,
}
export const DEFAULT_REPORT_SIZE_COLUMNS = {
  indicators: 12,
  others: 24,
}
export const DEFAULT_BENTOBOX_SIZE = {
  rows: 12,
  columns: 24,
}

export const getReportWithSmallestBentoboxOrder = (reports: Report[]): Report =>
  reports
    .filter(
      ({ bentoboxProperties }) =>
        bentoboxProperties &&
        bentoboxProperties.bentoboxId &&
        (bentoboxProperties.bentoboxOrder !== null ||
          bentoboxProperties.bentoboxOrder !== undefined)
    )
    .sort((a, b) => {
      // @ts-ignore
      const aOrder = a.bentoboxProperties?.bentoboxOrder
      const bOrder = b.bentoboxProperties?.bentoboxOrder
      // @ts-ignore
      return aOrder - bOrder
    })[0]

export const groupById = (reports: Report[]): Record<string, Report[]> =>
  reports.reduce((acc, report) => {
    const id = report.bentoboxProperties?.bentoboxId || report.id
    if (!acc[id]) {
      acc[id] = []
    }
    acc[id].push(report)
    return acc
  }, {} as Record<string, Report[]>)

const sortBentobox = (bentobox: HydratedBentoBox): HydratedBentoBox =>
  Object.fromEntries(
    Object.entries(bentobox).sort(([, a], [, b]) => a.order - b.order)
  )

export const hydrateBentoBoxData = (bentoBox: BentoBox): HydratedBentoBox => {
  const bentoBoxData: { [key: string]: BentoBoxProperties } = {}
  Object.keys(bentoBox).forEach((bentoboxId: string) => {
    const currentBentoBox = bentoBox[bentoboxId]
    if (currentBentoBox.length > 1) {
      // The smallest order report give their properties to the bentobox
      const smallestReport = getReportWithSmallestBentoboxOrder(currentBentoBox)
      if (
        smallestReport?.bentoboxProperties?.bentoboxOrder === undefined ||
        smallestReport?.bentoboxProperties.bentoboxOrder === null
      ) {
        throw Error(formatMessage('bentobox.errors.mustHaveBentoboxOrder'))
      }
      return (bentoBoxData[bentoboxId] = {
        reports: currentBentoBox,
        order: smallestReport.bentoboxProperties.bentoboxOrder,
        sizeColumns:
          smallestReport.bentoboxProperties.bentoboxSizeColumns || GRID_COLUMNS,
        sizeRows: smallestReport.bentoboxProperties.bentoboxSizeRows,
        sizePadding: smallestReport.sizePadding || '',
      })
    }
    return (bentoBoxData[bentoboxId] = {
      reports: currentBentoBox,
      order: currentBentoBox[0].order,
      sizeColumns: currentBentoBox[0].sizeColumns || GRID_COLUMNS,
      sizeRows: currentBentoBox[0].sizeRows,
      sizePadding: currentBentoBox[0].sizePadding || '',
    })
  })
  return bentoBoxData
}

export const setDefaultReportSizeValues = (reports: Report[]): Report[] =>
  reports.map((report: Report) => {
    if (report.bentobox !== undefined && report.bentobox !== null) {
      if (report.reportType === INDICATORS) {
        return {
          ...report,
          sizeRows: report.sizeRows || DEFAULT_REPORT_SIZE_ROWS.indicators,
          sizeColumns:
            report.sizeColumns || DEFAULT_REPORT_SIZE_COLUMNS.indicators,
        }
      }
      return {
        ...report,
        sizeRows: report.sizeRows || DEFAULT_REPORT_SIZE_ROWS.others,
        sizeColumns: report.sizeColumns || DEFAULT_REPORT_SIZE_COLUMNS.others,
      }
    }
    return report
  })

export const parseBentoboxData = (reports: Report[]): Report[] =>
  reports.map((report: Report) => {
    if (report.bentobox) {
      return {
        ...report,
        bentoboxProperties: JSON.parse(report.bentobox),
      }
    }
    return report
  })

export const generateBentoBox = (reports: Report[]): HydratedBentoBox => {
  const reportWithDefaults = setDefaultReportSizeValues(reports)
  const parsedBentoboxData = parseBentoboxData(reportWithDefaults)
  const bentoBox = groupById(parsedBentoboxData)
  const hydratedBentobox = hydrateBentoBoxData(bentoBox)
  const sortedBentobox = sortBentobox(hydratedBentobox)
  return sortedBentobox
}
