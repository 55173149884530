import React from 'react'
import { Typography, Alert } from '@mui/material'
import Main from 'components/Main'
import { formatMessage } from 'i18n/ShimokuIntl'

const LoadSectionError = () => (
  <Main padding={true}>
    <Typography component="h1" variant="h1" marginBottom="1rem">
      {formatMessage('page.loadSectionError.heading')}
    </Typography>
    <Alert severity="error">
      <span
        dangerouslySetInnerHTML={{
          __html: formatMessage('page.loadSectionError.bodyText'),
        }}
      />
    </Alert>
  </Main>
)

export default LoadSectionError
