import { PROMISE_STATUS } from 'lib/promises'
import { getErrorMessage } from 'lib/errors'
import { RawReportDataSet } from 'contexts/ReportDataSets/types'

const { REJECTED } = PROMISE_STATUS

interface SetReportDataSetsListDataParams {
  reportDataSets: RawReportDataSet[]
  res: any
}

const setReportDataSetsListData = (params: SetReportDataSetsListDataParams) => {
  const { reportDataSets, res } = params
  let start = 0
  let end = 0

  return reportDataSets.map((reportDataSet) => {
    const { dataSet } = reportDataSet
    const { totalQueries } = dataSet

    end = start + (totalQueries as number)
    const listDataRes = res.slice(start, end)
    start = end

    if (Boolean(reportDataSet.dataSet.errorMessage)) {
      return {
        ...reportDataSet,
        dataSet: {
          ...dataSet,
          data: [],
          loading: false,
        },
      } as RawReportDataSet
    }

    const error = listDataRes.find(({ status }: any) => status === REJECTED)

    if (Boolean(error)) {
      return {
        ...reportDataSet,
        dataSet: {
          ...dataSet,
          listDataStatus: error.status,
          errorMessage: getErrorMessage(error.reason),
          data: [],
          loading: false,
        },
      } as RawReportDataSet
    }

    const data = listDataRes.flatMap(
      ({ value }: any) => value.data.listData.items
    )

    return {
      ...reportDataSet,
      dataSet: {
        ...dataSet,
        data,
        loading: false,
      },
    } as RawReportDataSet
  })
}

export default setReportDataSetsListData
