import React, { useRef } from 'react'
import { Skeleton } from '@mui/material'
import useReportDataSetsByReportDataSets from 'contexts/ReportDataSets/hooks/useReportDataSetsByReportDataSets'
import ConditionalWrapper from 'hocs/conditionalWrapper'
import getAggregationFromReportDataSets from 'containers/Aggregation/helpers/getAggregationFromReportDataSets'
import isInputOperationEditable from 'containers/Aggregation/InputOperationEditable/isInputOperationEditable'
import IndicatorComponent from 'components/Indicator'
import AggregationWrapper from 'containers/Aggregation/AggregationWrapper'
import { INDICATOR_HEIGHT } from 'components/Indicator/indicator.styled'
import mappingDataItem from 'lib/reports/mappingDataItem'
import { MappingObject } from 'lib/reports/types'
import { IndicatorProperties } from 'lib/reports/types'
import parseReportProperties from './parseReportProperties'
import * as S from './Indicator.styled'
import {
  getCompatibleColor,
  getCompatibleVariant,
} from 'components/Indicator/compatibility-helper'

interface IndicatorProps {
  report: Report
  reportProperties: IndicatorProperties
}

const Indicator = (props: IndicatorProps) => {
  const { report, reportProperties: properties } = props
  const reportProperties = useRef<IndicatorProperties>(properties)

  const defaultReportDataSets = report.reportDataSets?.items || []
  const { reportDataSets } = useReportDataSetsByReportDataSets({
    reportDataSets: defaultReportDataSets,
  })
  const aggregation = getAggregationFromReportDataSets({ reportDataSets })
  const inputOperationEditable = isInputOperationEditable({ reportDataSets })

  const loading = reportDataSets.some(
    (reportDataSet) => reportDataSet.dataSet.loading === true
  )

  if (!loading && Boolean(reportDataSets.length)) {
    const [reportDataSet] = reportDataSets
    const [dataItem] = reportDataSet.dataSet.data || []

    const mappingReportProperties = mappingDataItem({
      mapping: properties as MappingObject,
      dataItem,
      aggregation,
    }) as IndicatorProperties

    reportProperties.current = parseReportProperties({
      reportProperties: mappingReportProperties,
    })
  }

  const {
    title,
    value,
    description,
    color,
    align,
    targetPath,
    icon,
    bigIcon,
    hideIcons,
    variant,
    backgroundImage,
    info,
  } = reportProperties.current

  if (loading) {
    return (
      <Skeleton
        variant="rectangular"
        height={INDICATOR_HEIGHT}
        role="progressbar"
        data-testid="indicator-loader"
      />
    )
  }

  return (
    <ConditionalWrapper
      condition={Boolean(inputOperationEditable.length)}
      wrapper={(children) => (
        <AggregationWrapper
          reportDataSets={reportDataSets}
          inputOperationEditable={inputOperationEditable}
        >
          {children}
        </AggregationWrapper>
      )}
    >
      <S.Container className="bentobox-reset">
        <IndicatorComponent
          title={title}
          value={value}
          description={description}
          color={getCompatibleColor(color)}
          align={align}
          variant={getCompatibleVariant({
            color,
            variant,
          })}
          backgroundImage={backgroundImage}
          targetPath={targetPath}
          icon={icon}
          bigIcon={bigIcon}
          hideIcons={hideIcons}
          info={info}
        />
      </S.Container>
    </ConditionalWrapper>
  )
}

export default Indicator
