import React, { SVGProps } from 'react'

export const User = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
    >
      <title>user-icon</title>
      <circle
        cx="4"
        cy="4"
        r="4"
        transform="matrix(1 0 0 -1 8 11)"
        stroke={color}
        stroke-width="2"
      />
      <path
        d="M20 21L19.6169 18.4138C19.3263 16.4525 17.6428 15 15.66 15H8.33995C6.35722 15 4.67371 16.4525 4.38314 18.4138L4 21"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
