import React from 'react'

interface HexagonSVGProps {
  width?: string
  color?: string
}

const HexagonSVG = (props: HexagonSVGProps) => {
  const { width = 61, color = 'var(--color-grey-200)' } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      fill="none"
      viewBox="0 0 61 70"
    >
      <path
        fill={color}
        d="M26.704 1.064a7.306 7.306 0 017.592 0l22.408 13.53c2.349 1.418 3.796 4.04 3.796 6.876v27.06c0 2.837-1.447 5.458-3.796 6.876l-22.408 13.53a7.306 7.306 0 01-7.592 0L4.296 55.406C1.947 53.988.5 51.366.5 48.53V21.47c0-2.837 1.447-5.458 3.796-6.876l22.408-13.53z"
      ></path>
    </svg>
  )
}

export default HexagonSVG
