import React from 'react'
import { Tooltip, IconButton } from '@mui/material'
import PaginationActions from './PaginationActions'
import InfoIcon from 'icons/Line/info'
import * as S from './GridFooter.styled'

interface GridFooterProps {
  footer: boolean
  info?: string
  loading: boolean
}

const GridFooter = (props: GridFooterProps) => {
  const { footer, info = '', loading } = props

  if (!footer) {
    return null
  }

  return (
    <S.GridFooterContainer info={Boolean(info.length)}>
      {Boolean(info.length) && (
        <Tooltip title={info}>
          <IconButton aria-label="info">
            <InfoIcon />
          </IconButton>
        </Tooltip>
      )}
      <S.GridPagination
        ActionsComponent={() => <PaginationActions loading={loading} />}
      />
    </S.GridFooterContainer>
  )
}

export default GridFooter
