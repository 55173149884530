import { CustomZIndex } from 'theme/common/zIndex'
import generateTheme from 'theme/lib/generateTheme'
import {
  ThemeDomain,
  BrandPallete,
  ChartOptions,
  ComplementaryColors,
  RadiusSizes,
  ShadowSizes,
  IconColors,
} from 'theme/types'

const ACTUAL_DOMAIN = window.location.hostname

export interface Custom {
  zIndex: CustomZIndex
  domain: ThemeDomain
  brandPallete: BrandPallete
  chart: {
    colors: Record<string, string>
    options: ChartOptions
  }
  reports: {
    indicators: {
      gap: string
    }
  }
  dimensions: {
    drawerWidth: number
    headerHeight: number
  }
  radius: RadiusSizes
  shadows: ShadowSizes
  icon: IconColors
  logo?: string
}

declare module '@mui/material/styles' {
  interface Theme {
    custom: Custom
  }

  interface DeprecatedThemeOptions {
    custom: Custom
  }
  interface Palette {
    complementary: ComplementaryColors
  }
  interface PaletteOptions {
    complementary?: ComplementaryColors
  }
}

const theme = generateTheme(ACTUAL_DOMAIN)

export default theme
