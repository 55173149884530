import { styled } from '@mui/material/styles'
import { TextInput } from 'components/Input'

export const FilterWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'end',
}))

export const ValueInput = styled(TextInput)(() => ({
  flexBasis: '0',
  flexGrow: 2,
  flexShrink: 1,
}))
