import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { Loading } from '../../types'

type State = Loading

const setLoadingAccount: CaseReducer<State, PayloadAction<boolean>> = (
  state,
  action
) => ({ ...state, loadingAccount: action.payload })

export default setLoadingAccount
