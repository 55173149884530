import getAccount from 'lib/account/getAccount'
import { setLoadingAccount } from 'redux/loading'
import { useSelector, useDispatch } from 'react-redux'
import { setApps } from 'redux/apps'
import { setDashboards } from 'redux/dashboards'
import { setAccount as setAccountRedux } from 'redux/account'
import getDashboardsAppsWithReports from 'lib/dashboards/getDashboardsAppsWithReports'
import useGraphQLOperation from 'hooks/useGraphQLOperation'
import { useSnackbar } from 'notistack'
import { selectUser } from 'redux/user'
import getApps from 'lib/apps/getApps'

const useWorkspaceUpdate = () => {
  const user = useSelector(selectUser)

  const graphQLOperation = useGraphQLOperation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const handleWorkspaceUpdate = async () => {
    dispatch(setLoadingAccount(true))

    const account = await getAccount({
      user,
      graphQLOperation,
      enqueueSnackbar,
    })

    if (account) {
      dispatch(setAccountRedux(account))

      const dashboardsAppsWithoutReports =
        account.business?.dashboards?.items || []
      const dashboards = await getDashboardsAppsWithReports({
        dashboards: dashboardsAppsWithoutReports,
        graphQLOperation,
      })
      dispatch(setDashboards(dashboards))

      const apps = getApps({ dashboards, haveInstallRemoveApps: false })
      dispatch(setApps(apps))
    }

    dispatch(setLoadingAccount(false))
  }

  return { handleWorkspaceUpdate }
}

export default useWorkspaceUpdate
