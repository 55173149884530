import { SeriesOption, HeatmapSeriesOption } from 'echarts'
import dayjs from 'dayjs'
import calendarSerie from '../../defaults/series/calendar'
import { SeriesParams, DataElement } from '../../types'

const series = (params: SeriesParams): SeriesOption => {
  const { data, seriesModifications = {}, theme } = params
  const seriesOption = calendarSerie({
    typeSeriesModifications: seriesModifications as HeatmapSeriesOption,
    theme,
  })

  const dataCalendar = data.map((dataElement: DataElement) => [
    dayjs(dataElement?.date as any).format('YYYY-MM-DD'),
    dataElement.value,
  ])

  const series = [
    {
      data: dataCalendar,
      ...seriesOption,
    },
  ]

  return series as SeriesOption
}

export default series
