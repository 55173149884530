import { styled } from '@mui/material/styles'
import { Align } from 'containers/ReportForm/types'

interface ContainerButtonProps {
  align: Align
}

export const ContainerButton = styled('div')<ContainerButtonProps>(
  ({ align }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: align,
  })
)
