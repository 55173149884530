import accountPlayground from '../accountPlayground'

const userPlayground: User = {
  id: 'userPlaygroundId',
  name: 'User Playground',
  isAdmin: true,
  email: 'userPlayground@shimoku.com',
  currentDateTime: new Date().toISOString(),
  apiToken: '',
  accountInUseId: accountPlayground.id,
  kindOfUser: null,
}

export default userPlayground
