import React, { SVGProps } from 'react'
import cx from 'classnames'

interface customSVG extends SVGProps<SVGSVGElement> {
  primaryColor?: string
  secondaryColor?: string
}

export const Deloitte = (props: customSVG) => {
  const { width, height, primaryColor, secondaryColor } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.5 53"
      style={{
        width,
        height,
      }}
      className={cx(props.className)}
      {...props}
    >
      <title>logo-deloitte</title>
      <path
        fill={primaryColor}
        d="M268.4 45.5c0-4.1 3.4-7.5 7.5-7.5s7.5 3.4 7.5 7.5-3.4 7.5-7.5 7.5-7.5-3.4-7.5-7.5"
      />
      <path
        fill={secondaryColor}
        d="M43.1 25.1c0 8.7-2.3 15.3-7 20-4.7 4.7-11.2 7-19.7 7H0V.1h17.6C25.7.1 32 2.2 36.5 6.5c4.3 4.4 6.6 10.5 6.6 18.6m-14.3.5c0-4.8-.9-8.3-2.8-10.6-1.8-2.3-4.6-3.4-8.4-3.4h-4v29.1h3.1c4.2 0 7.2-1.2 9.2-3.7 2-2.5 2.9-6.3 2.9-11.4M88.5 0h13.1v52.2H88.5zM144.4 32.7c0 6.3-1.7 11.3-5 14.8s-8 5.3-14 5.3c-5.7 0-10.3-1.8-13.7-5.4-3.4-3.6-5.1-8.5-5.1-14.7 0-6.3 1.7-11.2 5-14.7s8-5.2 14-5.2c3.7 0 7 .8 9.9 2.4 2.9 1.6 5 3.9 6.6 7 1.6 2.9 2.3 6.5 2.3 10.5m-24.5 0c0 3.3.4 5.9 1.3 7.6.9 1.7 2.3 2.6 4.4 2.6 2 0 3.5-.9 4.3-2.6.8-1.7 1.3-4.3 1.3-7.6s-.4-5.8-1.3-7.5c-.8-1.7-2.3-2.5-4.3-2.5s-3.4.8-4.3 2.5c-.9 1.7-1.4 4.2-1.4 7.5M149.6 13.4h13.1v38.8h-13.1zM149.6 0h13.1v8.7h-13.1zM189.1 42.3c1.8 0 3.9-.4 6.3-1.3v9.8c-1.8.8-3.4 1.3-5 1.7-1.6.3-3.5.5-5.6.5-4.4 0-7.5-1.1-9.5-3.3-1.9-2.2-2.9-5.6-2.9-10.1v-16h-4.6v-10h4.6V3.5l13.2-2.3v12.2h8.3v10h-8.3v15.1c0 2.5 1.2 3.8 3.5 3.8M219.4 42.3c1.8 0 3.9-.4 6.3-1.3v9.8c-1.8.8-3.4 1.3-5 1.7-1.6.3-3.5.5-5.6.5-4.4 0-7.5-1.1-9.5-3.3-1.9-2.2-2.9-5.6-2.9-10.1v-16h-4.6v-10h4.6v-10l13.2-2.1v12.2h8.3v10h-8.3v15.1c-.1 2.2 1.1 3.5 3.5 3.5M260.6 17.3c-3.1-3-7.5-4.6-13.2-4.6-6 0-10.6 1.7-13.8 5.3-3.2 3.5-4.8 8.5-4.8 15.1 0 6.3 1.7 11.2 5.2 14.7 3.5 3.4 8.4 5.1 14.7 5.1 3 0 5.6-.2 7.8-.6 2.2-.4 4.3-1.1 6.3-2.2l-2-8.7c-1.5.6-2.9 1.1-4.2 1.4-1.9.4-4 .7-6.3.7-2.5 0-4.5-.6-6-1.8-1.5-1.2-2.2-2.9-2.3-5.1h23.3v-5.9c-.1-6-1.6-10.4-4.7-13.4M242.2 28c.2-2.1.8-3.7 1.8-4.7s2.3-1.5 3.9-1.5c1.7 0 3 .6 4 1.7s1.5 2.6 1.6 4.5h-11.3zM78.8 17.3c-3.1-3-7.5-4.6-13.2-4.6-6 0-10.6 1.7-13.8 5.3-3.2 3.5-4.8 8.5-4.8 15.1 0 6.3 1.7 11.2 5.2 14.7 3.5 3.4 8.4 5.1 14.7 5.1 3 0 5.6-.2 7.8-.6 2.2-.4 4.3-1.1 6.3-2.2l-2-8.7c-1.5.6-2.9 1.1-4.2 1.4-1.9.4-4 .7-6.3.7-2.5 0-4.5-.6-6-1.8-1.5-1.2-2.2-2.9-2.3-5.1h23.3v-5.9c0-6-1.6-10.4-4.7-13.4M60.4 28c.2-2.1.8-3.7 1.8-4.7s2.3-1.5 3.9-1.5c1.7 0 3 .6 4 1.7s1.5 2.6 1.6 4.5H60.4z"
      />
    </svg>
  )
}
