import React from 'react'
import { InputLabelProps } from '@mui/material'
import * as S from './input.styled'

export type LabelInputProps = InputLabelProps & {
  label?: string
}

export const LabelInput = ({
  label,
  required,
  id,
  children,
  ...props
}: LabelInputProps) => (
  <>
    {label && (
      <S.InputLabel shrink={false} {...props} htmlFor={id}>
        {required ? `${label} *` : label}
        {children}
      </S.InputLabel>
    )}
  </>
)
