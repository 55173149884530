import React from 'react'
import { IconButton } from '@mui/material'
import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageSizeSelector,
} from '@mui/x-data-grid-pro'
import ChevronLeft from 'icons/Line/chevron-left'
import ChevronRight from 'icons/Line/chevron-right'
import * as S from '../GridFooter.styled'

interface PaginationActionsProps {
  loading: boolean
}

const PaginationActions = (props: PaginationActionsProps) => {
  const { loading } = props
  const apiRef = useGridApiContext()
  const currentPage = useGridSelector(apiRef, gridPageSelector)
  const totalRows = apiRef.current.state.rows.totalRowCount
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector)
  const lastPage = (currentPage + 1) * pageSize >= totalRows
  const disabledPreviousPageButton = currentPage === 0 || loading
  const disabledNextPageButton = lastPage || loading

  const handlePreviousPage = () => {
    const previousPage = currentPage - 1
    apiRef.current.setPage(previousPage)
  }

  const handleNextPage = () => {
    const nextPage = currentPage + 1
    apiRef.current.setPage(nextPage)
  }

  return (
    <S.ContatinerPaginationActions>
      <IconButton
        aria-label="previous page"
        onClick={handlePreviousPage}
        disabled={disabledPreviousPageButton}
      >
        <ChevronLeft
          width="1.5rem"
          height="1.5rem"
          color={
            !disabledPreviousPageButton
              ? 'var(--color-base-icon)'
              : 'var(--action-disabled)'
          }
        />
      </IconButton>
      <IconButton
        aria-label="next page"
        onClick={handleNextPage}
        disabled={disabledNextPageButton}
      >
        <ChevronRight
          width="1.5rem"
          height="1.5rem"
          color={
            !disabledNextPageButton
              ? 'var(--color-base-icon)'
              : 'var(--action-disabled)'
          }
        />
      </IconButton>
    </S.ContatinerPaginationActions>
  )
}

export default PaginationActions
