import { getGridNumericOperators, GridColDef } from '@mui/x-data-grid-pro'
import renderTableCell from 'containers/Table/utils/renderTableCell'
import { TablePropertiesColumn } from 'lib/reports/types'
import {
  TABLE_COLUMN_TYPES,
  TABLE_COLUMN_FILTER_OPERATORS,
  TableColumnFilterOperatorTypes,
} from 'containers/Table/types'
import { COLUMN_WIDTH } from 'containers/Table/utils/generateTableColumns/getColumn'

const { NUMBER } = TABLE_COLUMN_TYPES

const {
  NUMBER_EQUAL,
  NUMBER_DIFFERENT,
  NUMBER_GREATER_THAN,
  NUMBER_GREATER_OR_EQUAL_THAN,
  NUMBER_LESS_THAN,
  NUMBER_LESS_OR_EQUAL_THAN,
} = TABLE_COLUMN_FILTER_OPERATORS
const NUMBER_OPERATORS = [
  NUMBER_EQUAL,
  NUMBER_DIFFERENT,
  NUMBER_GREATER_THAN,
  NUMBER_GREATER_OR_EQUAL_THAN,
  NUMBER_LESS_THAN,
  NUMBER_LESS_OR_EQUAL_THAN,
]
export const getNumberFilterOperators = () =>
  getGridNumericOperators().filter(({ value }) =>
    NUMBER_OPERATORS.includes(value as TableColumnFilterOperatorTypes)
  )

interface GetNumberColumnParams {
  column: TablePropertiesColumn
}

const getNumberColumn = (params: GetNumberColumnParams): GridColDef => {
  const { column } = params
  const { field, headerName, width = COLUMN_WIDTH } = column

  return {
    field,
    headerName,
    type: NUMBER,
    width,
    filterOperators: getNumberFilterOperators(),
    valueFormatter: ({ value }) => Number(value).toLocaleString('es-ES'),
    renderCell: (gridRenderCellParams) =>
      renderTableCell({ gridRenderCellParams, column }),
  }
}

export default getNumberColumn
