import { OptionMultifilter } from 'containers/Multifilter/types'
import { REPORT_TYPES } from 'lib/reports'

const { MULTIFILTER } = REPORT_TYPES
interface DisableReportsLinkedToMultifiltersParams {
  reports: Report[]
}

const disableReportsLinkedToMultifilters = (
  params: DisableReportsLinkedToMultifiltersParams
) => {
  const { reports } = params

  const multifilterReports = reports.filter(
    (report: Report) => report?.reportType === MULTIFILTER
  )

  if (!multifilterReports.length) {
    return reports
  }

  const dataMultiFilterReports = multifilterReports.map((report: Report) =>
    report?.chartData ? JSON.parse(report.chartData) : []
  )

  const reportIdsLinkedToMultilfilters = new Set(
    dataMultiFilterReports
      .flat()
      .flatMap((option: OptionMultifilter) => option?.reportId)
  )

  const defaultReportsLinkedToMultifilters = new Set(
    dataMultiFilterReports.flatMap(
      (options: OptionMultifilter[]) => options[0]?.reportId
    )
  )

  const reportsLinkedToMultiFiltersDisabled = reports.map(
    (report: Report) =>
      ({
        ...report,
        isDisabled:
          reportIdsLinkedToMultilfilters.has(report.id) &&
          !defaultReportsLinkedToMultifilters.has(report.id),
      } as Report)
  )

  return reportsLinkedToMultiFiltersDisabled
}

export default disableReportsLinkedToMultifilters
