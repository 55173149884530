import { sanitizeString } from '../../strings'
import getAppName from '../getAppName'

export const getAppNormalizedName = (app: App): string => {
  if (app.normalizedName) {
    return app.normalizedName
  }
  if (app.type.normalizedName) {
    return app.type.normalizedName
  }
  return sanitizeString(getAppName(app))
}

export default getAppNormalizedName
