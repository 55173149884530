import { Theme } from '@mui/material'

const getCSSVariables = (theme: Theme) => {
  return {
    '--md': theme.breakpoints.values.md,
    '--drawer-width': `${theme.custom.dimensions.drawerWidth}rem`,
    '--header-height': `${theme.custom.dimensions.headerHeight}rem`,
    '--font-family': `${theme.typography.fontFamily}`,
    '--h1-font-size': `${theme.typography.h1.fontSize}`,
    '--h1-font-weight': `${theme.typography.h1.fontWeight}`,
    '--h1-letter-spacing': `${theme.typography.h1.letterSpacing}`,
    '--h2-font-size': `${theme.typography.h2.fontSize}`,
    '--h3-font-size': `${theme.typography.h3.fontSize}`,
    '--h3-font-weight': `${theme.typography.h3.fontWeight}`,
    '--h4-font-size': `${theme.typography.h4.fontSize}`,
    '--h4-font-weight': `${theme.typography.h4.fontWeight}`,
    '--h4-letter-spacing': `${theme.typography.h4.letterSpacing}`,
    '--h5-font-size': `${theme.typography.h5.fontSize}`,
    '--h5-font-weight': `${theme.typography.h5.fontWeight}`,
    '--h5-letter-spacing': `${theme.typography.h5.letterSpacing}`,
    '--h6-font-size': `${theme.typography.h6.fontSize}`,
    '--h6-letter-spacing': `${theme.typography.h6.letterSpacing}`,
    '--bodyM400-letter-spacing': `${theme.typography.bodyM400.letterSpacing}`,
    '--bodyS400-font-size': `${theme.typography.bodyS400.fontSize}`,
    '--bodyS400-letterSpacing': `${theme.typography.bodyS400.letterSpacing}`,
    '--bodyS700-font-size': `${theme.typography.bodyS700.fontSize}`,
    '--bodyS700-font-weight': `${theme.typography.bodyS700.fontWeight}`,
    '--bodyS700-letter-spacing': `${theme.typography.bodyS700.letterSpacing}`,
    '--note400-font-size': `${theme.typography.note400.fontSize}`,
    '--color-stripe-light': '#f5f8fc',
    '--color-grey-50': theme.palette.grey[50],
    '--color-grey-100': theme.palette.grey[100],
    '--color-grey-200': theme.palette.grey[200],
    '--color-grey-300': theme.palette.grey[300],
    '--color-grey-400': theme.palette.grey[400],
    '--color-grey-500': theme.palette.grey[500],
    '--color-grey-600': theme.palette.grey[600],
    '--color-grey-700': theme.palette.grey[700],
    '--color-grey-800': theme.palette.grey[800],
    '--box-shadow-xl': theme.custom.shadows.xl,
    '--box-shadow-l': theme.custom.shadows.l,
    '--box-shadow-m': theme.custom.shadows.m,
    '--box-shadow-s': theme.custom.shadows.s,
    '--border-radius-xxl': theme.custom.radius.xxl,
    '--border-radius-xl': theme.custom.radius.xl,
    '--border-radius-l': theme.custom.radius.l,
    '--border-radius-m': theme.custom.radius.m,
    '--border-radius-s': theme.custom.radius.s,
    '--border-radius-xs': theme.custom.radius.xs,
    '--color-primary': theme.palette.primary.main,
    '--color-primary-light': theme.palette.primary.light,
    '--color-primary-dark': theme.palette.primary.dark,
    '--color-secondary': theme.palette.secondary.main,
    '--color-secondary-light': theme.palette.secondary.light,
    '--color-secondary-dark': theme.palette.secondary.dark,
    '--color-success': theme.palette.success.main,
    '--color-success-light': theme.palette.success.light,
    '--color-warning': theme.palette.warning.main,
    '--color-warning-light': theme.palette.warning.light,
    '--color-error': theme.palette.error.main,
    '--color-error-light': theme.palette.error.light,
    //@ts-ignore
    '--color-status-error': theme.palette.status.error.main,

    '--color-white': theme.palette.common.white,
    '--color-black': theme.palette.common.black,
    '--color-base-icon': theme.custom.icon.base,

    '--background-default': theme.palette.background.default,
    '--background-paper': theme.palette.background.paper,

    '--logo': theme.palette.primary.main,

    '--text-primary': theme.palette.text.primary,
    '--text-secondary': theme.palette.text.secondary,
    '--text-disabled': theme.palette.text.disabled,
    //@ts-ignore
    '--text-hint': theme.palette.text.hint,

    '--divider': theme.palette.divider,

    '--action-disabled': theme.palette.action.disabled,

    '--gap-indicators': theme.custom.reports.indicators.gap,
    '--complementary-red': theme.palette.complementary.red[500],
    '--complementary-red-light': theme.palette.complementary.red[400],
    '--complementary-green': theme.palette.complementary.green[500],
    '--complementary-yellow': theme.palette.complementary.yellow[500],
    '--complementary-orange': theme.palette.complementary.orange[500],
    '--complementary-aqua': theme.palette.complementary.aqua[500],
    '--complementary-violet': theme.palette.complementary.violet[500],
    '--complementary-violet-light': theme.palette.complementary.violet[400],
    '--chart-C1': theme.custom.chart.colors.C1,
    '--chart-C2': theme.custom.chart.colors.C2,
    '--chart-C3': theme.custom.chart.colors.C3,
    '--chart-C4': theme.custom.chart.colors.C4,
    '--chart-C5': theme.custom.chart.colors.C5,
    '--chart-C6': theme.custom.chart.colors.C6,
    '--chart-C7': theme.custom.chart.colors.C7,
    '--chart-C8': theme.custom.chart.colors.C8,
    '--chart-C9': theme.custom.chart.colors.C9,
    '--chart-C10': theme.custom.chart.colors.C10,
    '[data-theme="dark"]': {
      '--logo': theme.palette.common.white,
    },
    '--zIndex-xs': theme.custom.zIndex.xs,
    '--zIndex-s': theme.custom.zIndex.s,
    '--zIndex-m': theme.custom.zIndex.m,
    '--zIndex-l': theme.custom.zIndex.l,
    '--zIndex-xl': theme.custom.zIndex.xl,

    '--stripe-highlight-gradient':
      'linear-gradient(90deg, #202A36 0%, #7208B4 48.44%, #F3B922 100%)',
  }
}

export default getCSSVariables
