import { useLocation } from 'react-router-dom'

export const useDashboardParams = () => {
  const { search, pathname } = useLocation()
  const params = new URLSearchParams(search)

  const shared = params.get('shared') === 'true'
  const token = params.get('token') || ''

  const [, dashboardId] = pathname.split('/')

  return { shared, token, dashboardId }
}
