import React from 'react'
import { Typography, AlertTitle, Alert } from '@mui/material'
import globalStyles from 'material/global.module.css'
import styles from './ReportWrapper.module.css'
import { formatMessage } from 'i18n/ShimokuIntl'
import getReportComponent from 'components/Reports/getReport'
import ReportErrorBundary from './ReportErrorBundary'

interface ReportWrapperProps {
  report: Report
  rows: number
  columns: number
  isBentoBox?: boolean
}

export const ReportWrapper = (props: ReportWrapperProps) => {
  const { report, rows, columns, isBentoBox } = props
  const { title, description } = report

  return (
    <ReportErrorBundary>
      {Boolean(title) && (
        <header className={styles.header}>
          <Typography component="h2" variant="h6" className={styles.title}>
            {title}
          </Typography>
        </header>
      )}
      <div className={styles.container_report}>
        {getReportComponent({
          report,
          columns,
          rows,
          isBentoBox,
        })}
      </div>
      {Boolean(description) && (
        <Alert severity="info" icon={false} className={globalStyles.caption}>
          <AlertTitle>{formatMessage('generic.reportExplanation')}</AlertTitle>
          {description}
        </Alert>
      )}
    </ReportErrorBundary>
  )
}

export default ReportWrapper
