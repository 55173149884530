import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useNavigation as useNavigationContext } from 'contexts/navigation'

const useHistoryManager = () => {
  const history = useHistory()
  const { setHistoryStack } = useNavigationContext()

  useEffect(() => {
    setHistoryStack({
      historyItems: [
        {
          pathname: history.location.pathname,
          key: history.location.key as string,
        },
      ],
      position: 0,
    })

    history.listen((location) => {
      setHistoryStack(
        ({ historyItems: currentHistoryItems, position: currentPosition }) => {
          const sameRoute =
            currentHistoryItems[currentPosition as number].pathname ===
            location.pathname
          const backHistory =
            currentHistoryItems[(currentPosition as number) - 1]?.key ===
            location.key
          const forwardHistory =
            currentHistoryItems[(currentPosition as number) + 1]?.key ===
            location.key

          if (sameRoute) {
            return {
              historyItems: [
                ...currentHistoryItems.slice(0, -1),
                {
                  pathname: location.pathname,
                  key: location.key as string,
                },
              ],
              position: currentPosition as number,
            }
          }

          if (backHistory) {
            return {
              historyItems: [...currentHistoryItems],
              position: (currentPosition as number) - 1,
            }
          }

          if (forwardHistory) {
            return {
              historyItems: [...currentHistoryItems],
              position: (currentPosition as number) + 1,
            }
          }

          return {
            historyItems: [
              ...currentHistoryItems,
              {
                pathname: location.pathname,
                key: location.key as string,
              },
            ],
            position: currentHistoryItems.length,
          }
        }
      )
    })
  }, []) // eslint-disable-line
}

export default useHistoryManager
