export const JS_SNACKBAR = 'js_snackbar'
export const JS_UNIVERSE_ID = 'js_universe_id'
export const JS_ACCESS_TOKEN = 'js_access_token'
export const JS_ENVIRONMENT = 'js_environment'
export const JS_WORKSPACE_ID = 'js_workspace_id'

export interface ActionScript {
  id: string
  order: number
  codeFragment: string
}
