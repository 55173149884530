import { IndicatorColor, IndicatorVariant } from 'lib/reports/types'

type getCompatibleVariantParams = {
  variant?: IndicatorVariant
  color?: IndicatorColor
}

export const getCompatibleVariant = ({
  color,
  variant = 'default',
}: getCompatibleVariantParams): IndicatorVariant =>
  color?.includes('background') ? 'contained' : variant

export const getCompatibleColor = (color?: string): any =>
  color?.includes('background') ? color.replace('-background', '') : color
