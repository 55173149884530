import { API } from 'aws-amplify'
import { GraphQLOperationParams } from 'hooks/useGraphQLOperation/types'
import getDashboardsAppsWithReports from 'lib/dashboards/getDashboardsAppsWithReports'
import getDashboard from 'gql/queries/getDashboard'

interface GetPublicDashboardParams {
  dashboardId: string
  token: string
  graphQLOperation: (params: GraphQLOperationParams) => Promise<any>
}

const getPublicDashboard = async (params: GetPublicDashboardParams) => {
  const { dashboardId, token, graphQLOperation } = params

  const resGetDashboardQuery: any = await API.graphql({
    query: getDashboard,
    variables: {
      dashboardId,
      publicPermissions: {
        resourceId: dashboardId,
        token,
      },
    },
    authMode: 'API_KEY',
  })

  const [dashboardsAppsWithReports] = await getDashboardsAppsWithReports({
    dashboards: [resGetDashboardQuery.data.getDashboard],
    graphQLOperation,
    isPublic: true,
    token,
  })

  return dashboardsAppsWithReports
}

export default getPublicDashboard
