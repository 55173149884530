import { Theme } from '@mui/material/styles'
import { TitleComponentOption } from 'echarts'
import { TEXT_STYLE } from './general'

interface TitleParams {
  title?: string
  subtitle?: string
  theme: Theme
}

const title = (params: TitleParams): TitleComponentOption => {
  const { title: text, subtitle: subtext, theme } = params

  return {
    id: '',
    show: true,
    text,
    link: '',
    target: 'blank',
    textAlign: 'left',
    textVerticalAlign: 'top',
    triggerEvent: false,
    padding: 0,
    itemGap: 8,
    left: 5,
    top: 4,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderWidth: 0,
    borderType: 'solid',
    borderRadius: 0,
    subtext,
    sublink: '',
    subtarget: 'blank',
    textStyle: {
      ...TEXT_STYLE(theme),
    },
    subtextStyle: {
      ...TEXT_STYLE(theme),
    },
  }
}

export default title
