import { Dispatch, SetStateAction } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  FilterValues,
  FilterInput,
  FilterValueDateRange,
  FilterDefaultValue,
  FILTER_TYPES,
} from 'containers/FilterDataSet/types'
import { NumericFilterOperatorType } from 'containers/Table/types'

dayjs.extend(utc)

interface HandleDateRangeParams {
  value: FilterDefaultValue
  filter: FilterInput
  setFilterValues: Dispatch<SetStateAction<FilterValues>>
}

interface HandleNumericFilterParams {
  value: number
  filter: FilterInput
  setFilterValues: Dispatch<SetStateAction<FilterValues>>
  setOperator: Dispatch<SetStateAction<NumericFilterOperatorType>>
  operator: NumericFilterOperatorType
}

export const handleDateRange = (params: HandleDateRangeParams) => {
  const { value, filter, setFilterValues } = params

  const [defaultStartDate, defaultEndDate] = value
  const startDate = defaultStartDate
    ? dayjs(defaultStartDate).utc().format()
    : null
  const endDate = defaultEndDate ? dayjs(defaultEndDate).utc().format() : null

  if (startDate && endDate) {
    setFilterValues((filterValues) => ({
      ...filterValues,
      [filter.field]: [startDate, endDate] as FilterValueDateRange,
    }))
  }
}

export const handleNumericFilter = (params: HandleNumericFilterParams) => {
  const { value, filter, setFilterValues, operator, setOperator } = params
  setOperator(operator)
  setFilterValues(() => ({
    [filter.field]: Number(value),
  }))
}

export const handleCategoricalFilter = (params: {
  value: string | null
  eventName: string
  filter: FilterInput
  setFilterValues: Dispatch<SetStateAction<FilterValues>>
}) => {
  const { value, filter, setFilterValues } = params

  if (filter.inputType === FILTER_TYPES.CATEGORICAL_MULTI) {
    return setFilterValues((prevState) => ({
      ...prevState,
      [filter.field]: value ? value.split(',') : [],
    }))
  }
  return setFilterValues((prevState) => ({
    ...prevState,
    [filter.field]: value,
  }))
}
