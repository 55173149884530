import { REPORT_TYPES } from 'lib/reports'

const {
  INDICATORS,
  BARCHART,
  LINECHART,
  STOCKLINECHART,
  HTML,
  FILTER,
  IFRAME,
  ECHARTS,
  MULTIFILTER,
  TABLE,
  INDICATOR,
  BUTTON,
  FORM,
} = REPORT_TYPES

export const DEFAULT_REPORT_ROWS = {
  [INDICATORS]: 1,
  [BARCHART]: 2,
  [LINECHART]: 2,
  [STOCKLINECHART]: 2,
  [HTML]: 1,
  [FILTER]: 3,
  [IFRAME]: 1,
  [ECHARTS]: 2,
  [MULTIFILTER]: 1,
  [TABLE]: 4,
  [INDICATOR]: 1,
  [BUTTON]: 1,
  [FORM]: 1,
}

export const DEFAULT_ROWS = 4

interface GetReportRowsParams {
  report: Report
}

const getReportRowsParams = (params: GetReportRowsParams) => {
  const {
    report: { reportType, sizeRows },
  } = params

  const defaultRows = reportType ? DEFAULT_REPORT_ROWS[reportType] : undefined

  return sizeRows || defaultRows || DEFAULT_ROWS
}

export default getReportRowsParams
