import { useState } from 'react'
import { FieldGroup } from 'components/ReportForm/types'

interface UseFormGroupsProps {
  currentFieldGroups: FieldGroup[]
}

const useFormGroups = (props: UseFormGroupsProps) => {
  const { currentFieldGroups } = props
  const defaultHideFormGroupIds = new Set(
    currentFieldGroups
      .filter((currentFieldGroup) => Boolean(currentFieldGroup?.nextFormGroup))
      .map((currentFieldGroup) => currentFieldGroup?.nextFormGroup?.id)
  )
  const [hideFormGroupIds, setHideFormGroupIds] = useState<
    Set<string | undefined>
  >(defaultHideFormGroupIds)
  const fieldGroups = currentFieldGroups.filter(
    (currentFieldGroup) => !hideFormGroupIds.has(currentFieldGroup?.id)
  )

  return { fieldGroups, hideFormGroupIds, setHideFormGroupIds }
}

export default useFormGroups
