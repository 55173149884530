import { createTheme } from '@mui/material/styles'
import COMMON_THEME_OPTIONS, {
  palette as PALLETE,
} from 'theme/common/theme-options'
import { DOMAIN_OPTIONS, ThemeParameters } from 'theme/types'

export const generateLightTheme = ({
  domain,
  logo,
  pallete = PALLETE,
}: ThemeParameters) => {
  const lightTheme = COMMON_THEME_OPTIONS
  lightTheme.custom.domain = domain
  lightTheme.custom.logo = logo
  lightTheme.palette = {
    ...pallete,
    mode: 'light',
  }

  lightTheme.components.MuiFilledInput = {
    styleOverrides: {
      root: {
        backgroundColor: 'var(--color-white)',
        '&:hover': {
          backgroundColor: 'var(--color-white)',
        },
        '&:focus': {
          backgroundColor: 'var(--color-white)',
        },
        '&$focused': {
          backgroundColor: 'var(--color-white)',
        },
      },
    },
  }
  lightTheme.components.MuiSelect = {
    select: {
      backgroundColor: 'var(--color-white)',
    },
  }
  lightTheme.components.MuiAccordionDetails = {
    styleOverrides: {
      root: {
        backgroundColor: 'var(--color-white)',
      },
    },
  }
  const theme = createTheme(lightTheme)
  return theme
}

const { SHIMOKU } = DOMAIN_OPTIONS

export const lightTheme = generateLightTheme({ domain: SHIMOKU })
