import { lazy } from 'react'
import { reloadPageOnChunkError } from 'lib/lazy'
import { haveInvitationToken, isClientDomain } from 'lib/domains'
import { BasicRoute } from '../types'

export const ROOT_PATH = '/'

const SIGN_IN: BasicRoute = {
  routeName: 'Sign In',
  component: lazy(() => import('pages/SignIn').catch(reloadPageOnChunkError)),
  exact: true,
}

export const SIGN_UP: BasicRoute = {
  routeName: 'Sign Up',
  component: lazy(() => import('pages/SignUp').catch(reloadPageOnChunkError)),
  exact: true,
  renderConditional: haveInvitationToken() || !isClientDomain(),
}

const FORGOT_PASSWORD: BasicRoute = {
  routeName: 'Forgot Password',
  component: lazy(() =>
    import('pages/ForgotPassword').catch(reloadPageOnChunkError)
  ),
  exact: true,
}

const RESET_PASSWORD: BasicRoute = {
  routeName: 'Reset Password',
  component: lazy(() =>
    import('pages/ResetPassword').catch(reloadPageOnChunkError)
  ),
  exact: true,
}

const CONFIRM_ACCOUNT_CODE: BasicRoute = {
  routeName: 'Confirm Account Code',
  component: lazy(() =>
    import('pages/ConfirmAccountCode').catch(reloadPageOnChunkError)
  ),
  exact: true,
}

const CONFIRM_ACCOUNT_LINK: BasicRoute = {
  routeName: 'Confirm Account Link',
  component: lazy(() =>
    import('pages/ConfirmAccountLink').catch(reloadPageOnChunkError)
  ),
  exact: true,
}

const SHOPIFY_SIGN_IN_PATH = '/shopify-sign-in/:appTypeId'
const SHOPIFY_SIGN_IN: BasicRoute = {
  routeName: 'Shopify Sign In',
  component: lazy(() =>
    import('pages/ShopifySignIn').catch(reloadPageOnChunkError)
  ),
  exact: true,
  path: SHOPIFY_SIGN_IN_PATH,
}

const SHOPIFY_CALLBACK_PATH = '/shopify-callback/:appTypeId'
const SHOPIFY_CALLBACK: BasicRoute = {
  routeName: 'Shopify Callback',
  component: lazy(() =>
    import('pages/ShopifyCallback').catch(reloadPageOnChunkError)
  ),
  exact: true,
  path: SHOPIFY_CALLBACK_PATH,
}

export const PLAYGROUND_PATH = '/playground'

const PLAYGROUND: BasicRoute = {
  routeName: 'Playground',
  component: lazy(() =>
    import('pages/Playground').catch(reloadPageOnChunkError)
  ),
  exact: false,
  path: PLAYGROUND_PATH,
}

export const PLAYGROUND_NO_SERVER_RUNNING: BasicRoute = {
  routeName: 'No Server Running',
  component: lazy(() =>
    import('pages/Playground/NoServerRunning').catch(reloadPageOnChunkError)
  ),
  exact: true,
}

const getDefaultRoutes = () => [
  SIGN_IN,
  SIGN_UP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CONFIRM_ACCOUNT_CODE,
  CONFIRM_ACCOUNT_LINK,
  SHOPIFY_SIGN_IN,
  SHOPIFY_CALLBACK,
  PLAYGROUND,
]

export default getDefaultRoutes
