const createFile = /* GraphQL */ `
  mutation createFile($input: CreateFileInputExposed!) {
    createFile(input: $input) {
      id
      fileName
      appId
      name
      url
    }
  }
`
export default createFile
