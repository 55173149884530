import { REPORT_TYPES } from 'lib/reports'

const {
  INDICATORS,
  HTML,
  MULTIFILTER,
  FORM,
  FILTERDATASET,
  INDICATOR,
  TABS,
  TABLE,
  BUTTON,
} = REPORT_TYPES

export const inlineReports = [
  INDICATORS,
  HTML,
  MULTIFILTER,
  FORM,
  FILTERDATASET,
  INDICATOR,
  TABS,
  TABLE,
  BUTTON,
  undefined,
]

interface IsInlineReportParams {
  reportType?: ReportType
  cols: number
  paddingRight: number
  paddingLeft: number
  gridColumns: number
}

const isInlineReport = (params: IsInlineReportParams) => {
  const { reportType, cols, paddingRight, paddingLeft, gridColumns } = params

  return (
    inlineReports.some((inlineReport) => inlineReport === reportType) &&
    cols + paddingRight + paddingLeft >= gridColumns
  )
}

export default isInlineReport
