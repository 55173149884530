import React, { Dispatch, SetStateAction } from 'react'
import { FilterValues, FilterInput } from 'containers/FilterDataSet/types'
import getInput from './getInput'
import * as S from './FilterDataSet.styled'
import { NumericFilterOperatorType } from 'containers/Table/types'

interface FilterDataSetProps {
  filter: FilterInput
  setFilterValues: Dispatch<SetStateAction<FilterValues>>
  setOperator: Dispatch<SetStateAction<NumericFilterOperatorType>>
}
const FilterDataSet = (props: FilterDataSetProps) => {
  const { filter, setFilterValues, setOperator } = props

  return (
    <S.Container>
      {getInput({ filter, setFilterValues, setOperator })}
    </S.Container>
  )
}

export default FilterDataSet
