import { REPORT_TYPES } from 'lib/reports'
import generateFilters from 'containers/FilterDataSet/generateFilters'
import { FILTER_TYPES, FilterInput } from 'containers/FilterDataSet/types'

const { TABLE } = REPORT_TYPES
const { CATEGORICAL_SINGLE } = FILTER_TYPES

const getDefaultReportDataSets = (
  reports: Report[],
  filterDataSetReports: any[]
) => {
  return reports
    .filter(
      ({ reportType, reportDataSets }) => reportType !== TABLE && reportDataSets
    )
    .flatMap((report) => {
      return (report.reportDataSets?.items || []).map((item: ReportDataSet) => {
        let updatedDataSet = { ...item.dataSet }

        filterDataSetReports.forEach((filterReport) => {
          const filters: FilterInput[] =
            filterReport.properties &&
            JSON.parse(filterReport.properties).filter
          const mapping = filterReport.reportProperties?.mapping

          if (filters) {
            filters.forEach((filter) => {
              if (filter.inputType === CATEGORICAL_SINGLE) {
                const defaultOption = filter.options?.[0]
                const defaultOperations = filter.operations?.[0]

                const generatedFilters = generateFilters({
                  filter: [filter],
                  mapping: mapping,
                  filterValues: { [filter.field]: defaultOption },
                  operator: defaultOperations,
                })

                generatedFilters.forEach((generatedFilter) => {
                  if (generatedFilter.dataSetId === updatedDataSet.id) {
                    updatedDataSet.filter = generatedFilter.filter
                    updatedDataSet.loading = true
                    updatedDataSet.fullfilled = true
                  }
                })
              }
            })
          }
        })

        return {
          ...item,
          dataSet: updatedDataSet,
        }
      })
    })
}

export default getDefaultReportDataSets
