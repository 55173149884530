import React from 'react'
import { Auth } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from 'redux/user'
import { selectAccount } from 'redux/account'
import { selectUniverseModules } from 'redux/universeModules'
import UserMenuComponent from 'components/UserMenu'
import { selectCurrentRoles } from 'redux/roles'
import { setModalState } from 'redux/modal'
import { ModalTypes } from 'redux/modal/types'
import { usePostHog } from 'posthog-js/react'
import { SUSCRIPTIONS_EVENTS, USER_EVENTS } from 'lib/postHogEvents'

const UserMenu = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const account: Account = useSelector(selectAccount)
  const universeModules = useSelector(selectUniverseModules)
  const rolePermissions = useSelector(selectCurrentRoles)
  const posthog = usePostHog()

  const signOut = async (global: any) => {
    try {
      await Auth.signOut({ global: Boolean(global) })
      posthog?.capture(USER_EVENTS.SIGN_OUT)
      posthog?.reset()
      window.location.reload()
    } catch (error) {
      if (Boolean(global)) {
        signOut(false)
      }
    }
  }

  const handleUpgradePlan = () => {
    posthog?.capture(SUSCRIPTIONS_EVENTS.UPGRADE_PLAN_MODAL_OPENED)
    dispatch(setModalState({ open: true, type: ModalTypes.PRICING_TABLE }))
  }

  return (
    <UserMenuComponent
      user={user}
      account={account}
      universeModules={universeModules}
      signOut={signOut}
      handleUpgradePlan={handleUpgradePlan}
      hasEditPermission={rolePermissions.USER_MANAGEMENT === 'WRITE'}
    />
  )
}

export default UserMenu
