import { EChartsOption, SeriesOption } from 'echarts'
import addOptionModifications from '../../addOptionModifications'
import defaultOptionModifications from './defaultOptionModifications'
import titleOption from '../../defaults/title'
import tooltipOption from '../../defaults/tooltip'
import toolboxOption, { getDefaultToolbox } from '../../defaults/toolbox'
import visualMapOption from '../../defaults/visualMap'
import calendarComponentOption from '../../defaults/calendar'
import seriesOption from './series'
import { Option, ChartParams, VisualMapType } from '../../types'
import validObject from '../validObject'

const calendar = (params: ChartParams): EChartsOption => {
  const {
    theme,
    title = '',
    subtitle = '',
    tooltip = true,
    toolbox = getDefaultToolbox(theme),
    visualMap = 'piecewise',
    data = [{}],
    optionModifications = {},
  } = params

  const paddingCalendar = validObject({
    object: {
      title: Boolean(title),
      subtitle: Boolean(subtitle),
      visualMap,
      toolbox: validObject({ object: toolbox }),
    },
  })

  const seriesModifications = optionModifications?.series as SeriesOption

  const defaultOption: Option = {
    title: titleOption({ title, subtitle, theme }),
    tooltip: tooltipOption({ tooltip, theme }),
    toolbox: toolboxOption({ ...toolbox, theme }),
    visualMap: visualMapOption({ type: visualMap as VisualMapType, theme }),
    calendar: calendarComponentOption(theme),
    series: seriesOption({ data, seriesModifications, theme }),
  }

  const calendarOption = addOptionModifications({
    defaultOption,
    defaultOptionModifications: defaultOptionModifications({
      data,
      paddingCalendar,
    }),
    optionModifications,
  })

  return calendarOption
}

export default calendar
