const universeFragment = /* GraphQL */ `
  fragment universeFields on UniverseFilteredExposed {
    id
    name
    theme
    activeUniversePlan {
      id
      planType {
        id
        limits
        price
        type
      }
    }
    modules {
      items {
        enabled
        id
        type
      }
    }
  }
`

export default universeFragment
