import { InputType } from 'components/ReportForm/types'
import generateDataSet from './generateDataSet'
import { ReportDataSetWithMapping, ReportDataSetProperties } from './types'
import { formatMessage } from 'i18n/ShimokuIntl'

const { select } = InputType

export const generateReportDataSetsWithMapping = (
  reportDataSets: ReportDataSet[]
): ReportDataSetWithMapping => {
  return reportDataSets.reduce((reportDataSetsWithMapping, reportDataSet) => {
    const { properties } = reportDataSet
    const { mapping }: ReportDataSetProperties = properties
      ? JSON.parse(properties)
      : {}
    const {
      dataSet: { data },
    } = reportDataSet
    return {
      ...reportDataSetsWithMapping,
      [`#{${reportDataSet.id}}`]: generateDataSet({
        dataItems: data as Data[],
        mapping,
      }),
    }
  }, {})
}

interface GetDefaultValueSelectReportFormParams {
  currentField: any
  fields: any
  defaultFields: any
  parsedCustomField: string
}

const getDefaultValueSelectReportForm = (
  params: GetDefaultValueSelectReportFormParams
) => {
  const { currentField, fields, defaultFields, parsedCustomField } = params
  const { mapping, dependsOn, options: defaultOptions } = currentField
  let defaultValue = parsedCustomField[mapping]
  let options: string[] = []

  if (!Boolean(dependsOn)) {
    options = defaultOptions
  }

  if (Boolean(dependsOn)) {
    const fieldDependsOn = fields.find(
      ({ fieldName }: any) => fieldName === dependsOn
    )

    if (Boolean(fieldDependsOn)) {
      const { mapping } = fieldDependsOn
      options = defaultOptions[mapping]
    } else {
      const fieldDependsOn = defaultFields.find(
        ({ fieldName }: any) => fieldName === dependsOn
      )
      const defaultValueSelectReportForm = getDefaultValueSelectReportForm({
        currentField: fieldDependsOn,
        fields,
        defaultFields,
        parsedCustomField,
      }) as any
      options = defaultOptions[defaultValueSelectReportForm]
    }
  }

  const [firstOption] = options
  return options.includes(defaultValue) ? defaultValue : firstOption
}

interface GetFieldReportForm {
  currentField: any
  fields: any
  defaultFields: any
  parsedCustomField: any
}

const getFieldReportForm = (params: GetFieldReportForm) => {
  const { currentField, fields, defaultFields, parsedCustomField } = params
  const { mapping, inputType } = currentField
  let defaultValue = parsedCustomField[mapping]

  if (inputType === select) {
    defaultValue = getDefaultValueSelectReportForm({
      currentField,
      fields,
      defaultFields,
      parsedCustomField,
    })
  }

  const field = {
    ...currentField,
    mapping: defaultValue,
    reference: currentField.mapping,
  }

  return field
}

export const generateFormReportDataSetsWithMapping = (
  reportDataSets: ReportDataSet[]
): ReportDataSetWithMapping => {
  return reportDataSets.reduce((reportDataSetsWithMapping, reportDataSet) => {
    const {
      properties,
      dataSet: { data },
    } = reportDataSet
    const { fields, sendButton, variant }: any = properties
      ? JSON.parse(properties)
      : {}
    if (!fields) throw new Error(formatMessage('errors.formWithoutFields'))

    const allData = data?.map((dataItem: Data) => {
      if (!dataItem.customField1)
        throw new Error(formatMessage('errors.formWithoutCustomField1'))
      const parsedCustomField = JSON.parse(dataItem.customField1)
      const fieldsWithReference = {
        dataId: dataItem.id,
        dataSetId: dataItem.dataSetId,
        sendButton,
        variant,
        fields: fields.map((childFields: any) => {
          return {
            ...childFields,
            fields: childFields.fields.reduce(
              (fields: [], currentField: any) => {
                const field = getFieldReportForm({
                  currentField,
                  fields,
                  defaultFields: childFields.fields,
                  parsedCustomField,
                })
                return [...fields, field]
              },
              []
            ),
          }
        }),
      }
      return fieldsWithReference
    })

    return {
      ...reportDataSetsWithMapping,
      ...allData,
    }
  }, {})
}
