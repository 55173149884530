import React, { FC, useEffect, useState } from 'react'
import Sentry from 'tracking/Sentry'
import { usePostHog } from 'posthog-js/react'
import ReactGA from 'react-ga'
import LoadingPage from 'components/LoadingPage/LoadingPage'
import { useDashboardParams } from 'hooks/useDashboardParams'
import useGraphQLOperation from 'hooks/useGraphQLOperation'
import getPublicDashboardsAppsRoutes from 'components/Routes/routes/getPublicDashboardsRoutes'
import useHistoryManager from 'contexts/navigation/hooks/useHistoryManager'
import { selectDashboards, setDashboards } from 'redux/dashboards'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import NotFound from 'pages/NotFound'
import FooterLite from 'components/FooterLite'
import DashboardMenu from 'components/Menu/DashboardMenu'
import DrawerMenu from 'components/Menu/DrawerMenu'
import Fab from 'components/Fab'
import getPublicDashboard from 'lib/dashboards/getPublicDashboard'
import Menu from 'icons/Line/menu'
import * as S from './SharedRoutes.styled'

export const SharedRoutes: FC = () => {
  useHistoryManager()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [routes, setRoutes] = useState<JSX.Element[]>()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { token, dashboardId } = useDashboardParams()
  const [dashboard]: Dashboard[] = useSelector(selectDashboards)
  const location = useLocation()
  const history = useHistory()
  const posthog = usePostHog()
  const graphQLOperation = useGraphQLOperation()

  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        const dashboard = await getPublicDashboard({
          dashboardId,
          token,
          graphQLOperation,
        })
        const publicRoutes = getPublicDashboardsAppsRoutes({
          dashboard,
        })
        dispatch(setDashboards([dashboard]))
        setRoutes(publicRoutes)
      } catch (error) {
        Sentry.captureException(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchDashboard()
  }, [dashboardId, dispatch, token]) // eslint-disable-line

  useEffect(() => {
    return history.listen((location) => {
      ReactGA.pageview(location.pathname)
      posthog?.capture('$pageview')
    })
  }, [history]) // eslint-disable-line

  const handleCloseMenu = () => setIsDrawerOpen(false)
  const handleOpenMenu = () => setIsDrawerOpen(true)

  return (
    <S.Container>
      {isLoading ? (
        <LoadingPage global />
      ) : (
        <Switch>
          {routes}
          <Route exact={false} path="*" render={() => <NotFound />} />
        </Switch>
      )}
      {dashboard && (
        <>
          <DrawerMenu
            open={isDrawerOpen}
            onClose={handleCloseMenu}
            variant="temporary"
            menuHeader={true}
            ModalProps={{ keepMounted: true }}
          >
            <DashboardMenu
              items={[dashboard]}
              pathname={location.pathname}
              isShared={true}
            />
          </DrawerMenu>
          <Fab autoHide handleClick={handleOpenMenu}>
            <Menu color="var(--color-white)" />
          </Fab>
        </>
      )}
      <FooterLite url={location.pathname} />
    </S.Container>
  )
}

export default SharedRoutes
