import {
  GraphQLOperationParams,
  GraphQLOperationTypeEnum,
} from 'hooks/useGraphQLOperation/types'
import { Sort } from 'lib/reports/types'
import listData from 'gql/queries/listData'

const { QUERY } = GraphQLOperationTypeEnum

export interface DataParams {
  dataSetId: string
  limit: number
  nextToken: string | null
  sort: Sort | undefined
  filter: null | any
}

interface GetDataParams extends DataParams {
  graphQLOperation: (params: GraphQLOperationParams) => Promise<any>
}

const getData = async (params: GetDataParams) => {
  const { dataSetId, limit, nextToken, sort, filter, graphQLOperation } = params
  const variables = { dataSetId, limit, nextToken, sort, filter }
  const options = {
    variables,
  }

  const {
    data: { listData: res },
  }: any = await graphQLOperation({
    operationType: QUERY,
    operation: listData,
    options,
  })

  return res
}

export default getData
