import React, { useRef, useEffect } from 'react'
import { BusinessChangeAccount } from 'containers/ChangeAccount'
import MenuItem from 'components/UserMenu/MenuItem'
import * as S from 'components/UserMenu/UserMenu.styled'

interface ChangeAccountComponentProps {
  userId: string
  accounts: BusinessChangeAccount[]
  activeAccount: string
  updateAccountInUseId: (
    accountInUseId: string,
    userId: string
  ) => Promise<void>
}

const ChangeAccount = (props: ChangeAccountComponentProps) => {
  const { userId, accounts, activeAccount, updateAccountInUseId } = props
  const listMenuItemsRef = useRef<HTMLDivElement | null>(null)
  const menuItemActiveRef = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    const listMenuItems = listMenuItemsRef.current
    const menuItemActive = menuItemActiveRef.current

    if (listMenuItems && menuItemActive) {
      const scrollPosition = menuItemActive.offsetTop - listMenuItems.offsetTop
      listMenuItems.scrollTop = scrollPosition
    }
  }, []) //eslint-disable-line

  return (
    <S.ListMenuItems ref={listMenuItemsRef} maxheight="18.75rem">
      {accounts.map((account: BusinessChangeAccount, index) => {
        const {
          id,
          business: { name },
        } = account
        const active = activeAccount === id

        return (
          <MenuItem
            testId={`account-${name}`}
            ref={active ? menuItemActiveRef : null}
            key={id}
            active={active}
            position={accounts.length === index + 1 ? 'last' : undefined}
            onClick={() => updateAccountInUseId(id, userId)}
          >
            {name}
          </MenuItem>
        )
      })}
    </S.ListMenuItems>
  )
}

export default ChangeAccount
