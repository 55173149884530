import React from 'react'
import { useTheme } from 'theme'
import getCSSVariables from 'theme/lib/getCSSVariables'

const CSSVariables = () => {
  const { theme } = useTheme()
  const styles: any = getCSSVariables(theme)
  const ROOT_SELECTOR = ':root'
  const css = Object.entries(styles)
    .map(([key, value]) => `${key}: ${value};`)
    .join('\n')
  return (
    <style>
      {`
        ${ROOT_SELECTOR} {
          ${css}
        }
      `}
    </style>
  )
}

export default CSSVariables
