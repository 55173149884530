import React, { SVGProps } from 'react'

export const LinkB = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
      role="img"
    >
      <title>link-b-icon</title>
      <path
        d="M7.05029 12.7071C7.37724 12.3802 7.76087 12.1445 8.16955 12C9.22534 11.6268 10.4483 11.8625 11.2929 12.7071C12.4645 13.8787 12.4645 15.7782 11.2929 16.9498L9.17161 19.0711C8.00004 20.2427 6.10055 20.2427 4.92897 19.0711C3.7574 17.8995 3.7574 16 4.92897 14.8285L7.05029 12.7071Z"
        stroke={color}
        stroke-width="2"
      />
      <path
        d="M14.8285 4.92897C16 3.7574 17.8995 3.7574 19.0711 4.92897C20.2427 6.10055 20.2427 8.00004 19.0711 9.17161L16.9498 11.2929C15.7782 12.4645 13.8787 12.4645 12.7071 11.2929C11.5356 10.1214 11.5356 8.22187 12.7071 7.05029L14.8285 4.92897Z"
        stroke={color}
        stroke-width="2"
      />
      <path
        d="M14.8284 9.17163L9.17151 14.8285"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  )
}
