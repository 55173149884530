import React, { SVGProps } from 'react'

export const Eye = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
    >
      <title>eye-icon</title>
      <path
        d="M20 13C20 14 16.4183 18 12 18C7.58172 18 4 14 4 13C4 12 7.58172 8 12 8C16.4183 8 20 12 20 13Z"
        stroke={color}
        stroke-width="2"
      />
      <circle
        cx="2"
        cy="2"
        r="2"
        transform="matrix(1 0 0 -1 10 15)"
        stroke={color}
        stroke-width="2"
      />
      <path
        d="M5 11L4 10"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.89443 6.55279C7.64744 6.05881 7.04676 5.85858 6.55279 6.10557C6.05881 6.35256 5.85858 6.95324 6.10557 7.44721L7.89443 6.55279ZM8.89443 8.55279L7.89443 6.55279L6.10557 7.44721L7.10557 9.44721L8.89443 8.55279Z"
        fill={color}
      />
      <path
        d="M13 6C13 5.44772 12.5523 5 12 5C11.4477 5 11 5.44772 11 6H13ZM13 8V6H11V8H13Z"
        fill={color}
      />
      <path
        d="M17.8944 7.44721C18.1414 6.95323 17.9412 6.35256 17.4472 6.10557C16.9532 5.85858 16.3526 6.05881 16.1056 6.55279L17.8944 7.44721ZM16.8944 9.44721L17.8944 7.44721L16.1056 6.55279L15.1056 8.55279L16.8944 9.44721Z"
        fill={color}
      />
      <path
        d="M20.7071 10.7071C21.0976 10.3166 21.0976 9.68342 20.7071 9.29289C20.3166 8.90237 19.6834 8.90237 19.2929 9.29289L20.7071 10.7071ZM19.7071 11.7071L20.7071 10.7071L19.2929 9.29289L18.2929 10.2929L19.7071 11.7071Z"
        fill={color}
      />
    </svg>
  )
}
