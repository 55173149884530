const keepOnlyDefaultValues = (
  allValues: any,
  dataWithFormattedCheckbox: any
): any =>
  Object.keys(allValues)
    .filter((key) => dataWithFormattedCheckbox.hasOwnProperty(key))
    .reduce((acc, key) => {
      //@ts-ignore
      acc[key] = dataWithFormattedCheckbox[key]
      return acc
    }, {})

export default keepOnlyDefaultValues
