import { Option } from '../../types'

interface DefaultOptionModificationsThemeRiver {
  paddingThemeRiver?: boolean
}

const defaultOptionModifications = (
  params: DefaultOptionModificationsThemeRiver
): Option => {
  const { paddingThemeRiver } = params

  return {
    tooltip: {
      trigger: 'axis',
    },
    singleAxis: {
      top: paddingThemeRiver ? 64 : 20,
      right: 32,
      bottom: 24,
      left: 32,
      scale: true,
    },
    grid: {
      show: false,
    },
    xAxis: {
      show: false,
    },
    yAxis: {
      show: false,
    },
    dataZoom: {
      show: false,
    },
    visualMap: {
      show: false,
      inRange: {},
      outOfRange: {},
    },
    calendar: {
      range: 2000,
      cellSize: 0,
      splitLine: {
        show: false,
      },
      itemStyle: {
        opacity: 0,
      },
      dayLabel: {
        show: false,
      },
      monthLabel: {
        show: false,
      },
      yearLabel: {
        show: false,
      },
    },
    animation: false,
  }
}

export default defaultOptionModifications
