import { API } from 'aws-amplify'
import getDomainTheme from 'gql/queries/getDomainTheme'
import Sentry from 'tracking/Sentry'

interface GetPublicDomainThemeParams {
  domainName: string
}

const getPublicDomainTheme = async (
  params: GetPublicDomainThemeParams
): Promise<any> => {
  const { domainName } = params

  try {
    const res: any = await API.graphql({
      query: getDomainTheme,
      variables: {
        domain: domainName,
      },
      authMode: 'API_KEY',
    })
    return res.data.getDomainTheme
  } catch (error) {
    Sentry.captureException(error)
    throw error
  }
}

export default getPublicDomainTheme
