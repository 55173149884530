import React from 'react'
import { Dialog, Grow } from '@mui/material'
import { ModalTypes, ModalType } from 'redux/modal/types'
import { UniverseModules } from 'redux/universeModules/types'
import PricingTable from 'containers/PricingTable'
import Icon from 'components/Icon'
import * as S from './ModalSwitch.styled'

type ModalSwitchProps = {
  open: boolean
  onClose: () => void
  type?: ModalType
  universeModules: UniverseModules
}

const ModalSwitch = (props: ModalSwitchProps) => {
  const { open, onClose, type, universeModules } = props
  const { SUBSCRIPTION_PLANS } = universeModules

  const renderModalContent = () => {
    switch (type) {
      case ModalTypes.PRICING_TABLE:
        return SUBSCRIPTION_PLANS ? <PricingTable /> : null
      default:
        return SUBSCRIPTION_PLANS ? <PricingTable /> : null
    }
  }

  const ModalContent = renderModalContent()

  if (Boolean(ModalContent)) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Grow}
        fullWidth
        maxWidth="xl"
      >
        <S.ModalIconButton onClick={onClose}>
          <Icon
            name="Line/cercle-close"
            width={24}
            height={24}
            color="var(--color-black)"
          />
        </S.ModalIconButton>
        {ModalContent}
      </Dialog>
    )
  }

  return null
}

export default ModalSwitch
