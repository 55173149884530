interface FormatDataItemWithCustomFieldParams {
  dataItem: Data
}

export const CUSTOM_FIELD = 'customField'

export const isCustomFieldWithValue = (key: string, value: any) =>
  key.includes(CUSTOM_FIELD) && Boolean(value)

const formatDataItemWithCustomField = (
  params: FormatDataItemWithCustomFieldParams
) => {
  const { dataItem: defaultDataItem } = params

  const dataItem = Object.entries(defaultDataItem).reduce(
    (dataItem, [key, value]) => {
      const isCustomField = isCustomFieldWithValue(key, value)

      return isCustomField
        ? { ...dataItem, [key]: JSON.parse(value) }
        : { ...dataItem, [key]: value }
    },
    {}
  )

  return dataItem as Data
}

export default formatDataItemWithCustomField
