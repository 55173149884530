import React, { ReactNode, Component, ErrorInfo } from 'react'
import Placeholder from './placheholder'
import Sentry from 'tracking/Sentry'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ReportErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry && Sentry.captureException(error)
  }

  public render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return <Placeholder />
    }

    return children
  }
}

export default ReportErrorBoundary
