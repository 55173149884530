import { SunburstSeriesOption } from 'echarts'
import { TEXT_STYLE, BORDER_STYLE, SHADOW_STYLE } from '../general'
import { TypeSeriesParams } from '../../types'

const sunburstSerie = (
  params: TypeSeriesParams<SunburstSeriesOption>
): SunburstSeriesOption => {
  const {
    typeSeriesModifications: sunburstSeriesModifications = {},
    paddingSunburst,
    theme,
  } = params

  return {
    type: 'sunburst',
    center: ['50%', '50%'],
    radius: ['0', `${paddingSunburst ? 70 : 95}%`],
    //@ts-ignore
    nodeClick: false,
    selectedMode: false,
    ...sunburstSeriesModifications,
    label: {
      show: true,
      rotate: 'radial',
      align: 'center',
      position: 'inside',
      distance: 4,
      offset: [0, 0],
      ...TEXT_STYLE(theme),
      fontSize: 12,
      backgroundColor: 'transparent',
      ...BORDER_STYLE,
      padding: 0,
      ...SHADOW_STYLE,
      ...sunburstSeriesModifications?.label,
    },
    itemStyle: {
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      opacity: 1,
      ...sunburstSeriesModifications?.itemStyle,
    },
    emphasis: {
      focus: 'descendant',
      ...sunburstSeriesModifications?.emphasis,
    },
  }
}

export default sunburstSerie
