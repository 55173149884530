import React, { ReactNode } from 'react'
import ConditionalWrapper from 'hocs/conditionalWrapper'
import getPadding from 'components/Grid/utils/getPadding'
import generateCols from 'components/Grid/utils/generateCols'
import { GRID_COLUMNS } from 'components/Grid/utils/defaultValues'
import { GridItemRenderProps } from 'components/Grid/GridItem/types'
import { ROWS } from 'components/Grid/utils/defaultValues'
import * as S from './GridItem.styled'

interface GridItemProp {
  render: (props: any) => ReactNode
  columns?: number
  rows?: number
  padding?: string
  gridColumns?: number
  dataTestId: string
  isBentoBox?: boolean
}

const GridItem = (props: GridItemProp) => {
  const {
    render,
    columns,
    rows = ROWS,
    padding: defaultPadding,
    gridColumns = GRID_COLUMNS,
    dataTestId,
    isBentoBox,
  } = props
  const { paddingTop, paddingRight, paddingBottom, paddingLeft, padding } =
    getPadding({
      defaultPadding,
    })
  const cols = generateCols({
    columns,
    paddingRight,
    paddingLeft,
    gridColumns,
  })

  const gridItemRenderProps: GridItemRenderProps = {
    cols,
    rows,
    gridColumns,
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  }

  return (
    <ConditionalWrapper
      condition={padding}
      wrapper={(children) => (
        <S.GridItemPadding
          data-testid={`gridItem-padding-${dataTestId}`}
          cols={cols}
          rows={rows}
          paddingTop={paddingTop}
          paddingRight={paddingRight}
          paddingLeft={paddingLeft}
          paddingBottom={paddingBottom}
          rowGap={isBentoBox ? 0 : undefined}
          columnGap={isBentoBox ? 0 : undefined}
        >
          {children}
        </S.GridItemPadding>
      )}
    >
      <S.GridItem
        data-testid={`gridItem-${dataTestId}`}
        cols={cols}
        rows={rows}
        padding={padding}
        paddingTop={paddingTop}
        paddingLeft={paddingLeft}
      >
        {render(gridItemRenderProps)}
      </S.GridItem>
    </ConditionalWrapper>
  )
}

export default GridItem
