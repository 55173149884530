import { API, graphqlOperation } from 'aws-amplify'
import { SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack'
import getStripeManagementUrl from 'gql/queries/getStripeManagmentUrl'
import { getErrorMessage } from 'lib/errors'
import Sentry from 'tracking/Sentry'

interface HandleManageSubscriptionParams {
  universeId: string
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey
}

const handleManageSubscription = async (
  params: HandleManageSubscriptionParams
) => {
  const { universeId, enqueueSnackbar } = params
  try {
    const res: any = await API.graphql(
      graphqlOperation(getStripeManagementUrl, { universeId })
    )
    window.location.replace(res.data.getStripeManagementUrl.url)
  } catch (error) {
    enqueueSnackbar(getErrorMessage(error, 'errors.createStripePaymentLink'), {
      variant: 'error',
    })
    Sentry.captureException(error)
  }
}

export default handleManageSubscription
