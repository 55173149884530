import React from 'react'
import { Alert } from '@mui/material'
import * as S from './FreeEcharts.styled'

interface NoDataProps {
  message: string
}

const NoData = (props: NoDataProps) => {
  const { message } = props

  return (
    <S.ContainerNoData>
      <Alert variant="filled" severity="info" sx={{ width: 'fit-content' }}>
        {message}
      </Alert>
    </S.ContainerNoData>
  )
}

export default NoData
