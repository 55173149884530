import React from 'react'
import ReportWrapper from 'containers/ReportWrapper'
import ReportGridItem from 'components/Grid/GridItem'
import getReportRows from './getReportRows'
import { GridItemRenderProps } from 'components/Grid/GridItem/types'
import isInlineReport from 'components/Reports/GridReports/GridReport/utils/isInlineReport'
import getRowHeight from 'components/Reports/GridReports/GridReport/utils/getRowHeight'
import getRowGap from 'components/Reports/GridReports/GridReport/utils/getRowGap'
import * as S from 'components/Reports/GridReports/GridReport/GridReport.styled'

interface GridReportProps {
  report: Report
  isBentoBox?: boolean
  gridColumns?: number
}

const GridReport = (props: GridReportProps) => {
  const { report, isBentoBox, gridColumns } = props
  const {
    id,
    reportType,
    sizeColumns: reportGridItemColumns,
    sizePadding: reportGridItemPadding,
    isDisabled,
  } = report
  const rows = getReportRows({ report })

  if (isDisabled) {
    return null
  }

  return (
    <ReportGridItem
      columns={reportGridItemColumns}
      rows={rows}
      padding={reportGridItemPadding}
      gridColumns={gridColumns}
      dataTestId={`report-${reportType}-${id}`}
      isBentoBox={isBentoBox}
      render={(props: GridItemRenderProps) => {
        const { cols, rows, paddingRight, paddingLeft, gridColumns } = props
        const rowHeight = getRowHeight({ isBentoBox })
        const rowGap = getRowGap({ isBentoBox })
        const inlineReport = isInlineReport({
          reportType,
          cols,
          paddingRight,
          paddingLeft,
          gridColumns,
        })

        return (
          <S.ReportGridItemWrapper
            reportType={reportType}
            inlineReport={inlineReport}
            cols={cols}
            rows={rows}
            rowHeight={rowHeight}
            rowGap={rowGap}
            gridColumns={gridColumns}
          >
            <ReportWrapper
              report={report}
              rows={rows}
              columns={cols}
              isBentoBox={isBentoBox}
            />
          </S.ReportGridItemWrapper>
        )
      }}
    />
  )
}

export default GridReport
