import { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import useGraphQLOperation from 'hooks/useGraphQLOperation'
import getDefaultTableState from 'containers/Table/utils/getDefaultTableState'
import generateTableColumns from 'containers/Table/utils/generateTableColumns'
import generateTableRows from 'containers/Table/utils/generateTableRows'
import getTableData from 'containers/Table/utils/getTableData'
import { TableState } from 'containers/Table/types'
import { TableProperties } from 'lib/reports/types'
import { useDashboardParams } from 'hooks/useDashboardParams'

interface UseTableStateProps {
  tableProperties: TableProperties
  dataSetId: string
}

const useTableState = (props: UseTableStateProps) => {
  const { tableProperties, dataSetId } = props
  const { enqueueSnackbar } = useSnackbar()
  const graphQLOperation = useGraphQLOperation()
  const { token, dashboardId, shared } = useDashboardParams()
  const [tableState, setTableState] = useState<TableState>(
    getDefaultTableState({ tableProperties })
  )
  const { sort, filter, paginationModel } = tableState
  const {
    rows: { mapping: defaultMapping },
  } = tableProperties

  const setTableData = async () => {
    setTableState((tableState) => ({
      ...tableState,
      loading: true,
    }))
    const { dataItems, queryTokens, lastPage, total } = await getTableData({
      graphQLOperation,
      dataSetId,
      tableState,
      enqueueSnackbar,
      publicPermissions: { token, dashboardId },
      shared,
    })

    const tableColumns = generateTableColumns({ tableProperties })
    const tableRowIdName = `SHIMOKU_TABLE_ROW_ID_${dataSetId}`
    const rowsMapping = {
      [tableRowIdName]: 'id',
      ...defaultMapping,
    }
    const tableRows = generateTableRows({
      dataItems,
      rowsMapping,
    })

    setTableState((tableState) => ({
      ...tableState,
      columns: tableColumns,
      rowIdName: tableRowIdName,
      rows: tableRows,
      queryTokens,
      lastPage,
      total,
      loading: false,
    }))
  }

  useEffect(() => {
    setTableData()
  }, [paginationModel]) // eslint-disable-line

  useEffect(() => {
    setTableState((tableState) => ({
      ...tableState,
      queryTokens: [],
      paginationModel: {
        ...tableState.paginationModel,
        page: 0,
      },
      lastPage: 0,
    }))
  }, [sort, filter]) // eslint-disable-line

  return {
    tableState,
    setTableState,
    setTableData,
  }
}

export default useTableState
