import sum from './sum'
import avg from './avg'
import { OperationType } from '../types'

const { SUM, AVG, MIN, MAX } = OperationType

interface DoOperationParams {
  operationType: AggregationOperation
  data: number[]
  count?: number
}

const doOperation = (params: DoOperationParams) => {
  const { operationType, data, count = data.length } = params

  switch (operationType) {
    case SUM:
      return sum({ data })
    case AVG:
      return avg({ data, count })
    case MIN:
      return Math.min(...data)
    case MAX:
      return Math.max(...data)
    default:
      return sum({ data })
  }
}

export default doOperation
