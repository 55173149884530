import React from 'react'
import { Tooltip } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectPlan } from 'redux/plan'
import ConditionalWrapper from 'hocs/conditionalWrapper'
import Download from 'icons/Line/download'
import { formatMessage } from 'i18n/ShimokuIntl'
import { IconContainer } from '../GridToolbar/GridToolbar.styled'
import * as S from '../GridToolbar/GridToolbar.styled'

interface ExportProps {
  handleOnClick?: () => void
}

const Export = (props: ExportProps) => {
  const { handleOnClick } = props
  const {
    limits: { enabledReportDataDownload = false },
  } = useSelector(selectPlan)

  return (
    <ConditionalWrapper
      condition={!enabledReportDataDownload}
      wrapper={(children) => (
        <Tooltip title={formatMessage('info.featureNotAvaibleInPlan')} arrow>
          <span>{children}</span>
        </Tooltip>
      )}
    >
      <S.ButtonExportTableDataToCSV
        data-testid="ExportTableDataToCSV"
        variant="text"
        disableRipple
        startIcon={
          <IconContainer>
            <Download
              width={20}
              height={20}
              color={
                enabledReportDataDownload
                  ? 'var(--color-black)'
                  : 'var(--action-disabled)'
              }
            />
          </IconContainer>
        }
        disabled={!enabledReportDataDownload}
        onClick={() => handleOnClick?.()}
      >
        <span className="toolbar-button-text">
          {formatMessage('generic.export')}
        </span>
      </S.ButtonExportTableDataToCSV>
    </ConditionalWrapper>
  )
}

export default Export
