const listUniversePlanTypes = /* GraphQL */ `
  query listUniversePlanTypes($universeId: ID!) {
    listUniversePlanTypes(universeId: $universeId) {
      items {
        id
        description
        features
        featured
        images
        limits
        name
        order
        price
        type
      }
    }
  }
`
export default listUniversePlanTypes
