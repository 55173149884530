import { EChartsOption, SeriesOption } from 'echarts'
import { Option } from './types'

interface addOptionModificationsParams {
  defaultOption: Option
  defaultOptionModifications: Option
  optionModifications: Option
}

const addOptionModifications = (params: addOptionModificationsParams) => {
  const { defaultOption, defaultOptionModifications, optionModifications } =
    params

  const option: EChartsOption = {
    ...defaultOption,
    ...defaultOptionModifications,
    ...optionModifications,
    title: {
      ...defaultOption.title,
      ...defaultOptionModifications.title,
      ...optionModifications.title,
      textStyle: {
        ...defaultOption?.title?.textStyle,
        ...defaultOptionModifications?.title?.textStyle,
        ...optionModifications?.title?.textStyle,
      },
      subtextStyle: {
        ...defaultOption?.title?.subtextStyle,
        ...defaultOptionModifications?.title?.subtextStyle,
        ...optionModifications?.title?.subtextStyle,
      },
    },
    legend: {
      ...defaultOption?.legend,
      ...defaultOptionModifications?.legend,
      ...optionModifications?.legend,
      itemStyle: {
        ...defaultOption?.legend?.itemStyle,
        ...defaultOptionModifications?.legend?.itemStyle,
        ...optionModifications?.legend?.itemStyle,
      },
      textStyle: {
        ...defaultOption?.legend?.textStyle,
        ...defaultOptionModifications?.legend?.textStyle,
        ...optionModifications?.legend?.textStyle,
      },
      pageTextStyle: {
        //@ts-ignore
        ...defaultOption?.legend?.pageTextStyle,
        //@ts-ignore
        ...defaultOptionModifications?.legend?.pageTextStyle,
        //@ts-ignore
        ...optionModifications?.legend?.pageTextStyle,
      },
    },
    radar: {
      ...defaultOption?.radar,
      ...defaultOptionModifications?.radar,
      ...optionModifications?.radar,
      axisLine: {
        ...defaultOption?.radar?.axisLine,
        ...defaultOptionModifications?.radar?.axisLine,
        ...optionModifications?.radar?.axisLine,
      },
      axisTick: {
        ...defaultOption?.radar?.axisTick,
        ...defaultOptionModifications?.radar?.axisTick,
        ...optionModifications?.radar?.axisTick,
      },
      axisLabel: {
        ...defaultOption?.radar?.axisLabel,
        ...defaultOptionModifications?.radar?.axisLabel,
        ...optionModifications?.radar?.axisLabel,
      },
      splitLine: {
        ...defaultOption?.radar?.splitLine,
        ...defaultOptionModifications?.radar?.splitLine,
        ...optionModifications?.radar?.splitLine,
      },
      splitArea: {
        ...defaultOption?.radar?.splitArea,
        ...defaultOptionModifications?.radar?.splitArea,
        ...optionModifications?.radar?.splitArea,
      },
      name: {
        //@ts-ignore
        ...defaultOption?.radar?.name,
        //@ts-ignore
        ...defaultOptionModifications?.radar?.name,
        //@ts-ignore
        ...optionModifications?.radar?.name,
      },
    },
    visualMap: {
      ...defaultOption?.visualMap,
      ...defaultOptionModifications?.visualMap,
      ...optionModifications?.visualMap,
      textStyle: {
        ...defaultOption?.visualMap?.textStyle,
        ...defaultOptionModifications?.visualMap?.textStyle,
        ...optionModifications?.visualMap?.textStyle,
      },
    },
    tooltip: {
      ...defaultOption?.tooltip,
      ...defaultOptionModifications?.tooltip,
      ...optionModifications?.tooltip,
      textStyle: {
        ...defaultOption?.tooltip?.textStyle,
        ...defaultOptionModifications?.tooltip?.textStyle,
        ...optionModifications?.tooltip?.textStyle,
      },
    },
    axisPointer: {
      ...defaultOption?.axisPointer,
      ...defaultOptionModifications?.axisPointer,
      ...optionModifications?.axisPointer,
      label: {
        ...defaultOption?.axisPointer?.label,
        ...defaultOptionModifications?.axisPointer?.label,
        ...optionModifications?.axisPointer?.label,
      },
      lineStyle: {
        ...defaultOption?.axisPointer?.lineStyle,
        ...defaultOptionModifications?.axisPointer?.lineStyle,
        ...optionModifications?.axisPointer?.lineStyle,
      },
      shadowStyle: {
        ...defaultOption?.axisPointer?.shadowStyle,
        ...defaultOptionModifications?.axisPointer?.shadowStyle,
        ...optionModifications?.axisPointer?.shadowStyle,
      },
      handle: {
        ...defaultOption?.axisPointer?.handle,
        ...defaultOptionModifications?.axisPointer?.handle,
        ...optionModifications?.axisPointer?.handle,
      },
    },
    toolbox: {
      ...defaultOption?.toolbox,
      ...defaultOptionModifications?.toolbox,
      ...optionModifications?.toolbox,
      feature: {
        ...defaultOption?.toolbox?.feature,
        ...defaultOptionModifications?.toolbox?.feature,
        ...optionModifications?.toolbox?.feature,
        saveAsImage: {
          ...defaultOption?.toolbox?.feature?.saveAsImage,
          ...defaultOptionModifications?.toolbox?.feature?.saveAsImage,
          ...optionModifications?.toolbox?.feature?.saveAsImage,
        },
        restore: {
          ...defaultOption?.toolbox?.feature?.restore,
          ...defaultOptionModifications?.toolbox?.feature?.restore,
          ...optionModifications?.toolbox?.feature?.restore,
        },
        dataView: {
          ...defaultOption?.toolbox?.feature?.dataView,
          ...defaultOptionModifications?.toolbox?.feature?.dataView,
          ...optionModifications?.toolbox?.feature?.dataView,
        },
        dataZoom: {
          ...defaultOption?.toolbox?.feature?.dataZoom,
          ...defaultOptionModifications?.toolbox?.feature?.dataZoom,
          ...optionModifications?.toolbox?.feature?.dataZoom,
          title: {
            ...defaultOption?.toolbox?.feature?.dataZoom?.title,
            ...defaultOptionModifications?.toolbox?.feature?.dataZoom?.title,
            ...optionModifications?.toolbox?.feature?.dataZoom?.title,
          },
        },
        magicType: {
          ...defaultOption?.toolbox?.feature?.magicType,
          ...defaultOptionModifications?.toolbox?.feature?.magicType,
          ...optionModifications?.toolbox?.feature?.magicType,
          title: {
            ...defaultOption?.toolbox?.feature?.magicType?.title,
            ...defaultOptionModifications?.toolbox?.feature?.magicType?.title,
            ...optionModifications?.toolbox?.feature?.magicType?.title,
          },
        },
      },
      iconStyle: {
        ...defaultOption?.toolbox?.iconStyle,
        ...defaultOptionModifications?.toolbox?.iconStyle,
        ...optionModifications?.toolbox?.iconStyle,
      },
      emphasis: {
        ...defaultOption?.toolbox?.emphasis,
        ...defaultOptionModifications?.toolbox?.emphasis,
        ...optionModifications?.toolbox?.emphasis,
        iconStyle: {
          ...defaultOption?.toolbox?.emphasis?.iconStyle,
          ...defaultOptionModifications?.toolbox?.emphasis?.iconStyle,
          ...optionModifications?.toolbox?.emphasis?.iconStyle,
        },
      },
    },
    singleAxis: {
      ...defaultOption?.singleAxis,
      ...defaultOptionModifications?.singleAxis,
      ...optionModifications?.singleAxis,
      nameTextStyle: {
        ...defaultOption?.singleAxis?.nameTextStyle,
        ...defaultOptionModifications?.singleAxis?.nameTextStyle,
        ...optionModifications?.singleAxis?.nameTextStyle,
      },
      axisLine: {
        ...defaultOption?.singleAxis?.axisLine,
        ...defaultOptionModifications?.singleAxis?.axisLine,
        ...optionModifications?.singleAxis?.axisLine,
        lineStyle: {
          ...defaultOption?.singleAxis?.axisLine?.lineStyle,
          ...defaultOptionModifications?.singleAxis?.axisLine?.lineStyle,
          ...optionModifications?.singleAxis?.axisLine?.lineStyle,
        },
      },
      axisTick: {
        ...defaultOption?.singleAxis?.axisTick,
        ...defaultOptionModifications?.singleAxis?.axisTick,
        ...optionModifications?.singleAxis?.axisTick,
        lineStyle: {
          ...defaultOption?.singleAxis?.axisTick?.lineStyle,
          ...defaultOptionModifications?.singleAxis?.axisTick?.lineStyle,
          ...optionModifications?.singleAxis?.axisTick?.lineStyle,
        },
      },
      axisLabel: {
        ...defaultOption?.singleAxis?.axisLabel,
        ...defaultOptionModifications?.singleAxis?.axisLabel,
        ...optionModifications?.singleAxis?.axisLabel,
      },
      splitLine: {
        ...defaultOption?.singleAxis?.splitLine,
        ...defaultOptionModifications?.singleAxis?.splitLine,
        ...optionModifications?.singleAxis?.splitLine,
        lineStyle: {
          ...defaultOption?.singleAxis?.splitLine?.lineStyle,
          ...defaultOptionModifications?.singleAxis?.splitLine?.lineStyle,
          ...optionModifications?.singleAxis?.splitLine?.lineStyle,
        },
      },
      splitArea: {
        ...defaultOption?.singleAxis?.splitArea,
        ...defaultOptionModifications?.singleAxis?.splitArea,
        ...optionModifications?.singleAxis?.splitArea,
        areaStyle: {
          ...defaultOption?.singleAxis?.splitArea?.areaStyle,
          ...defaultOptionModifications?.singleAxis?.splitArea?.areaStyle,
          ...optionModifications?.singleAxis?.splitArea?.areaStyle,
        },
      },
    },
    calendar: {
      ...defaultOption?.calendar,
      ...defaultOptionModifications?.calendar,
      ...optionModifications?.calendar,
      splitLine: {
        ...defaultOption?.calendar?.splitLine,
        ...defaultOptionModifications?.calendar?.splitLine,
        ...optionModifications?.calendar?.splitLine,
        lineStyle: {
          ...defaultOption?.calendar?.splitLine?.lineStyle,
          ...defaultOptionModifications?.calendar?.splitLine?.lineStyle,
          ...optionModifications?.calendar?.splitLine?.lineStyle,
        },
      },
      itemStyle: {
        ...defaultOption?.calendar?.itemStyle,
        ...defaultOptionModifications?.calendar?.itemStyle,
        ...optionModifications?.calendar?.itemStyle,
      },
      dayLabel: {
        ...defaultOption?.calendar?.dayLabel,
        ...defaultOptionModifications?.calendar?.dayLabel,
        ...optionModifications?.calendar?.dayLabel,
      },
      monthLabel: {
        ...defaultOption?.calendar?.monthLabel,
        ...defaultOptionModifications?.calendar?.monthLabel,
        ...optionModifications?.calendar?.monthLabel,
      },
      yearLabel: {
        ...defaultOption?.calendar?.yearLabel,
        ...defaultOptionModifications?.calendar?.yearLabel,
        ...optionModifications?.calendar?.yearLabel,
      },
    },
    grid: {
      ...defaultOption?.grid,
      ...defaultOptionModifications?.grid,
      ...optionModifications?.grid,
    },
    xAxis: {
      ...defaultOption?.xAxis,
      ...defaultOptionModifications?.xAxis,
      ...optionModifications?.xAxis,
      nameTextStyle: {
        ...defaultOption?.xAxis?.nameTextStyle,
        ...defaultOptionModifications?.xAxis?.nameTextStyle,
        ...optionModifications?.xAxis?.nameTextStyle,
      },
      axisLine: {
        ...defaultOption?.xAxis?.axisLine,
        ...defaultOptionModifications?.xAxis?.axisLine,
        ...optionModifications?.xAxis?.axisLine,
        lineStyle: {
          ...defaultOption?.xAxis?.axisLine?.lineStyle,
          ...defaultOptionModifications?.xAxis?.axisLine?.lineStyle,
          ...optionModifications?.xAxis?.axisLine?.lineStyle,
        },
      },
      axisTick: {
        ...defaultOption?.xAxis?.axisTick,
        ...defaultOptionModifications?.xAxis?.axisTick,
        ...optionModifications?.xAxis?.axisTick,
        lineStyle: {
          ...defaultOption?.xAxis?.axisTick?.lineStyle,
          ...defaultOptionModifications?.xAxis?.axisTick?.lineStyle,
          ...optionModifications?.xAxis?.axisTick?.lineStyle,
        },
      },
      axisLabel: {
        ...defaultOption?.xAxis?.axisLabel,
        ...defaultOptionModifications?.xAxis?.axisLabel,
        ...optionModifications?.xAxis?.axisLabel,
      },
      splitLine: {
        ...defaultOption?.xAxis?.splitLine,
        ...defaultOptionModifications?.xAxis?.splitLine,
        ...optionModifications?.xAxis?.splitLine,
        lineStyle: {
          ...defaultOption?.xAxis?.splitLine?.lineStyle,
          ...defaultOptionModifications?.xAxis?.splitLine?.lineStyle,
          ...optionModifications?.xAxis?.splitLine?.lineStyle,
        },
      },
      splitArea: {
        ...defaultOption?.xAxis?.splitArea,
        ...defaultOptionModifications?.xAxis?.splitArea,
        ...optionModifications?.xAxis?.splitArea,
        areaStyle: {
          ...defaultOption?.xAxis?.splitArea?.areaStyle,
          ...defaultOptionModifications?.xAxis?.splitArea?.areaStyle,
          ...optionModifications?.xAxis?.splitArea?.areaStyle,
        },
      },
    },
    yAxis: {
      ...defaultOption?.yAxis,
      ...defaultOptionModifications?.yAxis,
      ...optionModifications?.yAxis,
      nameTextStyle: {
        ...defaultOption?.yAxis?.nameTextStyle,
        ...defaultOptionModifications?.yAxis?.nameTextStyle,
        ...optionModifications?.yAxis?.nameTextStyle,
      },
      axisLine: {
        ...defaultOption?.yAxis?.axisLine,
        ...defaultOptionModifications?.yAxis?.axisLine,
        ...optionModifications?.yAxis?.axisLine,
        lineStyle: {
          ...defaultOption?.yAxis?.axisLine?.lineStyle,
          ...defaultOptionModifications?.yAxis?.axisLine?.lineStyle,
          ...optionModifications?.yAxis?.axisLine?.lineStyle,
        },
      },
      axisTick: {
        ...defaultOption?.yAxis?.axisTick,
        ...defaultOptionModifications?.yAxis?.axisTick,
        ...optionModifications?.yAxis?.axisTick,
        lineStyle: {
          ...defaultOption?.yAxis?.axisTick?.lineStyle,
          ...defaultOptionModifications?.yAxis?.axisTick?.lineStyle,
          ...optionModifications?.yAxis?.axisTick?.lineStyle,
        },
      },
      axisLabel: {
        ...defaultOption?.yAxis?.axisLabel,
        ...defaultOptionModifications?.yAxis?.axisLabel,
        ...optionModifications?.yAxis?.axisLabel,
      },
      splitLine: {
        ...defaultOption?.yAxis?.splitLine,
        ...defaultOptionModifications?.yAxis?.splitLine,
        ...optionModifications?.yAxis?.splitLine,
        lineStyle: {
          ...defaultOption?.yAxis?.splitLine?.lineStyle,
          ...defaultOptionModifications?.yAxis?.splitLine?.lineStyle,
          ...optionModifications?.yAxis?.splitLine?.lineStyle,
        },
      },
      splitArea: {
        ...defaultOption?.yAxis?.splitArea,
        ...defaultOptionModifications?.yAxis?.splitArea,
        ...optionModifications?.yAxis?.splitArea,
        areaStyle: {
          ...defaultOption?.yAxis?.splitArea?.areaStyle,
          ...defaultOptionModifications?.yAxis?.splitArea?.areaStyle,
          ...optionModifications?.yAxis?.splitArea?.areaStyle,
        },
      },
    },
    dataZoom: {
      ...defaultOption?.dataZoom,
      ...defaultOptionModifications?.dataZoom,
      ...optionModifications?.dataZoom,
      dataBackground: {
        //@ts-ignore
        ...defaultOption?.dataZoom?.dataBackground,
        //@ts-ignore
        ...defaultOptionModifications?.dataZoom?.dataBackground,
        //@ts-ignore
        ...optionModifications?.dataZoom?.dataBackground,
        lineStyle: {
          //@ts-ignore
          ...defaultOption?.dataZoom?.dataBackground?.lineStyle,
          //@ts-ignore
          ...defaultOptionModifications?.dataZoom?.dataBackground?.lineStyle,
          //@ts-ignore
          ...optionModifications?.dataZoom?.dataBackground?.lineStyle,
        },
        areaStyle: {
          //@ts-ignore
          ...defaultOption?.dataZoom?.dataBackground?.areaStyle,
          //@ts-ignore
          ...defaultOptionModifications?.dataZoom?.dataBackground?.areaStyle,
          //@ts-ignore
          ...optionModifications?.dataZoom?.dataBackground?.areaStyle,
        },
      },
      selectedDataBackground: {
        //@ts-ignore
        ...defaultOption?.dataZoom?.selectedDataBackground,
        //@ts-ignore
        ...defaultOptionModifications?.dataZoom?.selectedDataBackground,
        //@ts-ignore
        ...optionModifications?.dataZoom?.selectedDataBackground,
        lineStyle: {
          //@ts-ignore
          ...defaultOption?.dataZoom?.selectedDataBackground?.lineStyle,
          //@ts-ignore
          ...defaultOptionModifications?.dataZoom?.selectedDataBackground
            ?.lineStyle,
          //@ts-ignore
          ...optionModifications?.dataZoom?.selectedDataBackground?.lineStyle,
        },
        areaStyle: {
          //@ts-ignore
          ...defaultOption?.dataZoom?.selectedDataBackground?.areaStyle,
          //@ts-ignore
          ...defaultOptionModifications?.dataZoom?.selectedDataBackground
            ?.areaStyle,
          //@ts-ignore
          ...optionModifications?.dataZoom?.selectedDataBackground?.areaStyle,
        },
      },
      handleStyle: {
        //@ts-ignore
        ...defaultOption?.dataZoom?.handleStyle,
        //@ts-ignore
        ...defaultOptionModifications?.dataZoom?.handleStyle,
        //@ts-ignore
        ...optionModifications?.dataZoom?.handleStyle,
      },
      moveHandleStyle: {
        //@ts-ignore
        ...defaultOption?.dataZoom?.moveHandleStyle,
        //@ts-ignore
        ...defaultOptionModifications?.dataZoom?.moveHandleStyle,
        //@ts-ignore
        ...optionModifications?.dataZoom?.moveHandleStyle,
      },
      textStyle: {
        ...defaultOption?.dataZoom?.textStyle,
        ...defaultOptionModifications?.dataZoom?.textStyle,
        ...optionModifications?.dataZoom?.textStyle,
      },
      emphasis: {
        //@ts-ignore
        ...defaultOption?.dataZoom?.emphasis,
        //@ts-ignore
        ...defaultOptionModifications?.dataZoom?.emphasis,
        //@ts-ignore
        ...optionModifications?.dataZoom?.emphasis,
        moveHandleStyle: {
          //@ts-ignore
          ...defaultOption?.dataZoom?.emphasis?.moveHandleStyle,
          //@ts-ignore
          ...defaultOptionModifications?.dataZoom?.emphasis?.moveHandleStyle,
          //@ts-ignore
          ...optionModifications?.dataZoom?.emphasis?.moveHandleStyle,
        },
      },
    },
    //@ts-ignore
    series: [...(defaultOption?.series as SeriesOption)],
  }

  return option
}

export default addOptionModifications
