import mappingDataItem from 'lib/reports/mappingDataItem'
import formatDataItemWithCustomField, {
  isCustomFieldWithValue,
} from './formatDataItemWithCustomField'
import { Mapping } from 'lib/reports/types'

interface GenerateDataSetParams {
  dataItems: Data[]
  mapping: Mapping
}

const generateDataSet = (params: GenerateDataSetParams) => {
  const { dataItems, mapping } = params
  const [dataItem] = dataItems

  const dataItemWithCustomFields =
    Boolean(dataItem) &&
    Object.entries(dataItem).some(([key, value]) =>
      isCustomFieldWithValue(key, value)
    )

  const isCustomField = dataItems.length === 1 && dataItemWithCustomFields
  if (isCustomField) {
    return mappingDataItem({
      mapping,
      dataItem: formatDataItemWithCustomField({ dataItem }),
    })
  }
  return dataItems.map((dataItem) => {
    return mappingDataItem({ mapping, dataItem })
  })
}

export default generateDataSet
