import { styled } from '@mui/material/styles'
import {
  BENTOBOX_GRID_COLUMNS,
  COLUMNS,
  ROWS,
  ROW_HEIGHT as GRID_ROW_HEIGHT,
  GRID_ROW_GAP,
} from 'components/Grid/utils/defaultValues'

const resetStyles = {
  boxShadow: 'none',
  borderRadius: '0',
  backgroundColor: 'transparent',
}

interface GridBentoProps {
  columns?: number
  gridRowHeight?: number
  gridRowGap?: number
  bentoboxColumns?: number
  bentoboxRows?: number
}

export const GridBento = styled('div')<GridBentoProps>(
  ({
    theme,
    columns = BENTOBOX_GRID_COLUMNS,
    gridRowHeight = GRID_ROW_HEIGHT,
    gridRowGap = GRID_ROW_GAP,
    bentoboxRows = ROWS,
    bentoboxColumns = COLUMNS,
  }) => {
    const BENTOBOX_HEIGHT = `${
      gridRowHeight * bentoboxRows + gridRowGap * (bentoboxRows - 1)
    }px`
    return {
      minHeight: BENTOBOX_HEIGHT,
      maxHeight: BENTOBOX_HEIGHT,
      display: 'grid',
      gridAutoRows: 'auto',
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
      gap: '0',
      gridArea: `span ${bentoboxRows} / span ${bentoboxColumns} / span ${bentoboxRows} / span ${bentoboxColumns}`,
      boxShadow: 'var(--box-shadow-l)',
      borderRadius: 'var(--border-radius-s)',
      backgroundColor: 'var(--background-paper)',
      '.bentobox-reset': {
        ...resetStyles,
      },
      '.MuiPaper-root': {
        ...resetStyles,
        padding: '0',
      },
      [theme.breakpoints.down('md')]: {
        minHeight: ' auto',
        maxHeight: 'none',
        display: 'block',
        gridArea: `span 1 / span ${COLUMNS} / span 1 / span ${COLUMNS}`,
      },
    }
  }
)
