import { EChartsOption, SeriesOption } from 'echarts'
import { formatMessage } from 'i18n/ShimokuIntl'
import { isArray } from 'lib/variableType'
import { DataState } from 'lib/reports/types'

interface CheckSeriesDataParams {
  series: SeriesOption | SeriesOption[] | undefined
}

export const checkSeriesData = (params: CheckSeriesDataParams) => {
  const { series = {} } = params

  if (isArray(series)) {
    //@ts-ignore
    return series.some((seriesItem) => Boolean(seriesItem?.data?.length))
  }

  //@ts-ignore
  const { data = [] } = series
  return Boolean(data.length)
}

interface CheckDataParams {
  reportDataSets: ReportDataSet[]
  option: EChartsOption
}

const checkChartData = (params: CheckDataParams): DataState => {
  const { reportDataSets, option } = params

  const hasFilter = reportDataSets.some(
    (reportDataSet) => reportDataSet.dataSet.filter
  )
  const seriesData = checkSeriesData({ series: option?.series })
  //@ts-ignore
  const xAxisData = option?.xAxis?.data || []
  //@ts-ignore
  const yAxisData = option?.yAxis?.data || []
  //@ts-ignore
  const dataSetSource = option?.dataset?.source || []

  if (
    !xAxisData.length &&
    !yAxisData.length &&
    !seriesData &&
    !dataSetSource.length
  ) {
    if (hasFilter) {
      return {
        value: false,
        message: formatMessage('info.noDataReportDataSetsWithFilters'),
      }
    }

    return { value: false, message: formatMessage('generic.noData') }
  }

  return { value: true }
}

export default checkChartData
