import { styled } from '@mui/material/styles'

export const Container = styled('div')(() => ({
  display: 'flex',
  position: 'relative',
}))

export const IconContainer = styled('div')(() => ({
  display: 'flex',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))
