import { palette as commonPallete } from 'theme/common/theme-options'
import { DOMAIN_OPTIONS } from 'theme/types'

const { DELOITTE } = DOMAIN_OPTIONS

const pallete = {
  ...commonPallete,
  primary: {
    light: '#b9ef5a',
    main: '#86BC24',
    dark: '#548c00',
  },
  secondary: {
    light: '#4b4647',
    main: '#231f20',
    dark: '#000000',
  },
}

export const deloitteOptions = {
  domain: DELOITTE,
  pallete,
}
