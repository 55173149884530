import { RealTimeEventsReducerActionType } from 'contexts/realTime/realTimeEventsReducer/types'
import { RealTimeEvent } from 'contexts/realTime/types'
import { useSnackbar } from 'notistack'
import handleNotificationEvent from 'contexts/realTime/handleNotificationEvent'

const { NOTIFICATION } = RealTimeEventsReducerActionType

const useRealTimeEventsGeneral = () => {
  const { enqueueSnackbar } = useSnackbar()
  const handleGeneralEvent = (realTimeEvent: RealTimeEvent) => {
    if (realTimeEvent.type === NOTIFICATION)
      handleNotificationEvent({ realTimeEvent, enqueueSnackbar })
  }

  return { handleGeneralEvent }
}

export default useRealTimeEventsGeneral
