import React from 'react'
import { Alert } from '@mui/material'
import IframeComponent from 'components/Iframe'
import { formatMessage } from 'i18n/ShimokuIntl'
import withErrorWrapper from 'hocs/withErrorWrapper'

const Iframe = (props: WithReport) => {
  const { report } = props

  return (
    <IframeComponent
      fields={report.dataFields && JSON.parse(report.dataFields)}
    />
  )
}

const Placeholder = () => (
  <Alert severity="error">
    {formatMessage('errors.inconsistentReportData')}
  </Alert>
)

export default withErrorWrapper<WithReport>(Iframe, Placeholder)
