import { isNumber } from 'lib/variableType'

const sortApps = (apps: App[]) => {
  const sortedWithOrder = apps
    .filter((app) => isNumber(app.order))
    //@ts-ignore
    .sort((appA, appB) => appA.order - appB.order)
  const appsWithoutOrder = apps.filter((app) => !isNumber(app.order))

  return [...sortedWithOrder, ...appsWithoutOrder]
}

export default sortApps
