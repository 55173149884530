import React, { ReactNode } from 'react'
import InputOperationEditable from '../InputOperationEditable'
import { AggregationContainer } from './AggregationWrapper.styled'

interface AggregationWrapperProps {
  children: ReactNode
  reportDataSets: ReportDataSet[]
  inputOperationEditable: AggregationProperty[]
}

const AggregationWrapper = (props: AggregationWrapperProps) => {
  const { children, inputOperationEditable, reportDataSets } = props

  return (
    <>
      <AggregationContainer>
        {inputOperationEditable.map((aggregationProperty) => (
          <InputOperationEditable
            key={aggregationProperty.name}
            aggregationProperty={aggregationProperty}
            reportDataSets={reportDataSets}
          />
        ))}
      </AggregationContainer>
      {children}
    </>
  )
}

export default AggregationWrapper
