import { Theme } from '@mui/material'
import { styled } from '@mui/styles'
import {
  COLUMNS,
  ROWS,
  GRID_COLUMNS,
  GRID_ROW_GAP,
  GRID_COLUMN_GAP,
} from 'components/Grid/utils/defaultValues'
import { PaddingProps } from 'components/Grid/GridItem/types'

interface GridItemPaddingProps extends Omit<PaddingProps, 'padding'> {
  theme: Theme
  cols?: number
  rows?: number
  rowGap?: number
  columnGap?: number
  isBentoBox?: boolean
}

export const GridItemPadding = styled('div')(
  ({
    theme,
    cols = COLUMNS,
    rows = ROWS,
    columnGap = GRID_COLUMN_GAP,
    rowGap = GRID_ROW_GAP,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  }: GridItemPaddingProps) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${cols + paddingRight + paddingLeft}, 1fr)`,
    gridTemplateRows: `repeat(${rows + paddingTop + paddingBottom}, 1fr)`,
    gridArea: `span ${rows + paddingTop + paddingBottom} / span ${
      cols + paddingRight + paddingLeft
    } / span ${rows + paddingTop + paddingBottom} / span ${
      cols + paddingRight + paddingLeft
    }`,
    gap: `${rowGap}px ${columnGap}px`,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      gridArea: `span 1 / span ${GRID_COLUMNS} / span 1 / span ${GRID_COLUMNS}`,
    },
  })
)

interface GridItemProps
  extends Omit<PaddingProps, 'paddingRight' | 'paddingBottom'> {
  theme: Theme
  cols: number
  rows: number
}

export const GridItem = styled('div')(
  ({ theme, cols, rows, padding, paddingTop, paddingLeft }: GridItemProps) => ({
    gridArea: padding
      ? `${paddingTop + 1} / ${paddingLeft + 1} / span ${rows} / span ${cols}`
      : `span ${rows} / span ${cols} / span ${rows} / span ${cols}`,
    [theme.breakpoints.down('md')]: {
      gridArea: `span 1 / span ${GRID_COLUMNS} / span 1 / span ${GRID_COLUMNS}`,
    },
  })
)
