import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'redux/store/types'
import reduxSetUniverseModules from './setUniverseModules'
import { UniverseModules } from './types'

const initialState: UniverseModules = {
  SUBSCRIPTION_PLANS: false,
  ACTIVITY_WEBHOOKS: false,
}

const UNIVERSE_MODULES = 'universeModules'

const universeModulesSlice = createSlice({
  name: UNIVERSE_MODULES,
  initialState,
  reducers: {
    setUniverseModules: reduxSetUniverseModules,
  },
})

export const selectUniverseModules = (state: RootState) => {
  return state.universeModules
}

export const { setUniverseModules } = universeModulesSlice.actions

export default universeModulesSlice.reducer
