import { Theme } from '@mui/material/styles'
import { TooltipComponentOption } from 'echarts'
import { TEXT_STYLE } from './general'

interface TooltipParams {
  tooltip?: boolean
  theme: Theme
}

const tooltip = (params: TooltipParams): TooltipComponentOption => {
  const { tooltip: show, theme } = params
  const PALLETE = theme.palette

  return {
    show,
    trigger: 'item',
    showContent: true,
    alwaysShowContent: false,
    triggerOn: 'mousemove|click',
    showDelay: 0,
    hideDelay: 100,
    enterable: true,
    renderMode: 'html',
    confine: false,
    appendToBody: false,
    className: 'echarts-tooltip',
    transitionDuration: 0.3,
    position: undefined,
    formatter: undefined,
    backgroundColor: PALLETE.background.paper,
    borderColor: PALLETE.divider,
    padding: 8,
    textStyle: {
      ...TEXT_STYLE(theme),
      color: PALLETE.text.primary,
      fontSize: 14,
    },
  }
}

export default tooltip
