import { ThemeRiverSeriesOption } from 'echarts'
import { TEXT_STYLE, BORDER_STYLE, SHADOW_STYLE } from '../../defaults/general'
import { TypeSeriesParams } from '../../types'

const themeRiverSerie = (
  params: TypeSeriesParams<ThemeRiverSeriesOption>
): ThemeRiverSeriesOption => {
  const { typeSeriesModifications: themeRiverSeriesModifications = {}, theme } =
    params

  return {
    type: 'themeRiver',
    coordinateSystem: 'singleAxis',
    boundaryGap: [24, 24],
    selectedMode: false,
    ...themeRiverSeriesModifications,
    label: {
      show: true,
      position: 'left',
      distance: 0,
      rotate: 0,
      offset: [8, 0],
      ...TEXT_STYLE(theme),
      fontSize: 12,
      backgroundColor: 'transparent',
      ...BORDER_STYLE,
      padding: 0,
      ...SHADOW_STYLE,
      textBorderWidth: 2,
      textBorderColor: '#fff',
      ...themeRiverSeriesModifications?.label,
    },
    labelLayout: {
      hideOverlap: false,
      ...themeRiverSeriesModifications?.labelLayout,
    },
    itemStyle: {
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      ...themeRiverSeriesModifications?.itemStyle,
    },
  }
}

export default themeRiverSerie
