import { Theme } from '@mui/material/styles'
import { TooltipProps, LegendProps, YAxisProps, LabelProps } from 'recharts'

export const numericalAbbreviation = (value: number) => {
  if (value >= 1e6) {
    return value / 1e6 + 'M'
  } else if (value >= 1e3) {
    return value / 1e3 + 'K'
  } else {
    return value
  }
}
//@ts-ignore
export const tooltipProps = (theme: Theme): TooltipProps => {
  const PALLETE = theme.palette
  const CHART_OPTIONS = theme.custom.chart?.options

  return {
    itemStyle: {
      lineHeight: '1em',
      fontWeight: 500,
    },
    separator: ': ',
    wrapperStyle: {
      borderRadius: 4,
    },
    contentStyle: {
      borderRadius: 4,
      background: PALLETE.background.paper,
      borderColor: PALLETE.divider,
    },
    cursor: { fill: CHART_OPTIONS.backgroundHover },
  }
}

export const legendProps: LegendProps = {
  verticalAlign: 'top',
  iconType: 'circle',
  align: 'right',
  iconSize: 13,
  height: 35,
  wrapperStyle: {
    right: 0,
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 'calc(100% - 16px)',
  },
}

export const getYAxisProps = (
  theme: Theme,
  marginLeft?: boolean
): YAxisProps => {
  const CHART_OPTIONS = theme.custom.chart.options
  return {
    //@ts-ignore
    tickFormatter: numericalAbbreviation,
    width: marginLeft ? 85 : 65,
    tickLine: false,
    axisLine: false,
    domain: ['auto', 'auto'],
    stroke: CHART_OPTIONS.axislabel,
  }
}

export const getYLabelProps = (
  theme: Theme,
  marginLeft?: boolean
): LabelProps => {
  const CHART_OPTIONS = theme.custom.chart.options

  return {
    angle: -90,
    position: 'center',
    dx: marginLeft ? -17 : -10,
    fill: CHART_OPTIONS.text,
  }
}
