import React from 'react'
import { Tooltip } from '@mui/material'
import OverflowTooltip from 'components/OverflowTooltip/OverflowTooltip'
import Info from 'icons/Line/info'
import * as S from './indicator.styled'
import { IndicatorColor, IndicatorVariant } from 'lib/reports/types'

type indicatorHeaderProps = {
  title: string
  color?: IndicatorColor
  align?: string
  variant?: IndicatorVariant
  targetPath?: string
  backgroundImage?: string
  hideIcons?: boolean
  info?: string
  iconSize?: number
}

const IndicatorHeader = (props: indicatorHeaderProps) => {
  const {
    title,
    color,
    align,
    variant = 'default',
    targetPath,
    backgroundImage,
    hideIcons,
    info,
    iconSize,
  } = props
  return (
    <>
      {!hideIcons && info && (
        <Tooltip title={info} arrow placement="top">
          <div
            style={{
              position: 'absolute',
              right:
                align === 'left' || align === 'center' ? '18px' : 'inherit',
              left: align === 'right' ? '18px' : 'inherit',
              cursor: 'help',
            }}
          >
            <Info
              width={iconSize}
              height={iconSize}
              color={S.getIconColor({ color, variant, targetPath })}
            />
          </div>
        </Tooltip>
      )}
      {title && (
        <OverflowTooltip title={title} arrow>
          <S.Text
            component="h3"
            variant="h6"
            title={title}
            color={color}
            indicatorVariant={variant}
            backgroundImage={backgroundImage}
            targetPath={targetPath}
          >
            {title}
          </S.Text>
        </OverflowTooltip>
      )}
    </>
  )
}

export default IndicatorHeader
