const listAccounts = /* GraphQL */ `
  query listAccounts($userId: ID!) {
    listAccounts(userId: $userId) {
      items {
        id
      }
    }
  }
`

export default listAccounts
