import React, { useState, useRef } from 'react'
import dayjs from 'dayjs'
import Button from 'components/Button'
import FileList from 'components/Input/FileList/file-list'
import { InputType } from 'components/ReportForm/types'
import { handleChangeParams, FileData } from '../types'
import Mic from 'icons/mic'
import { LabelInput } from '../common'
import { formatMessage } from 'i18n/ShimokuIntl'
import { useSnackbar } from 'notistack'
import { CircleContainer, Circle } from './AudioInput.styled'

const constraints = { audio: true }
const { audio } = InputType

interface AudioInputProps {
  name: string
  fieldName: string
  handleChange: ({ files, eventName }: handleChangeParams) => void
  paginateBy: number
  value?: (File | FileData)[]
  disabled?: boolean
}

const Audio = (props: AudioInputProps) => {
  const {
    name,
    fieldName,
    handleChange,
    paginateBy,
    value = [],
    disabled = false,
  } = props
  const { enqueueSnackbar } = useSnackbar()
  const [listFiles, setListFiles] = useState<(File | FileData)[]>(value)
  const [isRecording, setIsRecording] = useState(false)
  //@ts-ignore
  const mediaRecorder = useRef<MediaRecorder | null>(null)
  const validMediaDevices = navigator.mediaDevices
  const audioChunks: any = []

  if (!validMediaDevices) {
    enqueueSnackbar(formatMessage('errors.mediaDevicesUndefined'), {
      variant: 'error',
    })
  }

  if (mediaRecorder.current) {
    mediaRecorder.current.ondataavailable = (e: any) => {
      audioChunks.push(e.data)
    }

    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(audioChunks, {
        type: 'audio/ogg; codecs=opus',
      })
      const date = dayjs().utc().format()
      const recordingName = `recording-${date}`
      const audioFile = new File([audioBlob], recordingName)

      setListFiles((prevListFiles) => {
        const files = [...prevListFiles, audioFile]
        handleChange({ files, eventName: name })

        return files
      })
    }
  }

  const getUserMediaPermission = async () => {
    if (validMediaDevices) {
      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      //@ts-ignore
      mediaRecorder.current = new MediaRecorder(stream)
    }
  }

  const handleRecordingAudio = async () => {
    if (!mediaRecorder.current) {
      await getUserMediaPermission()
    }

    if (isRecording) {
      mediaRecorder.current && mediaRecorder.current.stop()
      validMediaDevices && setIsRecording(false)
    } else {
      mediaRecorder.current && mediaRecorder.current.start()
      validMediaDevices && setIsRecording(true)
    }
  }

  return (
    <>
      <LabelInput label={fieldName} />
      <Button
        onClick={handleRecordingAudio}
        recording={isRecording}
        disabled={!validMediaDevices || disabled}
        fullWidth
        data-inputType={`${fieldName}-${audio}`}
      >
        {isRecording && (
          <CircleContainer>
            <Circle />
          </CircleContainer>
        )}
        <Mic style={{ zIndex: 1 }} />
      </Button>
      {Boolean(value.length) && (
        <FileList
          disabled={disabled}
          handleChange={handleChange}
          fieldName={fieldName}
          paginateBy={paginateBy}
          listFiles={listFiles}
          setListFiles={setListFiles}
        />
      )}
    </>
  )
}

export default Audio
