import React, { SetStateAction, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from 'redux/user'
import ChangeAccount from 'containers/ChangeAccount'
import MenuGroup from 'components/UserMenu/MenuGroup'
import { USER_MENUS } from 'components/UserMenu'
import ArrowLeft from 'icons/Line/arrow-left'
import { formatMessage } from 'i18n/ShimokuIntl'

interface AccountsMenuProps {
  setActiveMenu: (value: SetStateAction<string>) => void
}

const AccountsMenu = (props: AccountsMenuProps) => {
  const { setActiveMenu } = props
  const { MAIN } = USER_MENUS
  const user = useSelector(selectUser)
  const accounts = user.accounts?.items || []
  const accountNames = accounts?.map((account) => account.business.name)
  const [searchedAccounts, setSearchedAccounts] = useState(accountNames)

  const filterAccounts = (value: string) => {
    if (value) {
      const filteredAccounts = accountNames.filter((account) =>
        account.toLowerCase().includes(value.toLowerCase())
      )
      setSearchedAccounts(filteredAccounts)
    } else {
      setSearchedAccounts(accountNames)
    }
  }

  return (
    <MenuGroup
      title={formatMessage('generic.workspaces')}
      accountNames={searchedAccounts}
      handleSearch={filterAccounts}
      icon={
        <ArrowLeft testId="userMenu-arrowBack" color="var(--color-black)" />
      }
      onClickIcon={() => setActiveMenu(MAIN)}
    >
      <ChangeAccount
        filteredAccountsNames={searchedAccounts}
        setActiveMenu={setActiveMenu}
      />
    </MenuGroup>
  )
}

export default AccountsMenu
