import { LineSeriesOption } from 'echarts'
import { TEXT_STYLE, BORDER_STYLE, SHADOW_STYLE } from '../../defaults/general'
import { TypeSeriesParams } from '../../types'

const lineSerie = (
  params: TypeSeriesParams<LineSeriesOption>
): LineSeriesOption => {
  const { typeSeriesModifications: lineSeriesModifications = {}, theme } =
    params

  return {
    type: 'line',
    coordinateSystem: 'cartesian2d',
    symbol: 'emptyCircle',
    symbolSize: 4,
    symbolRotate: 0,
    symbolKeepAspect: false,
    symbolOffset: [0, 0],
    showSymbol: false,
    legendHoverLink: true,
    cursor: 'pointer',
    connectNulls: false,
    clip: true,
    step: false,
    smooth: false,
    smoothMonotone: 'none',
    seriesLayoutBy: 'column',
    ...lineSeriesModifications,
    label: {
      show: false,
      position: 'top',
      distance: 4,
      rotate: 0,
      offset: [0, 0],
      ...TEXT_STYLE(theme),
      color: 'rgba( 0, 0, 0, .6)',
      fontSize: 12,
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      ...lineSeriesModifications?.label,
    },
    labelLayout: {
      hideOverlap: false,
      ...lineSeriesModifications?.labelLayout,
    },
    itemStyle: {
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      ...lineSeriesModifications?.itemStyle,
    },
    lineStyle: {
      width: 2,
      type: 'solid',
      cap: 'round',
      ...SHADOW_STYLE,
      ...lineSeriesModifications?.lineStyle,
    },
    areaStyle: {
      color: 'transparent',
      ...SHADOW_STYLE,
      ...lineSeriesModifications?.areaStyle,
    },
  }
}

export default lineSerie
