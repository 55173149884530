import React, { ReactNode } from 'react'
import RouteComponent from './Route'
import { sanitizeString } from 'lib/strings'
import { Route, BasicRoute } from './types'

interface GenerateRoutesParams {
  routes: BasicRoute[]
  prefix?: string
}

export const generateRoutes = (params: GenerateRoutesParams) => {
  const { routes: defaultRoutes, prefix = '' } = params

  return defaultRoutes.map((route) => {
    const {
      routeName: name,
      component,
      exact,
      renderConditional = true,
      path: defaultPath,
    } = route

    const path = defaultPath
      ? `${prefix}${defaultPath}`
      : `${prefix}/${sanitizeString(name)}`

    return {
      exact,
      name,
      path,
      component,
      breadcrumb: [
        {
          name,
          path,
        },
      ],
      renderConditional,
    }
  }) as Route[]
}

const generateRouteComponents = (params: GenerateRoutesParams) => {
  const { routes: defaultRoutes, prefix = '' } = params

  const routes = generateRoutes({ routes: defaultRoutes, prefix })

  return routes.map((route) => {
    const { exact, path, component, breadcrumb, renderConditional } = route

    return (
      renderConditional && (
        <RouteComponent
          key={path}
          exact={exact}
          path={path}
          component={component}
          breadcrumb={breadcrumb}
        />
      )
    )
  }) as ReactNode[]
}

export default generateRouteComponents
