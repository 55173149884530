export type ThemeDomain =
  | 'shimoku.io'
  | 'tazawa.io'
  | 'gasolinow.com'
  | 'tool.whispers.at'
  | string

export interface BrandPallete {
  50?: string
  100?: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800?: string
}

interface ComplementaryPallete {
  400: string
  500: string
  600: string
}

export interface ChartColors {
  C1: string
  C2: string
  C3: string
  C4: string
  C5: string
  C6: string
  C7: string
  C8: string
  C9: string
  C10: string
}

export interface RadiusSizes {
  xs: string
  s: string
  m: string
  l: string
  xl: string
  xxl: string
}

export interface ShadowSizes {
  s: string
  m: string
  l: string
  xl: string
}

export interface ComplementaryColors {
  red: ComplementaryPallete
  violet: ComplementaryPallete
  aqua: ComplementaryPallete
  orange: ComplementaryPallete
  yellow: ComplementaryPallete
  green: ComplementaryPallete
}

export interface Text {
  primary: string
  secondary?: string
  disabled: string
  hint: string
  icon: string
}

interface Chart {
  colors?: string[]
  options?: ChartOptions
}

interface Reports {
  indicators: {
    gap: string
  }
}

interface Status {
  success: {
    main: string
  }
  danger: {
    main: string
  }
  warning: {
    main: string
  }
  error: {
    main: string
  }
  info: {
    main: string
  }
  alert: {
    main: string
  }
}

interface Dimensions {
  drawerWidth: number
}

export interface ChartOptions {
  text: string
  grid: string
  axisline?: string
  axislabel: string
  backgroundHover: string
  dataBackground: string
  controlBorderColor: string
  generalColor: BrandPallete
}

export interface Common {
  white: string
  black: string
  gray: string
}

export interface Background {
  paper: string
  default: string
}

export interface ThemeParameters {
  domain?: ThemeDomain
  logo?: string
  brandPallete?: BrandPallete
  text?: Text
  chartColors?: ChartColors
  chartOptions?: ChartOptions
  chart?: Chart
  common?: Common
  background?: Background
  overrides?: any
  dimensions?: Dimensions
  reports?: Reports
  status?: Status
  radius?: RadiusSizes
  shadows?: ShadowSizes
  pallete?: any
}

export interface IconColors {
  base: string
}

export const DOMAIN_OPTIONS = {
  SHIMOKU: 'shimoku.io',
  TAZAWA: 'tazawa.io',
  GASOLINOW: 'gasolinow.com',
  WHISPERS: 'tool.whispers.at',
  MEETING_DOCTORS: 'meetingdoctors.shimoku.io',
  DELOITTE: 'deloitte.shimoku.io',
}
