import React, { memo, FC } from 'react'
import Main from 'components/Main'
import globalStyles from 'material/global.module.css'
import Loading from 'icons/loading'

interface LoadingPageProps {
  global?: boolean
}

const LoadingPage: FC<LoadingPageProps> = memo(({ global = false }) => (
  <Main centered={true} fullHeight={global}>
    <Loading
      className={globalStyles.centred}
      width="200px"
      height="200px"
      primaryColor="var(--color-primary)"
      secondaryColor="var(--color-secondary)"
    />
  </Main>
))

export default LoadingPage
