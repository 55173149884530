const COMMON_OVERRIDES = {
  MuiInputBase: {
    styleOverrides: {
      root: {
        letterSpacing: 'var(--bodyM400-letter-spacing)',
        borderRadius: 'var(--border-radius-m)',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        letterSpacing: 'var(--bodyM400-letter-spacing)',
        borderRadius: 'var(--border-radius-m)',
        backgroundColor: 'var(--color-grey-200)',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: 'var(--box-shadow-l)',
        borderRadius: 'var(--border-radius-s)',
        backgroundColor: 'var(--background-paper)',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        borderRadius: 'var(--border-radius-m)',
        padding: '3px 8px',
      },
      select: {
        letterSpacing: 'var(--bodyM400-letter-spacing)',
        borderRadius: 'var(--border-radius-m)',
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        letterSpacing: 'var(--bodyS700-letter-spacing)',
        fontSize: 'var(--bodyS700-font-size)',
        fontWeight: 'var(--bodyS700-font-weight)',
      },
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: {
        letterSpacing: 'var(--bodyS400-letterSpacing)',
        fontSize: 'var(--bodyS400-font-size)',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        fontSize: 'inherit',
        fontWeight: 'inherit',
        borderBottom: 'none',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        letterSpacing: 'var(--bodyS700-letter-spacing)',
        fontSize: 'var(--bodyS700-font-size)',
        fontWeight: 'var(--bodyS700-font-weight)',
        borderRadius: 'var(--border-radius-m)',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        letterSpacing: 'var(--bodyM400-letter-spacing)',
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: 'var(--border-radius-m)',
      },
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      root: {
        borderRadius: 'var(--border-radius-m)',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        letterSpacing: 'var(--bodyS400-letterSpacing)',
        fontSize: 'var(--bodyS400-font-size)',
        color: 'var(--color-black)',
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        letterSpacing: 'var(--bodyS400-letterSpacing)',
        fontSize: 'var(--bodyS400-font-size)',
        color: 'var(--color-black)',
        position: 'relative !important',
        marginBottom: '8px',
        transform: 'inherit !important',
        '&.Mui-focused': {
          color: 'var(--color-black)',
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: 'var(--color-black)',
      },
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: {
        backgroundColor: 'var(--color-grey-200)',
        borderRadius: 'var(--border-radius-s)',
      },
    },
  },
}

export default COMMON_OVERRIDES
