import { TabsProperties } from 'lib/reports/types'

interface GetTabsAndTabPanelsParams {
  reportProperties: TabsProperties
  reports: Report[]
}

const getTabsAndTabPanels = (params: GetTabsAndTabPanelsParams) => {
  const { reportProperties, reports: defaultReports } = params
  const { tabs: defaultTabs = {} } = reportProperties

  return Object.entries(defaultTabs)
    .sort(([, prevValue], [, nextValue]) => prevValue.order - nextValue.order)
    .reduce(
      (tabProperties, [key, value]) => {
        const { reportIds = [] } = value
        const tabReportIds = new Set(reportIds)
        const tabReports = defaultReports.filter((defaultReport) =>
          tabReportIds.has(defaultReport.id)
        )

        return {
          tabs: [...tabProperties.tabs, key],
          tabPanels: [...tabProperties.tabPanels, tabReports],
        }
      },
      {
        tabs: [] as string[],
        tabPanels: [] as Report[][],
      }
    )
}

export default getTabsAndTabPanels
