import { SeriesOption, TreemapSeriesOption } from 'echarts'
import treemapSerie from '../../defaults/series/treemap'
import { SeriesParams } from '../../types'

const series = (params: SeriesParams): SeriesOption => {
  const { data, seriesModifications = {}, paddingTreemap, theme } = params

  const seriesOption = treemapSerie({
    typeSeriesModifications: seriesModifications as TreemapSeriesOption,
    paddingTreemap,
    theme,
  })

  const series = [
    {
      ...seriesOption,
      data,
    },
  ]

  return series as SeriesOption
}

export default series
