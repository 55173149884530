import React from 'react'
import { Skeleton } from '@mui/material'
import * as S from './PricingTable.styled'

const PlansSkeleton = () => (
  <>
    {[0, 1, 2, 3].map((item) => (
      <S.SkeletonContainer key={item}>
        <S.SkeletonImgContainer>
          <Skeleton
            variant="circular"
            sx={{ marginBottom: 3 }}
            width="60px"
            height="70px"
          />
        </S.SkeletonImgContainer>
        <Skeleton animation="wave" width="80%" sx={{ marginBottom: 3 }} />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" width="60%" height="50px" />
        <Skeleton
          animation="wave"
          width="80%"
          sx={{ marginBottom: 1, marginTop: 6 }}
        />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" height="80px" sx={{ marginTop: 2 }} />
      </S.SkeletonContainer>
    ))}
  </>
)
export default PlansSkeleton
