import { SeriesOption, FunnelSeriesOption } from 'echarts'
import funnelSerie from '../../defaults/series/funnel'
import { SeriesParams } from '../../types'

const series = (params: SeriesParams): SeriesOption => {
  const { data, seriesModifications = {}, paddingFunnel, theme } = params

  const seriesOption = funnelSerie({
    typeSeriesModifications: seriesModifications as FunnelSeriesOption,
    paddingFunnel,
    theme,
  })

  const series = [
    {
      ...seriesOption,
      data,
    },
  ]

  return series as SeriesOption
}

export default series
