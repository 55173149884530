import { styled } from '@mui/material/styles'
import {
  ButtonProps as MaterialButtonProps,
  Button as MaterialButton,
} from '@mui/material'

export interface ButtonProps extends MaterialButtonProps {
  status?: 'warning'
  size?: 'small' | 'medium' | 'large'
  inverted?: boolean
  recording?: boolean
}

export const Button = styled(MaterialButton)<ButtonProps>(
  ({
    size = 'medium',
    status,
    inverted,
    recording,
    variant,
    theme,
    color,
  }) => ({
    backgroundColor: 'var(--color-primary)',
    textTransform: 'inherit',
    padding: '16px 8px',
    color: 'var(--color-white)',
    boxShadow: 'none',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: 'var(--color-primary-dark)',
      boxShadow: 'none',
    },
    '&:disabled': {
      color: 'var(--color-white)',
      backgroundColor: 'var(--color-grey-500)',
    },
    ...(variant === 'outlined' && {
      backgroundColor: 'transparent',
      color: 'var(--color-primary)',
      border: '2px solid var(--color-primary)',
      '&:hover': {
        border: '2px solid var(--color-primary)',
        backgroundColor: `${theme.palette.primary.main}19`,
      },
    }),
    ...(status === 'warning' && {
      backgroundColor: 'var(--complementary-red)',
      '&:hover': {
        backgroundColor: 'var(--complementary-red-light)',
      },
    }),
    ...(inverted && {
      backgroundColor: 'var(--color-white)',
      color: 'var(--color-black)',
      '&:hover': {
        backgroundColor: 'rgba(var(--color-black), 0.1)',
      },
    }),
    ...(color === 'secondary' && {
      color: 'var(--color-black)',
      backgroundColor: 'var(--color-secondary)',
      '&:hover': {
        backgroundColor: 'var(--color-secondary-dark)',
      },
    }),
    ...(size === 'medium' && {
      minWidth: '150px',
    }),
    ...(size === 'large' && {
      minWidth: '350px',
    }),
    ...(size === 'small' && {
      minWidth: '100px',
      padding: '8px 0',
    }),
    ...(recording && {
      backgroundColor: 'var(--complementary-red)',
      '&:hover': {
        backgroundColor: 'var(--complementary-red-light)',
      },
    }),
  })
)
