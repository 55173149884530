import { SeriesOption, PieSeriesOption } from 'echarts'
import pieSerie from '../../defaults/series/pie'
import { SeriesParams } from '../../types'

const series = (params: SeriesParams): SeriesOption => {
  const { data, seriesModifications = {}, axisName, paddingPie, theme } = params

  const seriesOption = pieSerie({
    typeSeriesModifications: seriesModifications as PieSeriesOption,
    paddingPie,
    theme,
  })

  const series = Object.keys(data?.[0])
    .filter((name: string) => name !== axisName)
    .map((name: string) => ({
      ...seriesOption,
      name,
    }))

  return series as SeriesOption
}

export default series
