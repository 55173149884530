import { SeriesOption, ThemeRiverSeriesOption } from 'echarts'
import themeRiverSerie from '../../defaults/series/themeRiver'
import { SeriesParams, DataElement } from '../../types'

const series = (params: SeriesParams): SeriesOption => {
  const { data, seriesModifications = {}, theme } = params

  const seriesOption = themeRiverSerie({
    typeSeriesModifications: seriesModifications as ThemeRiverSeriesOption,
    theme,
  })

  const series = [
    {
      ...seriesOption,
      data: data.map((dataElement: DataElement) => [
        dataElement?.date,
        dataElement?.value,
        dataElement?.name,
      ]),
    },
  ]

  return series as SeriesOption
}

export default series
