import React, { SVGProps } from 'react'

export const AccountsA = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
    >
      <title>accounts-a-icon</title>
      <rect
        x="3"
        y="7"
        width="18"
        height="12"
        rx="2"
        stroke={color}
        stroke-width="2"
      />
      <rect
        x="3"
        y="7"
        width="18"
        height="8"
        rx="2"
        stroke={color}
        stroke-width="2"
      />
      <path
        d="M9 6C9 4.89543 9.89543 4 11 4H13C14.1046 4 15 4.89543 15 6V7H9V6Z"
        stroke={color}
        stroke-width="2"
      />
      <path
        d="M12 11L12 11.25L12 11.5L12 11.75L12 12"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  )
}
