import { TreemapSeriesOption } from 'echarts'
import { TEXT_STYLE, SHADOW_STYLE, BORDER_STYLE } from '../../defaults/general'
import { TypeSeriesParams } from '../../types'

const treemapSerie = (
  params: TypeSeriesParams<TreemapSeriesOption>
): TreemapSeriesOption => {
  const {
    typeSeriesModifications: treemapSeriesModifications = {},
    paddingTreemap,
    theme,
  } = params

  return {
    type: 'treemap',
    left: 'center',
    top: `${paddingTreemap ? 56 : 0}`,
    height: `${paddingTreemap ? 80 : 100}%`,
    right: 'auto',
    width: '100%',
    drillDownIcon: '▶',
    roam: true,
    selectedMode: false,
    //@ts-ignore
    nodeClick: false,
    ...treemapSeriesModifications,
    label: {
      show: true,
      position: 'inside',
      distance: 4,
      rotate: 0,
      offset: [0, 0],
      ...TEXT_STYLE(theme),
      fontSize: 12,
      color: '#fff',
      overflow: 'truncate',
      ...SHADOW_STYLE,
      ...BORDER_STYLE,
      ...treemapSeriesModifications?.label,
    },
    upperLabel: {
      show: false,
      position: 'inside',
      distance: 4,
      rotate: 0,
      offset: [0, 0],
      ...TEXT_STYLE(theme),
      fontSize: 12,
      ...SHADOW_STYLE,
      ...BORDER_STYLE,
      ...treemapSeriesModifications?.upperLabel,
    },
    labelLayout: {
      hideOverlap: false,
      ...treemapSeriesModifications?.labelLayout,
    },
    //@ts-ignore
    breadcrumb: {
      show: false,
      left: 'center',
      top: 'auto',
      right: 'auto',
      bottom: 0,
      height: 24,
      ...treemapSeriesModifications?.breadcrumb,
    },
  }
}

export default treemapSerie
