const URLs = {
  privacyPolicy: 'https://www.shimoku.com/legal/privacy-policy',
  scheduleACall: 'https://www.shimoku.com/schedule-a-call',
  github: 'https://github.com/shimoku-tech/shimoku-api-python',
  linkedin: 'https://www.linkedin.com/company/shimoku',
  youtube: 'https://www.youtube.com/@shimoku-ai',
  discord: 'https://discord.com/invite/C87vWAug6q',
}

export default URLs
