import React from 'react'
import Reports from 'pages/Reports'
import Route from 'components/Routes/Route'
import getAppRoutes from 'lib/apps/getAppRoutes'

interface GetPublicDashboardsRoutesParams {
  dashboard: Dashboard
}

const getPublicDashboardsAppsRoutes = (
  params: GetPublicDashboardsRoutesParams
) => {
  const { dashboard } = params
  return dashboard.appDashboards.items.flatMap((appDashboard: AppDashboard) => {
    const { allRoutes } = getAppRoutes({
      app: appDashboard.app,
      dashboard,
    })
    return allRoutes.map((appRoute) => {
      const { path, name } = appRoute
      const { id: appId } = appDashboard.app
      const breadcrumb = [
        {
          name,
          path,
        },
      ]
      return (
        <Route
          key={`${appId}|${path}`}
          exact
          path={path}
          breadcrumb={breadcrumb}
          render={() => (
            <Reports
              dashboard={dashboard}
              app={appDashboard.app}
              appRoute={appRoute}
            />
          )}
        />
      )
    })
  })
}

export default getPublicDashboardsAppsRoutes
