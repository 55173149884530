import { useRef, useState, useEffect } from 'react'
import {
  GRID_COLUMNS,
  GRID_COLUMN_GAP,
} from 'components/Grid/utils/defaultValues'

interface useScrollGridChildrenProps {
  columns?: number
}

const useScrollGridChildren = (props: useScrollGridChildrenProps) => {
  const { columns = GRID_COLUMNS } = props
  const ref = useRef<HTMLDivElement | null>(null)
  const [haveHorizontalScroll, setHaveHorizontalScroll] =
    useState<boolean>(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const handleResize = () => setWindowWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    const { current = null } = ref
    const { offsetWidth: parent = 0 } = document.querySelector(
      '#grid-reports'
    ) as any

    if (current) {
      const child = current.offsetWidth
      const columnWidth =
        (parent - GRID_COLUMN_GAP * (GRID_COLUMNS - 1)) / GRID_COLUMNS
      const containerGrid =
        columnWidth * columns + GRID_COLUMN_GAP * (columns - 1)

      if (containerGrid < child) {
        setHaveHorizontalScroll(true)
      }
    }
  }, [windowWidth]) // eslint-disable-line

  return { ref, haveHorizontalScroll }
}

export default useScrollGridChildren
