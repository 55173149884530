import { Theme } from '@mui/material'
import { styled } from '@mui/styles'
import {
  GRID_COLUMNS,
  GRID_ROW_GAP,
  GRID_COLUMN_GAP,
} from './utils/defaultValues'

interface GridProps {
  theme: Theme
  columns?: number
  rowGap?: number
  columnGap?: number
}

export const Grid = styled('div')(
  ({
    theme,
    columns = GRID_COLUMNS,
    rowGap = GRID_ROW_GAP,
    columnGap = GRID_COLUMN_GAP,
  }: GridProps) => ({
    display: 'grid',
    gridAutoRows: 'auto',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gap: `${rowGap}px ${columnGap}px`,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: `repeat(${GRID_COLUMNS}, 1fr)`,
    },
  })
)
