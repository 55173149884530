import { handleReportParams } from 'contexts/reports/types'
import formatReports from 'contexts/reports/formatReports'

interface handleCreateReportParams extends handleReportParams {}

const handleCreateReport = async (params: handleCreateReportParams) => {
  const { setReports, rawReport } = params

  setReports((prevReports) => {
    if (prevReports.find((report) => report.id === rawReport.id)) {
      return prevReports
    }
    const newReports = [...prevReports, rawReport]
    return formatReports({ reports: newReports })
  })
}

export default handleCreateReport
