import getLabels from './getLabels'
import { OptionMultifilter, OptionsMultifilter } from '../types'

interface OptionsWithAllLabelsParams {
  defaultOptions: OptionsMultifilter
}

const getOptionsWithAllLabels = (params: OptionsWithAllLabelsParams) => {
  const { defaultOptions } = params

  const labels = getLabels({ options: defaultOptions })
  const optionsWithAllLabels = defaultOptions.filter(
    (option: OptionMultifilter) =>
      !labels
        .map((label: string) => option[label] !== undefined)
        .some((value: boolean) => value === false)
  )

  return optionsWithAllLabels
}

export default getOptionsWithAllLabels
