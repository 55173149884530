import { ReportDataSetWithMapping } from './types'
import extractCSSVariable from 'lib/css/extractCSSVariable'

const AGGREGATION = 'agg-'
interface ReplaceStringParams {
  stringToReplace: string
  itemsToPlace: Data | ReportDataSetWithMapping
  property?: string
  aggregation?: Aggregation[]
}

const replaceString = (params: ReplaceStringParams) => {
  const {
    stringToReplace,
    itemsToPlace,
    aggregation = [] as Aggregation[],
  } = params
  const isAggregation = stringToReplace.includes(AGGREGATION)
  const cssVariable = extractCSSVariable(stringToReplace)

  if (cssVariable) {
    const cssVariableValue = getComputedStyle(document.body).getPropertyValue(
      cssVariable
    )

    return cssVariableValue
  }

  if (isAggregation) {
    const [, aggValue] = stringToReplace.split('-')

    return aggregation.map((aggregationItem) => aggregationItem[aggValue])
  }

  const replacedString = itemsToPlace
    ? itemsToPlace[stringToReplace as keyof Data]
    : undefined

  return replacedString !== undefined ? replacedString : stringToReplace
}

export default replaceString
