import { getItem } from 'lib/localStorage'
import { setStartupApp } from 'lib/apps/startupApp'
import { STARTUP_APP_KEY } from '../index'
import { StartupApps } from '../types'

interface GetStartupAppParams {
  account: Account
  dashboards: Dashboard[]
  apps: App[]
}

const getStartupApp = (params: GetStartupAppParams) => {
  const { account, dashboards, apps } = params
  const { id: accountId } = account
  const [firstDashboard] = dashboards
  const [firstApp] = apps

  const startupAppId = getItem<StartupApps>(STARTUP_APP_KEY)?.[accountId]
  const startupApp = apps.find((app) => app.id === startupAppId)
  const startupDashboard = dashboards.find((dashboard) =>
    dashboard.appDashboards.items.find(
      (appDashboard) => appDashboard.app.id === startupApp?.id
    )
  )

  if (Boolean(startupApp) && Boolean(startupDashboard)) {
    return { startupApp, startupDashboard }
  }

  setStartupApp({ account, app: firstApp })

  return { startupDashboard: firstDashboard, startupApp: firstApp }
}

export default getStartupApp
