import React, { useRef, useState } from 'react'
import { Skeleton } from '@mui/material'
import { EChartsOption } from 'echarts'
import dayjs from 'dayjs'
import useReportDataSetsByReportDataSets from 'contexts/ReportDataSets/hooks/useReportDataSetsByReportDataSets'
import AggregationWrapper from 'containers/Aggregation/AggregationWrapper'
import isInputOperationEditable from 'containers/Aggregation/InputOperationEditable/isInputOperationEditable'
import getAggregationFromReportDataSets from 'containers/Aggregation/helpers/getAggregationFromReportDataSets'
import FreeEchartsComponent from 'components/FreeEcharts'
import ConditionalWrapper from 'hocs/conditionalWrapper'
import { generateReportDataSetsWithMapping } from 'lib/reports/reportDataSetsWithMapping'
import generateOption from 'lib/reports/generateOption'
import { createData, deleteData, updateData } from 'lib/forms'
import { HistoryChartProperties } from 'lib/reports/types'
import { formatMessage } from 'i18n/ShimokuIntl'
import { useTheme } from '@mui/material/styles'
import { Theme } from '@mui/material'
import AnnotationModal from 'components/AnnotationModal'
import {
  Annotation,
  DefaultAnnotationFormValues,
} from 'components/AnnotationModal/annotationModal'
import { UserRolePermission, selectCurrentRoles } from 'redux/roles'
import setOptionsWithAnnotations, {
  formatXAxis,
  getFormatedSeriesData,
  generateDataToSend,
  deleteDataPointFromOption,
} from './helpers'
import { useSnackbar } from 'notistack'
import checkChartData from 'lib/reports/checkChartData'
import { breakTextAndAddLimit } from 'lib/strings'
import { useSelector } from 'react-redux'
import { useDashboardParams } from 'hooks/useDashboardParams'
import { selectDashboards } from 'redux/dashboards'

interface FreeEchartsProps {
  report: Report
  reportProperties: HistoryChartProperties
}

export const GRID_TOP = 48

const HistoryChart = (props: FreeEchartsProps) => {
  const theme: Theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const { report, reportProperties: properties } = props
  const { option: defaultOption = undefined, slider } = properties
  const [customOption, setCustomOption] = useState<any>(undefined)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedDataPoint, setSelectedDataPoint] =
    useState<DefaultAnnotationFormValues>()
  if (!defaultOption) {
    throw new Error(
      formatMessage('errors.freeEchartsProperties.option.undefined')
    )
  }
  const rolePermissions: UserRolePermission = useSelector(selectCurrentRoles)
  const { shared } = useDashboardParams()
  const [dashboard]: Dashboard[] = useSelector(selectDashboards)
  const hasEditPermission =
    (rolePermissions.DATA === 'WRITE' && !shared) ||
    (shared && dashboard.publicPermission?.permission === 'WRITE')

  const defaultReportDataSets = report.reportDataSets?.items || []
  const { reportDataSets } = useReportDataSetsByReportDataSets({
    reportDataSets: defaultReportDataSets,
  })
  const aggregation = getAggregationFromReportDataSets({ reportDataSets })
  const inputOperationEditable = isInputOperationEditable({ reportDataSets })
  const option = useRef<EChartsOption>({})

  const loading = reportDataSets.some(
    (reportDataSet) => reportDataSet.dataSet.loading === true
  )

  const hasData = reportDataSets.some(
    (reportDataSet) =>
      reportDataSet.dataSet?.data && reportDataSet.dataSet?.data.length
  )

  const handleOnReady = (echartsRef: HTMLDivElement | null) => {
    if (echartsRef) {
      const handleClick = (event: any) => {
        const { target } = event
        if (
          target.nodeName === 'path' &&
          (!target.nextElementSibling ||
            target.nextElementSibling.tagName !== 'text')
        ) {
          setIsModalOpen(true)
        }
      }

      echartsRef.addEventListener('click', handleClick)

      return () => {
        echartsRef.removeEventListener('click', handleClick)
      }
    }
  }

  const handleOnClickDataPoint = ({
    seriesName,
    data: { value, label, dataId },
  }: any) => {
    setIsModalOpen(true)
    setSelectedDataPoint({
      dataId,
      category: seriesName,
      intensity: value[1],
      date: [value[0], value[0]],
      text: label?.formatter() || '',
    })
  }

  if (!loading && hasData) {
    const reportDataSetsWithMapping =
      generateReportDataSetsWithMapping(reportDataSets)
    option.current = generateOption({
      reportDataSets: reportDataSetsWithMapping,
      option: defaultOption,
      aggregation,
    })
    option.current.series = getFormatedSeriesData(option, theme)
    option.current.xAxis = formatXAxis(option)
    option.current = {
      ...option.current,
      grid: {
        top: GRID_TOP * 2,
      },
      legend: {
        show: true,
      },
      tooltip: {
        trigger: 'item',
        position: 'top',
        formatter: (params: any) => {
          const { value, seriesName, data } = params
          const date = dayjs(value[0]).format('YYYY-MM-DD')
          const intensity = value[1]
          if (data.label?.formatter) {
            const text = data.label.formatter()
            const textWithBreaks = breakTextAndAddLimit(text, 6, 6)
            return `${seriesName} <br/> ${date} <br/> ${intensity} <br/> ${textWithBreaks}`
          }
          return `${seriesName} <br/> ${date} <br/> ${intensity}`
        },
        axisPointer: {
          type: 'cross',
        },
      },
    }
  }

  const data = checkChartData({ reportDataSets, option: option.current })
  if (loading || !hasData) {
    return (
      <Skeleton
        variant="rectangular"
        height="100%"
        role="progressbar"
        data-testid="freeEchartsLoader"
      />
    )
  }

  const addAnnotation = async (
    annotation: Annotation,
    currentOption: any,
    dataSetId: string
  ) => {
    const dataToSend = generateDataToSend({
      annotation,
      index: 0,
      dataSetId,
    })
    const dataToSendForSecondDate =
      annotation.date[0] !== annotation.date[1]
        ? generateDataToSend({
            annotation,
            index: 1,
            dataSetId,
            isSecondDate: true,
          })
        : null

    const createDataPromises = [createData(dataToSend)]

    if (dataToSendForSecondDate) {
      createDataPromises.push(createData(dataToSendForSecondDate))
    }

    const createdAnnotations = await Promise.all(createDataPromises)
    const newOptionsArray = createdAnnotations.map(
      (createdAnnotation, index) => {
        const annotationWithDataId = {
          dataId: createdAnnotation.id,
          ...annotation,
        }
        return setOptionsWithAnnotations({
          annotation: annotationWithDataId,
          option: currentOption,
          slider,
          theme,
          index,
        })
      }
    )
    const newOptions = Object.assign({}, ...newOptionsArray)
    return newOptions
  }

  const editAnnotation = async (annotation: Annotation, currentOption: any) => {
    const dataToSend = generateDataToSend({
      annotation,
      index: 0,
    })
    const dataToSendForSecondDate =
      annotation.date[0] !== annotation.date[1]
        ? generateDataToSend({
            annotation,
            index: 1,
            isSecondDate: true,
          })
        : null

    const updateDataPromises = [
      updateData({ id: selectedDataPoint?.dataId, ...dataToSend }),
    ]

    if (dataToSendForSecondDate) {
      updateDataPromises.push(
        updateData({
          id: selectedDataPoint?.dataId,
          ...dataToSendForSecondDate,
        })
      )
    }

    await Promise.all(updateDataPromises)
    const newOptions = setOptionsWithAnnotations({
      annotation,
      theme,
      option: currentOption,
      selectedDataPoint,
      slider,
      isEditing: true,
    })

    return newOptions
  }

  const handleOnSubmit = async (annotation: Annotation) => {
    const isEditing = Boolean(selectedDataPoint)
    const currentOption = customOption || option
    const { series } = currentOption.current
    const categoryIndex = series.findIndex(
      (serie: any) => serie.name === annotation.category
    )
    const { dataSet } = defaultReportDataSets[categoryIndex]
    setIsLoading(true)
    try {
      const newOptions = isEditing
        ? await editAnnotation(annotation, currentOption.current)
        : await addAnnotation(annotation, currentOption.current, dataSet.id)

      setCustomOption({ current: newOptions })

      enqueueSnackbar(
        isEditing
          ? formatMessage('container.HistoryChart.annotationEdited')
          : formatMessage('container.HistoryChart.annotationCreated'),
        {
          variant: 'success',
        }
      )
    } catch (error) {
      enqueueSnackbar(
        isEditing
          ? formatMessage('errors.editingData')
          : formatMessage('errors.creatingData'),
        {
          variant: 'error',
        }
      )
    } finally {
      setIsLoading(false)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedDataPoint(undefined)
  }

  const handleDelete = async () => {
    try {
      setIsLoading(true)
      if (!selectedDataPoint?.dataId) {
        throw new Error('No dataId')
      }
      await deleteData(selectedDataPoint.dataId)
      const optionsWithoutDeletedData = deleteDataPointFromOption({
        option: customOption || option,
        dataId: selectedDataPoint.dataId,
        category: selectedDataPoint.category,
      })
      setCustomOption(optionsWithoutDeletedData)
      enqueueSnackbar(
        formatMessage('container.HistoryChart.annotationDeleted'),
        {
          variant: 'success',
        }
      )
    } catch (error) {
      enqueueSnackbar(formatMessage('errors.deletingData'), {
        variant: 'error',
      })
    } finally {
      handleCloseModal()
      setIsLoading(false)
    }
  }

  const newOption = customOption || option
  const categories = newOption.current.series.map((serie: any) => serie.name)
  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        height="100%"
        role="progressbar"
        data-testid="freeEchartsLoader"
      />
    )
  }
  return (
    <ConditionalWrapper
      condition={Boolean(inputOperationEditable.length)}
      wrapper={(children) => (
        <AggregationWrapper
          reportDataSets={reportDataSets}
          inputOperationEditable={inputOperationEditable}
        >
          {children}
        </AggregationWrapper>
      )}
    >
      <>
        <AnnotationModal
          isOpen={isModalOpen}
          onSubmit={handleOnSubmit}
          isEditing={Boolean(selectedDataPoint)}
          defaultFormValues={selectedDataPoint || { category: categories[0] }}
          closeModal={handleCloseModal}
          handleDelete={handleDelete}
          hasEditPermission={hasEditPermission}
          categories={categories}
          slider={slider}
        />
        <FreeEchartsComponent
          handleOnReady={handleOnReady}
          handleOnClickDataPoint={handleOnClickDataPoint}
          option={newOption.current}
          data={data}
        />
      </>
    </ConditionalWrapper>
  )
}

export default HistoryChart
