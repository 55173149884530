import { FunnelSeriesOption } from 'echarts'
import { TEXT_STYLE, BORDER_STYLE, SHADOW_STYLE } from '../../defaults/general'
import { TypeSeriesParams } from '../../types'

const funnelSerie = (
  params: TypeSeriesParams<FunnelSeriesOption>
): FunnelSeriesOption => {
  const {
    typeSeriesModifications: funnelSeriesModifications = {},
    paddingFunnel = false,
    theme,
  } = params

  return {
    type: 'funnel',
    orient: 'vertical',
    sort: 'descending',
    gap: 0,
    legendHoverLink: true,
    funnelAlign: 'center',
    selectedMode: false,
    left: 64,
    top: `${paddingFunnel ? 64 : 10}`,
    right: 64,
    bottom: 10,
    ...funnelSeriesModifications,
    label: {
      show: true,
      position: 'inside',
      ...TEXT_STYLE(theme),
      color: 'inherit',
      fontSize: 12,
      backgroundColor: 'transparent',
      ...BORDER_STYLE,
      padding: 0,
      ...SHADOW_STYLE,
      ...funnelSeriesModifications?.label,
    },
    itemStyle: {
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      ...funnelSeriesModifications?.itemStyle,
    },
    labelLayout: {
      hideOverlap: true,
      ...funnelSeriesModifications?.labelLayout,
    },
  }
}

export default funnelSerie
