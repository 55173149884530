import { EChartsOption } from 'echarts'
import replaceObject from './replaceObject'
import { ReportDataSetWithMapping } from './types'

interface GenerateOptionParams {
  option: EChartsOption
  reportDataSets: ReportDataSetWithMapping
  aggregation?: Aggregation[]
}

const generateOption = (params: GenerateOptionParams): EChartsOption => {
  const { option: defaultoption, reportDataSets, aggregation = [] } = params

  const option = replaceObject({
    objectToReplace: defaultoption,
    itemsToPlace: reportDataSets,
    aggregation,
  })

  return option
}

export default generateOption
