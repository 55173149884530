import React from 'react'
import {
  FormControl,
  FormGroup,
  FormLabel,
  Box,
  FormControlLabel,
  Checkbox,
  Slider,
  RadioGroup,
  Radio,
} from '@mui/material'
import { Controller, Control } from 'react-hook-form'
import getFormatedDates from 'containers/ReportForm/helpers/getFormatedDates'
import {
  TextInput,
  Select,
  PasswordInput,
  FileInput,
  AudioInput,
} from 'components/Input'
import DateRangePicker from 'components/DateRangePicker'
import { DateRange } from '@mui/x-date-pickers-pro'
import { InputType, acceptedTypes } from 'components/ReportForm/types'
import { Mapping } from 'lib/reports/types'
import { handleChangeParams } from 'components/Input/types'

interface ReportFormInputsProps {
  inputType: InputType
  required?: boolean
  fieldName: string
  options: string[]
  stringMapping: string
  control: Control<any, any>
  errors: any
  mapping: Mapping
  handleInputChange: ({ value, eventName }: any) => void
  handleFileInputChange: ({ files, eventName }: handleChangeParams) => void
  showSearch?: boolean
  dragAndDrop?: boolean
  hasEditPermission?: boolean
}

const ReportFormInputs = (props: ReportFormInputsProps) => {
  const {
    inputType,
    required,
    fieldName,
    options,
    stringMapping,
    control,
    errors,
    mapping,
    handleInputChange,
    handleFileInputChange,
    showSearch,
    dragAndDrop,
    hasEditPermission,
  } = props
  switch (inputType) {
    case InputType.checkbox:
      return (
        <FormControl required={required}>
          <FormGroup>
            <FormLabel id={`check-group-${fieldName}`}>{fieldName}</FormLabel>
            <Box style={{ flexDirection: 'row' }}>
              {options.map((option, i) => {
                const defaultValue = stringMapping
                  .split(',')
                  .some((value) => value === option)
                return (
                  <FormControlLabel
                    key={`${fieldName}-${option}-${i}`}
                    control={
                      <Controller
                        name={`${fieldName}-${option}`}
                        control={control}
                        rules={{ required }}
                        defaultValue={defaultValue}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            defaultChecked={defaultValue}
                            disabled={!hasEditPermission}
                          />
                        )}
                      />
                    }
                    label={option}
                  />
                )
              })}
            </Box>
          </FormGroup>
        </FormControl>
      )

    case InputType.range:
      return (
        <FormGroup>
          {fieldName}
          <Controller
            name={fieldName}
            control={control}
            rules={{ required }}
            defaultValue={mapping || 50}
            render={({ field }) => (
              <Slider
                {...field}
                aria-label="Default"
                valueLabelDisplay="auto"
                disabled={!hasEditPermission}
              />
            )}
          />
        </FormGroup>
      )

    case InputType.radio:
      return (
        <FormControl required={required}>
          <FormLabel id={`radio-buttons-group-${fieldName}`}>
            {fieldName}
          </FormLabel>
          <Controller
            name={fieldName}
            control={control}
            rules={{ required }}
            defaultValue={mapping}
            render={({ field }) => (
              <RadioGroup
                {...field}
                aria-labelledby={`radio-buttons-group-${fieldName}`}
                style={{ flexDirection: 'row' }}
              >
                {options.map((option: string) => (
                  <FormControlLabel
                    name={option}
                    value={option}
                    control={<Radio disabled={!hasEditPermission} />}
                    label={option}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
      )

    case InputType.select:
      return (
        <Controller
          name={fieldName}
          control={control}
          rules={{ required }}
          render={({ field }) => (
            <Select
              {...field}
              defaultValue={stringMapping}
              disabled={!hasEditPermission}
              fullWidth
              handleInputChange={handleInputChange}
              id={fieldName}
              label={fieldName}
              labelId={`${fieldName}-label`}
              options={options}
              showSearch={showSearch}
            />
          )}
        />
      )

    case InputType.multiSelect:
      return (
        <Controller
          name={fieldName}
          control={control}
          rules={{ required }}
          render={({ field }) => (
            <Select
              {...field}
              defaultValue={stringMapping}
              disabled={!hasEditPermission}
              fullWidth
              handleInputChange={handleInputChange}
              id={fieldName}
              isMultiple
              label={fieldName}
              labelId={`${fieldName}-label`}
              options={options}
              showSearch={showSearch}
            />
          )}
        />
      )

    case InputType.color:
      return (
        <Controller
          name={fieldName}
          control={control}
          rules={{ required }}
          render={({ field }) => (
            <TextInput
              {...field}
              defaultValue={stringMapping}
              disabled={!hasEditPermission}
              fullWidth
              label={fieldName}
              name={fieldName}
              required={required}
              type={inputType}
            />
          )}
        />
      )

    case InputType.email:
      return (
        <Controller
          name={fieldName}
          control={control}
          rules={{
            required,
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          }}
          render={({ field }) => (
            <TextInput
              {...field}
              disabled={!hasEditPermission}
              defaultValue={stringMapping}
              type={inputType}
              required={required}
              name={fieldName}
              fullWidth
              label={fieldName}
              error={errors[fieldName]}
              helperText={errors[fieldName]?.type}
            />
          )}
        />
      )

    case InputType.password:
      return (
        <Controller
          name={fieldName}
          control={control}
          rules={{
            required,
          }}
          render={({ field }) => (
            <PasswordInput
              {...field}
              disabled={!hasEditPermission}
              defaultValue={stringMapping}
              required={required}
              name={fieldName}
              fullWidth
              label={fieldName}
              error={errors[fieldName]}
              helperText={errors[fieldName]?.type}
            />
          )}
        />
      )

    case InputType.dateRange:
      return (
        <>
          {(() => {
            const dateRangeArray = stringMapping.split(',')
            const startDate = new Date(dateRangeArray[0])
            const endDate = new Date(dateRangeArray[1])

            return (
              <FormControl required={required}>
                <FormLabel
                  id={`radio-buttons-group-${fieldName}`}
                  sx={{ marginBottom: '10px' }}
                >
                  {fieldName}
                </FormLabel>
                <Controller
                  name={fieldName}
                  control={control}
                  rules={{ required }}
                  defaultValue={[startDate, endDate]}
                  render={({ field }) => (
                    <DateRangePicker
                      {...field}
                      getCurrentRange={(value: DateRange<Date>) =>
                        field.onChange(getFormatedDates(value).toString())
                      }
                      disabled={!hasEditPermission}
                      dateRange={[startDate, endDate]}
                    />
                  )}
                />
              </FormControl>
            )
          })()}
        </>
      )

    case InputType.file:
      return (
        <Controller
          name={fieldName}
          control={control}
          rules={{
            required,
          }}
          defaultValue={stringMapping}
          render={({ field }) => (
            <FileInput
              {...field}
              acceptFileTypes={options}
              defaultValue={stringMapping}
              disabled={!hasEditPermission}
              fullWidth
              handleChange={handleFileInputChange}
              label={fieldName}
              multiple
              name={fieldName}
              fieldName={fieldName}
              paginateBy={3}
              placeholder={fieldName}
              required={required}
              dragAndDrop={dragAndDrop}
            />
          )}
        />
      )

    case InputType.audio:
      return (
        <Controller
          name={fieldName}
          control={control}
          rules={{
            required,
          }}
          render={({ field }) => (
            <AudioInput
              {...field}
              name={fieldName}
              fieldName={fieldName}
              paginateBy={3}
              disabled={!hasEditPermission}
              handleChange={handleFileInputChange}
            />
          )}
        />
      )

    default:
      return (
        <Controller
          name={fieldName}
          control={control}
          rules={{ required }}
          render={({ field }) => (
            <TextInput
              {...field}
              defaultValue={stringMapping}
              disabled={!hasEditPermission}
              error={errors[fieldName]}
              fullWidth
              helperText={errors[fieldName]?.type}
              label={fieldName}
              placeholder={fieldName}
              required={required}
              type={acceptedTypes.includes(inputType) ? inputType : 'text'}
            />
          )}
        />
      )
  }
}

export default ReportFormInputs
