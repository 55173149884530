import React, { useState, useEffect } from 'react'
import { SelectChangeEvent } from '@mui/material'
import MultiFilterComponent from 'components/Multifilter'
import { OptionMultifilter, OptionsMultifilter } from './types'
import { useReports } from 'contexts/reports'
import getSelectedOptionReportIds from './getSelectedOptionReportIds'
import { useLayoutState } from 'hooks/components'
import formatOptions from './formatOptions'

const MultiFilter = (props: WithReport) => {
  const { report } = props
  const { reports, setReports } = useReports()
  const { matches: isDesktop } = useLayoutState('md')

  const fields: ChartFields = report?.dataFields
    ? JSON.parse(report?.dataFields)
    : null
  const selectWidth = fields?.width

  const defaultOptions: OptionsMultifilter = report?.chartData
    ? JSON.parse(report?.chartData)
    : []

  const { options, reportIds, labels, optionsForSelects } = formatOptions({
    defaultOptions,
  })

  const initialSelectedOption = optionsForSelects
    .map((selectOption: OptionMultifilter) => ({
      [selectOption.label]: selectOption.options?.[0],
    }))
    .reduce((acc, val) => ({ ...acc, ...val }), {})

  const [selectedOption, setSelectedOption] = useState(initialSelectedOption)

  const handleChange = (e: SelectChangeEvent<string>, selectOption: string) => {
    setSelectedOption({
      ...selectedOption,
      [selectOption]: e.target.value as string,
    })
  }

  const selectedOptionReportIds = getSelectedOptionReportIds({
    options,
    labels,
    selectedOption,
  })

  useEffect(() => {
    const multiFilterReports = reports
      .filter((report) => reportIds.has(report.id))
      .map((report) => ({
        ...report,
        isDisabled: !selectedOptionReportIds.has(report.id),
      }))

    const newReports = reports.map((report) => {
      const multifilterReport = multiFilterReports.find(
        (multiFilterReport) => multiFilterReport.id === report.id
      )

      return multifilterReport || report
    })

    setReports(newReports)
  }, [selectedOption]) // eslint-disable-line

  return (
    <MultiFilterComponent
      selectOptions={optionsForSelects}
      selectedOption={selectedOption}
      handleChange={handleChange}
      selectWidth={selectWidth}
      isDesktop={isDesktop}
    />
  )
}

export default MultiFilter
