import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'redux/store/types'
import { getItem } from 'lib/localStorage'
import reducers from './reducers'
import { PlaygroundState } from './types'

export const PLAYGROUND_PORT = 'playground-port'
const DEFAULT_PLAYGROUND_PORT = getItem<null | number>(PLAYGROUND_PORT) || 8000
const PLAYGROUND = 'playground'

const initialState: PlaygroundState = {
  playgroundPort: DEFAULT_PLAYGROUND_PORT,
}

const playgroundSlice = createSlice({
  name: PLAYGROUND,
  initialState,
  reducers,
})

export const selectPlayground = (state: RootState) => state.playground

export const { setPlayground } = playgroundSlice.actions

export default playgroundSlice.reducer
