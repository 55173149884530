import { styled } from '@mui/material/styles'
import { Paper } from '@mui/material'
import { DEFAULT_REPORT_MINHEIGHT } from 'lib/reports'

export const ContainerReactEcharts = styled(Paper)`
  width: 100%;
  height: 100%;
  min-height: ${DEFAULT_REPORT_MINHEIGHT}px;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  background-color: var(--background-paper);
`

export const ContainerNoData = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
