interface Position {
  id: string
  coords: number[]
}

export const generateGrid = (reports: Report[]) => {
  const stringCoordinates: string[][] = reports.map((report: Report) =>
    (report.grid as string).replace(/\s/g, '').split(',')
  )
  const coordinates: number[][] = stringCoordinates.map((coords: string[]) =>
    coords.map((coord: string) => Number(coord))
  )

  const yCoordinates = coordinates.map((coords: number[]) => coords[0])
  const xCoordinates = coordinates.map((coords: number[]) => coords[1])
  const ySize = Math.max(...yCoordinates)
  const xSize = Math.max(...xCoordinates)
  const positions = coordinates.map((coords, i) => ({
    id: reports[i].id,
    coords: [...coords],
  }))

  // Order Reports

  positions.sort((positionA, positionB) => {
    if (positionA.coords[0] === positionB.coords[0]) {
      return positionA.coords[1] - positionB.coords[1]
    }

    return positionA.coords[0] - positionB.coords[0]
  })

  // Fill the horizontal space on the right

  for (let i = 0; i < positions.length; i++) {
    if (positions.length > i + 1) {
      if (positions[i].coords[0] === positions[i + 1].coords[0]) {
        if (positions[i].coords[1] < positions[i + 1].coords[1] - 1) {
          const difference = positions[i + 1].coords[1] - positions[i].coords[1]
          positions[i].coords.push(
            positions[i].coords[0] + 1,
            positions[i].coords[1] + difference
          )
        } else {
          positions[i].coords.push(
            positions[i].coords[0] + 1,
            positions[i].coords[1] + 1
          )
        }
      } else {
        positions[i].coords.push(positions[i].coords[0] + 1, xSize + 1)
      }
    } else {
      positions[i].coords.push(positions[i].coords[0] + 1, xSize + 1)
    }
  }

  // Position separated by rows

  const positionsPerRows: Position[][] = []

  for (let i = 1; i <= ySize; i++) {
    const positionsRows = positions.filter(
      (position) => position.coords[0] === i
    )
    positionsPerRows.push(positionsRows)
  }

  // Fill in the space below

  for (let i = 0; i < ySize; i++) {
    if (i + 1 < positionsPerRows.length) {
      for (let x = 0; x < positionsPerRows[i].length; x++) {
        if (positionsPerRows[i + 1][x]) {
          let y = 1

          do {
            if (positionsPerRows[i + y][x]) {
              if (
                positionsPerRows[i][x].coords[3] <=
                positionsPerRows[i + y][x].coords[1]
              ) {
                positionsPerRows[i][x].coords[2] =
                  positionsPerRows[i + y][x].coords[2]
              }
              if (
                positionsPerRows[i][x].coords[1] >=
                positionsPerRows[i + y][x].coords[3]
              ) {
                positionsPerRows[i][x].coords[2] =
                  positionsPerRows[i + y][x].coords[2]
              }
            } else {
              if (
                positionsPerRows[i][x].coords[3] <=
                positionsPerRows[ySize - 1][
                  positionsPerRows[ySize - 1].length - 1
                ].coords[1]
              ) {
                positionsPerRows[i][x].coords[2] =
                  positionsPerRows[ySize - 1][
                    positionsPerRows[ySize - 1].length - 1
                  ].coords[2]
              }
              if (
                positionsPerRows[i][x].coords[1] >=
                positionsPerRows[ySize - 1][
                  positionsPerRows[ySize - 1].length - 1
                ].coords[3]
              ) {
                positionsPerRows[i][x].coords[2] =
                  positionsPerRows[ySize - 1][
                    positionsPerRows[ySize - 1].length - 1
                  ].coords[2]
              }
            }

            y++
          } while (positionsPerRows[i + y])
        }
      }
    }
  }

  // Fill the horizontal space on the left

  for (let i = 0; i < positionsPerRows.length; i++) {
    if (positionsPerRows[i][0]?.coords[1] !== 1) {
      if (positionsPerRows[i - 1]) {
        if (
          positionsPerRows[i][0] &&
          positionsPerRows[i][0].coords[0] >=
            positionsPerRows[i - 1][0].coords[2]
        ) {
          positionsPerRows[i][0].coords[1] = 1
        }
      } else {
        positionsPerRows[i][0].coords[1] = 1
      }
    }
  }

  // Responsive Positions

  const responsivePositions: Position[] = []

  for (let i = 0; i < positions.length; i++) {
    const responsivePosition = { ...positions[i], coords: [i + 1, 1, i + 2, 2] }
    responsivePositions.push(responsivePosition)
  }

  return {
    ySize,
    xSize,
    positions,
    responsivePositions,
  }
}
