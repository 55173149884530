import React, { createContext, ReactNode, useContext, useEffect } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectPlayground,
  setPlayground,
  PLAYGROUND_PORT,
} from 'redux/playground'
import useQueryUrlParams from 'hooks/useQueryUrlParams'
import { setItem } from 'lib/localStorage'
import getApolloClient from './getApolloClient'

const PORT = 'port'

interface ApolloContextValue {
  apolloClient: ApolloClient<NormalizedCacheObject> | null
}

const ApolloContext = createContext<ApolloContextValue>({ apolloClient: null })

interface ApolloProviderProps {
  children: ReactNode
}

export const ApolloProvider = (props: ApolloProviderProps) => {
  const { children } = props
  const dispatch = useDispatch()
  const { playgroundPort } = useSelector(selectPlayground)
  const port = useQueryUrlParams().get(PORT)

  useEffect(() => {
    if (Boolean(port)) {
      setItem(PLAYGROUND_PORT, port)
      dispatch(setPlayground({ playgroundPort: Number(port) }))
    }
  }, []) // eslint-disable-line

  const apolloClient = getApolloClient({ port: playgroundPort })
  const value = { apolloClient }

  return (
    <ApolloContext.Provider value={value}>{children}</ApolloContext.Provider>
  )
}

export const useApollo = () => {
  const context = useContext(ApolloContext)

  return context
}

export default ApolloProvider
