import * as React from 'react'
import cx from 'classnames'

export const MeetingDoctors = (props: React.SVGProps<SVGSVGElement>) => {
  const { width = 100 } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 631.08 100.02"
      width={width}
      className={cx(props.className)}
      {...props}
    >
      <defs>
        <linearGradient
          id="Degradado_sin_nombre_20"
          x1={26.23}
          y1={45.42}
          x2={15.49}
          y2={87.21}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#00c2f7" />
          <stop offset={0.35} stopColor="#07b9ef" />
          <stop offset={0.93} stopColor="#1b9fda" />
          <stop offset={1} stopColor="#1e9cd7" />
        </linearGradient>
        <linearGradient
          id="Degradado_sin_nombre_20-2"
          x1={62.92}
          y1={35.78}
          x2={49}
          y2={87.61}
          xlinkHref="#Degradado_sin_nombre_20"
        />
        <linearGradient
          id="Degradado_sin_nombre_20-3"
          x1={116.48}
          y1={13.3}
          x2={96.26}
          y2={77.61}
          xlinkHref="#Degradado_sin_nombre_20"
        />
        <style>
          {
            '.cls-1{fill:#0464b2}.cls-2{fill:#0192d9}.cls-3{fill:#027cbf}.cls-5{fill:#055d99}'
          }
        </style>
      </defs>
      <title>logo-meeting-doctors</title>
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path
            className="cls-1"
            d="m150.2 85 4.8-43.58h1.81L172.66 81l15.82-39.56h1.88l4 36.28c.53 4.22 1.14 5.63 2.74 5.63a2.92 2.92 0 0 0 1.81-.6l.21.06.8 1.81-.13.27a4.62 4.62 0 0 1-2.89.94c-3.22 0-4.49-2.55-5-6.91l-3.35-31.24L173.6 85h-1.81l-14.95-37.34-4 37.35ZM206.32 69.12c.4 8.78 6.77 14.34 14.82 14.34 5.29 0 10.39-2.48 13.07-6.23h.2l1.68 1.34v.27c-3.15 4.29-8.92 7-14.89 7-9.58 0-17.5-6.77-17.5-17.57 0-9.92 6.71-17.56 16.9-17.56S237 58.32 237 68a5.61 5.61 0 0 1-.07 1.14Zm0-2.22h28c-.44-8.18-5.47-13.9-13.65-13.9s-13.95 5.72-14.35 13.9ZM245.41 69.12c.4 8.78 6.77 14.34 14.82 14.34 5.29 0 10.39-2.48 13.07-6.23h.2l1.68 1.34v.27c-3.15 4.29-8.92 7-14.89 7-9.58 0-17.5-6.77-17.5-17.57 0-9.92 6.71-17.56 16.9-17.56s16.36 7.64 16.36 17.3a5.61 5.61 0 0 1-.07 1.14Zm0-2.22h28C273 58.72 267.94 53 259.76 53s-13.95 5.72-14.35 13.9ZM299.52 84.34l-.14.2a7.62 7.62 0 0 1-4.56 1.27c-5.56 0-9.38-4-9.38-11.6V53.69h-6v-2.21h6V41.09l2.28-.47.27.13v10.73h11v2.21H288v20.52c0 6.17 2.61 9.25 7 9.25a5.33 5.33 0 0 0 3.42-1l.27.13ZM304.28 44.78a2.75 2.75 0 1 1 2.72 2.75 2.74 2.74 0 0 1-2.72-2.75Zm4 40.23V51.48h-2.55V85ZM348.4 64.15V85h-2.55V64.29c0-6.84-4.49-11.27-11.27-11.27-7.17 0-12 5-12 11.67V85H320V56c0-2.07-.74-3-2-3a2.7 2.7 0 0 0-1.67.6l-.27-.07-.6-1.67.06-.2a4.4 4.4 0 0 1 2.82-.94c2.55 0 4.09 1.74 4.09 4.69v2.08c2.34-4.09 6.77-6.77 12.47-6.77 8.1-.04 13.5 5.28 13.5 13.43ZM392.38 51.82l-.67 1.67-.2.07a2.87 2.87 0 0 0-1.68-.6c-1.27 0-2 .94-2 3V80c0 9.92-6.5 15.15-15.62 15.15-6.37 0-11.53-2.62-14.62-7.24v-.2l1.41-1.56h.27a15.18 15.18 0 0 0 13.07 6.63c7.78 0 12.94-4.62 12.94-12.87v-5.77A15.18 15.18 0 0 1 372 81.25c-9.12 0-15.62-6.64-15.62-15.29s6.5-15.28 15.69-15.28a14.87 14.87 0 0 1 13.27 7.24v-2.55c0-2.95 1.54-4.69 4.16-4.69a4.19 4.19 0 0 1 2.75.94Zm-7 14.21c0-7.44-5.43-13-13.21-13S359 58.59 359 66s5.3 13 13.14 13 13.2-5.59 13.2-13Z"
          />
          <path
            className="cls-2"
            d="M438.17 63.21c0 13-8.44 21.8-22.32 21.8H400.7V41.42h15.15c13.88 0 22.32 8.79 22.32 21.79Zm-2.74 0c0-11.66-7.38-19.37-19.58-19.37h-12.54v38.75h12.54c12.2 0 19.58-7.71 19.58-19.38ZM480.28 68.24c0 9.93-7.57 17.57-17.77 17.57a17.57 17.57 0 1 1 0-35.13c10.2 0 17.77 7.64 17.77 17.56Zm-2.68 0c0-8.65-6.37-15.22-15.09-15.22a14.78 14.78 0 0 0-15.15 15.22 14.78 14.78 0 0 0 15.15 15.22c8.72 0 15.09-6.57 15.09-15.22ZM486.38 68.24c0-9.92 7.31-17.56 17.5-17.56A17.17 17.17 0 0 1 518 57.45v.27l-1.54 1.2h-.27A14.9 14.9 0 0 0 504 53c-8.83 0-15 6.59-15 15.24s6 15.22 15.08 15.22a14.55 14.55 0 0 0 12.07-5.9h.27l1.58 1.21V79a17.2 17.2 0 0 1-14.08 6.77c-10.23.04-17.54-7.6-17.54-17.53ZM541.5 84.34l-.14.2a7.62 7.62 0 0 1-4.56 1.27c-5.56 0-9.38-4-9.38-11.6V53.69h-6v-2.21h6V41.09l2.28-.47.27.13v10.73h11v2.21H530v20.52c0 6.17 2.61 9.25 7 9.25a5.33 5.33 0 0 0 3.42-1l.27.13ZM580 68.24a17.77 17.77 0 0 1-35.54 0c0-9.92 7.58-17.56 17.77-17.56S580 58.32 580 68.24Zm-2.69 0c0-8.65-6.37-15.22-15.08-15.22a14.78 14.78 0 0 0-15.15 15.22 14.78 14.78 0 0 0 15.15 15.22c8.7 0 15.07-6.57 15.07-15.22ZM605.73 51.82l-.87 1.87-.27.07a7.07 7.07 0 0 0-3.35-.74c-5 0-9.52 4.43-9.52 11.94V85h-2.55V56c0-2.07-.74-3-2-3a2.71 2.71 0 0 0-1.68.6l-.26-.07-.61-1.67.07-.2a4.13 4.13 0 0 1 2.82-.94c2.54 0 4.09 1.67 4.09 4.69v1.88a10.89 10.89 0 0 1 9.92-6.57 9.73 9.73 0 0 1 4.16.87ZM619.55 50.68a12.56 12.56 0 0 1 10.39 5.23v.27l-1.68 1.2h-.2a10.15 10.15 0 0 0-8.45-4.38c-4.42 0-7.51 2.55-7.51 6s2.89 5 6.57 6.57c5.5 2.28 12.41 4.29 12.41 11.06 0 5.63-4.83 9.12-11.27 9.12-5.43 0-9.58-2.35-12.06-6.17v-.2l1.6-1.27h.27a11.2 11.2 0 0 0 10 5.29c5.3 0 8.92-2.68 8.92-6.7 0-5.1-5.63-6.71-11.13-9.05-4-1.68-7.91-3.62-7.91-8.52s4.28-8.45 10.05-8.45Z"
          />
          <path
            className="cls-3"
            d="M35 53.28s-.41 13.2-.34 16c.12 4 .68 9.85.68 9.85l-5.12 20.43a40.89 40.89 0 0 1-3.35-5.87c-1.57-3.36-3.36-7.39-3.36-7.39a57.15 57.15 0 0 0 5.26-11.75C30.23 69.18 35 53.28 35 53.28ZM74.45 45.67s-.12 12.65.11 17.46 1.45 13 1.45 13l-6.13 23.26s-5.17-7.15-6.41-9.84S61.91 86 61.91 86s3.36-7.73 4.59-11.53 7.95-28.8 7.95-28.8Z"
          />
          <path
            d="M0 99.5s11.71-43.2 13.6-47.79c2.07-5 5.24-11.84 13.59-18 8-5.87 14-6.21 15-6.21h1S40.07 37.91 36 52.86c-2.7 10-5.43 28.25-13.2 37.56-4.48 5.37-9.54 9-16.28 9.44-4.8.32-6.52-.36-6.52-.36Z"
            style={{
              fill: 'url(#Degradado_sin_nombre_20)',
            }}
          />
          <path
            className="cls-5"
            d="M35.49 79.7c-.23-2.3-.62-6.27-.62-6.27l-4.65 26.13ZM76 76.06c-.23-2.29-.79-6-.79-6l-5.35 29.29Z"
          />
          <path
            d="M30.22 99.56s12.26-48.77 14.62-54.12c2.57-5.84 7.5-15.81 18.65-22.73a51.66 51.66 0 0 1 18.68-7c2.07-.37 1.69.18 1.69.18S80.33 29.65 75.45 47c-3.25 11.6-5.74 30.69-15.73 41.71C54 95.06 47.58 99.18 40 99.83a33.92 33.92 0 0 1-9.78-.27Z"
            style={{
              fill: 'url(#Degradado_sin_nombre_20-2)',
            }}
          />
          <path
            d="M69.88 99.39S84.83 41.39 88 35a59.31 59.31 0 0 1 25-26.61C124.73 1.86 133.54.8 135.29.51a40.31 40.31 0 0 1 4.76-.51s-4.83 18.12-11.42 41.08c-4 13.87-5.15 21.16-9.4 32.46-4.4 11.7-17.21 22.16-30.12 25.3-8.28 2.02-19.23.55-19.23.55Z"
            style={{
              fill: 'url(#Degradado_sin_nombre_20-3)',
            }}
          />
        </g>
      </g>
    </svg>
  )
}
