import React, { FunctionComponent, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { usePostHog } from 'posthog-js/react'
import Logo from 'components/Logo'
import { StatusFormProps } from 'components/StatusForm/StatusForm'
import { formatMessage } from 'i18n/ShimokuIntl'
import { useLayoutState } from 'hooks/components'
import { useForm } from 'react-hook-form'
import AuthBackground from 'assets/illustration-sign-upx2.png'
import { isValidEmail } from 'lib/forms'
import { Link } from 'react-router-dom'
import AuthFormInputs, { AuthFormField } from './AuthFormInput'
import Button from 'components/Button'
import { Reload } from 'icons/Line/reload/reload'
import Indicator from 'components/Indicator'
import SocialIcons from 'components/SocialIcons/SocialIcons'
import { USER_EVENTS } from 'lib/postHogEvents'
import URLs from 'lib/URLs'
import * as S from './AuthForm.styled'

export interface FormFields {
  [key: string]: AuthFormField
}
interface AuthFormHeaderProps {
  fields: FormFields
  formHeading: string
  hideSubmitButton?: boolean
  loading?: boolean
  onReSendCode?: (data: any) => void
  onSubmitForm: (data: any) => void
  page?: string
  sendDirty?: boolean
  showSuccess?: boolean
  submitButtonLabel: string
  subtitle?: string
}

interface AuthFormProps extends AuthFormHeaderProps, StatusFormProps {}

const AuthForm: FunctionComponent<AuthFormProps> = (props) => {
  const {
    fields,
    onSubmitForm,
    onReSendCode,
    submitButtonLabel,
    formHeading,
    subtitle,
    children,
    sendDirty = false,
    loading = false,
    hideSubmitButton = false,
    showSuccess = false,
    page,
  } = props
  const { matches: isDesktop } = useLayoutState('md')
  const defaultValues = Object.keys(fields).reduce((acc, key) => {
    acc[key] = fields[key].defaultValue || ''
    return acc
  }, {} as any)
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<any>({
    defaultValues,
    mode: 'onChange',
  })

  const { email } = getValues()
  const posthog = usePostHog()

  useEffect(() => {
    if (email && isValidEmail(email)) {
      posthog?.capture(USER_EVENTS.EMAIL_ENTERED, {
        email,
        page,
      })
    }
  }, [email, page, posthog])

  return (
    <S.Container>
      <S.Box padding={5}>
        <S.LogoContainer>
          <Link to={'/'}>
            <Logo />
          </Link>
        </S.LogoContainer>
        <S.Content>
          <Box marginTop={2} marginBottom={2}>
            {!showSuccess && (
              <>
                <Typography
                  variant="h1"
                  marginBottom={!subtitle ? 3 : 1}
                  align="left"
                >
                  {formHeading}
                </Typography>
                {subtitle && (
                  <Typography variant="body1" marginBottom={3} align="left">
                    {subtitle}
                  </Typography>
                )}
              </>
            )}
            {showSuccess ? (
              <Box marginBottom={4} color="var(--color-primary)">
                <Indicator
                  title={formatMessage('page.signUp.success.title')}
                  description={formatMessage('page.signUp.success.description')}
                  color="success"
                  variant="contained"
                  align="left"
                  bigIcon="Line/check-cercle"
                  centerContent
                  elipsis={false}
                />
              </Box>
            ) : (
              <Box
                className="ph-no-capture"
                component="form"
                role="form"
                onSubmit={handleSubmit(onSubmitForm)}
              >
                {Object.keys(fields).map((key, index) => (
                  <Box
                    marginTop={3}
                    marginBottom={index === Object.keys(fields).length ? 3 : 0}
                  >
                    <AuthFormInputs
                      key={key}
                      control={control}
                      errors={errors}
                      getValues={getValues}
                      {...fields[key]}
                    />
                  </Box>
                ))}
                {onReSendCode && (
                  <Box marginTop={3} marginBottom={3}>
                    <Button
                      startIcon={<Reload color="var(--color-white)" />}
                      fullWidth
                      disabled={loading}
                      loading={loading}
                      onClick={() => onReSendCode(getValues('email'))}
                    >
                      {formatMessage('form.reSendCode')}
                    </Button>
                  </Box>
                )}
                {!hideSubmitButton && (
                  <Box marginTop={3} marginBottom={3}>
                    {sendDirty ? (
                      <Button
                        className="ph-no-capture"
                        type="submit"
                        fullWidth
                        disabled={isSubmitting || !isValid || loading}
                        loading={isSubmitting || loading}
                      >
                        {submitButtonLabel}
                      </Button>
                    ) : (
                      <Button
                        className="ph-no-capture"
                        type="submit"
                        fullWidth
                        disabled={
                          isSubmitting ||
                          !isDirty ||
                          (!isValid && isDirty) ||
                          loading
                        }
                        loading={isSubmitting || loading}
                      >
                        {submitButtonLabel}
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            )}
            {children}
          </Box>
        </S.Content>
        <Box>
          <SocialIcons />
          <S.Footer>
            {new Date().getFullYear()} © <strong>Shimoku</strong> |{' '}
            <S.ExternalLink
              href={URLs.privacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatMessage('generic.privacyPolicy')}
            </S.ExternalLink>
          </S.Footer>
        </Box>
      </S.Box>
      {isDesktop && (
        <S.Box
          sx={{
            backgroundImage: `url(${AuthBackground})`,
            backgroundSize: 'contain',
            backgroundPosition: 'right',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
          }}
        />
      )}
    </S.Container>
  )
}

export default AuthForm
