import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

interface CrumbTextProps {
  last: boolean
}

export const CrumbText = styled(Typography)<CrumbTextProps>(({ last }) => ({
  ...(last && {
    color: 'var(--color-grey-500)',
  }),
}))
