import { styled } from '@mui/material/styles'

type TextProps = {
  margin?: string
}

export const Text = styled('div')<TextProps>(({ margin = 0 }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  margin,
}))
