import { API, graphqlOperation } from 'aws-amplify'
import { FileData } from 'components/Input/types'
import { getErrorMessage } from 'lib/errors'
import deleteFile from 'gql/mutations/deleteFile'
import Sentry from 'tracking/Sentry'

interface DeleteFileEntitiesParams {
  files: FileData[]
}

const deleteFileEntities = async (params: DeleteFileEntitiesParams) => {
  const { files } = params

  if (Boolean(files.length)) {
    await Promise.allSettled(
      files.map(async (file) => {
        try {
          const deletedFile: any = await API.graphql(
            graphqlOperation(deleteFile, {
              id: file.id,
            })
          )
          return deletedFile
        } catch (error) {
          Sentry.captureException(error)
          throw new Error(getErrorMessage(error, 'errors.deleteFile'))
        }
      })
    )
  }
}

export default deleteFileEntities
