import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'redux/store/types'

enum moduleType {
  INSTALL_REMOVE_APPS = 'INSTALL_REMOVE_APPS',
}

interface HasModuleParams {
  items: Module[]
  type: ModuleType
}

type setAvailableModulesParams = {
  business?: Business
  apps?: App[]
  currentDateTime?: string
}

const initialState = {
  haveInstallRemoveApps: false,
}

const availableModulesSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    setAvailableModules: (state, action) => {
      const { business }: setAvailableModulesParams = action.payload
      if (!business?.modules) return initialState

      const {
        modules: { items },
      } = business
      const { INSTALL_REMOVE_APPS } = moduleType
      const hasModule = ({ items, type }: HasModuleParams) =>
        items.some(
          ({ type: itemType, enabled }) => itemType === type && enabled
        )
      const haveInstallRemoveApps = hasModule({
        items,
        type: INSTALL_REMOVE_APPS,
      })
      return {
        ...state,
        haveInstallRemoveApps,
      }
    },
  },
})

export const selectAvailableModules = (state: RootState) => {
  return state.modules
}

export const { setAvailableModules } = availableModulesSlice.actions

export default availableModulesSlice.reducer
