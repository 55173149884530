import React, { ReactNode } from 'react'
import * as S from './Main.styled'

interface MainProps {
  children: ReactNode
  padding?: boolean
  centered?: boolean
  fullHeight?: boolean
}

const Main = (props: MainProps) => {
  const {
    children,
    padding = false,
    centered = false,
    fullHeight = false,
  } = props

  return (
    <S.Main padding={padding} centered={centered} fullHeight={fullHeight}>
      {children}
    </S.Main>
  )
}

export default Main
