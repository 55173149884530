import { useSelector } from 'react-redux'
import { selectCurrentRoles } from 'redux/roles'
import { selectUniverseModules } from 'redux/universeModules'
import { useSnackbar } from 'notistack'
import useUpdateReportsPeriodically from 'contexts/reports/hooks/useUpdateReportsPeriodically'
import { EventParams } from 'hooks/useRunEvents/types'
import useGraphQLOperation from 'hooks/useGraphQLOperation'
import { GraphQLOperationTypeEnum } from 'hooks/useGraphQLOperation/types'
import createRun from 'gql/mutations/createRun'
import triggerWebhook from 'gql/mutations/triggerWebhook'
import {
  ActivityStatus,
  ActivityStatusEnum,
} from 'hooks/useRunEvents/actionEvents/useRunActivity/types'
import { getErrorMessage } from 'lib/errors'
import { formatMessage } from 'i18n/ShimokuIntl'

const { MUTATION } = GraphQLOperationTypeEnum
const { OK } = ActivityStatusEnum

interface UseRunActivityParams {
  appId: string
}

export interface RunActivityParams {
  activityId: string
  eventParams?: EventParams
}

const useRunActivity = (params: UseRunActivityParams) => {
  const { appId } = params
  const graphQLOperation = useGraphQLOperation()
  const rolePermissions = useSelector(selectCurrentRoles)
  const { ACTIVITY_WEBHOOKS } = useSelector(selectUniverseModules)
  const { enqueueSnackbar } = useSnackbar()
  //TODO: Remove this when implement events
  const { updateReportsPeriodically } = useUpdateReportsPeriodically({ appId })

  const hasRunActivityPermission =
    rolePermissions.DATA_EXECUTION === 'WRITE' && ACTIVITY_WEBHOOKS

  const runActivity = async (params: RunActivityParams) => {
    if (hasRunActivityPermission) {
      const { activityId, eventParams = {} } = params
      const { files = {}, formData } = eventParams

      try {
        const settings = JSON.stringify({ files })
        const createRunVariables = { input: { activityId, settings } }
        const createRunOptions = { variables: createRunVariables }
        const resCreateRun = await graphQLOperation({
          operationType: MUTATION,
          operation: createRun,
          options: createRunOptions,
        })
        const runId = resCreateRun?.data.createRun.id

        const triggerWebhookVariables = { runId, requestBody: formData }
        const triggerWebhookOptions = { variables: triggerWebhookVariables }
        const resTriggerWebhook = await graphQLOperation({
          operationType: MUTATION,
          operation: triggerWebhook,
          options: triggerWebhookOptions,
        })
        const triggerWebhookStatusJSON = resTriggerWebhook?.data.triggerWebhook
        const triggerWebhookStatus: ActivityStatus = JSON.parse(
          triggerWebhookStatusJSON
        )
        if (triggerWebhookStatus.STATUS === OK) {
          enqueueSnackbar(formatMessage('success.runActivity'), {
            variant: 'success',
          })
          updateReportsPeriodically()
        }
      } catch (error) {
        enqueueSnackbar(
          formatMessage('errors.runActivity', {
            error: getErrorMessage(error),
          }),
          { variant: 'error' }
        )
      }
    } else {
      enqueueSnackbar(formatMessage('error.runActivityPermission'), {
        variant: 'error',
      })
    }
  }

  return { runActivity }
}

export default useRunActivity
