import { FILTER_TYPES, FilterValue, FilterValueDateRange } from './types'

const { DATERANGE } = FILTER_TYPES

interface generateContainsParams {
  filterType: FILTER_TYPES
  value: FilterValue
}

const generateContains = (params: generateContainsParams) => {
  const { filterType, value } = params

  switch (filterType) {
    case DATERANGE:
      const [startDate = null, endDate = null] =
        (value as FilterValueDateRange) || []
      return { gt: startDate, lt: endDate }
    default:
      return { contains: value }
  }
}

export default generateContains
