import { GridInitialState } from '@mui/x-data-grid-pro'
import { TableProperties } from 'lib/reports/types'

interface GetInitialStateParams {
  tableProperties: TableProperties
}

const getInitialState = (params: GetInitialStateParams) => {
  const {
    tableProperties: { columns },
  } = params

  const columnVisibilityModel = columns?.reduce(
    (columnVisibilityModel, column) => {
      const { field, hideColumn } = column

      if (hideColumn) {
        return {
          ...columnVisibilityModel,
          [field]: false,
        }
      }

      return columnVisibilityModel
    },
    {}
  )

  const initialState: GridInitialState = {
    columns: {
      columnVisibilityModel,
    },
  }

  return initialState
}

export default getInitialState
