import React from 'react'

import { MenuItem, Checkbox } from '@mui/material'
import { decodeRegexSpace } from 'lib/strings'

import styles from './ReportTable.module.css'
import { SelectInput } from 'components/Input'

interface SelectFilterProps extends React.HTMLAttributes<HTMLDivElement> {
  reportId: string
  filter: EqualityFilter
  field: ReportTableDataField
  loading?: boolean
  cell: string
  onFilter: (field: string) => (event: any) => void
}

const formatInputValue = (
  filter: EqualityFilter,
  field: ReportTableDataField
): string[] => {
  const orFilters = filter.and.filter(({ or }) => {
    return Object.keys(or[0])[0] === field.field
  })
  if (!Boolean(orFilters.length)) {
    return []
  }
  return orFilters.flatMap(({ or }) =>
    or.map((orItem) => orItem[field.field]?.eq)
  )
}

const isInputChecked = (
  { and }: EqualityFilter,
  { field }: ReportTableDataField,
  value: string | undefined
): boolean =>
  and.some(({ or }) => or.some((orItem) => orItem[field]?.eq === value))

const SelectFilter = ({
  reportId,
  filter,
  onFilter,
  cell,
  field,
  loading,
}: SelectFilterProps) => (
  <>
    <SelectInput
      multiple
      label={cell}
      id="demo-simple-select"
      labelId={`report-${reportId}-filter-${field.field}`}
      value={formatInputValue(filter, field)}
      renderValue={(selected) =>
        typeof selected === 'string'
          ? selected
          : decodeRegexSpace((selected as string[]).join(', '))
      }
      onChange={onFilter(field.field)}
      classes={{ select: styles.select }}
    >
      {field.filterBy?.map((valueName: string) => {
        return (
          <MenuItem
            key={valueName}
            value={valueName}
            disabled={loading}
            dense
            style={{ paddingLeft: 5 }}
          >
            <Checkbox
              color="primary"
              size="small"
              checked={isInputChecked(filter, field, valueName)}
            />
            {valueName}
          </MenuItem>
        )
      })}
    </SelectInput>
  </>
)

export default SelectFilter
