import { CandlestickSeriesOption } from 'echarts'
import { TypeSeriesParams } from '../../types'
import { SHADOW_STYLE } from '../../defaults/general'

const candlestickSerie = (
  params: TypeSeriesParams<CandlestickSeriesOption>
): CandlestickSeriesOption => {
  const {
    typeSeriesModifications: candlestickSeriesModifications = {},
    theme,
  } = params
  const PALLETE = theme.palette

  return {
    type: 'candlestick',
    coordinateSystem: 'cartesian2d',
    legendHoverLink: true,
    barMaxWidth: 20,
    selectedMode: false,
    ...candlestickSeriesModifications,
    itemStyle: {
      color: PALLETE.error.main,
      color0: PALLETE.success.main,
      borderColor: PALLETE.error.main,
      borderColor0: PALLETE.success.main,
      borderWidth: 1,
      ...SHADOW_STYLE,
      ...candlestickSeriesModifications?.itemStyle,
    },
  }
}

export default candlestickSerie
