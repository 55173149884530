import { GridComponentOption } from 'echarts'
import { BORDER_STYLE, SHADOW_STYLE } from './general'
import { PaddingGrid } from '../types'

interface GridParams {
  paddingGrid?: PaddingGrid
}

const grid = (params: GridParams): GridComponentOption => {
  const { paddingGrid } = params

  return {
    id: '',
    show: true,
    top: 60,
    right: '10%',
    bottom: 80,
    left: '10%',
    ...paddingGrid,
    width: 'auto',
    height: 'auto',
    containLabel: true,
    backgroundColor: 'transparent',
    ...BORDER_STYLE,
    ...SHADOW_STYLE,
  }
}

export default grid
