import React, {
  Dispatch,
  SetStateAction,
  createContext,
  ReactNode,
  useState,
  useEffect,
  useContext,
} from 'react'
import { useSnackbar } from 'notistack'
import { useReports } from 'contexts/reports'
import useGraphQLOperation from 'hooks/useGraphQLOperation'
import getReportDataSetsByDataSetIds from './getReportDataSetsByDataSetIds'
import getReportDataSetsListData from './getReportDataSetsListData'
import { formatMessage } from 'i18n/ShimokuIntl'
import { REPORT_TYPES } from 'lib/reports'
import { ReportDataSetsByDataSetIds } from './types'
import { useDashboardParams } from 'hooks/useDashboardParams'
import getDefaultReportDataSets from './getDefaultReportDataSets'
import { useRealTime } from 'contexts/realTime'
import { RealTimeEventsReducerActionType } from 'contexts/realTime/realTimeEventsReducer/types'
import { RawReportDataSet } from 'contexts/ReportDataSets/types'

const { FILTERDATASET } = REPORT_TYPES

interface ReportDataSetsContextValue {
  reportDataSets: ReportDataSet[]
  reportDataSetsByDataSetIds: ReportDataSetsByDataSetIds
  setReportDataSets: Dispatch<SetStateAction<ReportDataSet[]>>
}

interface ReportDataSetsProviderProps {
  children: ReactNode
}

const ReportDataSetsContext = createContext<
  ReportDataSetsContextValue | undefined
>(undefined)

const getFilteredReports = (reports: Report[]) =>
  reports.filter(({ reportType }) => reportType === FILTERDATASET)

const ReportDataSetsProvider = ({ children }: ReportDataSetsProviderProps) => {
  const { reports } = useReports()
  const { enqueueSnackbar } = useSnackbar()
  const graphQLOperation = useGraphQLOperation()
  const { token, dashboardId, shared } = useDashboardParams()
  const { registerRealTimeComponent, unregisterRealTimeComponent } =
    useRealTime()
  const { DATASET_CONTENTS_UPDATED } = RealTimeEventsReducerActionType

  const filterDataSetReports = getFilteredReports(reports)
  const defaultReportDataSets = getDefaultReportDataSets(
    reports,
    filterDataSetReports
  )

  const [reportDataSets, setReportDataSets] = useState<ReportDataSet[]>(
    defaultReportDataSets
  )
  const reportDataSetsByDataSetIds = getReportDataSetsByDataSetIds({
    reportDataSets,
  })

  const fetchDataSetsListData = async (
    rawReportDataSets: RawReportDataSet[]
  ) => {
    const { rejectedReportDataSets, fulfilledReportDataSets } =
      await getReportDataSetsListData({
        graphQLOperation,
        reportDataSets: rawReportDataSets,
        publicPermissions: { token, dashboardId },
        shared,
      })

    rejectedReportDataSets.forEach(({ dataSet }) => {
      const errorMessage = dataSet.errorMessage as string
      enqueueSnackbar(formatMessage('errors.listData', { errorMessage }), {
        variant: 'error',
      })
    })
    return fulfilledReportDataSets
  }

  const dataSetsIds = defaultReportDataSets.map(({ dataSet }) => dataSet.id)
  useEffect(() => {
    dataSetsIds.forEach((dataSetId) => {
      registerRealTimeComponent({
        eventType: DATASET_CONTENTS_UPDATED,
        id: dataSetId,
        callback: (realTimeEvent) => {
          const { resourceId } = realTimeEvent
          const datasetToUpdate = defaultReportDataSets.filter(
            (reportDataSet) => resourceId === reportDataSet.dataSet.id
          )
          fetchDataSetsListData(datasetToUpdate).then(
            (fulfilledReportDataSets) => {
              const [dataSetToReplace] = fulfilledReportDataSets

              setReportDataSets((prevReportDataSets) => {
                return prevReportDataSets.map((reportDataSet) => {
                  if (reportDataSet.dataSet.id === resourceId) {
                    return dataSetToReplace
                  }
                  return reportDataSet
                })
              })
            }
          )
        },
      })
    })

    return () => {
      dataSetsIds.forEach((dataSetId) => {
        unregisterRealTimeComponent({
          eventType: DATASET_CONTENTS_UPDATED,
          id: dataSetId,
        })
      })
    }
    //https://stackoverflow.com/questions/59467758/passing-array-to-useeffect-dependency-list
  }, [JSON.stringify(dataSetsIds)]) // eslint-disable-line

  useEffect(() => {
    fetchDataSetsListData(defaultReportDataSets).then(
      (fulfilledReportDataSets) => {
        setReportDataSets(fulfilledReportDataSets)
      }
    )
  }, []) // eslint-disable-line

  return (
    <ReportDataSetsContext.Provider
      value={{ reportDataSets, reportDataSetsByDataSetIds, setReportDataSets }}
    >
      {children}
    </ReportDataSetsContext.Provider>
  )
}

const useReportDataSets = () => {
  const context = useContext(ReportDataSetsContext)

  if (!context) {
    throw new Error(
      'useReportDataSets must be used within a ReportDataSetsProvider'
    )
  }

  return context
}

export { ReportDataSetsProvider, useReportDataSets }
