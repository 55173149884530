import React, { SVGProps } from 'react'

export const Delete = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
      role="img"
    >
      <title>delete-icon</title>
      <path
        d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7"
        stroke={color}
        stroke-width="2"
      />
      <path
        d="M4 7H20"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M9 7V5C9 3.89543 9.89543 3 11 3H12H13C14.1046 3 15 3.89543 15 5V7"
        stroke={color}
        stroke-width="2"
      />
      <path
        d="M10 11V16"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M14 11V16"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  )
}
