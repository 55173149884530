import { RunActivityParams } from 'hooks/useRunEvents/actionEvents/useRunActivity'
import { RunActionParams } from 'hooks/useRunEvents/actionEvents/useRunAction'
import { HandleModalParams } from 'hooks/useRunEvents/actionEvents/useHandleModal'
import { FormDataSubmittedFiles } from 'containers/ReportForm/types'

type EventType = 'onClick' | 'onSubmit'

export enum EventActions {
  runActivity = 'runActivity',
  runAction = 'runAction',
  openModal = 'openModal',
  closeModal = 'closeModal',
}

export type EventAction = keyof typeof EventActions

export type EventActionParams =
  | RunActivityParams
  | RunActionParams
  | HandleModalParams

export interface Event {
  action: EventAction
  params: EventActionParams
}

export type Events = { [eventType in EventType]?: Event[] }

export type RunEvents = {
  [eventType in EventType]?: (eventParams?: EventParams) => void
}

export interface EventParams {
  files?: FormDataSubmittedFiles
  formData?: string
}
