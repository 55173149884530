import { SeriesOption, ScatterSeriesOption } from 'echarts'
import singleAxisSerie from '../../defaults/series/singleAxis'
import { DataElement, SeriesParams } from '../../types'

const series = (params: SeriesParams): SeriesOption => {
  const { data, seriesModifications = {}, axisName = '', theme } = params
  const seriesOption = singleAxisSerie({
    typeSeriesModifications: seriesModifications as ScatterSeriesOption,
    theme,
  })

  const firstValue = Object.keys(data?.[0]).filter(
    (key: string) => key !== axisName
  )?.[0]

  const series = [
    {
      ...seriesOption,
      data: data.map((dataElement: DataElement) => dataElement?.[firstValue]),
    },
  ]

  return series as SeriesOption
}

export default series
