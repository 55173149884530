interface GetAggregationFromReportDataSetsParams {
  reportDataSets: ReportDataSet[]
}

const getAggregationFromReportDataSets = (
  params: GetAggregationFromReportDataSetsParams
) => {
  const { reportDataSets } = params

  return reportDataSets
    .filter((reportDataSet) => Boolean(reportDataSet.aggregation))
    .flatMap((reportDataSet) => reportDataSet.aggregation as Aggregation[])
}

export default getAggregationFromReportDataSets
