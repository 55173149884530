const generateEchartTheme = (themeOptions: any) => {
  const {
    palette: { common, grey, complementary, status },
    custom: { chart },
  } = themeOptions
  const echartTheme = {
    color: Object.values(chart.colors),
    backgroundColor: 'rgba(255,255,255,0)',
    title: {
      textStyle: {
        color: common.black,
      },
      subtextStyle: {
        color: grey[700],
      },
    },
    line: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: 2,
      },
      symbolSize: '7',
      symbol: 'emptyCircle',
      smooth: false,
    },
    radar: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: 2,
      },
      symbolSize: '7',
      symbol: 'emptyCircle',
      smooth: false,
    },
    bar: {
      itemStyle: {
        barBorderWidth: '1',
        barBorderColor: common.white,
      },
    },
    pie: {
      itemStyle: {
        borderWidth: '1',
        borderColor: common.white,
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: '1',
        borderColor: common.white,
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: '1',
        borderColor: common.white,
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: '1',
        borderColor: common.white,
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: '1',
        borderColor: common.white,
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: '1',
        borderColor: common.white,
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: '1',
        borderColor: common.white,
      },
    },
    candlestick: {
      itemStyle: {
        color: status.error,
        color0: chart.colors.C2,
        borderColor: status.error,
        borderColor0: chart.colors.C2,
        borderWidth: '1',
      },
    },
    graph: {
      itemStyle: {
        borderWidth: '1',
        borderColor: common.white,
      },
      lineStyle: {
        width: 1,
        color: grey[600],
      },
      symbolSize: '7',
      symbol: 'emptyCircle',
      smooth: false,
      color: Object.values(chart.colors),
      label: {
        color: common.white,
      },
    },
    map: {
      itemStyle: {
        areaColor: grey[300],
        borderColor: grey[800],
        borderWidth: 0.5,
      },
      label: {
        color: common.black,
      },
      emphasis: {
        itemStyle: {
          areaColor: complementary.yellow[600],
          borderColor: grey[800],
          borderWidth: 1,
        },
        label: {
          color: 'rgb(100,0,0)',
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: grey[300],
        borderColor: grey[800],
        borderWidth: 0.5,
      },
      label: {
        color: common.black,
      },
      emphasis: {
        itemStyle: {
          areaColor: complementary.yellow[600],
          borderColor: grey[800],
          borderWidth: 1,
        },
        label: {
          color: 'rgb(100,0,0)',
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: false,
        lineStyle: {
          color: grey[800],
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: grey[300],
        },
      },
      axisLabel: {
        show: true,
        color: grey[700],
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: [grey[300]],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.2)', 'rgba(210,219,238,0.2)'],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: grey[800],
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: grey[800],
        },
      },
      axisLabel: {
        show: true,
        color: grey[800],
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: [grey[200]],
        },
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(255,255,255,0.2)', 'rgba(240,240,240,0.2)'],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: false,
        lineStyle: {
          color: grey[700],
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: grey[700],
        },
      },
      axisLabel: {
        show: true,
        color: grey[700],
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: [grey[300]],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.2)', 'rgba(210,219,238,0.2)'],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: grey[700],
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: grey[700],
        },
      },
      axisLabel: {
        show: true,
        color: grey[700],
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: [grey[300]],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.2)', 'rgba(210,219,238,0.2)'],
        },
      },
    },
    toolbox: {
      iconStyle: {
        borderColor: grey[700],
      },
      emphasis: {
        iconStyle: {
          borderColor: grey[600],
        },
      },
    },
    legend: {
      textStyle: {
        color: grey[700],
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: 'rgba(57,85,230,0.62)',
          width: '1',
        },
        crossStyle: {
          color: 'rgba(57,85,230,0.62)',
          width: '1',
        },
      },
    },
    timeline: {
      lineStyle: {
        color: grey[300],
        width: 2,
      },
      itemStyle: {
        color: grey[500],
        borderWidth: 1,
      },
      controlStyle: {
        color: grey[500],
        borderColor: grey[500],
        borderWidth: 1,
      },
      checkpointStyle: {
        color: chart.colors.C1,
        borderColor: common.white,
      },
      label: {
        color: grey[500],
      },
      emphasis: {
        itemStyle: {
          color: common.white,
        },
        controlStyle: {
          color: grey[500],
          borderColor: grey[500],
          borderWidth: 1,
        },
        label: {
          color: grey[500],
        },
      },
    },
    visualMap: {
      color: [chart.colors.C1, '#8199f1', '#dfe6fd'],
    },
    dataZoom: {
      handleSize: 'undefined',
    },
    markPoint: {
      label: {
        color: common.white,
      },
      emphasis: {
        label: {
          color: common.white,
        },
      },
    },
  }
  return echartTheme
}

export default generateEchartTheme
