import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro'
import { styled } from '@mui/material/styles'
import { ROW_HEIGHT, GRID_ROW_GAP } from 'components/Grid/utils/defaultValues'

export const panelTextStyles: any = {
  fontSize: 'var(--bodyS400-font-size)',
  color: 'var(--color-black)',
  fontWeight: '400',
  textTransform: 'none',
}

export const inputStyles: any = {
  margin: 0,
  padding: '0.5rem 0.75rem',
  backgroundColor: 'var(--color-grey-100)',
  borderRadius: 'var(--border-radius-m)',
  '&:before': {
    display: 'none',
  },
  '&:after': {
    display: 'none',
  },
}

export const selectStyles: any = {
  ...inputStyles,
  '& .MuiNativeSelect-select': {
    backgroundColor: 'var(--color-grey-100)',
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--color-black)',
  },
}

interface TableContainerProps {
  rows: number
}

export const TableContainer = styled('div')<TableContainerProps>(
  ({ rows }) => ({
    width: '100%',
    height: ROW_HEIGHT * rows + GRID_ROW_GAP * (rows - 1),
  })
)

interface DataGridProps extends DataGridProProps {
  toolbar: boolean
  footer: boolean
}

export const DataGrid = styled(DataGridPro)<DataGridProps>(
  ({ theme, toolbar, footer }) => ({
    border: '1px solid var(--color-grey-200)',
    borderRadius: 'var(--border-radius-m)',

    '& .MuiDataGrid-main': {
      borderTop: toolbar ? '1px solid var(--color-grey-200)' : 'none',
      borderBottom: footer ? '1px solid var(--color-grey-200)' : 'none',
      borderRight: 'none',
      borderLeft: 'none',
    },

    '& .MuiDataGrid-columnHeaders': {
      border: 'none',
    },

    '& .MuiDataGrid-columnHeader': {
      padding: '0 1rem',
      color: 'var(--color-grey-700)',
    },

    '& .MuiDataGrid-row': {
      '&:hover, &:nth-of-type(odd):hover': {
        backgroundColor: `${theme.palette.primary.main}0C`,
      },
      '&:nth-of-type(odd)': {
        backgroundColor: 'var(--color-grey-100)',
      },
    },

    '& .MuiDataGrid-cell': {
      border: 'none',
      padding: '0 1rem',
    },

    '& .MuiDataGrid-footerContainer': {
      border: 'none',
    },
  })
)
