import React from 'react'
import ReportWrapper from 'containers/ReportWrapper'
import { GRID_COLUMNS, ROWS } from 'components/Grid/utils/defaultValues'
import { useLayoutState } from 'hooks/components'
import { generateGrid } from 'lib/generateGrid'
import styles from './styles.module.css'

interface OldGridReportsProps {
  reports: Report[]
}

const OldGridReports = (props: OldGridReportsProps) => {
  const { reports } = props
  const { matches } = useLayoutState('lg')
  const grid = generateGrid(reports)

  return (
    <div
      id="grid-reports"
      className={styles.grid_reports}
      style={{
        gridTemplateRows: `repeat(${grid.ySize}, auto)`,
        gridTemplateColumns: matches
          ? `repeat(${grid.xSize}, 1fr)`
          : `repeat(1, 1fr)`,
      }}
      data-testid="grid-reports"
    >
      {reports.map((report) => {
        const position = grid.positions.find(
          (position) => position.id === report.id
        )
        const responsivePosition = grid.responsivePositions.find(
          (position) => position.id === report.id
        )

        return (
          <ReportWrapper
            key={report.id}
            report={report}
            oldGrid={true}
            rows={ROWS}
            columns={GRID_COLUMNS}
            style={{
              gridArea: matches
                ? `${position?.coords[0]} / ${position?.coords[1]} / ${position?.coords[2]} / ${position?.coords[3]}`
                : `${responsivePosition?.coords[0]} / ${responsivePosition?.coords[1]} / ${responsivePosition?.coords[2]} / ${responsivePosition?.coords[3]}`,
            }}
          />
        )
      })}
    </div>
  )
}

export default OldGridReports
