import { HeatmapSeriesOption } from 'echarts'
import { BORDER_STYLE, SHADOW_STYLE } from '../../defaults/general'
import { TypeSeriesParams } from '../../types'

const calendarSerie = (
  params: TypeSeriesParams<HeatmapSeriesOption>
): HeatmapSeriesOption => {
  const { typeSeriesModifications: calendarSeriesModifications = {} } = params

  return {
    type: 'heatmap',
    coordinateSystem: 'calendar',
    minOpacity: 0,
    maxOpacity: 1,
    selectedMode: false,
    ...calendarSeriesModifications,
    label: {
      show: false,
      ...calendarSeriesModifications?.label,
    },
    labelLayout: {
      hideOverlap: false,
      ...calendarSeriesModifications?.labelLayout,
    },
    itemStyle: {
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      ...calendarSeriesModifications?.itemStyle,
    },
  }
}

export default calendarSerie
