import { styled } from '@mui/material/styles'
import { PaletteMode, Typography } from '@mui/material'
import { IndicatorColor, IndicatorVariant } from 'lib/reports/types'

type TextProps = {
  component?: React.ElementType
  indicatorVariant?: IndicatorVariant
  targetPath?: string
  backgroundImage?: string
  color?: IndicatorColor
  isValue?: boolean
  isDescription?: boolean
}

type DescriptionProps = TextProps & {
  indicatorVariant?: IndicatorVariant
  background?: string
  elipsis?: boolean
}

type ValueContainerProps = {
  align?: string
  fullwidth: boolean
  icon?: React.ElementType
}

type IndicatorWrapperProps = {
  variant?: IndicatorVariant
  align?: string
  background?: string
  color?: IndicatorColor
  centerContent?: boolean
  description?: string
}

type GetBackgroundParams = {
  color?: IndicatorColor
  variant: IndicatorVariant
}

type GetTextParams = GetBackgroundParams & {
  mode: PaletteMode
  targetPath?: string
  backgroundImage?: string
  isValue?: boolean
  isDescription?: boolean
}

type getIconColorParams = {
  color?: IndicatorColor
  variant: IndicatorVariant
  backgroundImage?: string
  targetPath?: string
}

type ValueWrapperProps = {
  align?: string
  icon?: string
  targetPath?: string
}

export enum IndicatorVariants {
  default = 'default',
  outlined = 'outlined',
  contained = 'contained',
  topColor = 'topColor',
}

export const getTextColor = (params: GetTextParams) => {
  const {
    color = 'default',
    mode,
    variant,
    targetPath,
    backgroundImage,
    isValue,
    isDescription,
  } = params

  const hasBackgroundImage = () => Boolean(backgroundImage)
  const mustHaveBackground = () => variant === 'contained' || targetPath
  const isSpecialColor = () => ['success', 'caution'].includes(color)

  if (hasBackgroundImage()) return 'var(--color-white)'

  if (mustHaveBackground() && isSpecialColor() && variant !== 'topColor') {
    return 'var(--color-black)'
  }

  if (isDescription && variant === 'topColor') {
    return 'var(--color-black)'
  }

  if (variant === 'topColor' && !isValue) {
    return 'var(--color-white)'
  }

  if (variant === 'contained') return 'var(--color-white)'

  const currentColor = getColor(color)

  return (
    currentColor ||
    (mode === 'dark' ? 'var(--text-secondary)' : 'var(--color-black)')
  )
}

const getIndicatorBackground = ({ color, variant }: GetBackgroundParams) => {
  if (variant === 'contained' && color) {
    return getColor(color)
  }
  return 'var(--background-paper)'
}

const getColor = (color: IndicatorColor) => {
  const colorMap: { [key in IndicatorColor]: string } = {
    success: 'var(--chart-C2)',
    caution: 'var(--chart-C3)',
    warning: 'var(--chart-C4)',
    error: 'var(--color-status-error)',
    neutral: 'var(--color-grey-600)',
    default: 'var(--chart-C1)',
    'error-background': 'var(--color-status-error)',
    'success-background': 'var(--chart-C2)',
    'warning-background': 'var(--chart-C4)',
  }
  return colorMap[color]
}

const getContentAlign = (align: string) => {
  switch (align) {
    case 'left':
      return 'flex-start'
    case 'right':
      return 'flex-top'
    default:
      return 'center'
  }
}

export const Text = styled(Typography)<TextProps>(
  ({
    color,
    theme: {
      palette: { mode },
    },
    indicatorVariant = 'default',
    targetPath,
    backgroundImage,
    isValue = false,
    isDescription = false,
  }) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: getTextColor({
      color,
      mode,
      variant: indicatorVariant,
      targetPath,
      backgroundImage,
      isValue,
      isDescription,
    }),
  })
)

export const Description = styled(Text)<DescriptionProps>(
  ({
    elipsis,
    indicatorVariant = 'default',
    theme: {
      palette: { mode },
    },
    ...rest
  }) => ({
    margin: 0,
    ...(!elipsis && {
      overflow: 'auto',
      whiteSpace: 'break-spaces',
    }),
    color: getTextColor({
      ...rest,
      mode,
      isDescription: true,
      variant: indicatorVariant,
    }),
  })
)

export const Value = styled(Text)<TextProps>(() => ({
  margin: '8px 0',
}))

export const INDICATOR_HEIGHT = '6.875rem'

export const ContentWrapper = styled('div')<IndicatorWrapperProps>(
  ({ background, align = 'left', centerContent = false }) => ({
    minHeight: INDICATOR_HEIGHT,
    borderRadius: 'var(--border-radius-m)',
    justifyContent: getContentAlign(align),
    padding: '16px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: centerContent ? 'center' : 'flex-start',
    overflow: 'hidden',
    ...(background && {
      background: 'rgba(0, 0, 0, 0.5)',
    }),
  })
)

export const IndicatorWrapper = styled('div')<IndicatorWrapperProps>(
  ({
    color,
    variant = 'default',
    background,
    align = 'left',
    title,
    description,
    theme: {
      palette: { mode },
    },
  }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent:
      !title?.length && !description?.length
        ? 'flex-end'
        : getContentAlign(align),
    minHeight: INDICATOR_HEIGHT,
    height: ' 100%',
    borderRadius: 'var(--border-radius-m)',
    color: 'var(--color-text-primary)',
    backgroundColor: getIndicatorBackground({ color, variant }),
    ...(background && {
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
    }),
    ...(mode === 'dark' && {
      border: '1px solid var(--divider)',
      boxShadow: 'var(--box-shadow-m)',
    }),
  })
)

export const ValueContainer = styled('div')<ValueContainerProps>(
  ({ align, fullwidth, icon }) => ({
    display: 'grid',
    gap: '0.625rem',
    alignItems: 'center',
    ...(icon && {
      gridTemplateColumns: 'repeat(3, minmax(3rem, auto))',
    }),
    ...(align === 'left' && {
      '> p': {
        textAlign: 'left',
        gridColumn: '1 / 3',
        gridRow: '1/2',
      },
      '> svg': {
        justifySelf: 'flex-end',
        gridColumn: '3 / 4',
        gridRow: '1/2',
      },
    }),
    ...(align === 'center' && {
      '> p': {
        textAlign: 'center',
      },
    }),
    ...(align === 'right' && {
      '> p': {
        textAlign: 'right',
        gridColumn: '2 / 4',
        gridRow: '1/2',
      },
      '> svg': {
        justifySelf: 'flex-start',
        gridColumn: '1 / 2',
        gridRow: '1/2',
      },
    }),
    ...(fullwidth && {
      gridColumn: '1 / 4',
      gridRow: '1/2',
    }),
  })
)

export const TopColorWrapper = styled('div')<IndicatorWrapperProps>(
  ({ color = 'neutral' }) => ({
    margin: '8px 8px 0 8px',
    padding: '8px',
    borderRadius: 'var(--border-radius-m)',
    background: getColor(color),
    position: 'relative',
  })
)

export const LinkIconWrapper = styled('div')(() => ({
  marginRight: '8px',
  display: 'flex',
}))

export const getIconColor = (params: getIconColorParams) => {
  const { backgroundImage, variant, color, targetPath } = params

  if (backgroundImage) {
    return 'var(--color-white)'
  }

  if (variant === 'contained') {
    if (color === 'success' || color === 'caution') {
      return 'var(--color-base-icon)'
    }
    return 'var(--color-white)'
  }

  if (color && variant === 'topColor' && targetPath) {
    return getColor(color)
  }

  return 'var(--color-base-icon)'
}

export const Article = styled('article')({
  position: 'relative',
  height: '100%',
})

const getAlign = (align: string) => {
  switch (align) {
    case 'center':
      return 'center'
    case 'right':
      return 'flex-end'
    default:
      return 'flex-start'
  }
}

export const ValueWrapper = styled('div')<ValueWrapperProps>(
  ({ align = 'right', targetPath, icon }) => {
    if (targetPath || icon) {
      return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: getAlign(align),
      }
    }
    return {}
  }
)
