import React, { SVGProps } from 'react'

export const SuiteAnomalyB = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
    >
      <title>suite-anomaly-b-icon</title>
      <path
        d="M12.9999 2.73205L19.5262 6.5C20.145 6.85727 20.5262 7.51752 20.5262 8.23205V15.7679C20.5262 16.4825 20.145 17.1427 19.5262 17.5L12.9999 21.2679C12.3811 21.6252 11.6187 21.6252 10.9999 21.268L4.47363 17.5C3.85483 17.1427 3.47363 16.4825 3.47363 15.7679V8.23205C3.47363 7.51752 3.85483 6.85727 4.47363 6.5L10.9999 2.73205C11.6187 2.37479 12.3811 2.37479 12.9999 2.73205Z"
        stroke={color}
        stroke-width="2"
      />
      <path
        d="M4 9.5H12.5M16 9.5H20"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M4 14H5M15.5 14H20"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <circle cx="10" cy="14" r="1" stroke={color} stroke-width="2" />
    </svg>
  )
}
