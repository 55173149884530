export type CustomZIndex = {
  xs: number
  s: number
  m: number
  l: number
  xl: number
}

const COMMON_Z_INDEX = {
  xs: 1,
  s: 200,
  m: 1100,
  l: 2000,
  xl: 9999,
}

export default COMMON_Z_INDEX
