import rolePermissionsFragment from './rolePermissions'

const ROLE_PERMISSIONS_FRAGMENT_NAME = 'App'

const appFragment = /* GraphQL */ `
  ${rolePermissionsFragment({ name: ROLE_PERMISSIONS_FRAGMENT_NAME })}
  fragment AppFields on AppExposed {
    id
    hideTitle
    name
    normalizedName
    trialDays
    createdAt
    isDisabled
    churnedDate
    paymentType
    order
    hidePath
    showBreadcrumb
    showHistoryNavigation
    rolePermissions {
      items {
        ...RolePermissionsFields${ROLE_PERMISSIONS_FRAGMENT_NAME}
      }
    }
  }
`

export default appFragment
