import { SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack'
import { GraphQLOperationParams } from 'hooks/useGraphQLOperation/types'
import getData from 'containers/Table/utils/getTableData/getData'
import { TableState } from 'containers/Table/types'
import { getErrorMessage } from 'lib/errors'
import { formatMessage } from 'i18n/ShimokuIntl'

const LIMIT = 1000

interface GetAllTableDataParams {
  dataSetId: string
  tableState: TableState
  graphQLOperation: (params: GraphQLOperationParams) => Promise<any>
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey
}

const getAllTableData = async (params: GetAllTableDataParams) => {
  const { dataSetId, tableState, graphQLOperation, enqueueSnackbar } = params
  const { total, sort, filter } = tableState
  const totalQueries = Math.ceil(total / LIMIT)

  let allTableData = [] as Data[]
  let nextToken = null
  let totalRequestTime = 0

  try {
    for (let i = 0; i < totalQueries; i++) {
      const startTime = Date.now()

      const res: any = await getData({
        dataSetId,
        limit: LIMIT,
        nextToken,
        sort,
        filter,
        graphQLOperation,
      })

      const endTime = Date.now()
      const requestTime = (endTime - startTime) / 1000
      totalRequestTime += requestTime

      nextToken = res.nextToken
      const data = res.items
      allTableData = [...allTableData, ...data]

      const averageRequestTime = totalRequestTime / (i + 1)
      const remainingRequests = totalQueries - (i + 1)
      const remainingTime = Math.round(averageRequestTime * remainingRequests)

      if (totalQueries > 1) {
        const statusRequestsMessage = formatMessage(
          'containers.table.progress.requests',
          { currentRequest: i + 1, totalRequests: totalQueries }
        )

        const remainingRequestsMessage = remainingRequests
          ? formatMessage('containers.table.progress.remainingTime', {
              remainingTime,
            })
          : formatMessage('containers.table.progress.downloadingNow')

        enqueueSnackbar(
          `${statusRequestsMessage} ${remainingRequestsMessage}`,
          { variant: 'info' }
        )
      }
    }
  } catch (error) {
    enqueueSnackbar(getErrorMessage(error, 'errors.fetchTableData'), {
      variant: 'error',
    })
  }

  return allTableData
}

export default getAllTableData
