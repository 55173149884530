import { Theme } from '@mui/material/styles'
import { PieSeriesOption } from 'echarts'
import { TEXT_STYLE, BORDER_STYLE, SHADOW_STYLE } from '../general'
import { TypeSeriesParams } from '../../types'

const label = (theme: Theme): any => ({
  show: true,
  position: 'outside',
  rotate: 0,
  ...TEXT_STYLE(theme),
  fontSize: 12,
  backgroundColor: 'transparent',
  ...BORDER_STYLE,
  padding: 0,
  ...SHADOW_STYLE,
  alignTo: 'none',
  distanceToLabelLine: 4,
})

const labelLine: any = {
  show: true,
  length: 16,
  length2: 8,
  smooth: false,
  minTurnAngle: 90,
  lineStyle: {
    width: 1,
    type: 'solid',
    ...SHADOW_STYLE,
    opacity: 1,
  },
}

const itemStyle: any = {
  ...BORDER_STYLE,
  ...SHADOW_STYLE,
  opacity: 1,
  borderRadius: 0,
}

const PieSerie = (
  params: TypeSeriesParams<PieSeriesOption>
): PieSeriesOption => {
  const {
    typeSeriesModifications: pieSeriesModifications = {},
    paddingPie = false,
    theme,
  } = params

  return {
    type: 'pie',
    legendHoverLink: true,
    center: ['50%', '50%'],
    radius: [0, `${paddingPie ? 85 : 90}%`],
    top: `${paddingPie ? 15 : 0}%`,
    ...pieSeriesModifications,
    label: {
      ...label(theme),
      ...pieSeriesModifications?.label,
    },
    labelLine: {
      ...labelLine,
      ...pieSeriesModifications?.labelLine,
    },
    itemStyle: {
      ...itemStyle,
      ...pieSeriesModifications?.itemStyle,
    },
    emphasis: {
      scale: true,
      scaleSize: 8,
      focus: 'none',
      blurScope: 'coordinateSystem',
      ...pieSeriesModifications?.emphasis,
      label: {
        ...label(theme),
        position: '',
        ...pieSeriesModifications?.emphasis?.label,
      },
      labelLine: {
        ...labelLine,
        ...pieSeriesModifications?.emphasis?.labelLine,
      },
      itemStyle: {
        ...itemStyle,
        ...pieSeriesModifications?.emphasis?.itemStyle,
      },
    },
    blur: {
      label: {
        ...label(theme),
        position: '',
        ...pieSeriesModifications?.blur?.label,
      },
      labelLine: {
        ...labelLine,
        ...pieSeriesModifications?.blur?.labelLine,
      },
      itemStyle: {
        ...itemStyle,
        ...pieSeriesModifications?.blur?.itemStyle,
      },
    },
    select: {
      label: {
        ...label(theme),
        position: '',
        ...pieSeriesModifications?.select?.label,
      },
      labelLine: {
        ...labelLine,
        ...pieSeriesModifications?.select?.labelLine,
      },
      itemStyle: {
        ...itemStyle,
        ...pieSeriesModifications?.select?.itemStyle,
      },
    },
  }
}

export default PieSerie
