import { createTheme } from '@mui/material/styles'
import { BASE_COLORS, GREY_PALLETE, MAIN_PALLETE } from 'theme/common/colors'
import COMMON_THEME_OPTIONS from 'theme/common/theme-options'
import { Text, ChartOptions, Background, ThemeParameters } from 'theme/types'

declare module '@mui/material/styles' {
  interface ThemeOptions {
    custom?: ThemeParameters
  }
}

export const TEXT: Text = {
  primary: 'var(--color-white)',
  secondary: GREY_PALLETE[100],
  disabled: GREY_PALLETE[300],
  hint: GREY_PALLETE[500],
  icon: GREY_PALLETE[500],
}

export const CHART_OPTIONS: ChartOptions = {
  text: TEXT.primary,
  grid: 'rgba(255,255,255, .1)',
  axisline: TEXT.primary,
  axislabel: TEXT.primary,
  backgroundHover: 'rgba(255,255,255, .1)',
  dataBackground: MAIN_PALLETE[200],
  controlBorderColor: 'rgba(255,255,255,1)',
  generalColor: {
    50: 'rgba(255,255,255, .1)',
    100: 'rgba(255,255,255, .1)',
    200: 'rgba(255,255,255, .2)',
    300: 'rgba(255,255,255, .3)',
    400: 'rgba(255,255,255, .4)',
    500: 'rgba(255,255,255, .5)',
    600: 'rgba(255,255,255, .6)',
    700: 'rgba(255,255,255, .7)',
  },
}

export const BACKGROUND: Background = {
  paper: '#424242',
  default: '#303030',
}

export const generateDarkTheme = ({
  domain,
  brandPallete = MAIN_PALLETE,
}: ThemeParameters) => {
  const { white } = BASE_COLORS
  const darkTheme = COMMON_THEME_OPTIONS
  darkTheme.custom.domain = domain
  darkTheme.custom.icon.base = white
  darkTheme.palette = {
    ...darkTheme.palette,
    text: TEXT,
    background: BACKGROUND,
    mode: 'dark',
    primary: {
      light: brandPallete[100],
      main: brandPallete[500],
      dark: brandPallete[600],
    },
    secondary: {
      main: white,
    },
  }
  darkTheme.custom.chart.options = CHART_OPTIONS
  const customComponents = {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'var(--background-paper)',
      },
    },
    MuiCircularProgress: {
      colorPrimary: { color: 'var(--color-white)' },
    },
    MuiFormLabel: {
      root: {
        color: 'var(--color-white)',
        '&.Mui-focused': {
          color: 'var(--color-white)',
        },
      },
    },
    MuiInput: {
      backgroundColor: 'var(--color-grey-700)',
      underline: {
        '&::after': {
          borderBottom: `2px solid var(--color-white)`,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: 'var(--color-white)',
        },
      },
    },
    MuiPaper: {
      root: {
        '&$elevation1': {
          boxShadow: 'none',
        },
      },
    },
    MuiFilledInput: {
      underline: {
        '&::after': {
          borderColor: 'var(--color-white)',
        },
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        '&$checked': {
          color: 'var(--color-white)',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'var(--color-white)',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--color-grey-700)',
        },
      },
    },
  }
  darkTheme.components = {
    ...darkTheme.components,
    ...customComponents,
  }
  const theme = createTheme(darkTheme)

  return theme
}
