import React, { ReactNode, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from 'redux/user'
import { selectAccount } from 'redux/account'
import { selectApps } from 'redux/apps'
import { selectLoading } from 'redux/loading'
import { usePostHog } from 'posthog-js/react'
import userPlayground from 'hocs/WithPlayground/userPlayground'
import useSetAccount from 'hooks/useAccount'
import LoadingPage from 'components/LoadingPage/LoadingPage'
import useUser from 'hooks/useUser'
import { setAvailableModules } from 'redux/modules'
import { setUniverseModules } from 'redux/universeModules'

interface WithUserProps {
  children: ReactNode
  placeholder?: ReactNode
}

const WithUser = (props: WithUserProps) => {
  const { children, placeholder = <LoadingPage global={true} /> } = props
  const shimokuUser = useSelector(selectUser)
  const shimokuAccount: Account = useSelector(selectAccount)
  const shimokuUniverse = shimokuAccount.business?.universe
  const apps = useSelector(selectApps)
  const { loadingAccount } = useSelector(selectLoading)
  const dispatch = useDispatch()
  const { fetchUser } = useUser()
  const { setAccount } = useSetAccount()
  const posthog = usePostHog()

  const isLoaded = () =>
    Boolean(shimokuUser.id) &&
    Boolean(shimokuAccount.id) &&
    Boolean(!loadingAccount)

  useEffect(() => {
    dispatch(
      setAvailableModules({
        business: shimokuAccount.business,
        currentDateTime: shimokuUser.currentDateTime,
        apps,
      })
    )
  }, [shimokuAccount.business, shimokuUser.currentDateTime, apps]) // eslint-disable-line

  useEffect(() => {
    if (Boolean(shimokuUniverse)) {
      dispatch(setUniverseModules({ universe: shimokuUniverse }))
    }
  }, [shimokuUniverse]) // eslint-disable-line

  useEffect(() => {
    if (!Boolean(shimokuUser.id) || shimokuUser.id === userPlayground.id) {
      fetchUser()
    }
  }, [shimokuUser]) // eslint-disable-line

  useEffect(() => {
    if (shimokuUser && shimokuUser.email) {
      posthog?.identify(shimokuUser.id, {
        ...shimokuUser,
      })
      if (shimokuUser.companyName) {
        posthog?.group('company', shimokuUser.companyName)
      }
    }
  }, [posthog, shimokuUser])

  useEffect(() => {
    if (Boolean(shimokuUser.id) && Boolean(shimokuAccount.id)) {
      setAccount({ user: shimokuUser })
    }
  }, []) // eslint-disable-line

  return isLoaded() ? <>{children}</> : <>{placeholder}</>
}

export default WithUser
