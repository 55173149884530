import React from 'react'
import * as S from './Chip.styled'

export type ChipProperties = {
  color: string
  radius?: 'rectangle' | 'rounded'
  variant?: 'filled' | 'outlined'
  children: React.ReactNode
}

const Chip = ({
  color,
  variant = 'filled',
  radius = 'rectangle',
  children,
}: ChipProperties) => {
  return (
    <S.Chip color={color} variant={variant} radius={radius}>
      {children}
    </S.Chip>
  )
}

export default Chip
