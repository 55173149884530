import React, { useState } from 'react'
import { InputAdornment, IconButton } from '@mui/material'
import Eye from 'icons/Line/eye'
import EyeBlind from 'icons/Line/eye-blind'
import TextInput, { TextInputProps } from './text-input'

const PasswordInput = (props: TextInputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const handleClickShowPassword = () => setIsPasswordVisible(!isPasswordVisible)
  return (
    <TextInput
      {...props}
      type={isPasswordVisible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPassword}>
              {isPasswordVisible ? <EyeBlind /> : <Eye />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default PasswordInput
