import { styled } from '@mui/material/styles'
import { Box as MuiBox } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

export const Content = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '4rem auto',
  maxWidth: '420px',
  width: '100%',
}))

export const Container = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  height: '100vh',
}))

export const Box = styled(MuiBox)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
}))

export const LogoContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
  },
}))

const getCommonLinkStyles = ({ theme }: any) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
})

export const Link = styled(RouterLink)(({ theme }) => ({
  ...getCommonLinkStyles({ theme }),
}))

export const ExternalLink = styled('a')(({ theme }) => ({
  ...getCommonLinkStyles({ theme }),
}))

export const Footer = styled('footer')(({ theme }) => ({
  textAlign: 'center',
}))
