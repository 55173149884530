const createStripePaymentLink = /* GraphQL */ `
  query createStripePaymentLink($universeId: ID!, $universePlanTypeId: ID!) {
    createStripePaymentLink(
      universeId: $universeId
      universePlanTypeId: $universePlanTypeId
    ) {
      url
    }
  }
`
export default createStripePaymentLink
