import { API } from 'aws-amplify'
import {
  GraphQLOperationTypeEnum,
  FetchPolicyEnum,
  GraphQLOperationParams,
} from 'hooks/useGraphQLOperation/types'
import { getErrorMessage } from 'lib/errors'
import { PROMISE_STATUS } from 'lib/promises'
import { formatMessage } from 'i18n/ShimokuIntl'
import listReports from 'gql/queries/listReports'

const LIMIT = 300
const { REJECTED } = PROMISE_STATUS
const { QUERY } = GraphQLOperationTypeEnum
const { NETWORK_ONLY } = FetchPolicyEnum

interface GetPublicDashboardParams {
  dashboardId: string
  token: string
}

interface GetPublicListReportsParams extends GetPublicDashboardParams {
  appId: string
}

const getPublicListReports = async ({
  dashboardId,
  appId,
  token,
}: GetPublicListReportsParams): Promise<any> =>
  await API.graphql({
    query: listReports,
    variables: {
      appId,
      publicPermissions: {
        resourceId: dashboardId,
        token,
      },
    },
    authMode: 'API_KEY',
  })

type GetDashboardsAppsWithReportsParams = {
  dashboards: Dashboard[]
  graphQLOperation: (params: GraphQLOperationParams) => Promise<any>
  isPublic?: boolean
  token?: string
}

const getDashboardsAppsWithReports = async ({
  dashboards,
  graphQLOperation,
  isPublic = false,
  token,
}: GetDashboardsAppsWithReportsParams) => {
  const res: any[] = await Promise.allSettled(
    dashboards.flatMap((dashboard) =>
      dashboard.appDashboards.items.map(({ app }) => {
        if (isPublic && token?.length) {
          return getPublicListReports({
            dashboardId: dashboard.id,
            appId: app.id,
            token,
          })
        } else {
          const listReportsVariables = { appId: app.id, limit: LIMIT }
          const listReportsOptions = { variables: listReportsVariables }
          return graphQLOperation({
            operationType: QUERY,
            operation: listReports,
            options: listReportsOptions,
            fetchPolicy: NETWORK_ONLY,
          })
        }
      })
    )
  )

  let start
  let end = 0

  return dashboards.map((dashboard) => {
    start = end
    end = start + dashboard.appDashboards.items.length

    return {
      ...dashboard,
      appDashboards: {
        ...dashboard.appDashboards,
        items: res.slice(start, end).map((res, i) => {
          const { status } = res

          if (status === REJECTED) {
            return {
              ...dashboard.appDashboards.items[i],
              app: {
                ...dashboard.appDashboards.items[i].app,
                error: getErrorMessage(
                  res.reason,
                  formatMessage('errors.loadingReports')
                ),
                reports: { items: [] },
              },
            } as AppDashboard
          }

          return {
            ...dashboard.appDashboards.items[i],
            app: {
              ...dashboard.appDashboards.items[i].app,
              reports: res.value.data.listReports,
            },
          } as AppDashboard
        }),
      },
    } as Dashboard
  })
}

export default getDashboardsAppsWithReports
