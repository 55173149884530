const getFontSize = (
  fontSize: string | number,
  generalThemeFontSize: number
) => {
  return typeof fontSize === 'number'
    ? fontSize * 16
    : Number(fontSize.replace('rem', '')) * generalThemeFontSize
}

export default getFontSize
