import React from 'react'
import { GridColDef } from '@mui/x-data-grid-pro'
import { TablePropertiesColumn } from 'lib/reports/types'
import { COLUMN_WIDTH } from 'containers/Table/utils/generateTableColumns/getColumn'
import Button from 'containers/Button'
import { ButtonProperties } from 'lib/reports/types'

export interface GetButtonColumnParams {
  column: TablePropertiesColumn
}

// This interface should have a subset of the Report interface
export interface ButonCellReport {
  reportProperties?: ButtonProperties
  reportType: ReportType
}

const getButtonColumn = (params: GetButtonColumnParams): GridColDef => {
  const { column } = params
  const { field, headerName, width = COLUMN_WIDTH } = column

  return {
    field,
    type: 'actions',
    headerName,
    width,
    renderCell: (gridRenderCellParams) => {
      const { row } = gridRenderCellParams
      if (!Boolean(row[field])) {
        return
      }

      const report: ButonCellReport = JSON.parse(row[field])
      if (!report.reportProperties) {
        return
      }
      const btnReportProperties = report.reportProperties

      return <Button properties={{ ...btnReportProperties, size: 'small' }} />
    },
  }
}

export default getButtonColumn
