import { Theme } from '@mui/material/styles'
import { CalendarComponentOption } from 'echarts'
import {
  SPLIT_LINE,
  SHADOW_STYLE,
  TEXT_STYLE,
  BORDER_STYLE,
} from '../defaults/general'

const CALENDAR_LABEL = {
  fontSize: 12,
  backgroundColor: 'transparent',
  ...BORDER_STYLE,
  padding: 0,
  ...SHADOW_STYLE,
}

const calendar = (theme: Theme): CalendarComponentOption => ({
  id: '',
  left: 64,
  right: 6,
  cellSize: 20,
  height: '75%',
  top: 64,
  orient: 'horizontal',
  splitLine: {
    ...SPLIT_LINE(theme),
    lineStyle: {
      ...SPLIT_LINE(theme).lineStyle,
      color: 'rgba( 0, 0, 0, .6)',
      type: 'solid',
    },
  },
  itemStyle: {
    color: 'transparent',
    borderColor: '#e0e0e0',
    borderWidth: 1,
    ...SHADOW_STYLE,
  },
  dayLabel: {
    show: true,
    firstDay: 0,
    margin: 16,
    position: 'start',
    ...TEXT_STYLE(theme),
    ...CALENDAR_LABEL,
  },
  monthLabel: {
    show: true,
    margin: 8,
    position: 'start',
    nameMap: 'en',
    ...TEXT_STYLE(theme),
    ...CALENDAR_LABEL,
  },
  yearLabel: {
    show: true,
    margin: 40,
    position: 'left',
    ...TEXT_STYLE(theme),
    ...CALENDAR_LABEL,
    fontSize: 16,
  },
})

export default calendar
