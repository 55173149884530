import React, { FC } from 'react'
import { Alert } from '@mui/material'
import ShimokuBarChart from 'components/ShimokuBarChart/ShimokuBarChart'
import { formatMessage } from 'i18n/ShimokuIntl'
import withErrorWrapper from 'hocs/withErrorWrapper'

const ReportBarChart: FC<WithReport> = ({
  report: { dataFields = '{}', chartData = '[]' },
}) => (
  <ShimokuBarChart
    fields={JSON.parse(dataFields)}
    data={JSON.parse(chartData)}
  />
)

const Placeholder = () => (
  <Alert severity="error">
    {formatMessage('errors.inconsistentReportData')}
  </Alert>
)

export default withErrorWrapper<WithReport>(ReportBarChart, Placeholder)
