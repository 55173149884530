import React, { FC } from 'react'
import { Paper, PaperProps, Typography } from '@mui/material'
import {
  BarChart,
  Bar,
  XAxis,
  Legend,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
  CartesianGrid,
} from 'recharts'
import { useTheme } from 'theme'
import {
  tooltipProps,
  legendProps,
  getYAxisProps,
  getYLabelProps,
} from 'lib/charts'
import chartStyles from 'material/charts.module.css'
import styles from 'components/ShimokuLineChart/ShimokuLineChart.module.css'

const ShimokuBarChart: FC<ShimokuChartProps & PaperProps> = ({
  fields,
  data,
  title,
  ...props
}) => {
  const { theme } = useTheme()
  const { colors: CHART_COLORS, options: CHART_OPTIONS } = theme.custom.chart

  return (
    <Paper
      {...props}
      style={{ padding: 20, paddingLeft: 0 }}
      className={styles.paper}
    >
      {title && (
        <Typography variant="h6" component="h3" className={chartStyles.title}>
          {title}
        </Typography>
      )}
      <ResponsiveContainer className={styles.reponsiveContainer}>
        <BarChart
          data={data}
          margin={{ top: 0, bottom: 0, left: 0, right: 8 }}
          className={chartStyles.container}
        >
          <CartesianGrid
            horizontal={true}
            vertical={false}
            stroke={CHART_OPTIONS.grid}
          />
          <XAxis
            dataKey={fields.key}
            height={50}
            stroke={CHART_OPTIONS.axislabel}
          >
            <Label
              value={fields.labels.key}
              position="insideBottom"
              fill={CHART_OPTIONS.text}
            />
          </XAxis>
          <YAxis {...getYAxisProps(theme, true)}>
            <Label
              {...getYLabelProps(theme, true)}
              value={fields.labels.value}
            />
          </YAxis>
          <Tooltip {...tooltipProps(theme)} />
          {/* @ts-ignore */}
          <Legend {...legendProps} />
          {fields.values.map((value, index) => (
            <Bar
              key={index}
              dataKey={value}
              fill={CHART_COLORS[`C${index + 1}`]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  )
}

export default ShimokuBarChart
