import React, { SVGProps } from 'react'

export const CercleClose = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
    >
      <title>cercle-close-icon</title>
      <circle cx="12" cy="12" r="9" stroke={color} stroke-width="2" />
      <path
        d="M9 15L10.5 13.5L12 12L13.5 10.5L15 9"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M15 15L13.5 13.5L12 12L10.5 10.5L9 9"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  )
}
