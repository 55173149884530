import React, { useEffect } from 'react'
import ReportsComponent from 'components/Reports'
import { AppRoute } from 'components/Routes/types'
import { useRealTime } from 'contexts/realTime'
import { RealTimeEventsReducerActionType } from 'contexts/realTime/realTimeEventsReducer/types'
import useReportEventsHandler from 'contexts/realTime/realTimeEvents/useRealTimeEventsReports'
import { useReports } from 'contexts/reports'

interface ReportProps {
  app: App
  appRoute: AppRoute
  padding?: boolean
}

const Reports = (props: ReportProps) => {
  const { app } = props
  const { reports } = useReports()

  const { setReports } = useReports()
  const { registerRealTimeComponent, unregisterRealTimeComponent } =
    useRealTime()
  const { handleReportEvents } = useReportEventsHandler(setReports)
  useEffect(() => {
    const { REPORT_UPDATED, REPORT_CREATED, REPORT_DELETED } =
      RealTimeEventsReducerActionType
    const reportsEventsToHandle = [
      REPORT_CREATED,
      REPORT_DELETED,
      REPORT_UPDATED,
    ]

    reportsEventsToHandle.forEach((event) => {
      registerRealTimeComponent({
        eventType: event,
        callback: handleReportEvents,
      })
    })

    return () => {
      reportsEventsToHandle.forEach((event) => {
        unregisterRealTimeComponent({ eventType: event })
      })
    }
  }, []) // eslint-disable-line
  return <ReportsComponent app={app} reports={reports} />
}

export default Reports
