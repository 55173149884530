import { formatMessage } from 'i18n/ShimokuIntl'

export const stringifyGraphQLErrors = (errors: GraphQLError[]): string => {
  const reducer = (accumulator: string[], { message }: GraphQLError) => {
    accumulator.push(message)
    return accumulator
  }
  return errors.reduce(reducer, []).join(`; `)
}

export const getErrorMessage = (error: any, translation?: string): string => {
  if (error.errors) {
    return stringifyGraphQLErrors(error.errors)
  } else if (error.message) {
    return error.message
  } else if (translation) {
    return formatMessage(translation)
  } else {
    return ''
  }
}
