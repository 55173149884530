import React, { SVGProps } from 'react'

export const SubscriptionsA = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
    >
      <title>subscriptions-a-icon</title>
      <rect
        x="3"
        y="6"
        width="18"
        height="12"
        rx="2"
        stroke={color}
        stroke-width="2"
      />
      <path
        d="M3 10L7.5 10H12L16.5 10H21"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M15 15H15.75H16.5L17.25 15H18"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M11 15H11.25H11.5H11.75H12"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  )
}
