import { Storage } from 'aws-amplify'
import { formatMessage } from 'i18n/ShimokuIntl'
const { REACT_APP_DOWNLOADS_BUCKET } = process.env

export const sortByOrder = (prev: Report, next: Report) =>
  prev.order - next.order

// string characters length
const MIN_COLUMN_CHAR_LENGTH = 17

export const REPORT_TYPES = {
  TABLE: 'TABLE',
  INDICATORS: 'INDICATORS',
  BARCHART: 'BARCHART',
  LINECHART: 'LINECHART',
  STOCKLINECHART: 'STOCKLINECHART',
  HTML: 'HTML',
  FILTER: 'FILTER',
  IFRAME: 'IFRAME',
  ECHARTS: 'ECHARTS',
  ECHARTS2: 'ECHARTS2',
  MULTIFILTER: 'MULTIFILTER',
  FORM: 'FORM',
  FILTERDATASET: 'FILTERDATASET',
  INDICATOR: 'INDICATOR',
  TABS: 'TABS',
  BUTTON: 'BUTTON',
  ANNOTATED_ECHART: 'ANNOTATED_ECHART',
  MODAL: 'MODAL',
}

export const DEFAULT_REPORT_MINHEIGHT = 120

export const getColumnFixedClassName = (value: string): string | undefined =>
  value?.length <= MIN_COLUMN_CHAR_LENGTH ? 'fixed' : undefined

export const getDownloadFileURL = async (
  account: Account,
  report: Report,
  extension: string = '.xlsx'
): Promise<string | undefined> => {
  try {
    const bucketProps = {
      bucket: REACT_APP_DOWNLOADS_BUCKET,
      customPrefix: { public: '' },
      level: 'public',
    }
    //@ts-ignore
    const filesList: BucketItem[] = await Storage.list(
      `business-${account.business?.id}/app-${report.appId}/report-${report.id}`,
      //@ts-ignore
      bucketProps
    )
    const fileFound = filesList
      .filter((file: BucketItem) => Boolean(file.key.match(extension)))
      .pop()
    if (!fileFound)
      throw new Error(
        formatMessage('errors.noDownloadFileFound', {
          extension,
          reportId: report.id,
        })
      )
    //@ts-ignore
    return (await Storage.get(fileFound.key, bucketProps)) as string | undefined
  } catch (error) {
    // Sentry.captureException(error)
    return
  }
}

export const getReportType = ({ reportType }: Report) =>
  reportType || REPORT_TYPES.TABLE
