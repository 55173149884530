import { OptionsMultifilter, OptionMultifilter } from '../types'

interface SelectedReportsParams {
  options: OptionsMultifilter
  labels: string[]
  selectedOption: { [s: string]: string }
}

const getSelectedOptionReportIds = (params: SelectedReportsParams) => {
  const { options, labels, selectedOption } = params

  const selectedOptionReportIds = new Set(
    options.filter(
      (dataItem: OptionMultifilter) =>
        !labels
          .map(
            (dataKey: string) => dataItem[dataKey] === selectedOption[dataKey]
          )
          .some((value: boolean) => value === false)
    )?.[0]?.reportId
  )

  return selectedOptionReportIds
}

export default getSelectedOptionReportIds
