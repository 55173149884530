import { SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack'
import { EventParams } from 'hooks/useRunEvents/types'
import getDataToSend, { GetDataToSendParams } from './getDataToSend'
import { updateData } from 'lib/forms'
import { getErrorMessage } from 'lib/errors'
import { formatMessage } from 'i18n/ShimokuIntl'
import { UpdateReportDataSetDto, FormVariant } from '../types'

interface SubmitReportFormParams extends GetDataToSendParams {
  dataSetId: string
  updateReportsDataSetByIdOnMemory: (
    dataSetId: string,
    dataToUpdate: UpdateReportDataSetDto
  ) => ReportDataSet[]
  defaultReportDataSets: ReportDataSet[]
  updateReportDataSets: (reportdataSets: ReportDataSet[]) => void
  formVariant?: FormVariant
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey
  onSubmitReportEvent?: (eventParams: EventParams) => void
}

const submitReportForm = async (params: SubmitReportFormParams) => {
  const {
    appId,
    dataId,
    currentFieldGroups,
    defaultFormValues,
    formData,
    dataSetId,
    updateReportsDataSetByIdOnMemory,
    defaultReportDataSets,
    updateReportDataSets,
    formVariant,
    enqueueSnackbar,
    onSubmitReportEvent,
  } = params

  const { dataToSend, formDataWithFiles, stringifyedData } =
    await getDataToSend({
      appId,
      dataId,
      currentFieldGroups,
      defaultFormValues,
      formData,
    })

  try {
    await updateData(dataToSend)
    const updatedReportDataSets = updateReportsDataSetByIdOnMemory(
      dataSetId,
      dataToSend
    )
    const updatedReportDataSetsInThisForm = updatedReportDataSets.filter(
      (reportDataSet) =>
        defaultReportDataSets.some(
          (defaultReportDataSet) => defaultReportDataSet.id === reportDataSet.id
        )
    )
    updateReportDataSets(updatedReportDataSetsInThisForm)
    if (formVariant !== 'autoSend') {
      enqueueSnackbar(formatMessage('success.updateFormReport'), {
        variant: 'success',
      })
    }
  } catch (error) {
    enqueueSnackbar(getErrorMessage(error, 'errors.updateFormReport'), {
      variant: 'error',
    })
  } finally {
    const eventParams = {
      files: formDataWithFiles,
      formData: stringifyedData,
    }
    onSubmitReportEvent?.(eventParams)
  }
}

export default submitReportForm
