import React, { useState, useEffect } from 'react'
import { Paper } from '@mui/material'
import Echarts from 'echarts-for-react'
import { cloneDeep } from 'lodash'
import defaultOption from './defaultOption'
import { StockLineChartData } from '../ReportStockLineChart/index'
import { useTheme } from 'theme'
import styles from './styles.module.css'
import dayjs from 'dayjs'
import { DEFAULT_REPORT_MINHEIGHT } from 'lib/reports'

interface StockLineChartProps {
  id: string
  initialChartdata: StockLineChartData[]
  initialChartdataItem: StockLineChartData
  chartData?: StockLineChartData[] | null
  chartDataItem?: StockLineChartData | null
  fields: ChartFields
}

const StockLineCHart = (props: StockLineChartProps): JSX.Element => {
  const {
    id,
    initialChartdata,
    initialChartdataItem,
    chartData,
    chartDataItem,
    fields,
  } = props
  const { theme } = useTheme()
  const CHART_COLORS = theme.custom.chart.colors
  const { smooth = false, symbol = 'none' } = fields
  const xAxisLabel = fields.labels.key
  const yAxisLabel = fields.labels.value
  const xAxisLabelHide = Boolean(fields.labels.hideKey)
  const yAxisLabelHide = Boolean(fields.labels.hideValue)
  const xTypeAxis =
    xAxisLabel.includes('Date') ||
    xAxisLabel.includes('date') ||
    xAxisLabel.includes('Time') ||
    xAxisLabel.includes('time')
      ? 'time'
      : 'category'
  const yTypeAxis =
    yAxisLabel.includes('Date') ||
    yAxisLabel.includes('date') ||
    yAxisLabel.includes('Time') ||
    yAxisLabel.includes('time')
      ? 'time'
      : 'value'
  const dataZoomX = Boolean(fields?.dataZoomX)
  const height = fields.height || DEFAULT_REPORT_MINHEIGHT
  const tooltipLabel =
    xAxisLabel.includes('Date') || xAxisLabel.includes('date')
      ? {
          formatter: function (params: { value: Object }) {
            return dayjs(parseInt(params.value.toString())).format('YYYY-MM-DD')
          },
        }
      : { formatter: '{value}' }
  const DEFAULT_OPTION = defaultOption(
    id,
    xAxisLabelHide ? '' : xAxisLabel,
    yAxisLabelHide ? '' : yAxisLabel,
    xTypeAxis,
    yTypeAxis,
    dataZoomX,
    tooltipLabel,
    theme
  )
  const [option, setOption] = useState(DEFAULT_OPTION)

  const fetchNewData = (
    initialChartdata: StockLineChartData[],
    initialChartdataItem: StockLineChartData,
    chartData: StockLineChartData[] | null | undefined,
    chartDataItem: StockLineChartData | null | undefined
  ) => {
    let newOption = cloneDeep(option)

    if (option.id !== id) {
      newOption = DEFAULT_OPTION
    }

    // When initial values
    if (
      !chartData &&
      !chartDataItem &&
      initialChartdata &&
      !newOption.series.length
    ) {
      for (let i = 0; i < fields.values.length; i++) {
        const line = {
          data: initialChartdata.map((d: StockLineChartData) => [
            d[fields.key],
            d[fields.values[i]],
          ]),
          type: 'line',
          typeChartData: 'chartData',
          smooth,
          symbol,
          animation: false,
          itemStyle: {
            color: CHART_COLORS[`C${i + 1}`],
          },
          lineStyle: {
            color: CHART_COLORS[`C${i + 1}`],
            width: 1,
          },
        }

        if (initialChartdataItem) {
          line.data.push([
            initialChartdataItem[fields.key],
            initialChartdataItem[fields.values[i]],
          ])
          line.typeChartData = 'chartDataItem'
        }

        newOption.series.push(line)
      }

      // When real-time values
    } else {
      if (chartData) {
        for (let i = 0; i < fields.values.length; i++) {
          const line = {
            data: chartData.map((d: StockLineChartData) => [
              d[fields.key],
              d[fields.values[i]],
            ]),
            type: 'line',
            typeChartData: 'chartData',
            smooth,
            symbol,
            animation: false,
            itemStyle: {
              color: CHART_COLORS[`C${i + 1}`],
            },
            lineStyle: {
              color: CHART_COLORS[`C${i + 1}`],
              width: 1,
            },
          }

          newOption.series[i] = line
        }
      }

      if (chartDataItem) {
        for (let i = 0; i < fields.values.length; i++) {
          const lastPosition = newOption.series[i].data.length - 1

          if (newOption.series[i].typeChartData === 'chartDataItem') {
            newOption.series[i].data[lastPosition] = [
              chartDataItem[fields.key],
              chartDataItem[fields.values[i]],
            ]
          }

          if (newOption.series[i].typeChartData === 'chartData') {
            newOption.series[i].data.push([
              chartDataItem[fields.key],
              chartDataItem[fields.values[i]],
            ])
            newOption.series[i].typeChartData = 'chartDataItem'
          }
        }
      }
    }

    setOption(newOption)
  }

  useEffect(() => {
    fetchNewData(
      initialChartdata,
      initialChartdataItem,
      chartData,
      chartDataItem
    )
  }, [chartData, chartDataItem, id]) // eslint-disable-line

  return (
    <Paper
      className={styles.container__StockLineChart}
      style={{ minHeight: `${height}px` }}
    >
      <Echarts
        style={{
          height: 'calc(100% - 40px)',
          width: 'calc(100% - 20px)',
        }}
        className={styles.StockLineChart}
        option={option}
        opts={{ renderer: 'svg' }}
      />
    </Paper>
  )
}

export default StockLineCHart
