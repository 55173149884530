import { EChartsOption, SeriesOption } from 'echarts'
import addOptionModifications from '../../addOptionModifications'
import defaultOptionModifications from './defaultOptionModifications'
import titleOption from '../../defaults/title'
import legendOption from '../../defaults/legend'
import tooltipOption from '../../defaults/tooltip'
import axisPointerOption from '../../defaults/axisPointer'
import toolboxOption, { getDefaultToolbox } from '../../defaults/toolbox'
import gridOption from '../../defaults/grid'
import generatePaddingGrid from '../generatePaddingGrid'
import {
  PADDING_GRID_TOP,
  PADDING_GRID_RIGHT,
  PADDING_GRID_BOTTOM,
  PADDING_GRID_LEFT,
} from './grid'
import xAxisOption, { getDefaultXAxis } from '../../defaults/xAxis'
import yAxisOption, { getDefaultYAxis } from '../../defaults/yAxis'
import dataZoomOption from '../../defaults/dataZoom'
import generateDataset from '../../formatData/generateDataset'
import seriesOption from './series'
import { Option, ChartParams } from '../../types'
import validObject from '../validObject'

const Scatter = (params: ChartParams): EChartsOption => {
  const {
    theme,
    title = '',
    subtitle = '',
    legend = false,
    tooltip = true,
    axisPointer = false,
    toolbox = getDefaultToolbox(theme),
    xAxis = getDefaultXAxis(theme),
    yAxis = getDefaultYAxis(theme),
    dataZoom = false,
    data = [{}],
    optionModifications = {},
  } = params

  const paddingGrid = generatePaddingGrid({
    allChartProperties: {
      title: Boolean(title),
      subtitle: Boolean(subtitle),
      legend,
      toolbox: validObject({ object: toolbox }),
      xAxisName: validObject({ object: xAxis, ignoreKeys: ['type'] }),
      yAxisName: validObject({ object: yAxis, ignoreKeys: ['type'] }),
      dataZoom: Boolean(dataZoom),
    },
    gridSizes: {
      top: PADDING_GRID_TOP,
      left: PADDING_GRID_LEFT,
      bottom: PADDING_GRID_BOTTOM,
      right: PADDING_GRID_RIGHT,
    },
  })

  const axisName = 'xAxis'
  const seriesModifications = optionModifications?.series as SeriesOption

  const defaultOption: Option = {
    title: titleOption({ title, subtitle, theme }),
    legend: legendOption({ legend, theme }),
    tooltip: tooltipOption({ tooltip, theme }),
    axisPointer: axisPointerOption({ axisPointer, theme }),
    grid: gridOption({ paddingGrid }),
    toolbox: toolboxOption({ ...toolbox, theme }),
    xAxis: xAxisOption({ ...xAxis, theme }),
    yAxis: yAxisOption({ ...yAxis, theme }),
    dataZoom: dataZoomOption({ dataZoom, theme }),
    dataset: generateDataset({ data, axisName }),
    series: seriesOption({ data, seriesModifications, axisName, theme }),
  }

  const scatterOption = addOptionModifications({
    defaultOption,
    defaultOptionModifications: defaultOptionModifications(),
    optionModifications,
  })

  return scatterOption
}

export default Scatter
