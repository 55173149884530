import { Theme } from '@mui/material/styles'
import { VisualMapComponentOption } from 'echarts'
import { TEXT_STYLE } from '../defaults/general'
import { VisualMapType } from '../types'

interface VisualMapParams {
  type: VisualMapType
  theme: Theme
}

export const visualMap = (
  params: VisualMapParams
): VisualMapComponentOption => {
  const { type = 'piecewise', theme } = params

  const visualMapContinuous = {
    type: 'continuous',
    id: '',
    calculable: true,
    realtime: true,
    inverse: false,
    itemWidth: 16,
    itemHeight: 128,
    align: 'auto',
    text: undefined,
    textGap: 8,
    show: true,
    dimension: undefined,
    hoverLink: true,
    top: 54,
    right: 0,
    bottom: 'auto',
    left: 'auto',
    orient: 'vertical',
    padding: 0,
    backgroundColor: 'transparent',
    handleSize: '120%',
    indicatorIcon: 'circle',
    textStyle: {
      ...TEXT_STYLE(theme),
      fontSize: 10,
    },
  } as VisualMapComponentOption

  const visualMapPieceWise = {
    type: 'piecewise',
    splitNumber: 5,
    pieces: undefined,
    categories: undefined,
    minOpen: false,
    maxOpen: false,
    selectedMode: 'multiple',
    inverse: false,
    precision: 0,
    itemWidth: 24,
    itemHeight: 16,
    text: undefined,
    textGap: 8,
    showLabel: true,
    itemGap: 16,
    show: true,
    dimension: undefined,
    hoverLink: true,
    top: 60,
    right: 0,
    orient: 'vertical',
    backgroundColor: 'transparent',
    textStyle: {
      ...TEXT_STYLE(theme),
      fontSize: 10,
    },
  } as VisualMapComponentOption

  return type === 'piecewise' ? visualMapPieceWise : visualMapContinuous
}

export default visualMap
