import reportDataSetsFragment from 'gql/fragments/reportDataSets'

const reportFragment = /* GraphQL */ `
  ${reportDataSetsFragment}
  fragment ReportFields on ReportExposed {
    id
    appId
    bentobox
    chartData
    chartDataAux
    chartDataItem
    dataFields
    description
    grid
    isDisabled
    order
    path
    hidePath
    pathOrder
    properties
    reportType
    sizeColumns
    sizePadding
    sizeRows
    smartFilters
    subscribe
    title
    reportDataSets {
      ...ReportDataSetsFields
    }
  }
`

export default reportFragment
