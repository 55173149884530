import React, { useState, SyntheticEvent } from 'react'
import { Box } from '@mui/material'
import TabPanel from './TabPanel'
import a11yProps from './a11yProps'
import GridReports from 'components/Reports/GridReports'
import { TabsVariant } from 'lib/reports/types'
import { generateBentoBox } from 'lib/reports/bentoBox'
import * as S from './Tabs.styled'

interface TabsProps {
  tabs: string[]
  tabPanels: Report[][]
  sticky?: boolean
  variant?: TabsVariant
  columns: number
}

const Tabs = (props: TabsProps) => {
  const {
    tabs,
    tabPanels,
    sticky,
    variant = 'enclosedSolidRounded',
    columns: reportTabColumns,
  } = props
  const DEFAULT_VALUE = 0
  const [value, setValue] = useState(DEFAULT_VALUE)

  const handleChange = (e: SyntheticEvent, newValue: number) =>
    setValue(newValue)

  return (
    <Box>
      <S.Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="tabs"
        sticky={sticky}
        tabsVariant={variant}
      >
        {tabs.map((tab, i) => (
          <S.Tab
            key={`${tab}-${i}`}
            label={tab}
            {...a11yProps(i)}
            variant={variant}
          />
        ))}
      </S.Tabs>
      {tabPanels.map((tabPanelReports, i) => {
        const reportGroup = generateBentoBox(tabPanelReports)

        return (
          <TabPanel key={`${tabs[i]}-${i}`} value={value} index={i}>
            <GridReports
              reportGroup={reportGroup}
              gridColumns={reportTabColumns}
            />
          </TabPanel>
        )
      })}
    </Box>
  )
}

export default Tabs
