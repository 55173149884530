import Button from 'components/Button'
import { styled } from '@mui/material/styles'

export const DownloadContainer = styled('div')(() => ({
  gridColumn: '4/4',
  display: 'flex',
  justifyContent: 'end',
}))

export const IconButton = styled(Button)(() => ({
  width: '36px',
  height: '36px',
  minWidth: 'inherit',
  borderRadius: 'var(--border-radius-s)',
  backgroundColor: 'var(--color-grey-200)',
  '&:hover': {
    backgroundColor: 'var(--color-grey-300)',
    color: 'var(--color-black)',
  },
}))
