import { PaletteOptions } from '@mui/material'
import {
  BrandPallete,
  ChartColors,
  Common,
  ComplementaryColors,
} from 'theme/types'

export const BASE_COLORS: Common = {
  white: '#FFFFFF',
  black: '#202A36',
  gray: '#E5E5E5',
}

export const MAIN_PALLETE: BrandPallete = {
  50: '#EAEEFF',
  100: '#B8C7F9',
  200: '#7693FB',
  300: '#4C72F9',
  400: '#224EE9',
  500: '#002FD8',
  600: '#0315B9',
  700: '#031A92',
}

export const SECONDARY_PALLETE: BrandPallete = {
  50: '#E0F3E8',
  100: '#C7F0D8',
  200: '#B1F0CA',
  300: '#8DEAB2',
  400: '#57E791',
  500: '#29D86F',
  600: '#09B85A',
  700: '#0E9A57',
}

export const GREY_PALLETE: BrandPallete = {
  100: '#FAFAFB',
  200: '#F1F1F5',
  300: '#E2E2EA',
  400: '#D5D5DC',
  500: '#AEAEAE',
  600: '#92929D',
  700: '#696974',
  800: '#44444F',
}

export const COMPLEMENTARY_PALLETE: ComplementaryColors = {
  red: {
    600: '#D13A4C',
    500: '#EE3A4F',
    400: '#F86C7D',
  },
  violet: {
    600: '#560985',
    500: '#7208B4',
    400: '#A95CD9',
  },
  aqua: {
    600: '#0BA781',
    500: '#20C69E',
    400: '#61DABD',
  },
  orange: {
    600: '#C03D14',
    500: '#ED5627',
    400: '#F28867',
  },
  yellow: {
    600: '#D39316',
    500: '#F3B922',
    400: '#F3CD6B',
  },
  green: {
    600: '#08961F',
    500: '#2ED020',
    400: '#5EEE52',
  },
}

export const STATUS_COLORS: PaletteOptions = {
  success: {
    main: SECONDARY_PALLETE[500],
  },
  warning: {
    main: '#F78A68',
  },
  error: {
    main: '#F76868',
  },
  info: {
    main: '#ED5627',
  },
}

export const CHART_COLORS: ChartColors = {
  C1: '#4C72F9',
  C2: '#74D890',
  C3: '#F2BB67',
  C4: '#F78A68',
  C5: '#C6E2E9',
  C6: '#9BA4F5',
  C7: '#EAB8CD',
  C8: '#D28AC7',
  C9: '#B6AFD0',
  C10: '#4C5AA4',
}
