import { isArray } from 'lib/variableType'
import { IndicatorProperties } from 'lib/reports/types'
import { formatMessage } from 'i18n/ShimokuIntl'

interface ParseReportPropertiesParams {
  reportProperties: IndicatorProperties
}

const parseReportProperties = (params: ParseReportPropertiesParams) => {
  const { reportProperties } = params

  return Object.fromEntries(
    Object.entries(reportProperties).map(([key, value]) => {
      if (isArray(value)) {
        const [firstValue = formatMessage('generic.noData')] = value

        return [key, firstValue]
      }

      return [key, value]
    })
  ) as IndicatorProperties
}

export default parseReportProperties
