import { SeriesOption, SunburstSeriesOption } from 'echarts'
import sunburstSerie from '../../defaults/series/sunburst'
import { SeriesParams } from '../../types'

const series = (params: SeriesParams): SeriesOption => {
  const { data, seriesModifications = {}, paddingSunburst, theme } = params

  const seriesOption = sunburstSerie({
    typeSeriesModifications: seriesModifications as SunburstSeriesOption,
    paddingSunburst,
    theme,
  })

  const series = [
    {
      ...seriesOption,
      data,
    },
  ]

  return series as SeriesOption
}

export default series
