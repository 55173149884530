import { Plan } from 'redux/plan/types'

interface GetPlanParams {
  account: Account
}

const getPlan = (params: GetPlanParams) => {
  const { account } = params

  const {
    id,
    limits: defaultLimits,
    price,
    type,
  } = account.business.universe.activeUniversePlan.planType
  const limits = defaultLimits ? JSON.parse(defaultLimits) : {}

  const plan: Plan = { id, limits, price, type }

  return plan
}

export default getPlan
