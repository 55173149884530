import getDateKey from '../getDateKey'
import generateAggregationWithOperations from './generateAggregationWithOperations'
import { AggregationItem, AggregationByKeys } from '../types'
import { ReportWithAggregation } from 'contexts/ReportDataSets/types'

const KEY = 'key'
const DATE_FIELD = 'dateField'

interface GenerateAggregationParams {
  reportDataSets: ReportWithAggregation[]
}

const generateReportDataSetsAggregation = (
  params: GenerateAggregationParams
) => {
  const { reportDataSets } = params

  const reportDataSetsWithAggregation = reportDataSets.map((reportDataSet) => {
    const {
      dataSet: { data = [] },
      aggregationProperty = [],
    } = reportDataSet

    const keyFields = aggregationProperty
      .filter((aggregation) => Boolean(aggregation[KEY]))
      .map(({ field, dateSubType }) => ({
        field,
        dateSubType,
      }))

    const aggregation: Aggregation[] = Object.values(
      data.reduce((aggregationByKeys, dataItem) => {
        const key = keyFields
          .map((keyField) => {
            const { field, dateSubType } = keyField
            const dateField = field.includes(DATE_FIELD)

            if (dateField && dateSubType) {
              return getDateKey({ date: dataItem[field], dateSubType })
            }

            return dataItem[field]
          })
          .join(' | ')

        const item: AggregationItem = aggregationProperty.reduce(
          (item, aggregationPropertyItem) => {
            const { name, field } = aggregationPropertyItem

            return { ...item, [name]: dataItem[field] }
          },
          {}
        )
        const total = data.length

        if (aggregationByKeys[key]) {
          const items = [...aggregationByKeys[key].items, item]
          const count = items.length

          return {
            ...aggregationByKeys,
            [`${aggregationByKeys[key].key}`]: {
              ...aggregationByKeys[key],
              items,
              count,
            },
          } as AggregationByKeys
        }

        const items = [item]
        const count = items.length

        return {
          ...aggregationByKeys,
          [key]: {
            items,
            key,
            total,
            count,
          },
        } as AggregationByKeys
      }, {} as AggregationByKeys)
    )

    return {
      ...reportDataSet,
      aggregation,
    }
  })

  const aggregationWithOperations = generateAggregationWithOperations({
    reportDataSets: reportDataSetsWithAggregation,
  })

  return aggregationWithOperations
}

export default generateReportDataSetsAggregation
