import React from 'react'
import { Typography } from '@mui/material'
import Breadcrumb from 'components/Breadcrumb'
import HistoryNavigation from 'components/HistoryNavigation'
import { Container } from './ReportsHeader.styled'
import getAppName from 'lib/apps/getAppName'

interface ReportsHeaderProps {
  app: App
  reports: Report[]
}

const getReportsPath = (reports: Report[]): string | null =>
  reports[0]?.path || null

const ReportsHeader = (props: ReportsHeaderProps) => {
  const { app, reports } = props
  const {
    showHistoryNavigation = false,
    showBreadcrumb = false,
    hideTitle = false,
  } = app
  const appName = getAppName(app)

  return (
    <Container>
      {showHistoryNavigation && <HistoryNavigation />}
      {showBreadcrumb && <Breadcrumb />}
      {!hideTitle && (
        <Typography component="h1" variant="h3" data-testid="app-title">
          {appName} {getReportsPath(reports)}
        </Typography>
      )}
    </Container>
  )
}

export default ReportsHeader
