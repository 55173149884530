import { RadarComponentOption } from 'echarts'
import {
  NAME_TEXT_STYLE,
  AXIS_LINE,
  AXIS_TICK,
  AXIS_LABEL,
  SPLIT_LINE,
  SPLIT_AREA,
} from './general'
import { TypeSeriesParams } from '../types'

const radar = (
  params: TypeSeriesParams<RadarComponentOption>
): RadarComponentOption => {
  const { typeSeriesModifications: radarModifications, theme } = params
  const CHART_OPTIONS = theme.custom.chart.options

  return {
    id: '',
    center: ['50%', '50%'],
    radius: ['0%', '70%'],
    startAngle: 90,
    axisNameGap: 16,
    splitNumber: 5,
    shape: 'polygon',
    scale: false,
    triggerEvent: false,
    ...radarModifications,
    axisLine: {
      ...AXIS_LINE(theme),
      ...radarModifications?.axisLine,
      lineStyle: {
        ...AXIS_LINE(theme).lineStyle,
        color: CHART_OPTIONS.grid,
        ...radarModifications?.axisLine?.lineStyle,
      },
    },
    axisTick: {
      ...AXIS_TICK(theme),
      show: false,
      ...radarModifications?.axisTick,
    },
    axisLabel: {
      ...AXIS_LABEL(theme),
      show: false,
      ...radarModifications?.axisLabel,
    },
    splitLine: {
      ...SPLIT_LINE,
      ...radarModifications?.splitLine,
      lineStyle: {
        ...AXIS_LINE(theme).lineStyle,
        color: CHART_OPTIONS.grid,
        ...radarModifications?.splitLine?.lineStyle,
      },
    },
    splitArea: {
      ...SPLIT_AREA,
      show: true,
      ...radarModifications?.splitArea,
    },
    name: {
      ...NAME_TEXT_STYLE(theme), //@ts-ignore
      ...radarModifications?.name,
    },
  }
}

export default radar
