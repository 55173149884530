import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from 'redux/user'
import { selectAccount } from 'redux/account'
import { useSnackbar } from 'notistack'
import useSetTheme from 'hooks/useSetTheme'
import ReportsContainer from 'containers/Reports'
import Main from 'components/Main'
import { ReportsProvider } from 'contexts/reports'
import { ReportDataSetsProvider } from 'contexts/ReportDataSets'
import { AppRoute } from 'components/Routes/types'

interface ReportProps {
  dashboard: Dashboard
  app: App
  appRoute: AppRoute
  padding?: boolean
}

const Reports = (props: ReportProps) => {
  const { dashboard, app, appRoute, padding = true } = props
  const { enqueueSnackbar } = useSnackbar()
  const user = useSelector(selectUser)
  const account = useSelector(selectAccount)
  const { setThemeByDashboard } = useSetTheme()
  const { reports: initialReports = [] } = appRoute

  if (Boolean(app?.error)) {
    enqueueSnackbar(app.error, { variant: 'error' })
  }

  useEffect(() => {
    setThemeByDashboard({ user, account, dashboard })
  }, []) // eslint-disable-line


  return (
    <Main padding={padding}>
      <ReportsProvider initialReports={initialReports}>
        <ReportDataSetsProvider>
          <ReportsContainer app={app} appRoute={appRoute} />
        </ReportDataSetsProvider>
      </ReportsProvider>
    </Main>
  )
}

export default Reports
