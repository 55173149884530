import React, { Suspense, useEffect, useState } from 'react'
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import { selectUser } from 'redux/user'
import { selectAccount } from 'redux/account'
import { selectDashboards } from 'redux/dashboards'
import { selectUniverseModules } from 'redux/universeModules'
import useHistoryManager from 'contexts/navigation/hooks/useHistoryManager'
import WithUser from 'hocs/WithUser'
import useRedirect from 'hooks/useRedirect'
import Authenticated from 'components/Authenticated/Authenticated'
import LoadingPage from 'components/LoadingPage/LoadingPage'
import getDefaultRoutes from 'components/Routes/routes/getDefaultRoutes'
import getRoutes from './getRoutes'
import generateRouteComponents from './generateRoutes'
import { RouteTypes } from './types'
import { usePostHog } from 'posthog-js/react'

const Routes = () => {
  const user = useSelector(selectUser)
  const account = useSelector(selectAccount)
  const dashboards = useSelector(selectDashboards)
  const universeModules = useSelector(selectUniverseModules)
  const history = useHistory()
  const { redirect } = useRedirect()
  const [routes, setRoutes] = useState<RouteTypes>({
    authenticatedRoutes: [],
    adminRoutes: [],
    appsRoutes: [],
  })
  const { authenticatedRoutes, adminRoutes, appsRoutes } = routes
  const defaultRoutes = generateRouteComponents({
    routes: getDefaultRoutes(),
  })
  const location = useLocation()
  const posthog = usePostHog()
  useHistoryManager()

  const setupTracking = () => {
    if (user.id) {
      ReactGA.set({ userId: user.id })
      ReactGA.pageview(window.location.pathname)
      posthog?.capture('$pageview')
    }
  }

  useEffect(() => {
    setupTracking()
  }, [user.id, account]) // eslint-disable-line

  useEffect(() => {
    setRoutes(
      getRoutes({
        user,
        account,
        dashboards,
        universeModules,
      })
    )
  }, [user.id, account.id, dashboards, universeModules]) // eslint-disable-line

  useEffect(() => {
    return history.listen((location) => {
      ReactGA.pageview(location.pathname)
      posthog?.capture('$pageview', { location: location.pathname })
    })
  }, [history]) // eslint-disable-line

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location]) // eslint-disable-line

  return (
    <Suspense fallback={<LoadingPage global={true} />}>
      <Switch>
        {defaultRoutes}
        <Route exact path="*">
          <Authenticated>
            <WithUser>
              <Suspense fallback={<LoadingPage global={true} />}>
                <Switch>
                  <Redirect exact from={redirect.from} to={redirect.to} />
                  {adminRoutes}
                  {appsRoutes}
                  {authenticatedRoutes}
                </Switch>
              </Suspense>
            </WithUser>
          </Authenticated>
        </Route>
      </Switch>
    </Suspense>
  )
}

export default Routes
