import {
  BASE_COLORS,
  CHART_COLORS,
  COMPLEMENTARY_PALLETE,
  GREY_PALLETE,
  MAIN_PALLETE,
  SECONDARY_PALLETE,
  STATUS_COLORS,
} from 'theme/common/colors'
import CHART_OPTIONS from 'theme/common/chart-options'
import COMMON_OVERRIDES from 'theme/common/overrides'
import COMMON_RADIUS from 'theme/common/radius'
import COMMON_SHADOWS from 'theme/common/shadows'
import TEXT from 'theme/common/text'
import COMMON_Z_INDEX from 'theme/common/zIndex'
import typography from 'theme/common/typography'

export const palette = {
  common: BASE_COLORS,
  primary: {
    light: MAIN_PALLETE[100],
    main: MAIN_PALLETE[500],
    dark: MAIN_PALLETE[600],
  },
  secondary: {
    light: SECONDARY_PALLETE[100],
    main: SECONDARY_PALLETE[500],
    dark: SECONDARY_PALLETE[600],
  },
  complementary: COMPLEMENTARY_PALLETE,
  grey: GREY_PALLETE,
  background: {
    default: BASE_COLORS.white,
  },
  text: TEXT,
  status: { ...STATUS_COLORS },
}

const COMMON_THEME_OPTIONS: any = {
  typography,
  palette,
  custom: {
    brandPallete: MAIN_PALLETE,
    chart: {
      colors: CHART_COLORS,
      options: CHART_OPTIONS,
    },
    reports: {
      indicators: {
        gap: '0.625rem',
      },
    },
    dimensions: {
      drawerWidth: 19,
      headerHeight: 3.75,
    },
    radius: COMMON_RADIUS,
    shadows: COMMON_SHADOWS,
    icon: { base: BASE_COLORS.black },
    zIndex: {
      ...COMMON_Z_INDEX,
    },
  },
  components: {
    ...COMMON_OVERRIDES,
  },
}

export default COMMON_THEME_OPTIONS
