import React from 'react'
import { formatMessage } from 'i18n/ShimokuIntl'
import Layout from 'containers/Layout'
import Main from 'components/Main'
import { Box, Typography } from '@mui/material'
import { NotFoundError } from 'icons/draws/not-found-error/not-found-error'
import * as GS from '../../material/global.styled'

interface NotFoundProps {
  padding?: boolean
}

const NotFound = (props: NotFoundProps) => {
  const { padding = true } = props

  return (
    <Layout>
      <Main padding={padding} centered={true}>
        <Typography
          align="center"
          color="var(--complementary-orange)"
          component="h1"
          variant="h3"
        >
          {formatMessage('page.notFound.heading')}
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          marginTop="1.25rem"
          marginBottom="1.25rem"
        >
          <NotFoundError />
        </Box>
        <br />
        <GS.TextWithInnerLink
          align="center"
          variant="bodyM400"
          dangerouslySetInnerHTML={{
            __html: formatMessage('page.notFound.bodyText'),
          }}
        />
      </Main>
    </Layout>
  )
}

export default NotFound
