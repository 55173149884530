import { GenerateDatasetParams } from '../types'

const generateGeneralDataSet = (params: GenerateDatasetParams) => {
  const { data, axisName } = params

  const source = [...data]
  const dimensions = [
    axisName,
    ...Object.keys(data?.[0]).filter(
      (dimension: string) => dimension !== axisName
    ),
  ]

  return { dimensions, source }
}

export default generateGeneralDataSet
