import { useState, useCallback } from 'react'
import {
  RegisterRealTimeComponentParams,
  UnregisterRealTimeComponentParams,
} from 'contexts/realTime/types'
import { RealTimeEvent } from 'contexts/realTime/types'
import { isObject } from 'lib/variableType'

// THIS HOOK SHOULD ONLY BE CALLED IN THE CONTEXTS/REALTIME/INDEX.TSX FILE
// If called in another file, IT WILL reset the state to an empty map

export type callbackType = (event: RealTimeEvent) => void

export type callbackWithId = {
  [key: string]: callbackType
}

export type RealTimeComponent = {
  [key: string]: callbackType | callbackWithId
}

const useRealTimeComponents = () => {
  const [realTimeComponents, setRealTimeComponents] =
    useState<RealTimeComponent>()

  const registerRealTimeComponent = useCallback(
    (params: RegisterRealTimeComponentParams) => {
      const { id, eventType, callback } = params

      setRealTimeComponents((prevRealTimeComponents) => {
        return {
          ...prevRealTimeComponents,
          // Case callback without id
          ...(!id
            ? {
                [eventType]: callback,
              }
            : // Case callbackWithId
              {
                [eventType]: {
                  ...(prevRealTimeComponents &&
                    prevRealTimeComponents[eventType]),
                  [id]: callback,
                },
              }),
        }
      })
    },
    []
  )

  const unregisterRealTimeComponent = useCallback(
    (params: UnregisterRealTimeComponentParams) => {
      const { id, eventType } = params
      setRealTimeComponents((prevRealTimeComponents) => {
        if (!prevRealTimeComponents) return prevRealTimeComponents
        // Case callback without id
        if (!id) {
          const { [eventType]: _, ...restPrevRealTimeComponents } =
            prevRealTimeComponents
          return restPrevRealTimeComponents
          // Case callbackWithId
        } else {
          if (!isObject(prevRealTimeComponents[eventType]))
            return prevRealTimeComponents

          const { [eventType]: eventFns, ...restPrevRealTimeComponents } =
            prevRealTimeComponents as { [key: string]: callbackWithId }

          const { [id]: _, ...restEventFns } = eventFns

          return {
            ...restPrevRealTimeComponents,
            [eventType]: {
              ...restEventFns,
            },
          }
        }
      })
    },
    []
  )

  return {
    realTimeComponents,
    registerRealTimeComponent,
    unregisterRealTimeComponent,
  }
}

export { useRealTimeComponents }
