import React, { SVGProps } from 'react'

interface MenuProps extends SVGProps<SVGSVGElement> {
  testId?: string
}

export const Menu = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
  testId,
}: MenuProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
      data-testid={testId}
    >
      <title>menu-icon</title>
      <path
        d="M4 8L20 8"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M12 16L20 16"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  )
}
