import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from 'redux/user'
import { selectAccount } from 'redux/account'
import { selectApps } from 'redux/apps'
import { selectDashboards } from 'redux/dashboards'
import { selectAvailableModules } from 'redux/modules'
import usePlaygroundIsRunning from 'hooks/usePlaygroundIsRunning'
import { generateRoutes } from 'components/Routes/generateRoutes'
import {
  ROOT_PATH,
  PLAYGROUND_PATH,
} from 'components/Routes/routes/getDefaultRoutes'
import {
  ON_BOARDING,
  BUSINESS_WITHOUT_CONTENT,
} from 'components/Routes/routes/getAuthenticatedRoutes'
import getAppRoutes from 'lib/apps/getAppRoutes'
import { getStartupApp } from 'lib/apps/startupApp'

const useRedirect = () => {
  const user = useSelector(selectUser)
  const account = useSelector(selectAccount)
  const dashboards = useSelector(selectDashboards)
  const apps = useSelector(selectApps)
  const { haveInstallRemoveApps } = useSelector(selectAvailableModules)
  const playgroundIsRunning = usePlaygroundIsRunning()
  const from = playgroundIsRunning ? PLAYGROUND_PATH : ROOT_PATH
  const prefix = playgroundIsRunning ? PLAYGROUND_PATH : undefined
  const [redirect, setRedirect] = useState({
    from: from,
    to: from,
  })

  useEffect(() => {
    const [onBoarding, businessWithoutContent] = generateRoutes({
      routes: [ON_BOARDING, BUSINESS_WITHOUT_CONTENT],
      prefix,
    })

    if (
      (!Boolean(user.kindOfUser) || !Boolean(user.name)) &&
      !Boolean(prefix)
    ) {
      setRedirect({ from, to: onBoarding.path })
    } else if ((!dashboards.length || !apps.length) && !haveInstallRemoveApps) {
      setRedirect({ from, to: businessWithoutContent.path })
    } else if (apps.length) {
      const { startupApp, startupDashboard } = getStartupApp({
        account,
        apps,
        dashboards,
      })

      const {
        rootApp: { path: to },
      } = getAppRoutes({
        app: startupApp as App,
        dashboard: startupDashboard as Dashboard,
        prefix,
      })

      setRedirect({ from, to })
    }
  }, [user, account, dashboards, apps, haveInstallRemoveApps]) //eslint-disable-line

  return { redirect }
}

export default useRedirect
