import { styled } from '@mui/material/styles'

export const Footer = styled('footer')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '9px 16px',
  margin: '8px',
  borderRadius: 'var(--border-radius-m)',
  backgroundColor: 'var(--color-grey-200)',
}))
