import { SeriesOption, BarSeriesOption } from 'echarts'
import barSerie from '../../defaults/series/bar'
import { SeriesParams } from '../../types'

const series = (params: SeriesParams): SeriesOption => {
  const { data, seriesModifications = {}, axisName, theme } = params

  const seriesOption = barSerie({
    typeSeriesModifications: seriesModifications as BarSeriesOption,
    theme,
  })

  const series = Object.keys(data?.[0])
    .filter((name: string) => name !== axisName)
    .map((name: string) => ({
      ...seriesOption,
      name,
    }))

  return series as SeriesOption
}

export default series
