import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Helmet as HelmetComponent } from 'react-helmet-async'
import whispersFavicon16 from 'assets/favicon-whispers/favicon-16x16.png'
import whispersFavicon32 from 'assets/favicon-whispers/favicon-32x32.png'
import gasolinowFavicon16 from 'assets/favicon-gasolinow/favicon-16x16.png'
import gasolinowFavicon32 from 'assets/favicon-gasolinow/favicon-32x32.png'
import shimokuSVG from 'assets/favicon-shimoku/favicon-blue-bg-48.svg'
import meetingDoctorsFavicon16 from 'assets/favicon-meeting-doctors/favicon-16x16.png'
import meetingDoctorsFavicon32 from 'assets/favicon-meeting-doctors/favicon-32x32.png'
import { DOMAIN_OPTIONS } from 'theme/types'

const Helmet = () => {
  const { GASOLINOW, WHISPERS, MEETING_DOCTORS, DELOITTE } = DOMAIN_OPTIONS
  const {
    custom: { domain },
  } = useTheme()

  switch (domain) {
    case GASOLINOW:
      return (
        <HelmetComponent>
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={gasolinowFavicon32}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={gasolinowFavicon16}
          />
          <title>Gasolinow</title>
        </HelmetComponent>
      )

    case WHISPERS:
      return (
        <HelmetComponent>
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={whispersFavicon32}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={whispersFavicon16}
          />
          <title>Whispers</title>
        </HelmetComponent>
      )
    case MEETING_DOCTORS:
      return (
        <HelmetComponent>
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={meetingDoctorsFavicon32}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={meetingDoctorsFavicon16}
          />
          <title>Meeting Doctors</title>
        </HelmetComponent>
      )

    case DELOITTE:
      return (
        <HelmetComponent>
          <title>Deloitte</title>
        </HelmetComponent>
      )

    default:
      return (
        <HelmetComponent>
          <link rel="icon" type="image/svg+xml" href={shimokuSVG} />
          <title>Shimoku</title>
        </HelmetComponent>
      )
  }
}

export default Helmet
