import { DOMAIN_OPTIONS } from 'theme/types'

export const INVITATION_TOKEN_PARAM = 'invitationToken'

export const isClientDomain = (): boolean => {
  const shimokuExcluded = Object.values(DOMAIN_OPTIONS).filter(
    (hostname) => hostname !== DOMAIN_OPTIONS.SHIMOKU
  )
  return shimokuExcluded.some(
    (hostname) => window.location.hostname === hostname
  )
}

export const haveInvitationToken = (): boolean => {
  const urlParams = new URLSearchParams(window.location.search)
  const invitationToken = urlParams.get(INVITATION_TOKEN_PARAM)
  return Boolean(invitationToken)
}
