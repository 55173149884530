import React, { CSSProperties } from 'react'
import * as S from './indicator.styled'
import LinkB from 'icons/Line/link-b'
import { IndicatorProperties } from 'lib/reports/types'
import IndicatorHeader from './indicatorHeader'
import ConditionalWrapper from 'hocs/conditionalWrapper'
import OverflowTooltip from 'components/OverflowTooltip/OverflowTooltip'
import { useTheme } from '@mui/material/styles'
import ConditionalLink from 'components/ConditionalLink'
import {
  BigIconContent,
  DEFAULT_ICON_SIZE,
  IconContent,
  getIconPositionStyle,
} from './indicatorIcons'

export interface IndicatorProps extends IndicatorProperties {
  style?: CSSProperties
  centerContent?: boolean
}

export const Indicator = (props: IndicatorProps) => {
  const {
    title,
    value,
    color = 'default',
    align = 'left',
    description,
    style,
    icon,
    bigIcon,
    variant = 'default',
    targetPath,
    backgroundImage,
    hideIcons,
    info,
    centerContent = false,
    elipsis = true,
  } = props
  const {
    palette: { mode },
  } = useTheme()
  const showIcon = !targetPath && !hideIcons

  const IndicatorContent = () => (
    <S.Article data-testid={`indicator-${title}`}>
      <S.IndicatorWrapper
        {...{
          color,
          style,
          variant,
          align,
          background: backgroundImage,
          title,
          description,
        }}
        data-testid={`indicator-${title}-wrapper`}
      >
        {variant === 'topColor' && (
          <S.TopColorWrapper color={color} variant={variant}>
            <IndicatorHeader
              {...{
                title,
                color,
                align,
                variant,
                targetPath,
                backgroundImage,
                hideIcons,
                info,
              }}
              iconSize={DEFAULT_ICON_SIZE}
            />
          </S.TopColorWrapper>
        )}
        <S.ContentWrapper {...{ backgroundImage, align, centerContent }}>
          <div style={{ order: 1, width: '100%' }}>
            {variant !== 'topColor' && (
              <IndicatorHeader
                {...{
                  title,
                  color,
                  align,
                  variant,
                  targetPath,
                  backgroundImage,
                  hideIcons,
                  info,
                }}
                iconSize={DEFAULT_ICON_SIZE}
              />
            )}
            {value && (
              <S.ValueWrapper {...{ targetPath, icon, align }}>
                <S.Value
                  {...{
                    title: value,
                    color,
                    targetPath,
                    indicatorVariant: variant,
                    backgroundImage,
                  }}
                  data-testid={`indicator-${title}-value`}
                  component="p"
                  variant="h3"
                  style={{ order: 1 }}
                  isValue
                >
                  {value}
                </S.Value>
                {icon && showIcon && (
                  <IconContent
                    {...{
                      icon,
                      align,
                      color,
                      variant,
                      backgroundImage,
                      mode,
                      isValue: true,
                    }}
                  />
                )}
                {targetPath && !hideIcons && (
                  <S.LinkIconWrapper>
                    <LinkB
                      style={{ ...getIconPositionStyle(align) }}
                      color={S.getIconColor({ color, variant, targetPath })}
                    />
                  </S.LinkIconWrapper>
                )}
              </S.ValueWrapper>
            )}
            {description && (
              <OverflowTooltip title={description} arrow>
                <S.Description
                  {...{
                    title: description,
                    color,
                    indicatorVariant: variant,
                    backgroundImage,
                    targetPath,
                    elipsis,
                  }}
                  component="p"
                  variant="bodyS400"
                >
                  {description}
                </S.Description>
              </OverflowTooltip>
            )}
          </div>
          {bigIcon && showIcon && (
            <BigIconContent {...{ bigIcon, align, color, variant }} />
          )}
        </S.ContentWrapper>
      </S.IndicatorWrapper>
    </S.Article>
  )

  return (
    <ConditionalWrapper
      condition={Boolean(targetPath)}
      wrapper={(children) =>
        targetPath ? (
          <ConditionalLink
            to={targetPath}
            data-testid={`indicator-${title}-link`}
          >
            {children}
          </ConditionalLink>
        ) : (
          <>{children}</>
        )
      }
    >
      <IndicatorContent />
    </ConditionalWrapper>
  )
}

Indicator.defaultProps = {
  title: '',
  value: '',
  description: '',
  align: 'right',
}
