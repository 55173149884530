import React from 'react'
import ReportsHeader from './ReportsHeader'
import GridReports from './GridReports'
import OldGridReports from './OldGridReports'
import Modals from 'containers/Modals'
import { generateBentoBox } from 'lib/reports/bentoBox'
import { REPORT_TYPES } from 'lib/reports'

const { MODAL } = REPORT_TYPES

interface ReportsProps {
  app: App
  reports: Report[]
}

const Reports = (props: ReportsProps) => {
  const { app, reports: defaultReports } = props
  const oldGrid = defaultReports.every((report) => Boolean(report.grid))
  const reports = defaultReports.filter(
    ({ reportType, isDisabled, parentTabId, linkedToModal }) =>
      !isDisabled && !parentTabId && !linkedToModal && reportType !== MODAL
  )
  const modals = defaultReports.filter(({ reportType }) => reportType === MODAL)
  const reportGroup = generateBentoBox(reports)

  return (
    <>
      {(app.showHistoryNavigation || app.showBreadcrumb || !app.hideTitle) && (
        <ReportsHeader app={app} reports={defaultReports} />
      )}
      {!oldGrid ? (
        <GridReports reportGroup={reportGroup} />
      ) : (
        <OldGridReports reports={reports} />
      )}
      <Modals modals={modals} />
    </>
  )
}

export default Reports
