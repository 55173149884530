import React from 'react'
import { TextFieldProps, InputLabelProps } from '@mui/material'
import { LabelInput } from './common'
import * as S from './input.styled'

export type TextInputProps = TextFieldProps &
  InputLabelProps & {
    label?: string
    helperText?: string
  }

const TextInput = ({
  id,
  label,
  required,
  placeholder,
  ...props
}: TextInputProps) => (
  <>
    <LabelInput {...props} label={label} required={required} id={id} />
    <S.InputText
      {...props}
      variant="outlined"
      id={id}
      required={required}
      placeholder={
        placeholder ? (required ? `${placeholder} *` : placeholder) : ''
      }
      data-testid={`${label}-input`}
    />
  </>
)

export default TextInput
