import { ScatterSeriesOption } from 'echarts'
import { TEXT_STYLE, BORDER_STYLE, SHADOW_STYLE } from '../../defaults/general'
import { TypeSeriesParams } from '../../types'

const singleAxisSerie = (
  params: TypeSeriesParams<ScatterSeriesOption>
): ScatterSeriesOption => {
  const { typeSeriesModifications: singleAxisModifications = {}, theme } =
    params

  return {
    type: 'scatter',
    coordinateSystem: 'singleAxis',
    symbol: 'circle',
    symbolSize: 12,
    symbolRotate: 0,
    symbolOffset: [0, 0],
    cursor: 'pointer',
    ...singleAxisModifications,
    label: {
      show: false,
      //@ts-ignore
      position: 'outside',
      distance: 4,
      rotate: 0,
      offset: [0, 0],
      ...TEXT_STYLE(theme),
      fontSize: 12,
      align: 'center',
      backgroundColor: 'transparent',
      ...BORDER_STYLE,
      padding: 0,
      ...SHADOW_STYLE,
      ...singleAxisModifications?.label,
    },
    labelLine: {
      show: false,
      ...singleAxisModifications?.labelLine,
    },
    labelLayout: {
      hideOverlap: false,
      ...singleAxisModifications?.labelLayout,
    },
    itemStyle: {
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      ...singleAxisModifications?.itemStyle,
    },
  }
}

export default singleAxisSerie
