import React from 'react'
import Grid from 'components/Grid'
import GridBento from '../GridBento'
import GridReport from './GridReport'

interface GridReportsProps {
  reportGroup: HydratedBentoBox
  gridColumns?: number
}

const GridReports = (props: GridReportsProps) => {
  const { reportGroup, gridColumns } = props
  const columns = gridColumns

  return (
    <Grid id="grid-reports" columns={columns}>
      {Object.keys(reportGroup).map((reportId: string) =>
        reportGroup[reportId].reports.length > 1 ? (
          <GridBento
            key={`gridBento-${reportId}`}
            bentobox={reportGroup[reportId]}
            gridColumns={gridColumns}
          />
        ) : (
          <GridReport
            key={`gridReport-${reportId}`}
            report={reportGroup[reportId].reports[0]}
            gridColumns={gridColumns}
          />
        )
      )}
    </Grid>
  )
}

export default GridReports
