export const USER_EVENTS = {
  CONFIRM_ACCOUNT_CODE_SUCCESS: 'User > Confirm account code success',
  CONFIRM_ACCOUNT_CODE: 'User > Confirm account code sended',
  CONFIRM_ACCOUNT_LINK: 'User > Confirm account link sended',
  EMAIL_ENTERED: 'User > Email entered',
  FORGOT_PASSWORD_REQUESTED: 'User > Forgot password requested',
  FORGOT_PASSWORD: 'User > Forgot password',
  INVITE_USER: 'User > Invite User',
  OPEN_MENU: 'User > Open Menu',
  RESET_PASSWORD: 'User > Reset password',
  SET_PASSWORD: 'User > Set password',
  SIGN_IN: 'User > Sign in',
  SIGN_OUT: 'User > Sign out',
  SIGN_UP_FIRST_STEP: 'User > Sign up first step',
  SIGN_UP: 'User > Sign up',
}

export const NAVIGATION_EVENTS = {
  BOARD_MENU_CLICKED: 'Navigation > Board Menu Clicked',
  CLOSE_DRAWER: 'Navigation > Close Drawer',
  MENU_ITEM_CLICKED: 'Navigation > Menu Item Clicked',
  OPEN_DRAWER: 'Navigation > Open Drawer',
  SUB_MENU_ITEM_CLICKED: 'Navigation > Sub Menu Item Clicked',
}

export const SUSCRIPTIONS_EVENTS = {
  CONTACT_US: 'Suscriptions > Contact Us',
  DOWNGRADE_PLAN: 'Suscriptions > Downgrade Plan',
  MANAGE_PLAN: 'Suscriptions > Manage Plan',
  OPENED: 'Suscriptions > Opened',
  UPGRADE_PLAN_MODAL_OPENED: 'Suscriptions > Upgrade Plan Modal Opened',
  UPGRADE_PLAN: 'Suscriptions > Upgrade Plan',
}

export const SETTINGS_EVENTS = {
  COPY_API_TOKEN: 'Settings > Copy API Token',
  COPY_UNIVERSE_ID: 'Settings > Copy Universe ID',
  COPY_WORKSPACE_ID: 'Settings > Copy Workspace ID',
  FORGOT_PASSWORD: 'Settings > Forgot Password',
  GENERATE_API_TOKEN: 'Settings > Generated API Token',
  UPDATE_USER_INFO: 'Settings > Updated User Info',
  UPDATE_WORKSPACE_INFO: 'Settings > Updated Workspace Info',
}

export const PLAYGROUND_EVENTS = {
  CHANGE_PORT: 'Playground > Change Port',
  DISCORD: 'Playground > Discord',
  DOCUMENTATION: 'Playground > Documentation',
  LINK: 'Playground > Link',
}

export const IA_SOLUTION_CARD_EVENTS = {
  VIEW_MORE_BUTTON: 'IA Solution Card > View More Button',
  GET_A_DEMO: 'IA Solution Card > Get A Demo',
}

export const ONBOARDING_EVENTS = {
  SET_KIND_OF_USER: 'Onboarding > Set Kind Of User',
  CUSTOMIZE_EXPERIENCE: 'Onboarding > Customize Experience',
}
