import { handleReportParams } from 'contexts/reports/types'

interface handleDeleteReportParams
  extends Pick<handleReportParams, 'setReports'> {
  reportId: string
}

const handleDeleteReport = async (params: handleDeleteReportParams) => {
  const { setReports, reportId } = params

  setReports((prevReports) => {
    return prevReports.filter((report) => report.id !== reportId)
  })
}

export default handleDeleteReport
