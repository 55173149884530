import { OptionsMultifilter, OptionMultifilter } from '../types'

interface GetLabelsParams {
  options: OptionsMultifilter
}

const getLabels = (params: GetLabelsParams) => {
  const { options } = params

  const labels: string[] = Array.from(
    new Set(
      options.map((dataItem: OptionMultifilter) => Object.keys(dataItem)).flat()
    )
  )

  return labels
}

export default getLabels
