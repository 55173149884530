import React, { FC } from 'react'
import { useDashboardParams } from 'hooks/useDashboardParams'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'

interface ConditionalLinkProps extends RouterLinkProps {}

const isExternalLink = (url: string): boolean => /^https?:\/\//i.test(url)

const ConditionalLink: FC<ConditionalLinkProps> = ({
  to,
  children,
  ...rest
}) => {
  const { shared, token } = useDashboardParams()
  const queryParams = shared ? `?shared=true&token=${token}` : ''

  if (typeof to !== 'string') {
    return null
  }

  if (isExternalLink(to)) {
    return (
      <a href={to} {...rest}>
        {children}
      </a>
    )
  }

  const resolvedPath = `${to.startsWith('/') ? '' : '/'}${to}${queryParams}`

  return (
    <RouterLink to={resolvedPath} {...rest}>
      {children}
    </RouterLink>
  )
}

export default ConditionalLink
