import { Theme } from '@mui/material/styles'
import { LegendComponentOption } from 'echarts'
import { TEXT_STYLE, BORDER_STYLE, SHADOW_STYLE } from './general'
import { capitalize } from 'lib/strings'

interface LegendParams {
  legend?: boolean
  theme: Theme
}

const legend = (params: LegendParams): LegendComponentOption => {
  const { legend: show, theme } = params
  const CHART_OPTIONS = theme.custom.chart.options

  return {
    type: 'plain',
    id: '',
    show,
    width: 'auto',
    height: 'auto',
    align: 'left',
    itemGap: 8,
    itemWidth: 16,
    itemHeight: 16,
    formatter: (name: string) => capitalize(name),
    selectedMode: true,
    inactiveColor: '#ccc',
    inactiveBorderColor: 'transparent',
    backgroundColor: 'transparent',
    ...BORDER_STYLE,
    ...SHADOW_STYLE,
    //@ts-ignore
    icon: 'roundRect',
    itemStyle: {
      color: undefined,
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      opacity: 1,
    },
    textStyle: {
      ...TEXT_STYLE(theme),
      color: CHART_OPTIONS.text,
      fontSize: '0.75rem',
      backgroundColor: 'transparent',
      ...BORDER_STYLE,
      padding: 0,
      ...SHADOW_STYLE,
    },
    pageTextStyle: {
      ...TEXT_STYLE(theme),
      fontSize: '0.75rem',
    },
  }
}

export default legend
