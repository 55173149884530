import {
  FieldGroup,
  InputType,
  ReportFormDataset,
} from 'components/ReportForm/types'

const { checkbox } = InputType

const formatCheckBoxData = (formData: any, dataSet: any) => {
  const allCheckBoxesName = dataSet.map(({ fields }: FieldGroup) =>
    fields
      .filter(({ inputType }: ReportFormDataset) => inputType === checkbox)
      .map(({ fieldName }: ReportFormDataset) => fieldName)
  )

  if (Boolean(allCheckBoxesName)) {
    const allCheckBoxesValues = allCheckBoxesName.map(
      (checkBoxName: string) => {
        const ckeckBoxValues = Object.entries(formData)
          .flatMap(([key, value]: any) => {
            if (key.includes(`${checkBoxName}-`) && value) {
              return `${key.replace(`${checkBoxName}-`, '')}`
            }
            return false
          })
          .filter((value) => value !== false)
          .toString()
        return { [checkBoxName]: ckeckBoxValues }
      }
    )
    const checkBoxValues = Object.assign({}, ...allCheckBoxesValues)
    return { ...formData, ...checkBoxValues }
  }
  return formData
}

export default formatCheckBoxData
