import React from 'react'
import ReactEcharts from 'components/ReactEcharts'
import generateOption from './generateOption'
import { OptionParams } from './types'
import { ECHARTS_THEME_NAME } from 'theme'
import * as S from 'components/FreeEcharts/FreeEcharts.styled'

const GeneralChart = (params: OptionParams) => {
  const { data, type, optionModifications, chartOptions, isBentoBox, theme } =
    params

  const option = generateOption({
    data,
    type,
    theme,
    chartOptions,
    optionModifications,
  })

  return (
    <S.ContainerReactEcharts>
      <ReactEcharts
        option={option}
        style={{
          width: !isBentoBox ? 'calc(100% - 40px)' : '100%',
          height: !isBentoBox ? 'calc(100% - 40px)' : '100%',
          position: 'absolute',
        }}
        theme={ECHARTS_THEME_NAME}
      />
    </S.ContainerReactEcharts>
  )
}

export default GeneralChart
