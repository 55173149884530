import LoadSectionError from 'components/LoadSectionError'

export const reloadPageOnChunkError = (
  error: Error
): Promise<{ default: any }> => {
  if (
    error?.name === 'ChunkLoadError' &&
    !window.location.search.includes('update')
  ) {
    window.location.search = `update=${Date.now()}`
  }
  return Promise.resolve({ default: LoadSectionError })
}
