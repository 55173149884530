import React, { FC } from 'react'
import { Alert } from '@mui/material'
import { formatMessage } from 'i18n/ShimokuIntl'
import withErrorWrapper from 'hocs/withErrorWrapper'
import ShimokuLineChart from 'components/ShimokuLineChart/ShimokuLineChart'

const ReportLineChart: FC<WithReport> = ({
  report: { dataFields = '{}', chartData = '[]' },
}) => (
  <ShimokuLineChart
    fields={JSON.parse(dataFields)}
    data={JSON.parse(chartData)}
  />
)

const Placeholder = () => (
  <Alert severity="error">
    {formatMessage('errors.inconsistentReportData')}
  </Alert>
)

export default withErrorWrapper<WithReport>(ReportLineChart, Placeholder)
