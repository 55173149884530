import React, { useState } from 'react'
import { SelectChangeEvent, InputLabel, MenuItem } from '@mui/material'
import { FilterProps, ItemFilter } from './types'
import * as S from './filter.styled'

export const Filter = (props: FilterProps) => {
  const {
    children,
    placeholder,
    loading,
    defaultOption,
    callbackHandleChange,
    label,
    selectWidth,
    data,
    isTablet,
  } = props
  const [selectedOption, setSelectedOption] = useState(defaultOption)

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    setSelectedOption(e.target.value as string)
    //@ts-ignore
    callbackHandleChange({ id: e.target.value as string })
  }

  return (
    <>
      <S.FormControl
        variant="filled"
        style={{
          width: isTablet ? (selectWidth ? selectWidth : '100%') : '100%',
        }}
      >
        <InputLabel id="report-filter" role="label-select">
          {label}
        </InputLabel>
        <S.Select
          labelId="report-filter"
          value={selectedOption}
          onChange={handleChange}
          variant="filled"
        >
          {data.map((item: ItemFilter) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </S.Select>
      </S.FormControl>
      {!loading ? children : placeholder}
    </>
  )
}
