import React from 'react'
import customText from 'components/DataGrid/customText'
import customIcons from 'components/DataGrid/customIcons'
import GridToolbar from 'components/DataGrid/GridToolbar'
import ColumnMenu from 'components/DataGrid/ColumnMenu'
import ColumnsPanel from 'components/DataGrid/ColumnsPanel'
import FilterPanel from 'components/DataGrid/FilterPanel'
import GridFooter from 'components/DataGrid/GridFooter'
import {
  DataGrid as StyledDataGrid,
  DataGridProps as StyledDataGridProps,
} from './DataGrid.styled'

interface DataGridProps extends StyledDataGridProps {
  title?: string
  columnsButton?: boolean
  filtersButton?: boolean
  exportButton?: boolean
  exportOnClick?: () => void
  search?: boolean
  footerInfo?: string
}

const DataGridComponent = (props: DataGridProps) => {
  const {
    slots,
    title,
    toolbar,
    columnsButton,
    filtersButton,
    exportButton,
    exportOnClick,
    search,
    footer,
    footerInfo,
    loading,
    ...restProps
  } = props

  return (
    <StyledDataGrid
      {...restProps}
      toolbar={toolbar}
      footer={footer}
      localeText={customText}
      loading={loading}
      slots={{
        ...customIcons,
        //@ts-ignore
        toolbar: () => (
          <GridToolbar
            toolbar={toolbar}
            title={title}
            columnsButton={columnsButton}
            filtersButton={filtersButton}
            exportButton={exportButton}
            exportOnClick={exportOnClick}
            search={search}
          />
        ),
        columnMenu: ColumnMenu,
        columnsPanel: ColumnsPanel,
        filterPanel: FilterPanel,
        footer: () => (
          <GridFooter
            footer={footer}
            info={footerInfo}
            loading={Boolean(loading)}
          />
        ),
        ...slots,
      }}
    />
  )
}

export default DataGridComponent
