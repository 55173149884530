import { SeriesOption, RadarSeriesOption } from 'echarts'
import radarSerie from '../../defaults/series/radar'
import { SeriesParams, DataElement } from '../../types'

const series = (params: SeriesParams) => {
  const { data, seriesModifications = {}, theme } = params

  const seriesOption = radarSerie({
    typeSeriesModifications: seriesModifications as RadarSeriesOption,
    theme,
  })
  const dataWithoutName = Object.keys(data?.[0]).filter(
    (name: string) => name !== 'name'
  )
  const dataRadar = data.map((value: DataElement) => ({
    name: value?.name as string,
    value: dataWithoutName.map((name: string) => value[name] as number),
  }))

  const series = [
    {
      ...seriesOption,
      data: dataRadar,
    },
  ]

  return series as SeriesOption
}

export default series
