import React from 'react'
import { renderToString } from 'react-dom/server'
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'

const optionToContent = (option: any) => {
  const xAxisData = option.xAxis[0].data || []
  const yAxisData = option.yAxis[0].data || []
  const seriesData: [] = option.series[0].data || []

  return renderToString(
    <TableContainer>
      <Table>
        <TableBody>
          {seriesData.map((seriesDataItem) => (
            <TableRow>
              <TableCell>{xAxisData[seriesDataItem[0]]}</TableCell>
              <TableCell>{yAxisData[seriesDataItem[1]]}</TableCell>
              <TableCell>{seriesDataItem[2]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const toolboxDataView: any = {
  optionToContent,
}

export default toolboxDataView
