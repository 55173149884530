import { ScatterSeriesOption } from 'echarts'
import { TEXT_STYLE, BORDER_STYLE, SHADOW_STYLE } from '../../defaults/general'
import { TypeSeriesParams } from '../../types'

const scatterSerie = (
  params: TypeSeriesParams<ScatterSeriesOption>
): ScatterSeriesOption => {
  const { typeSeriesModifications: scatterSeriesModifications = {}, theme } =
    params

  return {
    type: 'scatter',
    coordinateSystem: 'cartesian2d',
    legendHoverLink: true,
    symbol: 'circle',
    symbolSize: 12,
    symbolRotate: 0,
    symbolOffset: [0, 0],
    cursor: 'pointer',
    selectedMode: false,
    ...scatterSeriesModifications,
    label: {
      show: false,
      //@ts-ignore
      position: 'outside',
      distance: 4,
      rotate: 0,
      offset: [0, 0],
      ...TEXT_STYLE(theme),
      fontSize: 12,
      align: 'center',
      backgroundColor: 'transparent',
      ...BORDER_STYLE,
      padding: 0,
      ...SHADOW_STYLE,
      ...scatterSeriesModifications?.label,
    },
    labelLine: {
      show: false,
      ...scatterSeriesModifications?.labelLine,
    },
    labelLayout: {
      hideOverlap: false,
      ...scatterSeriesModifications?.labelLayout,
    },
    itemStyle: {
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      ...scatterSeriesModifications?.itemStyle,
    },
    emphasis: {
      scale: true,
      focus: 'none',
      ...scatterSeriesModifications?.emphasis,
    },
  }
}

export default scatterSerie
