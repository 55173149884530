import React from 'react'
import { Alert } from '@mui/material'
import { formatMessage } from 'i18n/ShimokuIntl'

const Placeholder = () => (
  <Alert severity="error">
    {formatMessage('errors.inconsistentReportData')}
  </Alert>
)

export default Placeholder
