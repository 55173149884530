import React, { FunctionComponent } from 'react'
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material'
import { getColumnFixedClassName } from 'lib/reports'
import classNames from 'classnames'
import RadiusFillUp from 'icons/Line/radius-fill-up'
import RadiusFillDown from 'icons/Line/radius-fill-down'

interface ReportTableHeadProps {
  order: ReportTableOrder
  orderBy: string
  dataFields: ReportTableDataFields
  onSort?: (property: string) => void
}

const ReportTableHead: FunctionComponent<ReportTableHeadProps> = ({
  dataFields,
  order,
  orderBy,
  onSort,
}) => {
  const customChevron = () =>
    order === 'desc' ? (
      <RadiusFillDown style={{ marginLeft: '4px' }} />
    ) : (
      <RadiusFillUp style={{ marginLeft: '4px' }} />
    )
  const onCellClick =
    (property?: string) => (event: React.MouseEvent<unknown>) =>
      onSort && property && onSort(property)
  return (
    <TableHead>
      <TableRow>
        {Object.keys(dataFields).map((cell: string, index: number) => (
          <TableCell
            key={cell}
            align={index > 0 ? 'right' : 'left'}
            className={classNames(getColumnFixedClassName(cell))}
          >
            <TableSortLabel
              direction={order}
              disabled={!Boolean(dataFields[cell]?.field)}
              active={dataFields[cell]?.field === orderBy}
              onClick={onCellClick(dataFields[cell]?.field)}
              hideSortIcon={dataFields[cell]?.field !== orderBy}
              IconComponent={customChevron}
            >
              {cell}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default ReportTableHead
