import replaceObject from './replaceObject'
import replaceString from './replaceString'
import { isArray, isObject, isString } from 'lib/variableType'
import { ReportDataSetWithMapping, MappingObject } from './types'

interface ReplaceArrayParams {
  arrayToReplace: unknown[]
  itemsToPlace: Data | ReportDataSetWithMapping
  aggregation?: Aggregation[]
}

const replaceArray = (params: ReplaceArrayParams) => {
  const {
    arrayToReplace,
    itemsToPlace,
    aggregation = [] as Aggregation[],
  } = params

  const newArray: any[] = arrayToReplace.map((arrayItem) => {
    if (isArray(arrayItem)) {
      return replaceArray({
        arrayToReplace: arrayItem as [],
        itemsToPlace,
        aggregation,
      })
    }

    if (isObject(arrayItem)) {
      return replaceObject({
        objectToReplace: arrayItem as MappingObject,
        itemsToPlace,
        aggregation,
      })
    }

    if (isString(arrayItem)) {
      return replaceString({
        stringToReplace: arrayItem as string,
        itemsToPlace,
        aggregation,
      })
    }

    return arrayItem
  })

  return newArray
}

export default replaceArray
