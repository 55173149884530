import { TreeSeriesOption } from 'echarts'
import { BORDER_STYLE, SHADOW_STYLE, TEXT_STYLE } from '../../defaults/general'
import { TypeSeriesParams } from '../../types'

const treeSerie = (
  params: TypeSeriesParams<TreeSeriesOption>
): TreeSeriesOption => {
  const { typeSeriesModifications: treeSeriesModifications = {}, theme } =
    params
  const PALLETE = theme.palette

  return {
    type: 'tree',
    left: '10%',
    top: '10%',
    right: '10%',
    bottom: '10%',
    layout: 'orthogonal',
    orient: 'LR',
    symbol: 'emptyCircle',
    symbolSize: 8,
    symbolRotate: 0,
    edgeShape: 'curve',
    roam: false,
    expandAndCollapse: false,
    selectedMode: false,
    ...treeSeriesModifications,
    itemStyle: {
      color: PALLETE.grey['400'],
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      opacity: 1,
      ...treeSeriesModifications?.itemStyle,
    },
    label: {
      show: true,
      ...TEXT_STYLE(theme),
      fontSize: 12,
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      ...treeSeriesModifications?.label,
    },
    labelLayout: {
      hideOverlap: false,
      ...treeSeriesModifications?.labelLayout,
    },
    lineStyle: {
      color: PALLETE.grey['400'],
      width: 2,
      curveness: 0.5,
      ...SHADOW_STYLE,
      ...treeSeriesModifications?.lineStyle,
    },
    emphasis: {
      focus: 'none',
      ...treeSeriesModifications?.emphasis,
    },
  }
}

export default treeSerie
