import React from 'react'
import { formatMessage } from 'i18n/ShimokuIntl'

const customText = {
  toolbarColumns: (
    <span className="toolbar-button-text">
      {formatMessage('generic.columns')}
    </span>
  ),
  toolbarFilters: (
    <span className="toolbar-button-text">
      {formatMessage('generic.filters')}
    </span>
  ),
}

export default customText
