import React, { SVGProps } from 'react'

export const FilterA = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      width,
      height,
    }}
    fill="none"
  >
    <title>filter-a-icon</title>
    <path
      stroke={color}
      stroke-linecap="round"
      stroke-width={2}
      d="M17.046 6H6.954a2 2 0 0 0-1.857 2.743l2.4 6A2 2 0 0 0 9.354 16h5.292a2 2 0 0 0 1.857-1.257l2.4-6A2 2 0 0 0 17.046 6ZM12 16v4"
    />
  </svg>
)
