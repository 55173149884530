import { DatasetComponentOption } from 'echarts'
import { GenerateDatasetParams, DataElement } from '../../types'

const dataset = (params: GenerateDatasetParams) => {
  const { data } = params

  const dimensions = ['date', 'open', 'close', 'highest', 'lowest']
  const source = data.map((itemData: DataElement) => [
    itemData?.date,
    itemData?.open,
    itemData?.close,
    itemData?.highest,
    itemData?.lowest,
  ])

  return { dimensions, source } as DatasetComponentOption
}

export default dataset
