import getColumn from 'containers/Table/utils/generateTableColumns/getColumn'
import { TableProperties } from 'lib/reports/types'

interface GenerateTableColumnsParams {
  tableProperties: TableProperties
}

const generateTableColumns = (params: GenerateTableColumnsParams) => {
  const { tableProperties } = params
  const { columns: defaultColumns, rows } = tableProperties
  const { mapping: rowsMapping } = rows

  const orderedColumns = defaultColumns.sort((a, b) => a.order - b.order)
  const columns = orderedColumns.map((column) =>
    getColumn({ column, rowsMapping })
  )

  return columns
}

export default generateTableColumns
