import getAppName from '../getAppName'
import getAppNormalizedName from '../getAppNormalizedName'
import getPathOrder from '../getPathOrder'
import { AppRoute } from 'components/Routes/types'
import { isNullOrUndefined } from 'lib/variableType'
import { sanitizeString } from 'lib/strings'

interface GetAppRoutesParams {
  app: App
  dashboard: Dashboard
  isPublic?: boolean
  prefix?: string
}

const getAppRoutes = (params: GetAppRoutesParams) => {
  const { app, dashboard, prefix = '' } = params
  const appName = getAppName(app)
  const appNormalizedName = getAppNormalizedName(app)
  const reports = app?.reports?.items || []
  const reportsWithPath: Report[] = reports.filter(
    (report) => !isNullOrUndefined(report.path)
  )
  const reportsWithoutPath: Report[] = reports.filter((report) =>
    isNullOrUndefined(report.path)
  )

  const rootAppRoute = `${prefix}/${dashboard.id}/${sanitizeString(
    appNormalizedName
  )}`
  const rootApp: AppRoute = {
    name: appName,
    path: rootAppRoute,
    breadcrumb: [{ name: appName, path: rootAppRoute }],
    reports: reportsWithoutPath,
    appOrder: 0,
  }

  const reportsPaths = Array.from(
    new Set(reportsWithPath.map((reportWithPath) => reportWithPath.path))
  ) as string[]

  const subApps = reportsPaths
    .map((reportPath) => {
      const subAppReports = reportsWithPath.filter(
        (reportWithPath) => reportWithPath.path === reportPath
      )
      const appOrder = getPathOrder(subAppReports)
      const hidePath = subAppReports.some((report) => report.hidePath)
      const subAppRoute = `${rootAppRoute}/${sanitizeString(reportPath)}`

      return {
        name: reportPath,
        path: subAppRoute,
        breadcrumb: [
          { name: appName, path: rootAppRoute },
          { name: reportPath, path: subAppRoute },
        ],
        reports: subAppReports,
        appOrder,
        hidePath,
      } as AppRoute
    })
    .sort((pathA, pathB) => pathA.appOrder - pathB.appOrder)

  if (!rootApp.reports.length && reports.length) {
    const subAppsWithoutReportsInRootApp = subApps.map((subApp) => {
      const [rootAppBreadcrumb, supAppBreadcrumb] = subApp.breadcrumb

      return {
        ...subApp,
        breadcrumb: [
          { ...rootAppBreadcrumb, path: subApps[0].path },
          supAppBreadcrumb,
        ],
      }
    })
    const rootAppWithoutReports: AppRoute = {
      ...rootApp,
      path: subAppsWithoutReportsInRootApp[0].path,
      breadcrumb: subAppsWithoutReportsInRootApp[0].breadcrumb,
      reports: subAppsWithoutReportsInRootApp[0].reports,
    }

    return {
      allRoutes: [rootAppWithoutReports, ...subAppsWithoutReportsInRootApp],
      rootApp: rootAppWithoutReports,
      subApps: subAppsWithoutReportsInRootApp,
    }
  }

  return {
    allRoutes: [rootApp, ...subApps],
    rootApp,
    subApps,
  }
}

export default getAppRoutes
