import React from 'react'
import ReportIndicators from 'components/ReportIndicators/ReportIndicators'
import ReportBarChart from 'components/ReportBarChart/ReportBarChart'
import ReportLineChart from 'components/ReportLineChart/ReportLineChart'
import ReportStockLineChart from 'components/ReportStockLineChart'
import ReportHtml from 'containers/Html'
import Filter from 'containers/Filter'
import FilterDataSet from 'containers/FilterDataSet'
import Iframe from 'containers/Iframe'
import GeneralChart from 'containers/GeneralChart'
import Multifilter from 'containers/Multifilter'
import FreeEcharts from 'containers/FreeEcharts'
import ReportForm from 'containers/ReportForm'
import Indicator from 'containers/Indicator'
import Tabs from 'containers/Tabs'
import Button from 'containers/Button'
import { REPORT_TYPES } from 'lib/reports'
import {
  FilterDataSetProperties,
  FreeEchartsProperties,
  IndicatorProperties,
  TabsProperties,
  OldTableProperties,
  TableProperties,
  ButtonProperties,
  HistoryChartProperties,
  FormProperties,
} from 'lib/reports/types'
import HistoryChart from 'containers/HistoryChart'
import Table from 'containers/Table'

interface GetReportComponentParams {
  report: Report
  rows: number
  columns: number
  isBentoBox?: boolean
}

const getReportComponent = (
  params: GetReportComponentParams
): JSX.Element | null => {
  const { report, rows, columns, isBentoBox } = params
  const { reportProperties } = report
  const MAX_ERRORS = 20

  switch (report.reportType) {
    case REPORT_TYPES.INDICATORS:
      return <ReportIndicators report={report} maxErrors={MAX_ERRORS} />
    case REPORT_TYPES.BARCHART:
      return <ReportBarChart report={report} />
    case REPORT_TYPES.LINECHART:
      return <ReportLineChart report={report} />
    case REPORT_TYPES.STOCKLINECHART:
      return <ReportStockLineChart report={report} maxErrors={MAX_ERRORS} />
    case REPORT_TYPES.HTML:
      return <ReportHtml report={report} />
    case REPORT_TYPES.FILTER:
      return <Filter report={report} columns={columns} />
    case REPORT_TYPES.FILTERDATASET:
      return (
        <FilterDataSet
          report={report}
          reportProperties={reportProperties as FilterDataSetProperties}
        />
      )
    case REPORT_TYPES.IFRAME:
      return <Iframe report={report} />
    case REPORT_TYPES.ECHARTS:
      return <GeneralChart report={report} isBentoBox={isBentoBox} />
    case REPORT_TYPES.ECHARTS2:
      return (
        <FreeEcharts
          report={report}
          reportProperties={reportProperties as FreeEchartsProperties}
          isBentoBox={isBentoBox}
        />
      )
    case REPORT_TYPES.ANNOTATED_ECHART:
      return (
        <HistoryChart
          report={report}
          reportProperties={reportProperties as HistoryChartProperties}
        />
      )
    case REPORT_TYPES.MULTIFILTER:
      return <Multifilter report={report} />
    case REPORT_TYPES.FORM:
      return (
        <ReportForm
          report={report}
          reportProperties={reportProperties as FormProperties}
          rows={rows}
          columns={columns}
          isBentoBox={isBentoBox}
        />
      )
    case REPORT_TYPES.INDICATOR:
      return (
        <Indicator
          report={report}
          reportProperties={reportProperties as IndicatorProperties}
        />
      )
    case REPORT_TYPES.TABS:
      return (
        <Tabs
          reportProperties={reportProperties as TabsProperties}
          columns={columns}
        />
      )
    case REPORT_TYPES.BUTTON:
      return <Button properties={reportProperties as ButtonProperties} />
    case REPORT_TYPES.TABLE:
      return (
        <Table
          report={report}
          reportProperties={reportProperties as TableProperties}
          rows={rows}
        />
      )
    default:
      return (
        <Table
          report={report}
          reportProperties={
            reportProperties as TableProperties | OldTableProperties
          }
          rows={rows}
        />
      )
  }
}

export default getReportComponent
