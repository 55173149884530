import { GridFilterItem, GridLogicOperator } from '@mui/x-data-grid-pro'
import { TableProperties } from 'lib/reports/types'
import getOperator from 'containers/Table/utils/getFilter/getOperator'

interface GetFieldsFilterParams {
  items: GridFilterItem[]
  logicOperator: GridLogicOperator
  tableProperties: TableProperties
}

const getFieldsFilter = (params: GetFieldsFilterParams) => {
  const { items, logicOperator, tableProperties } = params

  if (items.some(({ value }) => !Boolean(value))) {
    return null
  }

  const {
    rows: { mapping: rowsMapping },
  } = tableProperties
  const fieldsFilter = {
    [logicOperator]: items.map((item) => {
      const { field: defaultField, operator: defaultOperator, value } = item
      const field = rowsMapping[defaultField]
      const operator = getOperator(defaultOperator)

      return { [field]: { [operator]: value } }
    }),
  }

  return fieldsFilter
}

export default getFieldsFilter
