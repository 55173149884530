import React from 'react'
import { MenuItem, SelectChangeEvent } from '@mui/material'
import generateAggregationWithOperations from 'contexts/ReportDataSets/aggregation/generateReportDataSetsAggregation/generateAggregationWithOperations'
import { OperationType } from 'contexts/ReportDataSets/aggregation/types'
import { SelectInput } from 'components/Input'
import useUpdateReportDataSets from 'contexts/ReportDataSets/hooks/useUpdateReportDataSets'
import { InputOperationEditableContainer } from './inputOperationEditable.styled'

export const AGGREGATION_OPERATIONS: AggregationOperation[] =
  Object.values(OperationType)
const { SUM } = OperationType

interface InputOperationEditableProps {
  aggregationProperty: AggregationProperty
  reportDataSets: ReportDataSet[]
}

const InputOperationEditable = (props: InputOperationEditableProps) => {
  const {
    aggregationProperty: { name, operation = SUM },
    reportDataSets: defaultReportDataSets,
  } = props
  const { updateReportsDataSets } = useUpdateReportDataSets()

  const handleChange = (e: SelectChangeEvent) => {
    const reportDataSets = defaultReportDataSets.map((reportDataSet) => {
      const { aggregationProperty = [] } = reportDataSet

      return {
        ...reportDataSet,
        aggregationProperty: aggregationProperty.map((aggregationProperty) => {
          if (aggregationProperty.name === name) {
            return {
              ...aggregationProperty,
              operation: e.target.value,
            }
          }
          return aggregationProperty
        }),
      }
    }) as ReportDataSet[]

    const reportDataSetsWithAggregationWithOperations =
      generateAggregationWithOperations({ reportDataSets })

    updateReportsDataSets({
      reportDataSets: reportDataSetsWithAggregationWithOperations,
    })
  }

  return (
    <InputOperationEditableContainer>
      <SelectInput
        label={name}
        defaultValue={operation}
        //@ts-ignore
        onChange={handleChange}
      >
        {AGGREGATION_OPERATIONS.map((AGGREGATION_OPERATION) => (
          <MenuItem key={AGGREGATION_OPERATION} value={AGGREGATION_OPERATION}>
            {AGGREGATION_OPERATION}
          </MenuItem>
        ))}
      </SelectInput>
    </InputOperationEditableContainer>
  )
}

export default InputOperationEditable
