import replaceArray from './replaceArray'
import replaceString from './replaceString'
import { isArray, isObject, isString } from 'lib/variableType'
import { ReportDataSetWithMapping, MappingArray, MappingObject } from './types'

interface ReplaceObjectParams {
  objectToReplace: { [k: string]: unknown }
  itemsToPlace: Data | ReportDataSetWithMapping
  aggregation?: Aggregation[]
}

const replaceObject = (params: ReplaceObjectParams) => {
  const {
    objectToReplace,
    itemsToPlace,
    aggregation = [] as Aggregation[],
  } = params
  let newObject = {}

  for (const property in objectToReplace) {
    const objectValue = objectToReplace[property]
    if (isArray(objectValue)) {
      newObject = {
        ...newObject,
        [property]: replaceArray({
          arrayToReplace: objectValue as MappingArray,
          itemsToPlace,
          aggregation,
        }),
      }
    }

    if (isObject(objectValue)) {
      newObject = {
        ...newObject,
        [property]: replaceObject({
          objectToReplace: objectValue as MappingObject,
          itemsToPlace,
          aggregation,
        }),
      }
    }

    if (isString(objectValue)) {
      newObject = {
        ...newObject,
        [property]: replaceString({
          stringToReplace: objectValue as string,
          itemsToPlace,
          property,
          aggregation,
        }),
      }
    }

    if (
      !isArray(objectValue) &&
      !isObject(objectValue) &&
      !isString(objectValue)
    ) {
      newObject = {
        ...newObject,
        [property]: objectValue,
      }
    }
  }

  return newObject
}

export default replaceObject
