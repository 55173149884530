import React, {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
} from 'react'
import { Auth } from 'aws-amplify'
import LoadingPage from 'components/LoadingPage/LoadingPage'
import { useHistory } from 'react-router-dom'

/**
 * TODO: convert to HOC `withAuth`, apply to `Layout`
 * Checks if user is logged in at "load" and "history change" behaviours
 */
const Authenticated: FunctionComponent<any> = (props) => {
  const [logged, setLogged] = useState<undefined | boolean>(undefined)
  const history = useHistory()

  const checkUserLogged = useCallback(async () => {
    try {
      await Auth.currentAuthenticatedUser()
      setLogged(true)
    } catch (error) {
      history.push('/sign-in')
    }
  }, [history])

  useEffect(() => {
    checkUserLogged()
    return history.listen(() => {
      checkUserLogged()
    })
  }, [history, checkUserLogged])

  return typeof logged === 'undefined' ? (
    <LoadingPage global={true} />
  ) : (
    props.children
  )
}

export default Authenticated
