import * as Sentry from '@sentry/react'
const {
  REACT_APP_RELEASE: release,
  REACT_APP_SENTRY_DSN: dsn,
  NODE_ENV: environment,
} = process.env

Sentry.init({
  dsn,
  release,
  logLevel: 1,
  environment,
  enabled: environment === 'production',
})

export const configureUserScope = (user: User) => {
  Sentry.configureScope(function (scope) {
    const { email, id, name: username } = user
    scope.setUser({ email, id, username })
  })
}

export default Sentry
