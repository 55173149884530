import { styled } from '@mui/material/styles'

interface MainProps {
  padding: boolean
  centered: boolean
  fullHeight: boolean
}

export const Main = styled('main')<MainProps>(
  ({ padding, centered, fullHeight }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    ...(padding && {
      padding: '2rem 1.25rem',
    }),
    ...(centered && {
      justifyContent: 'center',
      alignItems: 'center',
    }),
    ...(fullHeight && {
      height: '100vh',
    }),
  })
)
