import { ChartOptions } from 'theme/types'
import { GREY_PALLETE, MAIN_PALLETE } from 'theme/common/colors'
import TEXT from 'theme/common/text'

const CHART_OPTIONS: ChartOptions = {
  text: TEXT.primary,
  grid: GREY_PALLETE[200],
  axisline: TEXT.secondary,
  axislabel: TEXT.primary,
  backgroundHover: 'rgba(0,0,0,.1)',
  dataBackground: MAIN_PALLETE[500],
  controlBorderColor: 'rgba(255,255,255,1)',
  generalColor: {
    50: 'rgba(155,155,155, .1)',
    100: 'rgba(155,155,155, .1)',
    200: 'rgba(155,155,155, .2)',
    300: 'rgba(155,155,155, .3)',
    400: 'rgba(155,155,155, .4)',
    500: 'rgba(155,155,155, .5)',
    600: 'rgba(155,155,155, .6)',
    700: 'rgba(155,155,155, .7)',
  },
}

export default CHART_OPTIONS
