import { lazy } from 'react'
import { reloadPageOnChunkError } from 'lib/lazy'
import { UniverseModules } from 'redux/universeModules/types'
import { UNSETTLED } from 'pages/SignUp'
import { BasicRoute } from '../types'

export const SETTINGS: BasicRoute = {
  routeName: 'Settings',
  component: lazy(() =>
    import('pages/UserSettings').catch(reloadPageOnChunkError)
  ),
  exact: true,
}

export const SUBSCRIPTIONS: BasicRoute = {
  routeName: 'Subscriptions',
  component: lazy(() =>
    import('pages/Subscriptions').catch(reloadPageOnChunkError)
  ),
  exact: true,
}

export const BUSINESS_WITHOUT_CONTENT: BasicRoute = {
  routeName: 'Workspace Without Content',
  component: lazy(() =>
    import('pages/BusinessWithoutContent').catch(reloadPageOnChunkError)
  ),
  exact: false,
}

export const NOT_ALLOWED: BasicRoute = {
  routeName: 'Not Allowed',
  component: lazy(() =>
    import('pages/NotAllowed').catch(reloadPageOnChunkError)
  ),
  exact: false,
}

export const NOT_FOUND: BasicRoute = {
  routeName: 'Not Found',
  component: lazy(() => import('pages/NotFound').catch(reloadPageOnChunkError)),
  exact: false,
  path: '*',
}

export const ORDER_SUCCESS: BasicRoute = {
  routeName: 'Order Success',
  component: lazy(() =>
    import('pages/OrderSuccess').catch(reloadPageOnChunkError)
  ),
  exact: true,
}

export const ORDER_CANCELLED: BasicRoute = {
  routeName: 'Order Cancelled',
  component: lazy(() =>
    import('pages/OrderCancelled').catch(reloadPageOnChunkError)
  ),
  exact: true,
}

export const ON_BOARDING: BasicRoute = {
  routeName: 'On Boarding',
  component: lazy(() =>
    import('pages/OnBoarding').catch(reloadPageOnChunkError)
  ),
  exact: true,
}

export const IA_SOLUTIONS_PATH = '/ia-solutions'
export const IA_SOLUTIONS: BasicRoute = {
  routeName: 'IA Solutions',
  component: lazy(() =>
    import('pages/IASolutions').catch(reloadPageOnChunkError)
  ),
  exact: true,
  path: IA_SOLUTIONS_PATH,
}

interface GetAuthenticatedRoutesParams {
  user: User
  universeModules: UniverseModules
}

const getAuthenticatedRoutes = (params: GetAuthenticatedRoutesParams) => {
  const { user, universeModules } = params
  const { kindOfUser, name } = user
  const { SUBSCRIPTION_PLANS } = universeModules

  const SUBSCRIPTIONS_ROUTE: BasicRoute = {
    ...SUBSCRIPTIONS,
    renderConditional: SUBSCRIPTION_PLANS,
  }

  const ON_BOARDING_ROUTE: BasicRoute = {
    ...ON_BOARDING,
    renderConditional:
      Boolean(!kindOfUser) || Boolean(name.includes(UNSETTLED)),
  }

  return [
    SETTINGS,
    SUBSCRIPTIONS_ROUTE,
    BUSINESS_WITHOUT_CONTENT,
    ON_BOARDING_ROUTE,
    IA_SOLUTIONS,
    ORDER_SUCCESS,
    ORDER_CANCELLED,
    NOT_ALLOWED,
    NOT_FOUND, // This always has to be the last route
  ]
}

export default getAuthenticatedRoutes
