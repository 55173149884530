import { lazy } from 'react'
import { reloadPageOnChunkError } from 'lib/lazy'
import { USER_TYPE } from 'lib/userType'
import { BasicRoute } from '../types'

interface GetAdminRoutesParams {
  account: Account
}

const USERS_AND_ROLES_PATH = '/users-and-roles'
export const USERS_PATH = `${USERS_AND_ROLES_PATH}/users`
export const ROLES_PATH = `${USERS_AND_ROLES_PATH}/roles`
export const USERS_AND_ROLES: BasicRoute = {
  routeName: 'Users And Roles',
  component: lazy(() =>
    import('pages/UsersAndRoles').catch(reloadPageOnChunkError)
  ),
  exact: false,
  path: USERS_AND_ROLES_PATH,
}

const getAdminRoutes = (params: GetAdminRoutesParams) => {
  const { account } = params
  const { userType } = account
  const { ADMIN } = USER_TYPE
  const renderConditional = userType === ADMIN

  const USERS_AND_ROLES_ROUTE: BasicRoute = {
    ...USERS_AND_ROLES,
    renderConditional,
  }

  return [USERS_AND_ROLES_ROUTE]
}

export default getAdminRoutes
