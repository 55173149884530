import dashboardFragment from 'gql/fragments/dashboards'

const getDashboard = /* GraphQL */ `
  query getDashboard(
    $dashboardId: ID!
    $publicPermissions: DashboardPublicPermissionInputExposed
  ) {
    getDashboard(
      dashboardId: $dashboardId
      publicPermissions: $publicPermissions
    ) {
      ...dashboardFields
    }
  }
  ${dashboardFragment}
`
export default getDashboard
