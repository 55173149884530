import React, { SVGProps } from 'react'

export const Close = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill={color}
    >
      <title>close-icon</title>
      <path
        d="M7 17L9.5 14.5L12 12L14.5 9.5L17 7"
        stroke-width="2"
        stroke-linecap="round"
        stroke={color}
      />
      <path
        d="M17 17L14.5 14.5L12 12L9.5 9.5L7 7"
        stroke-width="2"
        stroke-linecap="round"
        stroke={color}
      />
    </svg>
  )
}
