import { SeriesOption } from 'echarts'
import gaugeSerie from '../../defaults/series/gauge'
import { SeriesParams, DataElement } from '../../types'

const series = (params: SeriesParams): SeriesOption => {
  const { data, seriesModifications = {}, paddingGauge, theme } = params

  const GAUGE_WIDTH = 8

  const seriesOption = gaugeSerie({
    typeSeriesModifications: {
      ...seriesModifications,
      axisLine: {
        //@ts-ignore
        lineStyle: {
          width: GAUGE_WIDTH * data.length,
        },
      },
    },
    paddingGauge,
    theme,
  })
  const GAUGE_AREA = 180

  const gap = GAUGE_AREA / data.length
  let offset: number = -GAUGE_AREA / 2 + gap / 2

  const series = [
    {
      ...seriesOption,
      data: data.map((dataElement: DataElement) => {
        const offsetDetail = offset
        offset += gap

        return {
          ...dataElement,
          detail: {
            offsetCenter: [0, offsetDetail],
            formatter: (value: string) =>
              `${dataElement?.name}: {detail|${value}}`,
          },
        }
      }),
    },
  ]

  return series as SeriesOption
}

export default series
