import React, { ReactNode, forwardRef } from 'react'
import * as S from 'components/UserMenu/UserMenu.styled'

type MenuItemRef = HTMLButtonElement
interface MenuItemProps {
  children: ReactNode
  onClick?: () => void
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
  testId?: string
  position?: 'first' | 'last'
  highlighted?: boolean
  active?: boolean
}

const MenuItem = forwardRef<MenuItemRef, MenuItemProps>((props, ref) => {
  const {
    children,
    onClick,
    leftIcon,
    rightIcon,
    testId,
    position,
    highlighted,
    active,
  } = props

  const menuItemOnClick = () => {
    onClick?.()
  }

  return (
    <S.MenuItem
      ref={ref}
      haveIcon={Boolean(leftIcon)}
      position={position}
      highlighted={highlighted}
      active={active}
      data-testid={testId}
      onClick={menuItemOnClick}
      className="ph-no-capture"
    >
      <S.MenuIcon highlighted={highlighted} position="left">
        {leftIcon}
      </S.MenuIcon>
      <S.Text component="span" variant="bodyS400">
        {children}
      </S.Text>
      <S.MenuIcon highlighted={highlighted} position="right">
        {rightIcon}
      </S.MenuIcon>
    </S.MenuItem>
  )
})

export default MenuItem
