import React, { FunctionComponent } from 'react'
import { CircularProgress } from '@mui/material'
import * as S from './Button.styled'

export interface CustomButtonProps extends S.ButtonProps {
  loading?: boolean
  recording?: boolean
  component?: FunctionComponent
  testId?: string
}

const Button: FunctionComponent<CustomButtonProps> = ({
  startIcon,
  endIcon,
  disabled,
  loading,
  recording,
  children,
  testId,
  ...restProps
}) => {
  return (
    <S.Button
      {...restProps}
      startIcon={loading ? undefined : startIcon}
      endIcon={loading ? undefined : endIcon}
      disabled={typeof disabled !== 'undefined' ? disabled : loading}
      recording={recording}
      data-testid={testId}
    >
      {loading ? <CircularProgress size={25} thickness={2} /> : children}
    </S.Button>
  )
}

export default Button
