import React, { useEffect, useState, useRef } from 'react'
import * as S from './Fab.styled'

interface FabProps {
  handleClick: () => void
  autoHide?: boolean
  color?: 'success' | 'error' | 'primary' | 'secondary'
  timeout?: number
}

const Fab: React.FC<FabProps> = ({
  handleClick,
  color = 'primary',
  autoHide = false,
  timeout = 3000,
  children,
}) => {
  const [isFabVisible, setIsFabVisible] = useState(true)
  const mouseMoveTimeout = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (autoHide) {
      handleMouseMove()
      document.addEventListener('mousemove', handleMouseMove)

      return () => {
        document.removeEventListener('mousemove', handleMouseMove)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoHide])

  const handleMouseMove = () => {
    setIsFabVisible(true)
    if (mouseMoveTimeout.current) {
      clearTimeout(mouseMoveTimeout.current)
    }
    mouseMoveTimeout.current = setTimeout(() => {
      setIsFabVisible(false)
    }, timeout)
  }

  return (
    <S.Fab onClick={handleClick} color={color} isFabVisible={isFabVisible}>
      {children}
    </S.Fab>
  )
}

export default Fab
