interface ValidObjectParams {
  object: { [s: string]: any }
  ignoreKeys?: string[]
}

const validObject = (params: ValidObjectParams): boolean => {
  const { object, ignoreKeys } = params

  const valid = Object.entries(object)
    .map(([key, value]) => {
      const ignore = ignoreKeys?.includes(key)

      if (!ignore) {
        return Boolean(value)
      }

      return false
    })
    .includes(true)

  return valid
}

export default validObject
