import { OptionsMultifilter, OptionMultifilter } from '../types'

interface GetOptionsForSelectsParams {
  labels: string[]
  options: OptionsMultifilter
}

const getOptionsForSelects = (params: GetOptionsForSelectsParams) => {
  const { labels, options } = params

  const optionsForSelects = labels.map((label: string) => ({
    label,
    options: Array.from(
      new Set(options.map((option: OptionMultifilter) => option[label]))
    ),
  }))

  return optionsForSelects
}

export default getOptionsForSelects
