import React, { CSSProperties, useRef, useEffect } from 'react'
import { ListItemText, Checkbox } from '@mui/material'
import * as S from './Select.styled'

interface SelectOptionProps {
  index: number
  style: CSSProperties
  option: string
  isMultiple: boolean
  selected: string[]
  setRowHeight: (index: number, size: number) => void
  handleClick: (option: string) => void
}

const SelectOption = (props: SelectOptionProps) => {
  const {
    index,
    style,
    option,
    isMultiple,
    selected,
    setRowHeight,
    handleClick,
  } = props
  const selectOptionRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (selectOptionRef.current) {
      setRowHeight(index, selectOptionRef.current.clientHeight)
    }
  }, [selectOptionRef]) // eslint-disable-line

  return (
    <S.MenuItem
      value={option}
      onClick={() => handleClick(option)}
      style={style}
    >
      {isMultiple && <Checkbox checked={selected.includes(option)} />}
      <ListItemText
        ref={selectOptionRef}
        primary={option}
        primaryTypographyProps={{ variant: 'bodyS400' }}
      />
    </S.MenuItem>
  )
}

export default SelectOption
