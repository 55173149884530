import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import { registerTheme } from 'echarts'
import {
  Theme,
  ThemeProvider as MaterialThemeProvider,
} from '@mui/material/styles'
import CSSVariables from 'theme/CSSVariables'
import generateEchartTheme from 'theme/lib/generateEchartTheme'
import defaultTheme from 'theme/defaultTheme'
import './normalize.css'

export const ECHARTS_THEME_NAME = 'shimoku'

interface ThemeContextValue {
  theme: Theme
  setTheme: Dispatch<SetStateAction<Theme>>
}

const ThemeContext = createContext<ThemeContextValue | undefined>(undefined)

interface ThemeProviderProps {
  children: React.ReactNode
  customTheme?: Theme
}

const ThemeProvider = (props: ThemeProviderProps) => {
  const { children, customTheme } = props
  const [theme, setTheme] = useState<Theme>(customTheme || defaultTheme)
  const echartsTheme = generateEchartTheme(theme)
  registerTheme(ECHARTS_THEME_NAME, echartsTheme)

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <CSSVariables />
      <MaterialThemeProvider theme={theme}>{children}</MaterialThemeProvider>
    </ThemeContext.Provider>
  )
}

const useTheme = () => {
  const context = useContext(ThemeContext)

  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }

  return context
}

export { ThemeProvider, useTheme }
