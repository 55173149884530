import { Breakpoint } from '@mui/material'
import { styled } from '@mui/material/styles'

export const LayoutContainer = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'column',
  minHeight: '100vh',
}))

interface ContentContainerProps {
  isSideMenuOpen: boolean
}

export const ContentContainer = styled('div')<ContentContainerProps>(
  ({ theme, isSideMenuOpen }) => ({
    transition: isSideMenuOpen
      ? `${theme.transitions.easing.easeOut} ${theme.transitions.duration.enteringScreen}ms`
      : `${theme.transitions.easing.sharp} ${theme.transitions.duration.leavingScreen}ms`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,

    [theme.breakpoints.up('md')]: {
      width: isSideMenuOpen ? 'calc(100% - var(--drawer-width))' : '100%',
      marginLeft: isSideMenuOpen ? 'var(--drawer-width)' : 0,
    },
  })
)

interface ContentProps {
  maxWidth?: Breakpoint | false
}

export const Content = styled('div')<ContentProps>(
  ({ theme, maxWidth = false }) => ({
    width: '100%',
    maxWidth: !Boolean(maxWidth)
      ? '100%'
      : theme.breakpoints.values[maxWidth as Breakpoint],
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  })
)
