import React, { useRef } from 'react'
import { Skeleton } from '@mui/material'
import { EChartsOption } from 'echarts'
import useReportDataSetsByReportDataSets from 'contexts/ReportDataSets/hooks/useReportDataSetsByReportDataSets'
import AggregationWrapper from 'containers/Aggregation/AggregationWrapper'
import isInputOperationEditable from 'containers/Aggregation/InputOperationEditable/isInputOperationEditable'
import getAggregationFromReportDataSets from 'containers/Aggregation/helpers/getAggregationFromReportDataSets'
import FreeEchartsComponent from 'components/FreeEcharts'
import ConditionalWrapper from 'hocs/conditionalWrapper'
import { generateReportDataSetsWithMapping } from 'lib/reports/reportDataSetsWithMapping'
import generateOption from 'lib/reports/generateOption'
import { FreeEchartsProperties } from 'lib/reports/types'
import { formatMessage } from 'i18n/ShimokuIntl'
import checkChartData from 'lib/reports/checkChartData'

interface FreeEchartsProps {
  report: Report
  reportProperties: FreeEchartsProperties
  isBentoBox?: boolean
}

const FreeEcharts = (props: FreeEchartsProps) => {
  const { report, reportProperties: properties, isBentoBox } = props
  const { option: defaultOption = undefined } = properties

  if (!defaultOption) {
    throw new Error(
      formatMessage('errors.freeEchartsProperties.option.undefined')
    )
  }

  const defaultReportDataSets = report.reportDataSets?.items || []
  const { reportDataSets } = useReportDataSetsByReportDataSets({
    reportDataSets: defaultReportDataSets,
  })
  const aggregation = getAggregationFromReportDataSets({ reportDataSets })
  const inputOperationEditable = isInputOperationEditable({ reportDataSets })
  const option = useRef<EChartsOption>({})

  const loading = reportDataSets.some(
    (reportDataSet) => reportDataSet.dataSet.loading === true
  )

  const hasData = reportDataSets.some(
    (reportDataSet) =>
      reportDataSet.dataSet?.data && reportDataSet.dataSet?.data.length
  )

  if (!loading && hasData) {
    const reportDataSetsWithMapping =
      generateReportDataSetsWithMapping(reportDataSets)
    option.current = generateOption({
      reportDataSets: reportDataSetsWithMapping,
      option: defaultOption,
      aggregation,
    })
  }

  const data = checkChartData({ reportDataSets, option: option.current })

  if (loading || !hasData) {
    return (
      <Skeleton
        variant="rectangular"
        height="100%"
        role="progressbar"
        data-testid="freeEchartsLoader"
      />
    )
  }

  return (
    <ConditionalWrapper
      condition={Boolean(inputOperationEditable.length)}
      wrapper={(children) => (
        <AggregationWrapper
          reportDataSets={reportDataSets}
          inputOperationEditable={inputOperationEditable}
        >
          {children}
        </AggregationWrapper>
      )}
    >
      <FreeEchartsComponent
        option={option.current}
        data={data}
        isBentoBox={isBentoBox}
      />
    </ConditionalWrapper>
  )
}

export default FreeEcharts
