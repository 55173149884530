import { API, graphqlOperation } from 'aws-amplify'
import uploadFile from '../uploadFile'
import deleteFileEntities from '../deleteFileEntities'
import createFileEntity from 'gql/mutations/createFile'
import { getErrorMessage } from 'lib/errors'
import { getSanitizedFileName } from 'lib/strings'
import Sentry from 'tracking/Sentry'

interface UploadFileEntitiesParams {
  files: File[]
  appId: string
}

const uploadFileEntities = async (params: UploadFileEntitiesParams) => {
  const { files, appId } = params

  const fileEntity = await Promise.allSettled(
    files.map(async (file) => {
      try {
        const input = {
          appId,
          contentType: file.type,
          fileName: getSanitizedFileName(file.name),
          name: file.name,
        }
        const fileEntity: any = await API.graphql(
          graphqlOperation(createFileEntity, { input })
        )

        try {
          const { status } = await uploadFile({
            file,
            url: fileEntity?.data?.createFile.url,
          })
          if (status === 200) {
            return fileEntity?.data?.createFile
          }
          return false
        } catch (error) {
          // If the file upload fails, delete the file entity
          deleteFileEntities({ files: [fileEntity?.data?.createFile] })
          Sentry.captureException(error)
          throw new Error(getErrorMessage(error, 'errors.uploadFile'))
        }
      } catch (error) {
        Sentry.captureException(error)
        throw new Error(getErrorMessage(error, 'errors.createFile'))
      }
    })
  )

  return fileEntity
}

export default uploadFileEntities
