import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

type TextProps = {
  component?: React.ElementType
  status?: 'warning' | 'ok' | 'caution'
}

type CircleProps = {
  width?: string
  height?: string
  children?: JSX.Element | JSX.Element[]
}

export const Label = styled(Typography)<TextProps>(
  ({
    status,
    theme: {
      palette: { mode },
    },
  }) => ({
    display: 'inline-block',
    borderRadius: '5px',
    padding: '0 5px',
    minWidth: '90px',
    color: 'var(--color-grey-600)',
    textAlign: 'center',
    backgroundColor: 'var(--color-grey-50)',
    border: 'solid 1px var(--color-grey-500)',
    ...(mode === 'dark' && {
      backgroundColor: 'var(--color-grey-600)',
      color: 'var(--text-primary)',
    }),
    ...(status === 'ok' && {
      color: 'var(--complementary-green)',
      borderColor: 'var(--complementary-green)',
    }),
    ...(status === 'warning' && {
      color: 'var(--complementary-orange)',
      borderColor: 'var(--color-orange)',
    }),
    ...(status === 'caution' && {
      color: 'var(--complementary-red)',
      borderColor: 'var(--complementary-red)',
    }),
    ...(status === 'ok' &&
      mode === 'dark' && {
        color: 'var(--color-warning)',
        borderColor: 'var(--color-warning)',
      }),
    ...(status === 'warning' &&
      mode === 'dark' && {
        color: 'var(--text-primary)',
        backgroundColor: 'var(--complementary-orange)',
        borderColor: 'var(--complementary-orange)',
      }),
    ...(status === 'caution' &&
      mode === 'dark' && {
        color: 'var(--text-primary)',
        backgroundColor: 'var(--complementary-red)',
        borderColor: 'var(--complementary-red)',
      }),
  })
)

export const Circle = styled('div')<CircleProps>(
  ({ width = '24px', height = '24px', color = 'var(--color-base-icon)' }) => ({
    width,
    height,
    borderColor: color,
    background: 'none',
    border: '2px solid',
    borderRadius: '50%',
    padding: '1px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '25px',
  })
)

const getCommonLinkStyles = ({ theme }: any) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
})

export const TextWithInnerLink = styled(Typography)(({ theme }) => ({
  '& a': {
    ...getCommonLinkStyles({ theme }),
  },
}))

export const Link = styled(RouterLink)(({ theme }) => ({
  ...getCommonLinkStyles({ theme }),
}))

export const ExternalLink = styled('a')(({ theme }) => ({
  ...getCommonLinkStyles({ theme }),
}))

export const TextWithLink = styled('span')(({ theme }) => ({
  '& a': {
    ...getCommonLinkStyles({ theme }),
  },
}))
