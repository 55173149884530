import { SeriesOption, ScatterSeriesOption } from 'echarts'
import scatterSerie from '../../defaults/series/scatter'
import { SeriesParams } from 'components/GeneralChart/types'

const series = (params: SeriesParams) => {
  const { data, seriesModifications = {}, axisName, theme } = params

  const seriesOption = scatterSerie({
    typeSeriesModifications: seriesModifications as ScatterSeriesOption,
    theme,
  })

  const series = Object.keys(data?.[0])
    .filter((name: string) => name !== axisName)
    .map((name: string) => ({
      ...seriesOption,
      name,
    }))

  return series as SeriesOption
}

export default series
