import { Theme } from '@mui/material'
import {
  generateLightTheme,
  lightTheme,
} from 'theme/defaultTheme/defaultThemes/light'
import { gasolinowOptions } from 'theme/defaultTheme/defaultThemes/gasolinow'
import { generateDarkTheme } from 'theme/defaultTheme/defaultThemes/dark'
import { deloitteOptions } from 'theme/defaultTheme/defaultThemes/deloitte'
import { DOMAIN_OPTIONS, ThemeDomain } from 'theme/types'

const { TAZAWA, GASOLINOW, WHISPERS, MEETING_DOCTORS, SHIMOKU, DELOITTE } =
  DOMAIN_OPTIONS

const selectTheme = ({ domain = SHIMOKU }: { domain: ThemeDomain }) => {
  if (domain?.includes(TAZAWA)) {
    return lightTheme
  }

  if (domain?.includes(GASOLINOW)) {
    return generateDarkTheme({ ...gasolinowOptions })
  }

  if (domain?.includes(WHISPERS)) {
    return generateLightTheme({ domain: WHISPERS })
  }

  if (domain?.includes(MEETING_DOCTORS)) {
    return generateLightTheme({ domain: MEETING_DOCTORS })
  }

  if (domain?.includes(DELOITTE)) {
    return generateLightTheme({ ...deloitteOptions })
  }

  return lightTheme
}

const generateTheme = (domain: ThemeDomain): Theme => {
  const theme = selectTheme({ domain })
  document.documentElement.setAttribute('data-theme', theme.palette.mode)

  return theme
}

export default generateTheme
