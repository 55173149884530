import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { Loading } from '../../types'

type State = Loading

const setLoading: CaseReducer<State, PayloadAction<Loading>> = (
  state,
  action
) => ({ ...state, ...action.payload })

export default setLoading
