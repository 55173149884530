import { AxisPointerComponentOption } from 'echarts'
import { TEXT_STYLE, LINE_STYLE, SHADOW_STYLE } from './general'
import { Theme } from '@mui/material/styles'

interface AxisPointerParams {
  axisPointer?: boolean
  theme: Theme
}

const axisPointer = (params: AxisPointerParams): AxisPointerComponentOption => {
  const { axisPointer: show, theme } = params
  const PALLETE = theme.palette

  return {
    id: '',
    show,
    type: 'line',
    snap: false,
    triggerTooltip: true,
    value: undefined,
    status: undefined,
    triggerOn: 'mousemove|click',
    label: {
      show: true,
      precision: 'auto',
      formatter: undefined,
      margin: 4,
      ...TEXT_STYLE(theme),
      fontSize: 12,
      padding: 8,
      backgroundColor: PALLETE.background.paper,
      borderColor: PALLETE.divider,
      borderWidth: 1,
      shadowBlur: 4,
      shadowColor: 'rgba(0, 0, 0, .2)',
      shadowOffsetX: 0,
      shadowOffsetY: 0,
    },
    lineStyle: {
      ...LINE_STYLE(theme),
      color: PALLETE.divider,
      type: 'dashed',
    },
    shadowStyle: {
      ...SHADOW_STYLE,
    },
    handle: {
      show: false,
      size: 48,
      margin: 40,
      ...SHADOW_STYLE,
    },
  }
}

export default axisPointer
