import React from 'react'
import { TextWithInnerLink } from 'material/global.styled'
import { formatMessage } from 'i18n/ShimokuIntl'
import { AlignTextEnum, AlignText } from 'material/types'
import URLs from 'lib/URLs'
import * as S from './Footer.styled'

const { left } = AlignTextEnum

interface FooterProps {
  align?: AlignText
}

const Footer = (props: FooterProps) => {
  const { align = left } = props

  return (
    <S.FooterContainer data-testid="footer">
      <TextWithInnerLink fontSize="1rem" align={align}>
        {new Date().getFullYear()} © <strong>Shimoku</strong> |{' '}
        <a href={URLs.privacyPolicy} target="_blank" rel="noopener noreferrer">
          {formatMessage('generic.privacyPolicy')}
        </a>
      </TextWithInnerLink>
    </S.FooterContainer>
  )
}

export default Footer
