import React, { SVGProps } from 'react'

export const Reload = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
    >
      <title>reload-icon</title>
      <path
        d="M19.6911 8.93106C18.6056 4.88007 14.4417 2.47603 10.3907 3.56149C7.23946 4.40586 5.08482 7.11306 4.7941 10.1808"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M9.47473 9.36597L4.28812 10.7557L2.89837 5.5691"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.99994 15.0768C5.10334 19.1948 9.33608 21.6385 13.454 20.5351C16.6573 19.6768 18.8476 16.9249 19.1431 13.8064"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M14.3851 14.6348L19.6575 13.222L21.0702 18.4944"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
