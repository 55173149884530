import useGraphQLOperation from 'hooks/useGraphQLOperation'
import { useSnackbar } from 'notistack'
import getReportById from 'lib/reports/getReportById'
import handleCreateReport from './handleCreateReport'
import handleDeleteReport from './handleDeleteReport'
import handleUpdateReport from './handleUpdateReport'
import { RealTimeEvent } from 'contexts/realTime/types'
import { RealTimeEventsReducerActionType } from 'contexts/realTime/realTimeEventsReducer/types'
import { setReportsType } from 'contexts/reports/types'

const useReportEventsHandler = (setReports: setReportsType) => {
  const { REPORT_UPDATED, REPORT_CREATED, REPORT_DELETED } =
    RealTimeEventsReducerActionType
  const graphQLOperation = useGraphQLOperation()
  const { enqueueSnackbar } = useSnackbar()

  const handleReportEvents = async (realTimeEvent: RealTimeEvent) => {
    const reportId = realTimeEvent.resourceId
    if (realTimeEvent.type === REPORT_DELETED) {
      handleDeleteReport({ setReports, reportId })
    } else {
      const rawReport = await getReportById({
        reportId,
        enqueueSnackbar,
        graphQLOperation,
      })
      if (!rawReport) return

      if (realTimeEvent.type === REPORT_CREATED)
        handleCreateReport({ setReports, rawReport })
      if (realTimeEvent.type === REPORT_UPDATED)
        handleUpdateReport({ setReports, rawReport })
    }
  }
  return { handleReportEvents }
}

export default useReportEventsHandler
