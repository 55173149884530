import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker'

export enum FILTER_TYPES {
  DATERANGE = 'DATERANGE',
  CATEGORICAL_MULTI = 'CATEGORICAL_MULTI',
  CATEGORICAL_SINGLE = 'CATEGORICAL_SINGLE',
  NUMERIC = 'NUMERIC',
}
export enum OPERATIONS {
  CONTAINS = 'contains',
  EQ = 'eq',
}

export interface FilterInput {
  field: string
  inputType: FILTER_TYPES
  operations: OPERATIONS[]
  options?: string[]
}

export interface FilterMapping {
  id: string
  [keyField: string]: string
}

export type FilterDefaultValue = DateRange<Date>

export type FilterValueDateRange = [string, string]
type FilterValueCategoricalMulti = string[]

export type FilterValue =
  | FilterValueDateRange
  | FilterValueCategoricalMulti
  | string
  | number
  | undefined
  | null

export interface FilterValues {
  [k: string]: FilterValue
}

export interface Filter {
  dataSetId: string
  filter: {}
}
