import { styled } from '@mui/styles'
import { Theme } from '@mui/material'
import { inlineReports } from 'components/Reports/GridReports/GridReport/utils/isInlineReport'

interface ReportGridItemWrapperProps {
  theme: Theme
  reportType?: ReportType
  inlineReport: boolean
  cols: number
  rows: number
  rowHeight: number
  rowGap: number
  gridColumns: number
}

export const ReportGridItemWrapper = styled('div')(
  ({
    theme,
    reportType,
    inlineReport,
    cols,
    rows,
    rowHeight: defaultRowHeight,
    rowGap,
    gridColumns,
  }: ReportGridItemWrapperProps) => {
    const ROW_HEIGHT = `${defaultRowHeight * rows + rowGap * (rows - 1)}px`

    return {
      display: 'flex',
      alignItems: 'stretch',
      minHeight: !inlineReport ? ROW_HEIGHT : 'auto',
      maxHeight: cols < gridColumns ? ROW_HEIGHT : 'auto',
      [theme.breakpoints.down('md')]: {
        minHeight: !inlineReports.some(
          (inlineReport) => inlineReport === reportType
        )
          ? ROW_HEIGHT
          : 'auto',
        maxHeight: 'none',
      },
    }
  }
)
