import getFiles from 'containers/ReportForm/submitReportForm/getFiles'
import {
  FormDataSubmittedFiles,
  FormData,
  FormDataArrayFilesData,
} from '../../types'
import { FileData } from 'components/Input/types'

interface GetDeletedFilesParams {
  formDataWithFiles: FormDataSubmittedFiles
  defaultFormValues: FormData
}

const getDeletedFiles = (params: GetDeletedFilesParams) => {
  const { formDataWithFiles, defaultFormValues } = params

  const defaultFormValuesWithFiles = getFiles({
    formData: defaultFormValues,
  }) as FormDataArrayFilesData
  const deletedFiles = defaultFormValuesWithFiles.reduce(
    (deletedFiles, [key, defaultFilesData]) => {
      const newDeletedFile = defaultFilesData.filter(
        (defaultFileData) =>
          !formDataWithFiles[key].some(
            (fileData) =>
              fileData.id === defaultFileData.id &&
              fileData.name === defaultFileData.name &&
              fileData.URL === defaultFileData.URL
          )
      )

      return [...deletedFiles, ...newDeletedFile]
    },
    [] as FileData[]
  )

  return deletedFiles
}

export default getDeletedFiles
