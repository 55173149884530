interface GetDefaultOrderParams {
  dataFields: ReportTableDataFields
}

const getDefaultOrder = (params: GetDefaultOrderParams) => {
  const { dataFields } = params
  const DEFAULT_ORDER = 'defaultOrder'

  const [defaultOrder] = Object.keys(dataFields).filter((cell: string) => {
    if (Boolean(dataFields[cell])) {
      return Object.keys(dataFields[cell] as ReportTableDataField).some(
        (cellProperty: string) => cellProperty === DEFAULT_ORDER
      )
    }

    return false
  })

  return dataFields[defaultOrder] as ReportTableDataField | undefined
}

export default getDefaultOrder
