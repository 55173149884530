import { Auth } from 'aws-amplify'

const {
  REACT_APP_BACKEND_URL,
  REACT_APP_BUCKET: bucket,
  REACT_APP_AWS_REGION: region,
  REACT_APP_AWS_POOL_DOMAIN: domain,
  REACT_APP_AWS_OAUTH_REDIRECT_SIGN_IN: redirectSignIn,
  REACT_APP_AWS_OAUTH_REDIRECT_SIGN_OUT: redirectSignOut,
  REACT_APP_AWS_USER_POOL_ID: userPoolId,
  REACT_APP_AWS_IDENTITY_POOL_ID: identityPoolId,
  REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID: userPoolWebClientId,
  REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT: aws_appsync_graphqlEndpoint,
  REACT_APP_AWS_API_KEY: aws_appsync_apiKey,
} = process.env

const awsConfig = {
  aws_appsync_graphqlEndpoint,
  aws_appsync_region: region,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey,
  Auth: {
    region,
    userPoolId,
    identityPoolId,
    userPoolWebClientId,
    mandatorySignIn: false,
    oauth: {
      domain,
      redirectSignIn,
      redirectSignOut,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: 'DashboardBackend',
        endpoint: REACT_APP_BACKEND_URL,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          }
        },
      },
    ],
  },
  Storage: {
    bucket,
    region,
    identityPoolId,
    level: 'protected',
  },
}

export default awsConfig
