import { REPORT_TYPES } from 'lib/reports'
import { TabsProperties } from 'lib/reports/types'

const { TABS } = REPORT_TYPES

interface LinkReportsToTabsParams {
  reports: Report[]
}

const linkReportsToTabs = (params: LinkReportsToTabsParams) => {
  const { reports } = params

  const tabsReports = reports.filter((report) => report.reportType === TABS)

  if (!tabsReports.length) {
    return reports
  }

  const reportsByParentTabIds = tabsReports.reduce(
    (reportsByParentTabIds, tabReport) => {
      const { id: parentTabId, reportProperties } = tabReport

      const { tabs = {} }: TabsProperties = reportProperties
      const tabsReportIds = Object.values(tabs)
        .map(({ reportIds }) => reportIds)
        .flat()

      return { ...reportsByParentTabIds, [parentTabId]: tabsReportIds }
    },
    {} as Record<string, string[]>
  )

  const reportsLinkedToTabs = reports.map((report) => {
    const { id: reportId } = report

    for (const [parentTabId, tabReportIds] of Object.entries(
      reportsByParentTabIds
    )) {
      const reportLinkedToTabs = tabReportIds.some(
        (tabReportId) => tabReportId === reportId
      )

      if (reportLinkedToTabs) {
        return {
          ...report,
          parentTabId,
        }
      }
    }

    return report
  })

  return reportsLinkedToTabs
}

export default linkReportsToTabs
