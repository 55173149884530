interface SumParams {
  data: number[]
}

const sum = (params: SumParams) => {
  const { data } = params

  return data.reduce((sum, dataItem) => sum + dataItem, 0)
}

export default sum
