import React, { Dispatch, SetStateAction, FC } from 'react'
import {
  FILTER_TYPES,
  FilterValues,
  FilterInput,
  FilterDefaultValue,
} from 'containers/FilterDataSet/types'
import DateRangePicker from 'components/DateRangePicker'
import { handleDateRange, handleCategoricalFilter } from './filterHandlers'
import { formatMessage } from 'i18n/ShimokuIntl'
import { Select } from 'components/Input'
import { NumericFilter } from './NumericFilter'
import { NumericFilterOperatorType } from 'containers/Table/types'

const { DATERANGE, CATEGORICAL_MULTI, CATEGORICAL_SINGLE, NUMERIC } =
  FILTER_TYPES

interface FilterInputRendererProps {
  filter: FilterInput
  setFilterValues: Dispatch<SetStateAction<FilterValues>>
  setOperator: Dispatch<SetStateAction<NumericFilterOperatorType>>
}

const handleDateChange = (
  value: FilterDefaultValue,
  filter: FilterInput,
  setFilterValues: Dispatch<SetStateAction<FilterValues>>
) => {
  handleDateRange({ value, filter, setFilterValues })
}

const FilterInputRenderer: FC<FilterInputRendererProps> = ({
  filter,
  setFilterValues,
  setOperator,
}) => {
  const defaultOption = filter.options?.length ? filter.options[0] : ''

  switch (filter.inputType) {
    case DATERANGE:
      return (
        <DateRangePicker
          getCurrentRange={(value) =>
            handleDateChange(value, filter, setFilterValues)
          }
        />
      )
    case CATEGORICAL_MULTI:
    case CATEGORICAL_SINGLE:
      return (
        <Select
          options={filter.options || []}
          labelId={filter.field}
          label={filter.field}
          id={filter.field}
          defaultValue={defaultOption}
          value={[defaultOption]}
          fullWidth
          name={filter.field}
          handleInputChange={(values) =>
            handleCategoricalFilter({ ...values, filter, setFilterValues })
          }
          isMultiple={filter.inputType === CATEGORICAL_MULTI}
        />
      )
    case NUMERIC:
      return (
        <NumericFilter
          filter={filter}
          setFilterValues={setFilterValues}
          setOperator={setOperator}
        />
      )
    default:
      throw new Error(formatMessage('errors.invalidFilterDataSetType'))
  }
}

export default FilterInputRenderer
