import { useSnackbar } from 'notistack'
import useGraphQLOperation from 'hooks/useGraphQLOperation'
import { Filter } from 'containers/FilterDataSet/types'
import { useReportDataSets } from '../index'
import getReportDataSetsListData from '../getReportDataSetsListData'
import generateReportDataSetsAggregation from '../aggregation/generateReportDataSetsAggregation'
import { formatMessage } from 'i18n/ShimokuIntl'
import { UpdateReportDataSetDto } from 'containers/ReportForm/types'
import { useDashboardParams } from 'hooks/useDashboardParams'

interface UpdateReportDataSetsParams {
  reportDataSets: ReportDataSet[]
}

interface UpdateReportDataSetsByDataSetsParams {
  filters: Filter[]
}

const useUpdateReportDataSets = () => {
  const { reportDataSets, setReportDataSets, reportDataSetsByDataSetIds } =
    useReportDataSets()
  const { enqueueSnackbar } = useSnackbar()
  const graphQLOperation = useGraphQLOperation()
  const { token, dashboardId, shared } = useDashboardParams()

  const updateReportsDataSets = (params: UpdateReportDataSetsParams): void => {
    const { reportDataSets: reportDataSetsToUpdate } = params
    const updatedReportDataSets = reportDataSets.map((reportDataSet) => {
      const foundDataSet = reportDataSetsToUpdate.find(
        (toUpdate) => toUpdate.id === reportDataSet.id
      )
      return foundDataSet || reportDataSet
    })
    setReportDataSets(updatedReportDataSets)
  }

  const updateReportsDataSetsByDataSetIds = (
    params: UpdateReportDataSetsByDataSetsParams
  ): void => {
    const { filters } = params

    const reportDataSetsToUpdateByDataSetIds = filters.flatMap(
      ({ dataSetId, filter }) => {
        const dataSetData = reportDataSetsByDataSetIds[dataSetId]
        if (!dataSetData) return []
        return dataSetData.map((dataSet) => ({
          ...dataSet,
          dataSet: {
            ...dataSet.dataSet,
            loading: true,
            filter,
          },
        }))
      }
    )

    updateReportsDataSets({
      reportDataSets: reportDataSetsToUpdateByDataSetIds,
    })

    getReportDataSetsListData({
      graphQLOperation,
      reportDataSets: reportDataSetsToUpdateByDataSetIds,
      publicPermissions: { token, dashboardId },
      shared,
    }).then(({ rejectedReportDataSets, fulfilledReportDataSets }) => {
      rejectedReportDataSets.forEach((rejectedDataSet) => {
        const errorMessage = rejectedDataSet.dataSet.errorMessage as string
        enqueueSnackbar(formatMessage('errors.listData', { errorMessage }), {
          variant: 'error',
        })
      })

      const reportDataSetsWithAggregations = generateReportDataSetsAggregation({
        reportDataSets: fulfilledReportDataSets,
      })

      updateReportsDataSets({ reportDataSets: reportDataSetsWithAggregations })
    })
  }

  const updateReportsDataSetByIdOnMemory = (
    dataSetId: string,
    dataToUpdate: UpdateReportDataSetDto
  ): ReportDataSet[] => {
    const reportDataSetToUpdate = reportDataSets.find(
      (reportDataSet) => reportDataSet.dataSet.id === dataSetId
    )

    if (!reportDataSetToUpdate) {
      throw new Error('ReportDataSet not found')
    }

    if (!reportDataSetToUpdate.dataSet.data) {
      throw new Error('Data in ReportDataSet is undefined')
    }

    const updatedDataOfDataset = reportDataSetToUpdate.dataSet.data.map(
      (data) => {
        if (data.id === dataToUpdate.id) {
          return {
            ...data,
            ...dataToUpdate,
          }
        }
        return data
      }
    )

    const updatedReportDataSet = {
      ...reportDataSetToUpdate,
      dataSet: {
        ...reportDataSetToUpdate.dataSet,
        data: updatedDataOfDataset,
      },
    }
    const updatedReportDataSets = reportDataSets.map((reportDataSet) => {
      if (reportDataSet.dataSet.id === dataSetId) {
        return updatedReportDataSet
      }
      return reportDataSet
    })

    setReportDataSets(updatedReportDataSets)
    return updatedReportDataSets
  }

  return {
    updateReportsDataSetsByDataSetIds,
    updateReportsDataSets,
    updateReportsDataSetByIdOnMemory,
  }
}

export default useUpdateReportDataSets
