import { ROW_HEIGHT } from 'components/Grid/utils/defaultValues'

interface GetRowHeightParams {
  isBentoBox?: boolean
}

const getRowHeight = ({ isBentoBox }: GetRowHeightParams) =>
  isBentoBox ? 16 : ROW_HEIGHT

export default getRowHeight
