import React, { ReactNode, Suspense, lazy } from 'react'
import usePlaygroundIsRunning from 'hooks/usePlaygroundIsRunning'
import LoadingPage from 'components/LoadingPage/LoadingPage'
import { reloadPageOnChunkError } from 'lib/lazy'

const ApolloProvider = lazy(() =>
  import('contexts/apollo').catch(reloadPageOnChunkError)
)

interface WithGraphQLProps {
  children: ReactNode
}

const WithGraphQL = (props: WithGraphQLProps) => {
  const { children } = props
  const playgroundIsRunning = usePlaygroundIsRunning()

  if (Boolean(playgroundIsRunning)) {
    return (
      <Suspense fallback={<LoadingPage global={true} />}>
        <ApolloProvider>{children}</ApolloProvider>
      </Suspense>
    )
  }

  return <>{children}</>
}

export default WithGraphQL
