import React from 'react'
import { Link } from 'react-router-dom'
import ConditionalWrapper from 'hocs/conditionalWrapper'
import * as S from './Crumb.styled'

interface CrumbProps {
  text: string
  href: string
  last: boolean
}

const Crumb = (props: CrumbProps) => {
  const { text, href, last } = props

  return (
    <ConditionalWrapper
      condition={!last}
      wrapper={(children) => <Link to={href}>{children}</Link>}
    >
      <S.CrumbText variant="body2" last={last}>
        {text}
      </S.CrumbText>
    </ConditionalWrapper>
  )
}

export default Crumb
