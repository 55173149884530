import React, { useEffect } from 'react'
import {
  Route as RouteRRD,
  RouteProps as RouteRRDProps,
  useLocation,
} from 'react-router-dom'
import { useNavigation } from 'contexts/navigation'
import { Crumb } from 'contexts/navigation/types'

export interface RouteProps extends RouteRRDProps {
  breadcrumb: Crumb[]
}

const Route = (props: RouteProps) => {
  const { exact, path, component, breadcrumb } = props
  const { setBreadcrumb } = useNavigation()
  const { pathname } = useLocation()

  useEffect(() => {
    setBreadcrumb(breadcrumb)
  }, [pathname]) // eslint-disable-line

  return <RouteRRD exact={exact} path={path} component={component} {...props} />
}

export default Route
