import React from 'react'
import AuthForm from 'components/AuthForm/AuthForm'
import { formatMessage } from 'i18n/ShimokuIntl'
import { signUpStepOneFields, signUpStepTwoFields } from 'lib/forms/formFields'
import { Box, Divider, Typography } from '@mui/material'
import { SignUpFormValues } from 'pages/SignUp'
import * as S from './SignUp.styled'
import * as GS from '../../material/global.styled'

interface SignUpStepProps {
  handleSubmit: (values: Partial<SignUpFormValues>) => void
  goToStepOne?: () => void
  sendedLink?: boolean
}

interface GoBackButtonProps {
  goToStepOne: () => void
}

const SignInLink = () => (
  <Typography align="center" mb="1.5rem">
    {formatMessage('page.signUp.signIn.text')}{' '}
    <GS.Link to="/sign-in">{formatMessage('page.signUp.signIn.link')}</GS.Link>
  </Typography>
)

const GoBackButton: React.FC<GoBackButtonProps> = ({ goToStepOne }) => (
  <S.GoBack align="center" mt="1.5rem" onClick={goToStepOne}>
    {formatMessage('generic.goBack')}
  </S.GoBack>
)

export const SignUpStepOne = ({ handleSubmit }: SignUpStepProps) => (
  <AuthForm
    fields={signUpStepOneFields}
    onSubmitForm={handleSubmit}
    submitButtonLabel={formatMessage('generic.next')}
    formHeading={formatMessage('generic.createAccount')}
  >
    <SignInLink />
  </AuthForm>
)

export const SignUpStepTwo = ({
  handleSubmit,
  goToStepOne,
  sendedLink,
}: SignUpStepProps) => (
  <AuthForm
    fields={signUpStepTwoFields}
    formHeading={formatMessage('generic.createAccount')}
    onSubmitForm={handleSubmit}
    page="signUp"
    showSuccess={sendedLink}
    submitButtonLabel={formatMessage('generic.signUp')}
  >
    {!sendedLink && (
      <Box mb={2}>
        <GS.TextWithLink
          style={{
            fontSize: 'var(--note400-font-size)',
          }}
          dangerouslySetInnerHTML={{
            __html: formatMessage('page.signUp.termsAndConditions.text'),
          }}
        />
      </Box>
    )}
    <SignInLink />
    <Divider />
    {goToStepOne && <GoBackButton goToStepOne={goToStepOne} />}
  </AuthForm>
)
