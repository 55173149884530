import { SeriesOption, TreeSeriesOption } from 'echarts'
import treeSerie from '../../defaults/series/tree'
import { SeriesParams } from '../../types'

const series = (params: SeriesParams): SeriesOption => {
  const { data, seriesModifications, theme } = params

  const seriesOption = treeSerie({
    typeSeriesModifications: seriesModifications as TreeSeriesOption,
    theme,
  })

  const series = [
    {
      ...seriesOption,
      data,
    },
  ]

  return series as SeriesOption
}

export default series
