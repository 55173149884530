import { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery, Breakpoint } from '@mui/material'

export const useComponentState = (initialState: any) => {
  const [state, setState] = useState(initialState)
  return [
    state,
    function (params: any) {
      setState({ ...state, ...params })
    },
  ]
}

interface LayoutStateProps {
  matches: boolean
}

export const useLayoutState = (key: Breakpoint = 'md'): LayoutStateProps => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up(key), { noSsr: true })
  return {
    matches,
  }
}
