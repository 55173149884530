import dataFragment from 'gql/fragments/data'

const listData = /* GraphQL */ `
  ${dataFragment}
  query listData(
    $dataSetId: ID!
    $from: Int
    $limit: Int
    $nextToken: String
    $filter: SearchableDataFilterInput
    $sort: SearchableDataSortInput
    $publicPermissions: DashboardPublicPermissionInputExposed
  ) {
    listData(
      dataSetId: $dataSetId
      from: $from
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sort: $sort
      publicPermissions: $publicPermissions
    ) {
      items {
        ...DataFields
      }
      total
      nextToken
    }
  }
`

export default listData
