import React, { SVGProps } from 'react'

export const ChevronDown = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
  style,
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width,
        height,
        ...style,
      }}
      viewBox="0 0 24 24"
      fill="none"
    >
      <title>chevron-down-icon</title>
      <path
        d="M6 9L12 15L18 9"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
