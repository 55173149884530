import { isNullOrUndefined } from 'lib/variableType'

const getPathOrder = (reports: Report[]) => {
  const reportsPathOrder = reports
    .filter((report) => !isNullOrUndefined(report.pathOrder))
    .map((report) => report.pathOrder) as number[]

  if (Boolean(reportsPathOrder.length)) {
    return Math.min(...reportsPathOrder)
  }

  const reportsOrder = reports
    .filter((report) => !isNullOrUndefined(report.order))
    .map((report) => report.order)

  return Math.min(...reportsOrder)
}

export default getPathOrder
