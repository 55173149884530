import { styled } from '@mui/material/styles'

type WrapperProps = {
  align: string
}

type ContainerProps = {
  columns: number
}

export const Wrapper = styled('div')<WrapperProps>(
  ({ align, theme: { breakpoints } }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: align,
    width: 'calc(100% + var(--gap-indicators))',
    margin:
      'calc(-1 * var(--gap-indicators)) 0 0 calc(-1 * var(--gap-indicators))',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  })
)

interface IndicatorWidth {
  columns: number
}

const indicatorWidthDesktop = ({ columns }: IndicatorWidth) =>
  `calc(${100 / columns}% - var(--gap-indicators))`

const indicatorWidthTablet = ({ columns }: IndicatorWidth) =>
  columns === 1
    ? 'calc(100% - var(--gap-indicators))'
    : 'calc(50% - var(--gap-indicators))'

const indicatorWidthMobile = 'calc(100% - var(--gap-indicators))'

export const Container = styled('div')<ContainerProps>(
  ({ columns, theme: { breakpoints } }) => ({
    borderRadius: 'var(--border-radius-m)',
    width: indicatorWidthDesktop({ columns }),
    maxWidth: indicatorWidthDesktop({ columns }),
    margin: 'var(--gap-indicators) 0 0 var(--gap-indicators)',
    boxShadow: 'var(--box-shadow-l)',
    [breakpoints.down('lg')]: {
      width: indicatorWidthTablet({ columns }),
      maxWidth: indicatorWidthTablet({ columns }),
      minWidth: indicatorWidthTablet({ columns }),
    },
    [breakpoints.down('md')]: {
      width: indicatorWidthMobile,
      maxWidth: indicatorWidthMobile,
      minWidth: indicatorWidthMobile,
    },
  })
)
