import React, { FC, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import dayjs from 'dayjs'
import { Box, Dialog, DialogActions, Slider, Typography } from '@mui/material'
import { Select, TextAreaInput } from 'components/Input'
import Button from 'components/Button'
import * as S from './annotationModal.styled'
import DateRangePicker from 'components/DateRangePicker'
import { DateRange } from '@mui/x-date-pickers-pro'
import { formatMessage } from 'i18n/ShimokuIntl'
import { SliderProperties } from 'lib/reports/types'

export interface Annotation {
  dataId?: string
  category: string
  date: Date[]
  text: string
  intensity: number
}

export interface DefaultAnnotationFormValues {
  category: string
  date?: Date[]
  text?: string
  intensity?: number
  dataId?: string
}

export interface AnnotationModalProps {
  onSubmit: (annotation: Annotation) => void
  categories: string[]
  closeModal: () => void
  isOpen: boolean
  isEditing?: boolean
  defaultFormValues: DefaultAnnotationFormValues
  slider?: SliderProperties
  handleDelete?: () => void
  hasEditPermission?: boolean
}

export const AnnotationModal: FC<AnnotationModalProps> = ({
  categories,
  closeModal,
  isOpen,
  onSubmit,
  handleDelete,
  defaultFormValues,
  slider,
  isEditing = false,
  hasEditPermission = true,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid, isDirty },
    setValue,
    reset,
  } = useForm<Annotation>({
    defaultValues: defaultFormValues,
    mode: 'onChange',
  })

  useEffect(() => {
    reset(defaultFormValues)
  }, [defaultFormValues, reset])

  const handleInputChange = ({ value, eventName }: any) => {
    setValue(eventName, value, { shouldDirty: true })
  }

  const formatDate = (value: DateRange<Date>) => {
    const [defaultStartDate, defaultEndDate] = value
    const startDate = defaultStartDate
      ? dayjs(defaultStartDate).utc().format()
      : null
    const endDate = defaultEndDate ? dayjs(defaultEndDate).utc().format() : null
    if (startDate && endDate) {
      return [startDate, endDate]
    }
    return [undefined, undefined]
  }
  return (
    <Dialog
      onClose={closeModal}
      open={isOpen}
      data-testid="annotation-modal"
      PaperProps={{
        sx: {
          padding: 3,
        },
      }}
    >
      <Typography component="h6" variant="bodyM600" marginBottom={2}>
        {formatMessage('component.annotationModal.new')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="category"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              fullWidth
              labelId={`${field.name}-label`}
              label={field.name}
              id={field.name}
              defaultValue={categories[0]}
              value={[categories[0]]}
              options={categories}
              disabled={!hasEditPermission || categories.length === 1}
              handleInputChange={handleInputChange}
            />
          )}
        />
        {errors.category && <p>{formatMessage('generic.required')}</p>}
        <S.MarginTopBox>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <DateRangePicker
                {...field}
                disabled={!hasEditPermission}
                getCurrentRange={(value: DateRange<Date>) =>
                  field.onChange(formatDate(value))
                }
                dateRange={
                  field.value ? [field.value[0], field.value[1]] : undefined
                }
              />
            )}
          />
        </S.MarginTopBox>
        <S.MarginTopBox>
          <p>{formatMessage('component.annotationModal.intensity')}</p>
          <Controller
            name="intensity"
            control={control}
            rules={{ required: true }}
            defaultValue={slider?.defaultValue || 5}
            render={({ field }) => (
              <Slider
                {...field}
                aria-label="Default"
                valueLabelDisplay="auto"
                disabled={!hasEditPermission}
                max={slider?.max || 10}
                marks={slider?.marks}
              />
            )}
          />
        </S.MarginTopBox>
        <S.MarginTopBox>
          <Controller
            name="text"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextAreaInput
                {...field}
                fullWidth
                maxRows={20}
                disabled={!hasEditPermission}
                defaultValue={field.value}
                placeholder={formatMessage('component.annotationModal.addText')}
              />
            )}
          />
        </S.MarginTopBox>
        <DialogActions sx={{ paddingTop: 2, justifyContent: 'space-between' }}>
          <Button type="button" onClick={closeModal} inverted size="small">
            {formatMessage('generic.cancel')}
          </Button>
          <Box>
            {isEditing && (
              <Button
                type="button"
                size="small"
                onClick={handleDelete}
                status="warning"
                variant="text"
                disabled={!hasEditPermission}
              >
                {formatMessage('generic.delete')}
              </Button>
            )}
            <Button
              type="submit"
              size="small"
              disabled={
                !hasEditPermission ||
                isSubmitting ||
                !isDirty ||
                (!isValid && isDirty)
              }
              loading={isSubmitting}
              sx={{ marginLeft: 1 }}
            >
              {isEditing
                ? formatMessage('generic.edit')
                : formatMessage('generic.add')}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  )
}
