import React, { SVGProps } from 'react'

export const Bright = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
    >
      <title>bright-icon</title>
      <rect width="24" height="24" fill="none" />
      <path
        d="M12.9464 3.77107C12.6382 2.86863 11.3619 2.86863 11.0537 3.77107L9.35857 8.73514C9.25851 9.02814 9.02839 9.25827 8.73539 9.35832L3.77132 11.0535C2.86887 11.3617 2.86887 12.638 3.77132 12.9462L8.73539 14.6413C9.02839 14.7414 9.25851 14.9715 9.35857 15.2645L11.0537 20.2286C11.3619 21.131 12.6382 21.131 12.9464 20.2286L14.6416 15.2645C14.7416 14.9715 14.9718 14.7414 15.2648 14.6413L20.2288 12.9462C21.1313 12.638 21.1313 11.3617 20.2288 11.0535L15.2648 9.35832C15.2464 9.35207 15.2284 9.34531 15.2106 9.33806C14.9435 9.22926 14.7354 9.00983 14.6416 8.73515L12.9464 3.77107Z"
        fill={color}
      />
    </svg>
  )
}
