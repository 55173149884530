import React, { SVGProps } from 'react'

export const NotFoundError = ({
  width = '395',
  height = '322',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>not-found-error</title>
      <circle cx="204" cy="155" r="127" fill="white" />
      <rect
        x="25"
        y="6"
        width="75"
        height="48"
        rx="15"
        fill="#202A36"
        stroke="#202A36"
        stroke-width="2"
      />
      <rect x="339" y="34" width="31" height="15" rx="7.5" fill="#ED5627" />
      <rect
        x="370"
        y="26"
        width="56"
        height="15"
        rx="7.5"
        transform="rotate(-180 370 26)"
        fill="#202A36"
      />
      <rect
        x="34"
        y="14"
        width="75"
        height="48"
        rx="15"
        fill="white"
        stroke="#202A36"
        stroke-width="2"
      />
      <path
        d="M55.6666 53.3335L62.8541 46.146L70.0416 38.9585L77.2291 31.771L84.4166 24.5835"
        stroke="#202A36"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M72.9166 36.5962C74.0624 37.259 74.8333 38.4978 74.8333 39.9167C74.8333 42.0338 73.117 43.75 70.9999 43.75C69.5811 43.75 68.3422 42.9791 67.6794 41.8333"
        stroke="#202A36"
        stroke-width="2"
      />
      <path
        d="M71 30.3335C62.5316 30.3335 55.6666 38.0002 55.6666 39.9168C55.6666 40.5774 56.482 41.9209 57.8974 43.3982M77.7083 31.8821C82.8147 34.2254 86.3333 38.5447 86.3333 39.9168C86.3333 41.8335 79.4683 49.5002 71 49.5002C67.968 49.5002 65.1417 48.5174 62.7623 47.1677"
        stroke="#202A36"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M57.5833 36.0832L55.6666 34.1665"
        stroke="#202A36"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M62.3112 27.9693C62.0642 27.4753 61.4635 27.2751 60.9695 27.5221C60.4756 27.7691 60.2753 28.3697 60.5223 28.8637L62.3112 27.9693ZM64.2278 31.8026L62.3112 27.9693L60.5223 28.8637L62.439 32.6971L64.2278 31.8026Z"
        fill="#202A36"
      />
      <path
        d="M72 26.5C72 25.9477 71.5523 25.5 71 25.5C70.4477 25.5 70 25.9477 70 26.5H72ZM72 30.3333V26.5H70V30.3333H72Z"
        fill="#202A36"
      />
      <path
        d="M87.0405 34.8736C87.431 34.4831 87.431 33.8499 87.0405 33.4594C86.65 33.0689 86.0168 33.0689 85.6263 33.4594L87.0405 34.8736ZM85.1239 36.7903L87.0405 34.8736L85.6263 33.4594L83.7096 35.3761L85.1239 36.7903Z"
        fill="#202A36"
      />
      <path
        d="M338.772 251.311C339.131 250.952 339.619 250.75 340.127 250.75H353.873C354.381 250.75 354.869 250.952 355.228 251.311L363.689 259.772C364.048 260.131 364.25 260.619 364.25 261.127V274.873C364.25 275.381 364.048 275.869 363.689 276.228L355.228 284.689C354.869 285.048 354.381 285.25 353.873 285.25H340.127C339.619 285.25 339.131 285.048 338.772 284.689L330.311 276.228C329.952 275.869 329.75 275.381 329.75 274.873V261.127C329.75 260.619 329.952 260.131 330.311 259.772L338.772 251.311Z"
        stroke="#202A36"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M341.25 273.75L344.125 270.875L347 268L349.875 265.125L352.75 262.25"
        stroke="#202A36"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M352.75 273.75L349.875 270.875L347 268L344.125 265.125L341.25 262.25"
        stroke="#202A36"
        stroke-width="2"
        stroke-linecap="round"
      />
      <rect
        x="135"
        y="250"
        width="76"
        height="15"
        rx="7.5"
        transform="rotate(-180 135 250)"
        stroke="#F78A68"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="43.3112"
        y="252.622"
        width="14.5822"
        height="14.5822"
        rx="4"
        transform="rotate(-135 43.3112 252.622)"
        stroke="#202A36"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M198 94.5C192.618 94.4999 187.118 97.5 185.118 101.5C183.118 105.5 181.455 116.226 179.5 129C176.819 146.518 173.765 167.193 175.5 175C177.696 184.883 224.5 185.5 234 177"
        stroke="#202A36"
        stroke-width="2"
      />
      <path
        d="M180 123C180 123 153.5 111 148 105C142.5 98.9996 127.5 71.9998 127.5 71.9998C128 69.8331 130.2 65.7998 135 66.9998L157.5 88.5L184.5 98"
        stroke="#202A36"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M215.473 67.965C216.534 68.3168 219.009 67.9592 218.651 65.4849"
        stroke="#202A36"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M237.168 187.904L237.921 196.817L237.588 206.559C237.55 207.667 238.469 208.57 239.576 208.513V208.513C241.734 208.402 243.642 207.075 244.497 205.091L247.678 197.708C248.15 196.613 248.4 195.435 248.413 194.242L248.5 186"
        stroke="#202A36"
        stroke-width="2"
      />
      <path
        d="M232.048 143.37C226.967 118.516 214.284 99.8063 228 97.0011C241.675 94.2043 248.258 121.139 251.944 139.136C251.966 139.244 251.979 139.34 251.982 139.45C252.079 142.27 253.376 182.914 248.265 186.422C242.97 190.056 240.321 189.577 236.881 187.729C233.441 185.88 232.048 143.37 232.048 143.37Z"
        stroke="#202A36"
        stroke-width="2"
      />
      <path
        d="M196.882 91C196.882 96.5 207.382 104 215.882 95C219.754 94.6667 224.5 95.5 227.5 97.5"
        stroke="#202A36"
        stroke-width="2"
      />
      <path
        d="M214.875 54.7151C221.248 60.5052 231.889 74.3815 223.116 79.5813C221.415 80.5896 215.805 82.0401 215.805 82.0401M210 83.5L215.805 82.0401M215.805 82.0401L215 95.5"
        stroke="#202A36"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M208.873 43.7042C220.49 47.8888 215.105 61.0525 210.96 67.1113C209.226 66.4294 205.249 66.1148 203.214 70.3121C201.179 74.5093 203.865 77.6165 205.463 78.6454C200.987 101.634 186.916 123.972 176.27 128.435C165.625 132.899 151.407 108.566 148.768 90.2278C146.128 71.8898 179.201 82.4933 182.297 72.1775C185.393 61.8617 194.351 38.4735 208.873 43.7042Z"
        fill="#202A36"
      />
      <path
        d="M233.511 178.25L221.411 234.338L211 287.5L197.359 287.912L195.072 233.355L195.282 227.736L200.5 189"
        stroke="#202A36"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195 236.5L191.88 233.38C188.71 230.211 186.638 226.109 185.966 221.677L179.5 179M222 231.5L242 269L235 275L217 258.5"
        stroke="#202A36"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M211.299 287.419L199.346 287.788L190.927 288.129C185.344 288.968 181.007 293.568 183.734 299.793L212.242 299.96L211.299 287.419Z"
        fill="#202A36"
      />
      <path
        d="M211.299 287.419L199.346 287.788L190.927 288.129C185.344 288.968 181.007 293.568 183.734 299.793L212.242 299.96L211.299 287.419Z"
        stroke="#202A36"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M242.442 268.127L233.047 275.525L226.478 280.802C222.486 284.794 221.73 291.071 227.623 294.457L250.652 277.653L242.442 268.127Z"
        fill="#202A36"
      />
      <path
        d="M242.442 268.127L233.047 275.525L226.478 280.802C222.486 284.794 221.73 291.071 227.623 294.457L250.652 277.653L242.442 268.127Z"
        stroke="#202A36"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M127.5 70.5L124.341 67.0542C122.997 65.5882 122.935 63.3575 124.194 61.8183L125.443 60.2918C126.119 59.4651 126.437 58.4023 126.325 57.3401L125.577 50.2331C125.532 49.8049 125.791 49.403 126.199 49.2669V49.2669C126.652 49.116 127.145 49.3373 127.332 49.7758L134.5 66.5"
        stroke="#202A36"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M178.5 135.5C187.5 144 207 149 231 135.5"
        stroke="#202A36"
        stroke-width="2"
      />
      <circle cx="302.5" cy="18.5" r="7.5" fill="#202A36" />
    </svg>
  )
}
