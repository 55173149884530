import React, { SVGProps } from 'react'

export const Info = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
      role="img"
    >
      <title>info-icon</title>
      <circle cx="12" cy="12" r="9" stroke={color} stroke-width="2" />
      <path
        d="M12 16.01L12 11.01"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 8.01001L12 8.00001"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
