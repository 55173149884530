import { Theme } from '@mui/material/styles'
import { useTheme } from 'theme'
import getTheme from 'lib/theme/getTheme'
import getGatewayDomainTheme from 'lib/theme/getDomainTheme/getGatewayDomainTheme'
import getPublicDomainTheme from 'lib/theme/getDomainTheme/getPublicDomainTheme'

interface SetThemeByAccountParams {
  user: User
  account: Account
}

interface SetThemeByDashboardParams extends SetThemeByAccountParams {
  dashboard: Dashboard
}

const useSetTheme = () => {
  const { theme, setTheme } = useTheme()

  const setNewTheme = (newTheme: Theme) => {
    if (JSON.stringify(newTheme) !== JSON.stringify(theme)) {
      setTheme(newTheme)
    }
  }

  const getThemesByDomain = async (params: SetThemeByAccountParams) => {
    const { user, account } = params
    const domainName = window.location.hostname

    const domainTheme =
      Boolean(user.id) || process.env.NODE_ENV === 'test'
        ? getGatewayDomainTheme({ account }) || null
        : await getPublicDomainTheme({ domainName })

    return { domainTheme }
  }

  const getThemesByAccount = async (params: SetThemeByAccountParams) => {
    const { account } = params
    const { business } = account
    const { domainTheme } = await getThemesByDomain(params)
    const universeTheme = business?.universe?.theme || null
    const businessTheme = business?.theme || null

    return { domainTheme, universeTheme, businessTheme }
  }

  const getThemesByDashboard = async (params: SetThemeByDashboardParams) => {
    const { user, account, dashboard } = params
    const dashboardTheme = dashboard.theme || null

    if (Boolean(user.id) && Boolean(account.id)) {
      const { domainTheme, universeTheme, businessTheme } =
        await getThemesByAccount({ user, account })

      return { domainTheme, universeTheme, businessTheme, dashboardTheme }
    }

    return {
      domainTheme: null,
      universeTheme: null,
      businessTheme: null,
      dashboardTheme,
    }
  }

  const setThemeByAccount = async (params: SetThemeByAccountParams) => {
    const { domainTheme, universeTheme, businessTheme } =
      await getThemesByAccount(params)
    // This order is important, because the first theme is less prioritary in deepmerge.
    const themes = [domainTheme, universeTheme, businessTheme]
    const theme = getTheme({ themes })
    setNewTheme(theme)
  }

  const setThemeByDashboard = async (params: SetThemeByDashboardParams) => {
    const { domainTheme, universeTheme, businessTheme, dashboardTheme } =
      await getThemesByDashboard(params)
    // This order is important, because the first theme is less prioritary in deepmerge.
    const themes = [domainTheme, universeTheme, businessTheme, dashboardTheme]
    const theme = getTheme({ themes })
    setNewTheme(theme)
  }

  return { setThemeByAccount, setThemeByDashboard }
}

export default useSetTheme
