import {
  GraphQLOperationTypeEnum,
  FetchPolicyEnum,
} from 'hooks/useGraphQLOperation/types'
import { ProviderContext } from 'notistack'
import getDashboardQuery from 'gql/queries/getDashboard'
import useGraphQLOperation from 'hooks/useGraphQLOperation'
import { formatMessage } from 'i18n/ShimokuIntl'

export interface GetDashboardByIdParams {
  id: string
  enqueueSnackbar: ProviderContext['enqueueSnackbar']
  graphQLOperation: ReturnType<typeof useGraphQLOperation>
}

const { QUERY } = GraphQLOperationTypeEnum
const { NETWORK_ONLY } = FetchPolicyEnum

// eslint-disable-next-line consistent-return
const getDashboardById = async (params: GetDashboardByIdParams) => {
  const { id, enqueueSnackbar, graphQLOperation } = params
  try {
    const dashboardRes = await graphQLOperation({
      operationType: QUERY,
      operation: getDashboardQuery,
      fetchPolicy: NETWORK_ONLY,
      options: {
        variables: {
          dashboardId: id,
        },
      },
    })
    const dashboardWithOutReports = dashboardRes.data.getDashboard
    return dashboardWithOutReports
  } catch (error) {
    enqueueSnackbar(formatMessage('errors.fetchReports'), {
      variant: 'error',
    })
  }
}

export default getDashboardById
