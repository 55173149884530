import { InputType, ReportFormDataset } from 'components/ReportForm/types'
import transformStringsToDates from '../transformStringsToDates'

const { file, audio } = InputType

const getDefaultValues = (fields: ReportFormDataset[]) => {
  const mappedFormValues = fields.map(
    ({ mapping: defaultMapping, fieldName, inputType }: ReportFormDataset) => {
      if (!defaultMapping) {
        const isFileInput = inputType === file || inputType === audio
        const mapping = isFileInput ? [] : ''
        return { [fieldName]: mapping }
      }
      if (inputType === InputType.dateRange) {
        const mapping = transformStringsToDates(defaultMapping)
        return { [fieldName]: mapping }
      }
      return { [fieldName]: defaultMapping }
    }
  )
  return Object.assign({}, ...mappedFormValues)
}

export default getDefaultValues
