import React from 'react'
import { Alert } from '@mui/material'
import Html from 'components/ReportHtml'
import { formatMessage } from 'i18n/ShimokuIntl'
import withErrorWrapper from 'hocs/withErrorWrapper'

const ReportHTML = (props: WithReport) => {
  const { report } = props

  return <Html data={report.chartData && JSON.parse(report.chartData)} />
}

const Placeholder = () => {
  return (
    <Alert severity="error">
      {formatMessage('errors.inconsistentReportData')}
    </Alert>
  )
}

export default withErrorWrapper<WithReport>(ReportHTML, Placeholder)
