import { GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-pro'
import { TableProperties } from 'lib/reports/types'
import getFieldsFilter from 'containers/Table/utils/getFilter/getFieldsFilter'
import getSearchFilter from 'containers/Table/utils/getFilter/getSearchFilter'

interface GetFilterParams {
  tableProperties: TableProperties
  filterModel: GridFilterModel
}

const { And: AND } = GridLogicOperator

const getFilter = (params: GetFilterParams) => {
  const {
    tableProperties,
    filterModel: { quickFilterValues = [], items, logicOperator = AND },
  } = params

  if (!items?.length && !quickFilterValues?.length) {
    return null
  }

  const fieldsFilter = getFieldsFilter({
    items,
    logicOperator,
    tableProperties,
  })

  const searchFilter = getSearchFilter({
    quickFilterValues,
    tableProperties,
  })

  const filters = [fieldsFilter, searchFilter].filter((filter) =>
    Boolean(filter)
  )

  if (!filters.length) {
    return null
  }

  const filter = {
    or: filters,
  }

  return filter
}

export default getFilter
