import dayjs from 'dayjs'
import { Option, DefaultData, DataElement } from '../../types'

interface DefaultOptionModificationsCalendar {
  data: DefaultData
  paddingCalendar?: boolean
}

const defaultOptionModifications = (
  params: DefaultOptionModificationsCalendar
): Option => {
  const { data, paddingCalendar = false } = params

  const values = data.map(
    (dataElement: DataElement) => dataElement?.value
  ) as number[]
  const startRangeCalendar = `${dayjs(data[0]?.date as any).format(
    'YYYY-MM'
  )}-01`
  const lastDay = data[data.length - 1]?.date
  const endRangeCalendar = `${dayjs(lastDay as any).format('YYYY-MM')}-${dayjs(
    lastDay as any
  ).daysInMonth()}`

  return {
    tooltip: {
      trigger: 'item',
    },
    toolbox: {
      feature: {
        restore: { show: false },
        dataZoom: { show: false },
        magicType: { show: false },
      },
    },
    calendar: {
      range: [startRangeCalendar, endRangeCalendar],
      height: `${paddingCalendar ? 75 : 90}%`,
      top: `${paddingCalendar ? 64 : 20}`,
    },
    visualMap: {
      min: Math.min(...values),
      max: Math.max(...values),
      orient: 'horizontal',
      left: 'center',
      top: 0,
    },
    axisPointer: {
      show: false,
    },
    grid: {
      show: false,
    },
    xAxis: {
      show: false,
    },
    yAxis: {
      show: false,
    },
    dataZoom: {
      show: false,
    },
    singleAxis: {
      show: false,
      axisLine: {
        show: false,
      },
    },
    animation: false,
  }
}

export default defaultOptionModifications
