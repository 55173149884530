import { styled } from '@mui/material/styles'

interface LoadingContainerProps {
  gap: number
}

export const LoadingContainer = styled('div')<LoadingContainerProps>(
  ({ gap }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${gap}px`,
    margin: `${gap / 2}px 0px`,
  })
)
