import { TypographyOptions } from '@mui/material/styles/createTypography'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    mega: React.CSSProperties
    bodyM700: React.CSSProperties
    bodyM600: React.CSSProperties
    bodyM400: React.CSSProperties
    bodyS700: React.CSSProperties
    bodyS600: React.CSSProperties
    bodyS400: React.CSSProperties
    note700: React.CSSProperties
    note400: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    mega?: React.CSSProperties
    bodyM700?: React.CSSProperties
    bodyM600?: React.CSSProperties
    bodyM400?: React.CSSProperties
    bodyS700?: React.CSSProperties
    bodyS600?: React.CSSProperties
    bodyS400?: React.CSSProperties
    note700?: React.CSSProperties
    note400?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    mega: true
    bodyM700: true
    bodyM600: true
    bodyM400: true
    bodyS600: true
    bodyS700: true
    bodyS400: true
    note700: true
    note400: true
  }
}

const fontSize = 16

const getRemUnit = (pixelUnit: number) => {
  return `${pixelUnit / fontSize}rem`
}

const typography: TypographyOptions = {
  fontFamily: ['Rubik', 'sans-serif'].join(','),
  fontSize,
  mega: {
    fontSize: getRemUnit(42),
    fontWeight: 700,
  },
  h1: {
    fontSize: getRemUnit(36),
    fontWeight: 600,
    letterSpacing: 0.5,
  },
  h2: {
    fontSize: getRemUnit(36),
  },
  h3: {
    fontSize: getRemUnit(32),
    fontWeight: 600,
  },
  h4: {
    fontSize: getRemUnit(24),
    fontWeight: 700,
    letterSpacing: 0.5,
  },
  h5: {
    fontSize: getRemUnit(24),
    fontWeight: 300,
    letterSpacing: 0.5,
  },
  h6: {
    fontSize: getRemUnit(18),
    letterSpacing: 0.5,
  },
  bodyM700: {
    fontSize: getRemUnit(16),
    fontWeight: 700,
    letterSpacing: 0.2,
  },
  bodyM600: {
    fontSize: getRemUnit(16),
    fontWeight: 600,
    letterSpacing: 0.2,
  },
  bodyM400: {
    fontSize: getRemUnit(16),
    fontWeight: 400,
    letterSpacing: 0.2,
  },
  bodyS700: {
    fontSize: getRemUnit(14),
    fontWeight: 700,
    letterSpacing: 0.2,
  },
  bodyS600: {
    fontSize: getRemUnit(14),
    fontWeight: 600,
    letterSpacing: 0.2,
  },
  bodyS400: {
    fontSize: getRemUnit(14),
    fontWeight: 400,
    letterSpacing: 0.2,
  },
  note700: {
    fontSize: getRemUnit(12),
    fontWeight: 700,
  },
  note400: {
    fontSize: getRemUnit(12),
  },
}

export default typography
