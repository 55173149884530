import { FormData, FormDataArrayFilesAndFilesData } from '../../types'
import { InputType } from 'components/ReportForm/types'

const { file, audio } = InputType

interface GetFilesParams {
  formData: FormData
}

const getFiles = (params: GetFilesParams) => {
  const { formData: defaultFormData } = params

  const formData = Object.entries(defaultFormData)
  const formDataFiles = formData.filter(([key]) => {
    const fileInput = document.querySelector(
      `[data-inputType="${key}-${file}"]`
    )
    const audioInput = document.querySelector(
      `[data-inputType="${key}-${audio}"]`
    )

    return Boolean(fileInput) || Boolean(audioInput)
  }) as FormDataArrayFilesAndFilesData

  return formDataFiles
}

export default getFiles
