import { useSelector } from 'react-redux'
import { selectUser } from 'redux/user'
import { selectAccount } from 'redux/account'
import { useSnackbar } from 'notistack'
import loadPyodide from 'hooks/useRunEvents/actionEvents/useRunAction/loadPyodide'
import actionExecutionWrapper from 'hooks/useRunEvents/actionEvents/useRunAction/actionExecutionWrapper'
import { ActionScript } from 'hooks/useRunEvents/actionEvents/useRunAction/types'
import useGraphQLOperation from 'hooks/useGraphQLOperation'
import {
  GraphQLOperationTypeEnum,
  FetchPolicyEnum,
} from 'hooks/useGraphQLOperation/types'
import { formatMessage } from 'i18n/ShimokuIntl'
import getAction from 'gql/queries/getAction'

const { QUERY } = GraphQLOperationTypeEnum
const { NETWORK_ONLY } = FetchPolicyEnum

export interface RunActionParams {
  actionId: string
}

const useRunAction = () => {
  const { enqueueSnackbar } = useSnackbar()
  const user = useSelector(selectUser)
  const account = useSelector(selectAccount)
  const graphQLOperation = useGraphQLOperation()

  const runAction = async (params: RunActionParams) => {
    const { actionId } = params

    try {
      const variables = { actionId }
      const options = { variables }
      const res = await graphQLOperation({
        operationType: QUERY,
        operation: getAction,
        options,
        fetchPolicy: NETWORK_ONLY,
      })
      const action = res?.data?.getAction
      const pythonLibraries: string[] = action?.pythonLibraries || []

      const actionScripts = action?.actionScripts?.items || []

      const { pyodide, micropip } = await loadPyodide({
        user,
        account,
        enqueueSnackbar,
      })

      for (const pythonLibrary of pythonLibraries) {
        await micropip.install(pythonLibrary)
      }

      actionScripts.forEach(async (actionScript: ActionScript) => {
        const { codeFragment } = actionScript
        await pyodide.runPython(actionExecutionWrapper({ codeFragment }))
      })
    } catch (error) {
      enqueueSnackbar(formatMessage('errors.fetchAction'), { variant: 'error' })
    }
  }

  return { runAction }
}

export default useRunAction
