import { styled } from '@mui/material/styles'

interface WrapperProps {
  type?: ReportType
  haveHorizontalScroll: boolean
  oldGrid: boolean
}

export const Wrapper = styled('div')<WrapperProps>(
  ({ type, haveHorizontalScroll, oldGrid }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    ...(type &&
      type !== 'TABLE' &&
      type !== 'INDICATOR' &&
      type !== 'INDICATORS' &&
      type !== 'TABS' && {
        minWidth: 'fit-content',
      }),
    ...((!type || type === 'TABLE') &&
      haveHorizontalScroll &&
      oldGrid && {
        overflowX: 'hidden',
        minHeight: 'fit-content',
      }),
  })
)
