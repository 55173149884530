import { getItem, setItem } from 'lib/localStorage'
import { useEffect, useState } from 'react'

const FEEDBACK_MODAL_DISPLAY_KEY = 'feedbackModalDisplayCount'
export const FEEDBACK_MODAL_DISPLAY_MAX_COUNT = 3

const incrementFeedbackDisplayCount = () => {
  const currentCount = getItem<number>(FEEDBACK_MODAL_DISPLAY_KEY) || 0
  setItem<number>(FEEDBACK_MODAL_DISPLAY_KEY, currentCount + 1)
}

const canShowFeedbackModal = () => {
  const currentCount = getItem<number>(FEEDBACK_MODAL_DISPLAY_KEY) || 0
  return currentCount < FEEDBACK_MODAL_DISPLAY_MAX_COUNT
}

export const setMaxFeedbackDisplayCount = () => {
  setItem<number>(FEEDBACK_MODAL_DISPLAY_KEY, FEEDBACK_MODAL_DISPLAY_MAX_COUNT)
}

export const useFeedback = (haveApps: boolean) => {
  const [showFeedback, setShowFeedback] = useState(false)

  useEffect(() => {
    if (haveApps && canShowFeedbackModal()) {
      setShowFeedback(true)
      incrementFeedbackDisplayCount()
    }
  }, [haveApps])

  return { showFeedback, setShowFeedback }
}
