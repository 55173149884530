import { getGridStringOperators, GridColDef } from '@mui/x-data-grid-pro'
import renderTableCell from 'containers/Table/utils/renderTableCell'
import { TablePropertiesColumn } from 'lib/reports/types'
import {
  TABLE_COLUMN_TYPES,
  TABLE_COLUMN_FILTER_OPERATORS,
  TableColumnFilterOperatorTypes,
} from 'containers/Table/types'
import { COLUMN_WIDTH } from 'containers/Table/utils/generateTableColumns/getColumn'

const { STRING } = TABLE_COLUMN_TYPES

const { STRING_EQUALS, STRING_STARTS_WITH } = TABLE_COLUMN_FILTER_OPERATORS
const STRING_OPERATORS = [STRING_EQUALS, STRING_STARTS_WITH]
export const getStringFilterOperators = () =>
  getGridStringOperators().filter(({ value }) =>
    STRING_OPERATORS.includes(value as TableColumnFilterOperatorTypes)
  )

interface GetStringColumnParams {
  column: TablePropertiesColumn
}

const getStringColumn = (params: GetStringColumnParams): GridColDef => {
  const { column } = params
  const { field, headerName, width = COLUMN_WIDTH } = column

  return {
    field,
    headerName,
    type: STRING,
    width,
    filterOperators: getStringFilterOperators(),
    renderCell: (gridRenderCellParams) =>
      renderTableCell({ gridRenderCellParams, column }),
  }
}

export default getStringColumn
