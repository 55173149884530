import { Option } from '../../types'

const defaultOptionModifications = (): Option => ({
  tooltip: {
    trigger: 'axis',
  },
  grid: {
    containLabel: true,
  },
  yAxis: {
    nameGap: 80,
    axisPointer: {
      show: false,
    },
  },
  visualMap: {
    show: false,
    inRange: {},
    outOfRange: {},
  },
  singleAxis: {
    show: false,
    axisLine: {
      show: false,
    },
  },
  calendar: {
    range: 2000,
    cellSize: 0,
    splitLine: {
      show: false,
    },
    itemStyle: {
      opacity: 0,
    },
    dayLabel: {
      show: false,
    },
    monthLabel: {
      show: false,
    },
    yearLabel: {
      show: false,
    },
  },
  animation: false,
})

export default defaultOptionModifications
