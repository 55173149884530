import React, { SVGProps } from 'react'

export const Linkedin = ({
  width = '32',
  height = '32',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>linkedin-icon</title>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2ZM12.6186 9.69215C12.6186 10.6267 11.8085 11.3843 10.8093 11.3843C9.81004 11.3843 9 10.6267 9 9.69215C9 8.7576 9.81004 8 10.8093 8C11.8085 8 12.6186 8.7576 12.6186 9.69215ZM9.24742 12.6281H12.3402V22H9.24742V12.6281ZM14.2268 12.6281H17.3196V13.8305C17.8387 13.1701 18.8354 12.4401 20.4433 12.4401C23.1508 12.4401 23.9738 13.8954 24 17.0537V22H20.9072C20.9072 21.3889 20.903 20.7745 20.8987 20.1604V20.1601V20.1598V20.1597V20.1595C20.8927 19.2883 20.8867 18.4175 20.8928 17.5571C20.9008 16.4345 20.911 14.9855 19.2062 14.9855C17.6977 14.9855 17.3196 16.0976 17.3196 17.2049V22H14.2268V12.6281Z"
        fill={color}
      />
    </svg>
  )
}
