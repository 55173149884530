import React from 'react'
import GridReport from '../GridReports/GridReport'
import { GRID_COLUMNS } from 'components/Grid/utils/defaultValues'
import * as S from './GridBento.styled'

interface GridReportProps {
  bentobox: BentoBoxProperties
  gridColumns?: number
}

const BENTO_GRID_COLUMNS = 24

const GridBento = ({
  bentobox,
  gridColumns = GRID_COLUMNS,
}: GridReportProps) => {
  const { sizeRows, sizeColumns } = bentobox
  const bentoboxRows = sizeRows || 1
  const bentoboxColumns =
    sizeColumns && sizeColumns < gridColumns ? sizeColumns : gridColumns

  return (
    <S.GridBento
      id="grid-reports"
      data-testid="grid-bentobox"
      bentoboxRows={bentoboxRows}
      bentoboxColumns={bentoboxColumns}
    >
      {bentobox.reports.map((report) => (
        <GridReport
          report={report}
          key={report.id}
          isBentoBox
          gridColumns={BENTO_GRID_COLUMNS}
        />
      ))}
    </S.GridBento>
  )
}

export default GridBento
