import React from 'react'
import { Breadcrumbs } from '@mui/material'
import { useNavigation } from 'contexts/navigation'
import Crumb from './Crumb'

const Breadcrumb = () => {
  const { breadcrumb } = useNavigation()

  return (
    <Breadcrumbs aria-label="breadcrumb" color="var(--color-black)">
      {breadcrumb.map((crumb, i) => (
        <Crumb
          key={`${crumb.name}-${i}`}
          text={crumb.name}
          href={crumb.path}
          last={breadcrumb.length - 1 === i}
        />
      ))}
    </Breadcrumbs>
  )
}

export default Breadcrumb
