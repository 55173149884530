import userFragment from 'gql/fragments/user'

export const getUserByCognitoIdQuery = /* GraphQL */ `
  ${userFragment}
  query getUserByCognitoIdQuery($id: ID!) {
    currentDateTime
    user: getUserByCognitoId(id: $id) {
      ...UserFields
    }
  }
`
