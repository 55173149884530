import React from 'react'
import { useReports } from 'contexts/reports'
import TabsComponent from 'components/Tabs'
import { TabsProperties } from 'lib/reports/types'
import getTabsAndTabPanels from './getTabsAndTabPanels'

interface TabsProps {
  reportProperties: TabsProperties
  columns: number
}

const Tabs = (props: TabsProps) => {
  const { reports } = useReports()
  const { reportProperties, columns } = props
  const { sticky, variant } = reportProperties
  const { tabs, tabPanels } = getTabsAndTabPanels({ reportProperties, reports })

  return (
    <TabsComponent
      tabs={tabs}
      tabPanels={tabPanels}
      columns={columns}
      sticky={sticky}
      variant={variant}
    />
  )
}

export default Tabs
