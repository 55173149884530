import appFragment from './app'

const appDashboardFragment = /* GraphQL */ `
  ${appFragment}
  fragment appDashboardFields on AppDashboardExposed {
    id
    createdAt
    dashboard {
      id
    }
    app {
      ...AppFields
    }
  }
`

export default appDashboardFragment
