import React, { SVGProps } from 'react'

export const SignOutA = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
    >
      <title>sign-out-a-icon</title>
      <path
        d="M4 12H6.75L9.5 12H12.25L15 12"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M12 8L16 12L12 16"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 8V8C4 5.79086 5.79086 4 8 4H16C18.2091 4 20 5.79086 20 8V12V16C20 18.2091 18.2091 20 16 20H8C5.79086 20 4 18.2091 4 16V16"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
