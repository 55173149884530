import { styled } from '@mui/material/styles'

export const PlansContainer = styled('div')(({ theme }) => ({
  padding: '80px 40px 24px 40px',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridGap: '16px',
  justifyContent: 'space-between',
  minHeight: 'calc(100vh - 320px)',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}))

export const SkeletonContainer = styled('div')(() => ({
  width: ' 100%',
  padding: '20px',
}))

export const SkeletonImgContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))
