import { SeriesOption, LineSeriesOption } from 'echarts'
import lineSerie from '../../defaults/series/line'
import { SeriesParams } from '../../types'

const series = (params: SeriesParams): SeriesOption => {
  const { data, seriesModifications = {}, axisName, theme } = params

  const seriesOption = lineSerie({
    typeSeriesModifications: seriesModifications as LineSeriesOption,
    theme,
  })

  const series = Object.keys(data?.[0])
    .filter((name: string) => name !== axisName)
    .map((name: string) => ({
      ...seriesOption,
      name,
    }))

  return series as SeriesOption
}

export default series
