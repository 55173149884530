import { SeriesOption, SankeySeriesOption } from 'echarts'
import sankeySerie from '../../defaults/series/sankey'
import { DataElement, SeriesParams } from '../../types'

const series = (params: SeriesParams): SeriesOption => {
  const { data, seriesModifications = {}, paddingSankey, theme } = params

  const seriesOption = sankeySerie({
    typeSeriesModifications: seriesModifications as SankeySeriesOption,
    paddingSankey,
    theme,
  })

  const dataSankey = Array.from(
    new Set(
      data.flatMap(
        (item: DataElement) => [item?.source, item?.target] as string[]
      )
    )
  ).map((name: string) => ({
    name,
  }))

  return [
    {
      ...seriesOption,
      data: dataSankey,
      links: data,
    },
  ] as SeriesOption
}

export default series
