import { NotificationContent, RealTimeEvent } from './types'
import { ProviderContext } from 'notistack'

interface HandleNotificationParams {
  realTimeEvent: RealTimeEvent
  enqueueSnackbar: ProviderContext['enqueueSnackbar']
}

const handleNotificationEvent = (params: HandleNotificationParams) => {
  const { realTimeEvent, enqueueSnackbar } = params
  const content: NotificationContent = JSON.parse(realTimeEvent.content)
  enqueueSnackbar(content.message, { variant: content.variant })
}

export default handleNotificationEvent
