import React, { Component } from 'react'
import Sentry from 'tracking/Sentry'

interface ErrorBoundaryIconProps {
  width: number
  height: number
}

class ErrorBoundaryIcon extends Component<ErrorBoundaryIconProps> {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    Sentry && Sentry.captureException(error)
  }

  render() {
    const { width, height } = this.props

    if (this.state.hasError) {
      return <span style={{ width, height, display: 'block' }} />
    }

    return this.props.children
  }
}

export default ErrorBoundaryIcon
