import { PaddingGrid } from '../../types'

export const PADDING_GRID_TOP: PaddingGrid = {
  default: 10,
  toolbox: 40,
  title: 44,
  subtitle: 64,
}

export const PADDING_GRID_RIGHT: PaddingGrid = {
  default: 72,
}

export const PADDING_GRID_BOTTOM: PaddingGrid = {
  default: 10,
  xAxisName: 22,
}

export const PADDING_GRID_LEFT: PaddingGrid = {
  default: 72,
}
