const EventFragment = /* GraphQL */ `
  fragment eventFields on Event {
    id
    type
    resourceId
    content
  }
`

export default EventFragment
