import React from 'react'
import { Alert } from '@mui/material'
import { useTheme } from 'theme'
import GeneralChartComponent from 'components/GeneralChart'
import withErrorWrapper from 'hocs/withErrorWrapper'
import { formatMessage } from 'i18n/ShimokuIntl'

interface GeneralChartProps {
  report: Report
  isBentoBox?: boolean
}

const GeneralChart = (params: GeneralChartProps) => {
  const { report, isBentoBox } = params
  const { chartData = '', dataFields = '' } = report
  const { theme } = useTheme()

  const data = JSON.parse(chartData)
  const fields = JSON.parse(dataFields)
  const { type, chartOptions = {}, optionModifications = {} } = fields

  return (
    <GeneralChartComponent
      data={data}
      theme={theme}
      type={type}
      chartOptions={chartOptions}
      optionModifications={optionModifications}
      isBentoBox={isBentoBox}
    />
  )
}

const Placeholder = () => (
  <Alert severity="error">
    {formatMessage('errors.inconsistentReportData')}
  </Alert>
)

export default withErrorWrapper<WithReport>(GeneralChart, Placeholder)
