import React from 'react'
import Shimoku from 'icons/shimoku'
import GasoilNow from 'icons/gasolinow'
import Whispers from 'icons/whispers'
import MeetingDoctors from 'icons/meeting-doctors'
import Deloitte from 'icons/deloitte'
import { useTheme } from '@mui/material/styles'
import { isImageUrl } from 'lib/strings'
import { DOMAIN_OPTIONS } from 'theme/types'

interface LogoProps {
  className?: string
  width?: string
  height?: string
  primaryColor?: string
  secondaryColor?: string
  themeLogo?: boolean
}

export const Logo = (props: LogoProps) => {
  const {
    className = '',
    width,
    height,
    primaryColor,
    secondaryColor,
    themeLogo = true,
  } = props
  const { GASOLINOW, WHISPERS, MEETING_DOCTORS, DELOITTE } = DOMAIN_OPTIONS
  const {
    custom: { domain, logo },
  } = useTheme()

  if (logo && isImageUrl(logo) && themeLogo) {
    return (
      <img
        src={logo}
        alt="Logo"
        className={className}
        style={{ objectFit: 'contain' }}
      />
    )
  }
  switch (domain) {
    case GASOLINOW:
      return <GasoilNow className={className} />

    case WHISPERS:
      return <Whispers className={className} />

    case MEETING_DOCTORS:
      return <MeetingDoctors className={className} />

    case DELOITTE:
      return (
        <Deloitte
          className={className}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
      )

    default:
      return (
        <Shimoku
          className={className}
          width={width}
          height={height}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
      )
  }
}
