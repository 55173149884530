import { HeatmapSeriesOption } from 'echarts'
import { TEXT_STYLE, BORDER_STYLE, SHADOW_STYLE } from '../../defaults/general'
import { TypeSeriesParams } from '../../types'

const heatmapSerie = (
  params: TypeSeriesParams<HeatmapSeriesOption>
): HeatmapSeriesOption => {
  const { typeSeriesModifications: heatmapSeriesModifications = {}, theme } =
    params

  return {
    type: 'heatmap',
    coordinateSystem: 'cartesian2d',
    minOpacity: 0,
    maxOpacity: 1,
    selectedMode: true,
    ...heatmapSeriesModifications,
    label: {
      show: true,
      position: 'inside',
      distance: 4,
      rotate: 0,
      offset: [0, 0],
      ...TEXT_STYLE(theme),
      color: 'inherit',
      fontSize: 12,
      backgroundColor: 'transparent',
      ...BORDER_STYLE,
      padding: 0,
      ...SHADOW_STYLE,
      ...heatmapSeriesModifications?.label,
    },
    labelLayout: {
      hideOverlap: false,
      ...heatmapSeriesModifications?.labelLayout,
    },
    itemStyle: {
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      ...heatmapSeriesModifications?.itemStyle,
    },
  }
}

export default heatmapSerie
