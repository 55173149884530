type generateCSVParams = {
  headers: string[]
  rows: any
  title?: string
}

export const generateCSV = ({ headers, rows }: generateCSVParams): string => {
  const csvRows = [headers.join(',')]
  const values = rows.map((row: any) => {
    const values = headers.map((header) => {
      const escaped = ('' + row[header]).replace(/"/g, '\\"')
      return `"${escaped}"`
    })
    return values.join(',')
  })
  csvRows.push(...values)
  return csvRows.join('\n')
}

export const downloadCSV = ({
  headers,
  rows,
  title = 'report',
}: generateCSVParams): void => {
  const csv = generateCSV({ headers, rows })
  const a = document.createElement('a')
  a.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
  a.target = '_blank'
  a.download = `${title}.csv`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
