import { TABLE_COLUMN_FILTER_OPERATORS } from 'containers/Table/types'

const {
  STRING_EQUALS,
  STRING_STARTS_WITH,
  NUMBER_EQUAL,
  NUMBER_DIFFERENT,
  NUMBER_GREATER_THAN,
  NUMBER_GREATER_OR_EQUAL_THAN,
  NUMBER_LESS_THAN,
  NUMBER_LESS_OR_EQUAL_THAN,
  DATE_IS,
  DATE_AFTER,
  DATE_ON_OR_AFTER,
  DATE_BEFORE,
  DATE_ON_OR_BEFORE,
} = TABLE_COLUMN_FILTER_OPERATORS

export const MATCH_PHRASE_PREFIX = 'matchPhrasePrefix'

const operators: Record<string, string> = {
  [STRING_EQUALS]: 'eq',
  [STRING_STARTS_WITH]: MATCH_PHRASE_PREFIX,
  [NUMBER_EQUAL]: 'eq',
  [NUMBER_DIFFERENT]: 'ne',
  [NUMBER_GREATER_THAN]: 'gt',
  [NUMBER_GREATER_OR_EQUAL_THAN]: 'gte',
  [NUMBER_LESS_THAN]: 'lt',
  [NUMBER_LESS_OR_EQUAL_THAN]: 'lte',
  [DATE_IS]: 'eq',
  [DATE_AFTER]: 'gt',
  [DATE_ON_OR_AFTER]: 'gte',
  [DATE_BEFORE]: 'lt',
  [DATE_ON_OR_BEFORE]: 'lte',
}

const DEFAULT_OPERATOR = MATCH_PHRASE_PREFIX

const getOperator = (defaultOperator: string) => {
  const operator = operators[defaultOperator]

  return operator || DEFAULT_OPERATOR
}

export default getOperator
