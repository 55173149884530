import getDashboardById, {
  GetDashboardByIdParams,
} from 'lib/dashboards/getDashboardsById'
import getDashboardsAppsWithReports from 'lib/dashboards/getDashboardsAppsWithReports'
import { setDashboards } from 'redux/dashboards'
import { useDispatch } from 'react-redux'

interface HandleCreateDashboardParams extends GetDashboardByIdParams {
  dispatch: ReturnType<typeof useDispatch>
  dashboards: Dashboard[]
}

const handleCreateDashboard = async (params: HandleCreateDashboardParams) => {
  const { id, graphQLOperation, enqueueSnackbar, dispatch, dashboards } = params

  const dashboardWithOutReports = await getDashboardById({
    id,
    graphQLOperation,
    enqueueSnackbar,
  })

  if (dashboardWithOutReports) {
    const [dashboardWithReports] = await getDashboardsAppsWithReports({
      dashboards: [dashboardWithOutReports],
      graphQLOperation,
    })

    const newDashboards = [...dashboards, dashboardWithReports]
    dispatch(setDashboards(newDashboards))
  }
}
export default handleCreateDashboard
