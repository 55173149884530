import React from 'react'
import { IconButton } from '@mui/material'
import { Link } from 'react-router-dom'
import useUser from 'hooks/useUser'
import UserMenu from 'containers/UserMenu'
import Logo from 'components/Logo'
import Button from 'components/Button'
import { SIGN_UP } from 'components/Routes/routes/getDefaultRoutes'
import { generateRoutes } from 'components/Routes/generateRoutes'
import MenuIcon from 'icons/Line/menu'
import { formatMessage } from 'i18n/ShimokuIntl'
import * as S from './Header.styled'

interface HeaderProps {
  sideMenu: boolean
  playground: boolean
  onMenuToggle?: () => void
}

const Header = (props: HeaderProps) => {
  const { onMenuToggle, sideMenu, playground } = props
  const { cognitoUser } = useUser()

  const [{ path: signUpPath }] = generateRoutes({ routes: [SIGN_UP] })

  return (
    <S.Header data-testid="header">
      {Boolean(sideMenu) && (
        <IconButton onClick={onMenuToggle} size="small">
          <MenuIcon color="var(--color-black)" />
        </IconButton>
      )}

      <Link to="/">
        <Logo width="auto" height="1.75rem" />
      </Link>

      {Boolean(playground) && (
        <S.PlaygroundChip label={formatMessage('generic.playground')} />
      )}

      {!playground && (
        <S.ContainerUserMenu>
          <UserMenu />
        </S.ContainerUserMenu>
      )}

      {Boolean(playground) && (
        <S.ContainerButton>
          <Link to={Boolean(cognitoUser) ? '/' : signUpPath}>
            <Button sx={{ padding: '0.375rem 1rem' }}>
              {Boolean(cognitoUser)
                ? formatMessage('generic.seeChanges')
                : formatMessage('generic.createAccount')}
            </Button>
          </Link>
        </S.ContainerButton>
      )}
    </S.Header>
  )
}

export default Header
