import React, { SVGProps } from 'react'

export const Plus = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
    >
      <title>plus-icon</title>
      <path
        d="M12 6L12 18"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M18 12L6 12"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  )
}
