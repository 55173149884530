import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { UniverseModules, UniverseModuleTypes } from '../types'

const UNIVERSE_MODULES = Object.values(UniverseModuleTypes)

type State = UniverseModules

export interface PayloadActionParams {
  universe: Universe
}

const setUniverseModules: CaseReducer<
  State,
  PayloadAction<PayloadActionParams>
> = (state, action) => {
  const { universe } = action.payload
  const {
    modules: { items: universeModulesItems },
  } = universe

  const newUniverseModules = universeModulesItems.reduce(
    (newUniverseModules, universeModulesItem) => {
      const { type, enabled } = universeModulesItem
      const validUniverseModule = UNIVERSE_MODULES.some(
        (universeModule) => universeModule === type
      )

      if (validUniverseModule) {
        return {
          ...newUniverseModules,
          [type]: enabled,
        }
      }

      return newUniverseModules
    },
    {} as UniverseModules
  )

  return { ...state, ...newUniverseModules }
}

export default setUniverseModules
