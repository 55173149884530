import { Theme } from '@mui/material/styles'
import { XAXisComponentOption } from 'echarts'
import { AXIS } from './general'
import { AxisParams } from '../types'

export const getDefaultXAxis = (theme: Theme): AxisParams => ({
  name: '',
  type: 'category',
  theme,
})

const xAxis = (params: AxisParams): XAXisComponentOption => {
  const { name = '', type = 'category', theme } = params

  return {
    ...AXIS(theme),
    name,
    type,
    nameTextStyle: {
      ...AXIS(theme)?.nameTextStyle,
    },
    axisLine: {
      ...AXIS(theme)?.axisLine,
      lineStyle: {
        ...AXIS(theme)?.axisLine?.lineStyle,
      },
    },
    axisTick: {
      ...AXIS(theme)?.axisTick,
      lineStyle: {
        ...AXIS(theme)?.axisTick?.lineStyle,
      },
    },
    axisLabel: {
      ...AXIS(theme)?.axisLabel,
    },
    splitLine: {
      ...AXIS(theme)?.splitLine,
      lineStyle: {
        ...AXIS(theme)?.splitLine?.lineStyle,
      },
    },
    splitArea: {
      ...AXIS(theme)?.splitArea,
      areaStyle: {
        ...AXIS(theme)?.splitArea?.areaStyle,
      },
    },
  } as XAXisComponentOption
}

export default xAxis
