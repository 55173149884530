const getSidePadding = (padding: number | undefined) => padding || 0

interface GetPaddingParams {
  defaultPadding?: string
}

const getPadding = (params: GetPaddingParams) => {
  const { defaultPadding: stringPadding } = params
  const defaultPadding = stringPadding
    ? stringPadding.split(',').map((paddingSide) => Number(paddingSide))
    : []
  const padding = Boolean(defaultPadding.length)
  const paddingTop = getSidePadding(defaultPadding[0])
  const paddingRight = getSidePadding(defaultPadding[1])
  const paddingBottom = getSidePadding(defaultPadding[2])
  const paddingLeft = getSidePadding(defaultPadding[3])

  return { paddingTop, paddingRight, paddingBottom, paddingLeft, padding }
}

export default getPadding
