import posthog from 'posthog-js'

const getPostHogClient = (): any => {
  const { REACT_APP_PUBLIC_POSTHOG_KEY, REACT_APP_PUBLIC_POSTHOG_HOST } =
    process.env

  if (!REACT_APP_PUBLIC_POSTHOG_KEY) {
    throw new Error('Environment variable REACT_APP_PUBLIC_POSTHOG_KEY not set')
  }
  if (!REACT_APP_PUBLIC_POSTHOG_HOST) {
    throw new Error(
      'Environment variable REACT_APP_PUBLIC_POSTHOG_HOST not set'
    )
  }

  return posthog.init(REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
  })
}

export default getPostHogClient
