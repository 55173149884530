import React from 'react'
import { Typography } from '@mui/material'
import { GridToolbarQuickFilter } from '@mui/x-data-grid-pro'
import ExportButton from 'components/DataGrid/Export'
import ColumnsA from 'icons/Line/columns-a'
import FilterA from 'icons/Line/filter-a'
import * as S from './GridToolbar.styled'

interface GridToolbarProps {
  toolbar: boolean
  title?: string
  columnsButton?: boolean
  filtersButton?: boolean
  exportButton?: boolean
  exportOnClick?: () => void
  search?: boolean
}

const GridToolbar = (props: GridToolbarProps) => {
  const {
    toolbar,
    title,
    columnsButton = false,
    filtersButton = false,
    exportButton = false,
    exportOnClick,
    search = false,
  } = props

  if (!toolbar) {
    return null
  }

  return (
    <S.GridToolbarContainer>
      {Boolean(title) && (
        <S.GridToolbarTitle>
          <Typography variant="h6">{title}</Typography>
        </S.GridToolbarTitle>
      )}
      <S.GridToolbarButtonsAndQuickFilter>
        <S.GridToolbarButtons>
          {Boolean(columnsButton) && (
            <S.ColumnsButton
              data-testid="ColumnsButton"
              startIcon={
                <S.IconContainer>
                  <ColumnsA width={20} height={20} />
                </S.IconContainer>
              }
              disableRipple
            />
          )}
          {Boolean(filtersButton) && (
            <S.FilterButton
              data-testid="FilterButton"
              componentsProps={{
                button: {
                  startIcon: (
                    <S.IconContainer>
                      <FilterA width={20} height={20} />
                    </S.IconContainer>
                  ),
                  disableRipple: true,
                },
              }}
            />
          )}
          {Boolean(exportButton) && (
            <ExportButton handleOnClick={exportOnClick} />
          )}
        </S.GridToolbarButtons>
        {Boolean(search) && (
          <GridToolbarQuickFilter data-testid="QuickFilter" />
        )}
      </S.GridToolbarButtonsAndQuickFilter>
    </S.GridToolbarContainer>
  )
}

export default GridToolbar
