import React, { FC } from 'react'
import { formatMessage } from 'i18n/ShimokuIntl'
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { Logo } from 'components/Logo/logo'
import Icon from 'components/Icon'
import { useLayoutState } from 'hooks/components'
import * as S from './FooterLite.styled'

type FooterLiteProps = {
  url: string
}

const FooterLite: FC<FooterLiteProps> = ({ url }) => {
  const { matches: isTablet } = useLayoutState('sm')
  return (
    <S.Footer data-testid="footer-lite">
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography>{formatMessage('component.footerLite.created')}</Typography>
        <Logo height="24px" width="120px" themeLogo={false} />
      </Box>
      <Link to={url}>
        <Box display="flex" flexDirection="row" alignItems="center">
          {isTablet && <>{formatMessage('component.footerLite.view')}</>}
          <Icon name="Line/chevron-right" height={24} width={24} />
        </Box>
      </Link>
    </S.Footer>
  )
}

export default FooterLite
