import { SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack'
import { GraphQLOperationParams } from 'hooks/useGraphQLOperation/types'
import Sentry from 'tracking/Sentry'
import { getErrorMessage } from 'lib/errors'
import { TableState } from 'containers/Table/types'
import { PublicPermissions } from 'contexts/ReportDataSets/getReportDataSetsListData'
import getData from 'containers/Table/utils/getTableData/getData'
import getPublicData from 'containers/Table/utils/getTableData/getPublicData'

interface getTableDataProps {
  graphQLOperation: (params: GraphQLOperationParams) => Promise<any>
  dataSetId: string
  tableState: TableState
  publicPermissions?: PublicPermissions
  shared?: boolean
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey
}

const getTableData = async (props: getTableDataProps) => {
  const {
    graphQLOperation,
    dataSetId,
    tableState,
    enqueueSnackbar,
    publicPermissions,
    shared,
  } = props
  const {
    sort,
    filter,
    paginationModel,
    lastPage,
    queryTokens: defaultQueryTokens,
  } = tableState
  const { page, pageSize: limit } = paginationModel
  const nextPage = page >= lastPage
  const tokenNextPage = defaultQueryTokens[defaultQueryTokens.length - 1]
  const tokenPreviousPage = defaultQueryTokens[defaultQueryTokens.length - 3]
  const token = nextPage ? tokenNextPage : tokenPreviousPage
  const nextToken = defaultQueryTokens.length && token ? token : null

  let dataItems: Data[] = []
  let queryTokens: string[] = []
  let total: number = 0

  try {
    let res: any
    if (shared && publicPermissions) {
      res = await getPublicData({
        dataSetId,
        limit,
        nextToken,
        sort,
        filter,
        publicPermissions,
      })
    } else {
      res = await getData({
        dataSetId,
        limit,
        nextToken,
        sort,
        filter,
        graphQLOperation,
      })
    }

    dataItems = res.items
    const nextPageQueryTokens = [...defaultQueryTokens, res.nextToken]
    const previousPageQueryTokens = defaultQueryTokens.slice(
      0,
      defaultQueryTokens.length - 1
    )
    queryTokens = nextPage ? nextPageQueryTokens : previousPageQueryTokens
    total = res.total

    return { dataItems, queryTokens, lastPage: page, total }
  } catch (error) {
    enqueueSnackbar(getErrorMessage(error, 'errors.fetchTableData'), {
      variant: 'error',
    })
    Sentry.captureException(error)
  }

  return { dataItems, queryTokens, lastPage: page, total }
}

export default getTableData
