import React, { memo, Fragment, useState, useEffect, MouseEvent } from 'react'
import { Collapse, Skeleton } from '@mui/material'
import {
  addDashboardActiveStatus,
  getAllApps,
  toogleOpenedStatus,
} from './utils'
import sortApps from 'lib/apps/sortApps'
import OverflowTooltip from 'components/OverflowTooltip/OverflowTooltip'
import ShareDashboardModal from 'components/ShareDashboardModal'
import ConditionalLink from 'components/ConditionalLink'
import { PLAYGROUND_PATH } from 'components/Routes/routes/getDefaultRoutes'
import ChevronDown from 'icons/Line/chevron-down'
import { ChevronRight } from 'icons/Line/chevron-right/chevron-right'
import { LinkA } from 'icons/Line/link-a/link-a'
import getAppRoutes from 'lib/apps/getAppRoutes'
import Menu from './Menu'
import { usePostHog } from 'posthog-js/react'
import { NAVIGATION_EVENTS } from 'lib/postHogEvents'
import * as S from './Menu.styled'

export interface DashboardMenuProps {
  items: Dashboard[]
  loadingAccount?: boolean
  pathname: string
  isShared?: boolean
  playground?: boolean
}

type handleModalParams = {
  event: MouseEvent
  dashboard: Dashboard
  rootAppPath: string
}

const DashboardMenu = memo((props: DashboardMenuProps) => {
  const {
    items,
    loadingAccount,
    pathname,
    isShared = false,
    playground = false,
  } = props
  const posthog = usePostHog()
  const [menuItemList, setMenuItemList] = useState<Dashboard[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [selectedDashboardURL, setSelectedDashboardURL] = useState<string>('')
  const [selectedDashboardName, setSelectedDashboardName] = useState<string>('')
  useEffect(() => {
    if (items.length) {
      const menuList = addDashboardActiveStatus(items, pathname)
      setMenuItemList(menuList)
    } else {
      setMenuItemList([])
    }
  }, [items, pathname]) // eslint-disable-line

  useEffect(() => {
    if (loadingAccount) {
      setMenuItemList([])
    }
  }, [loadingAccount])

  const toogleMenuOpened = (event: MouseEvent, index: number) => {
    event.preventDefault()
    const newMenuItemList: MenuItem[] = toogleOpenedStatus(menuItemList, index)
    setMenuItemList(newMenuItemList)
  }
  const handleOpenModal = ({
    event,
    dashboard,
    rootAppPath,
  }: handleModalParams) => {
    event.preventDefault()
    const dashboardUrl = `${window.location.origin}${rootAppPath}?shared=true&token=${dashboard.publicPermission?.token}`
    setSelectedDashboardURL(dashboardUrl)
    setSelectedDashboardName(dashboard.name)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => setIsModalOpen(false)

  return (
    <>
      <S.List className="ph-no-capture">
        {loadingAccount ? (
          <Placeholder />
        ) : (
          menuItemList.map((dashboard: MenuItem, index: number) => {
            const haveApps = Boolean(dashboard.appDashboards.items.length)
            if (!haveApps) {
              return null
            }
            const allApps = getAllApps(dashboard.appDashboards.items)
            const sortedApps = sortApps(allApps)
            const { rootApp } = getAppRoutes({
              app: sortedApps[0],
              dashboard,
              prefix: playground ? PLAYGROUND_PATH : '',
            })

            return (
              <ConditionalLink to={rootApp.path}>
                <Fragment key={`${dashboard.id}-fragment`}>
                  {!dashboard.isDisabled && haveApps && (
                    <S.DashboardMenuItem
                      key={dashboard.id}
                      selected={dashboard.active}
                      haveArrow
                      data-testid={dashboard.name}
                      data-active={dashboard.active}
                      onClick={() =>
                        posthog?.capture(NAVIGATION_EVENTS.BOARD_MENU_CLICKED, {
                          menuItemName: dashboard.name,
                        })
                      }
                    >
                      <S.IconAndTextAgruppation>
                        <S.Icon
                          active={dashboard.active}
                          onClick={(event) => toogleMenuOpened(event, index)}
                          data-testid="dahsboardMenu-icon"
                        >
                          {dashboard.opened ? (
                            <ChevronDown
                              width="100%"
                              color={
                                dashboard.active
                                  ? 'var(--color-white)'
                                  : 'var(--color-grey-700)'
                              }
                            />
                          ) : (
                            <ChevronRight
                              width="100%"
                              color={
                                dashboard.active
                                  ? 'var(--color-white)'
                                  : 'var(--color-grey-700)'
                              }
                            />
                          )}
                        </S.Icon>

                        <OverflowTooltip
                          arrow
                          title={dashboard.name}
                          placement="top"
                          margin="0 0 0 5px"
                        >
                          {dashboard.name}
                        </OverflowTooltip>
                      </S.IconAndTextAgruppation>
                      {!isShared && dashboard.publicPermission?.isPublic && (
                        <S.Icon
                          onClick={(event) =>
                            handleOpenModal({
                              event,
                              dashboard,
                              rootAppPath: rootApp.path,
                            })
                          }
                        >
                          <LinkA
                            width="100%"
                            color={
                              dashboard.active
                                ? 'var(--color-white)'
                                : 'var(--color-grey-700)'
                            }
                          />
                        </S.Icon>
                      )}
                    </S.DashboardMenuItem>
                  )}

                  <Collapse
                    in={dashboard.opened}
                    timeout="auto"
                    unmountOnExit
                    data-testid={`collapse-${dashboard.id}`}
                  >
                    <S.MenuContainer>
                      <Menu
                        items={sortedApps}
                        pathname={pathname}
                        dashboard={dashboard}
                        playground={playground}
                      />
                    </S.MenuContainer>
                  </Collapse>
                  {menuItemList.length > 1 && (
                    <S.Divider
                      role="separator"
                      key={`${dashboard.id}-divider`}
                    />
                  )}
                </Fragment>
              </ConditionalLink>
            )
          })
        )}
      </S.List>
      <ShareDashboardModal
        open={isModalOpen}
        dashboardName={selectedDashboardName}
        onClose={handleCloseModal}
        url={selectedDashboardURL}
      />
    </>
  )
})

const Placeholder = () => (
  <div data-testid="loader-menu">
    {[0, 1, 2].map((item) => (
      <Fragment key={item}>
        <S.MenuItem>
          <Skeleton animation="wave" variant="text" style={{ width: '100%' }} />
        </S.MenuItem>
        {[0, 1].map((item) => (
          <S.MenuItem key={item}>
            <Skeleton
              variant="text"
              animation="wave"
              style={{ width: '100%', marginLeft: '20px' }}
            />
          </S.MenuItem>
        ))}
        <S.Divider />
      </Fragment>
    ))}
  </div>
)

export default DashboardMenu
