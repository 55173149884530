import { GridColDef, getGridSingleSelectOperators } from '@mui/x-data-grid-pro'
import renderTableCell from 'containers/Table/utils/renderTableCell'
import { TablePropertiesColumn, TableChip } from 'lib/reports/types'
import {
  TABLE_COLUMN_TYPES,
  TABLE_COLUMN_FILTER_OPERATORS,
  TableColumnFilterOperatorTypes,
} from 'containers/Table/types'
import { COLUMN_WIDTH } from 'containers/Table/utils/generateTableColumns/getColumn'

const { SINGLE_SELECT } = TABLE_COLUMN_TYPES

const { SINGLE_SELECT_IS } = TABLE_COLUMN_FILTER_OPERATORS
const SINGLE_SELECT_OPERATORS = [SINGLE_SELECT_IS]
export const getSingleSelectFilterOperators = () =>
  getGridSingleSelectOperators().filter(({ value }) =>
    SINGLE_SELECT_OPERATORS.includes(value as TableColumnFilterOperatorTypes)
  )

interface GetSingleSelectColumnParams {
  column: TablePropertiesColumn
}

const getSingleSelectColumn = (
  params: GetSingleSelectColumnParams
): GridColDef => {
  const { column } = params
  const {
    field,
    headerName,
    options: defaultValueOptions = [],
    width = COLUMN_WIDTH,
    chips,
  } = column

  const { options: chipOptions = [] } = chips || ({} as TableChip)
  const chipValueOptions = chipOptions.map(({ value }) => value)
  const valueOptions = chipValueOptions.length
    ? chipValueOptions
    : defaultValueOptions

  return {
    field,
    headerName,
    type: SINGLE_SELECT,
    width,
    valueOptions,
    filterOperators: getSingleSelectFilterOperators(),
    renderCell: (gridRenderCellParams) =>
      renderTableCell({ gridRenderCellParams, column }),
  }
}

export default getSingleSelectColumn
