import universeFragment from './universe'
import dashboardFragment from './dashboards'
import rolePermissionsFragment from './rolePermissions'

const ROLE_PERMISSIONS_FRAGMENT_NAME = 'Business'

const businessFragment = /* GraphQL */ `
  ${universeFragment}
  ${dashboardFragment}
  ${rolePermissionsFragment({ name: ROLE_PERMISSIONS_FRAGMENT_NAME })}
  fragment BusinessFields on BusinessExposed {
    id
    name
    type
    defaultAppPaymentType
    theme
    roles
    modules {
      items {
        enabled
        id
        type
      }
    }
    universe {
      ...universeFields
    }
    dashboards {
      items {
        ...dashboardFields
      }
    }
    rolePermissions {
      items {
        ...RolePermissionsFields${ROLE_PERMISSIONS_FRAGMENT_NAME}
      }
    }
  }
`

export default businessFragment
