import { EChartsOption, SeriesOption } from 'echarts'
import addOptionModifications from '../../addOptionModifications'
import defaultOptionModifications from './defaultOptionModifications'
import titleOption from '../../defaults/title'
import tooltipOption from '../../defaults/tooltip'
import toolboxOption, { getDefaultToolbox } from '../../defaults/toolbox'
import seriesOption from './series'
import { Option, ChartParams } from '../../types'

const tree = (params: ChartParams): EChartsOption => {
  const {
    theme,
    title = '',
    subtitle = '',
    tooltip = true,
    toolbox = getDefaultToolbox(theme),
    data = [{}],
    optionModifications = {},
  } = params

  const seriesModifications = optionModifications?.series as SeriesOption

  const defaultOption: Option = {
    title: titleOption({ title, subtitle, theme }),
    tooltip: tooltipOption({ tooltip, theme }),
    toolbox: toolboxOption({ ...toolbox, theme }),
    series: seriesOption({ data, seriesModifications, theme }),
  }

  const treeOption = addOptionModifications({
    defaultOption,
    defaultOptionModifications: defaultOptionModifications(),
    optionModifications,
  })

  return treeOption
}

export default tree
