import { OptionParams } from './types'
import line from './options/line'
import bar from './options/bar'
import pie from './options/pie'
import scatter from './options/scatter'
import radar from './options/radar'
import tree from './options/tree'
import treemap from './options/treemap'
import sunburst from './options/sunburst'
import candlestick from './options/candlestick'
import heatmap from './options/heatmap'
import sankey from './options/sankey'
import funnel from './options/funnel'
import gauge from './options/gauge'
import themeRiver from './options/themeRiver'
import singleAxisOption from './options/singleAxis'
import calendarOption from './options/calendar'

const generateOption = (params: OptionParams) => {
  const {
    data,
    type,
    theme,
    optionModifications = {},
    chartOptions = {},
  } = params
  const chartParams = { ...chartOptions, data, theme, optionModifications }

  switch (type) {
    case 'line':
      return line(chartParams)

    case 'bar':
      return bar(chartParams)

    case 'pie':
      return pie(chartParams)

    case 'scatter':
      return scatter(chartParams)

    case 'radar':
      return radar(chartParams)

    case 'tree':
      return tree(chartParams)

    case 'treemap':
      return treemap(chartParams)

    case 'sunburst':
      return sunburst(chartParams)

    case 'candlestick':
      return candlestick(chartParams)

    case 'heatmap':
      return heatmap(chartParams)

    case 'sankey':
      return sankey(chartParams)

    case 'funnel':
      return funnel(chartParams)

    case 'gauge':
      return gauge(chartParams)

    case 'themeRiver':
      return themeRiver(chartParams)

    case 'singleAxis':
      return singleAxisOption(chartParams)

    case 'calendar':
      return calendarOption(chartParams)
    default: {
      return line(chartParams)
    }
  }
}

export default generateOption
