import React, { memo } from 'react'
import Icon from 'components/Icon'
import { IndicatorColor, IndicatorVariant } from 'lib/reports/types'
import { PaletteMode } from '@mui/material'
import * as S from './indicator.styled'

export const DEFAULT_ICON_SIZE = 18
export const DEFAULT_ICON_SIZE_BIG = 35

type AlignDirection = 'left' | 'right' | 'center'

interface IconContentProps {
  icon: string
  align: AlignDirection
  color: IndicatorColor
  variant: IndicatorVariant
  backgroundImage?: string
  mode: PaletteMode
  isValue: boolean
}

interface BigIconContentProps {
  bigIcon: string
  align: AlignDirection
  color: IndicatorColor
  variant: IndicatorVariant
}

export const getIconPositionStyle = (align: AlignDirection) => ({
  order: align === 'right' ? 0 : 2,
  marginRight: align === 'right' ? '8px' : 0,
  marginLeft: align === 'right' ? 0 : '8px',
  height: DEFAULT_ICON_SIZE,
})

const getIconPositionStyleBig = (align: AlignDirection) => ({
  marginRight: align === 'left' ? '8px' : 0,
  marginLeft: align === 'left' ? 0 : '8px',
  order: align === 'left' ? 0 : 2,
  alignSelf: 'center',
  display: 'flex',
})

export const IconContent = memo(
  ({
    icon,
    align,
    color,
    variant,
    backgroundImage,
    mode,
    isValue,
  }: IconContentProps) => (
    <div style={{ ...getIconPositionStyle(align) }}>
      <Icon
        name={icon}
        width={DEFAULT_ICON_SIZE}
        height={DEFAULT_ICON_SIZE}
        color={S.getTextColor({
          color,
          variant,
          backgroundImage,
          mode,
          isValue,
        })}
      />
    </div>
  )
)

export const BigIconContent = memo(
  ({ bigIcon, align, color, variant }: BigIconContentProps) => (
    <div style={{ ...getIconPositionStyleBig(align) }}>
      <Icon
        name={bigIcon}
        width={DEFAULT_ICON_SIZE_BIG}
        height={DEFAULT_ICON_SIZE_BIG}
        color={S.getIconColor({ color, variant })}
      />
    </div>
  )
)
