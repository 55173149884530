import { styled } from '@mui/material/styles'
import {
  TextField,
  InputBase as MuiInputBase,
  InputLabel as InputLabelBase,
  Select as Selectbase,
  ListItemText as ListItemTextBase,
  ListItem as ListItemBase,
  TextareaAutosize as TextareaAutosizeBase,
  InputBaseProps,
} from '@mui/material'
import Button from 'components/Button'
import { SelectInputProps } from './select-input'

export const ITEM_HEIGHT = 56

type CustomInputBase = InputBaseProps & {
  textAlign?: 'center' | 'left' | 'right'
}

const setCommonStyles = ({
  theme: {
    palette: { mode },
    transitions,
  },
}: any) => {
  return {
    '&.Mui-error': {
      '.MuiInputBase-input': {
        transition: 'none',
        boxShadow: 'var(--complementary-red) 0 0 0 1px',
        '&:focus': {
          boxShadow: 'var(--complementary-red) 0 0 0 2px',
        },
      },
    },
    '& .MuiOutlinedInput-root': {
      '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '2px solid var(--color-black)',
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: '1px solid var(--complementary-red)',
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '2px solid var(--complementary-red)',
      },
    },
    '& .MuiInputBase-input': {
      color: 'var(--color-black)',
      borderRadius: 'var(--border-radius-m)',
      position: 'relative',
      backgroundColor:
        mode !== 'dark' ? 'var(--color-grey-200)' : 'var(--color-black)',
      width: '100%',
      padding: '10px 12px',
      transition: transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&.MuiSelect-select': {
        '&:focus': {
          borderRadius: 'var(--border-radius-m)',
          boxShadow: 'var(--color-black) 0 0 0 2px',
        },
        '&:active': {
          borderRadius: 'var(--border-radius-m)',
          boxShadow: 'var(--color-black) 0 0 0 2px',
        },
        '&:selected': {
          borderRadius: 'var(--border-radius-m)',
          boxShadow: 'var(--color-black) 0 0 0 2px',
        },
      },
    },
    '.MuiFormHelperText-root': {
      marginLeft: 0,
    },
  }
}

export const InputText = styled(TextField)((props) => {
  return {
    ...setCommonStyles(props),
  }
})

export const InputBase = styled(MuiInputBase)<CustomInputBase>((props) => {
  return {
    textAlign: props.textAlign || 'inherit',
    padding: 0,
    ...setCommonStyles(props),
  }
})

export const InputTextArea: any = styled(TextareaAutosizeBase)(
  ({
    theme: {
      palette: { mode },
      transitions,
    },
    fullWidth,
  }: any) => {
    return {
      color: 'var(--color-black)',
      borderRadius: 'var(--border-radius-m)',
      position: 'relative',
      backgroundColor:
        mode !== 'dark' ? 'var(--color-grey-200)' : 'var(--color-black)',
      width: fullWidth ? '100%' : 'auto',
      padding: '10px 12px',
      border: 'none',
      maxWidth: '100%',
      transition: transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
    }
  }
)

export const Select = styled(Selectbase)<SelectInputProps>(({ minWidth }) => {
  return {
    ...(minWidth && {
      minWidth: minWidth,
    }),
    '.MuiSelect-icon': {
      right: '16px',
      top: 'calc(50% - 0.6em)',
    },
  }
})

export const InputLabel = styled(InputLabelBase)(
  ({
    theme: {
      typography: { bodyS400 },
    },
  }) => {
    return {
      ...bodyS400,
      color: 'var(--color-black)',
    }
  }
)

export const InputFileButton = styled(Button)(
  ({
    theme: {
      palette: { mode },
      typography: { bodyM400 },
    },
  }) => {
    return {
      maxHeight: '46px',
      ...bodyM400,
      justifyContent: 'space-between',
      padding: '12px 16px',
      backgroundColor:
        mode !== 'dark' ? 'var(--color-grey-200)' : 'var(--color-black)',
      color: 'var(--color-grey-600)',
      '&:hover': {
        backgroundColor: 'var(--color-grey-300)',
        color: 'var(--color-black)',
      },
    }
  }
)

export const ListItemText = styled(ListItemTextBase)(
  ({
    theme: {
      typography: { bodyS400 },
    },
  }) => {
    return {
      '& > span': {
        ...bodyS400,
      },
    }
  }
)

export const ListItem = styled(ListItemBase)(() => {
  return {
    height: `${ITEM_HEIGHT}px`,
    paddingLeft: 0,
    '&.item-exit': {
      opacity: 1,
    },
    '&.item-exit-active': {
      opacity: 0,
      transition: 'opacity 700ms ease-out',
    },
    '&.item-enter': {
      opacity: 0,
    },
    '&.item-enter-active': {
      opacity: 1,
      transition: 'opacity 700ms ease-out',
    },
  }
})
