import React from 'react'
import ReactEcharts from 'components/ReactEcharts'
import { EChartsOption } from 'echarts'
import NoData from './NoData'
import { DataState } from 'lib/reports/types'
import { ECHARTS_THEME_NAME } from 'theme'
import * as S from './FreeEcharts.styled'

interface FreeEchartsProps {
  option: EChartsOption
  data: DataState
  isBentoBox?: boolean
  handleOnClickDataPoint?: (params: any) => void
  handleOnReady?: (echartRef: HTMLDivElement | null) => void
}

const FreeEcharts = (props: FreeEchartsProps) => {
  const { option, data, isBentoBox, handleOnReady, handleOnClickDataPoint } =
    props
  return (
    <S.ContainerReactEcharts data-testid="freeEcharts">
      {data.value ? (
        <ReactEcharts
          option={option}
          theme={ECHARTS_THEME_NAME}
          handleOnReady={handleOnReady}
          handleOnClickDataPoint={handleOnClickDataPoint}
          style={{
            width: !isBentoBox ? 'calc(100% - 40px)' : '100%',
            height: !isBentoBox ? 'calc(100% - 40px)' : '100%',
            position: 'absolute',
          }}
        />
      ) : (
        <NoData message={data.message as string} />
      )}
    </S.ContainerReactEcharts>
  )
}

export default FreeEcharts
