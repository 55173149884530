import { Theme } from '@mui/material/styles'
import { XAXisComponentOption, YAXisComponentOption } from 'echarts'

export const TEXT_STYLE = (theme: Theme): {} => {
  const CHART_OPTIONS = theme.custom.chart.options

  return {
    color: CHART_OPTIONS.text,
    fontStyle: 'normal',
    fontFamily: 'Rubik',
    fontSize: '1rem',
    textBorderColor: undefined,
    textBorderWidth: undefined,
    textBorderType: 'solid',
    textBorderDashOffset: 0,
    textShadowColor: 'transparent',
    textShadowBlur: 0,
    textShadowOffsetX: 0,
    textShadowOffsetY: 0,
    overflow: undefined,
  }
}

export const BORDER_STYLE = {
  borderColor: 'transparent',
  borderWidth: 0,
  borderType: 'solid' as 'solid' | 'dashed' | 'dotted',
  borderRadius: 0,
}

export const SHADOW_STYLE = {
  shadowBlur: 0,
  shadowColor: 'transparent',
  shadowOffsetX: 0,
  shadowOffsetY: 0,
}

export const LINE_STYLE = (theme: Theme): {} => {
  const CHART_OPTIONS = theme.custom.chart.options

  return {
    color: CHART_OPTIONS.axisline,
    width: 1,
    type: 'solid',
    dashOffset: 0,
    cap: 'square',
    join: 'bevel',
    ...SHADOW_STYLE,
    opacity: 1,
  }
}

export const NAME_TEXT_STYLE = (theme: Theme): {} => ({
  ...TEXT_STYLE(theme),
  fontSize: '0.875rem',
  backgroundColor: 'transparent',
  ...BORDER_STYLE,
  padding: 0,
  ...SHADOW_STYLE,
})

export const AXIS_LINE = (theme: Theme): any => ({
  show: true,
  symbol: 'none',
  lineStyle: {
    ...LINE_STYLE(theme),
  },
})

export const AXIS_TICK = (theme: Theme): {} => ({
  show: true,
  alignWithLabel: false,
  inside: false,
  length: 4,
  lineStyle: {
    ...LINE_STYLE(theme),
  },
})

export const AXIS_LABEL = (theme: Theme): {} => ({
  show: true,
  interval: 'auto',
  inside: false,
  rotate: 0,
  margin: 8,
  formatter: undefined,
  ...TEXT_STYLE(theme),
  color: 'rgba(0, 0, 0, 0.54)',
  fontSize: '0.75rem',
  fontWeight: 400,
  align: 'center',
  verticalAlign: undefined,
  backgroundColor: 'transparent',
  ...BORDER_STYLE,
  padding: 0,
  ...SHADOW_STYLE,
})

export const SPLIT_LINE = (theme: Theme): any => {
  const CHART_OPTIONS = theme.custom.chart.options

  return {
    show: true,
    interval: 'auto',
    lineStyle: {
      ...LINE_STYLE(theme),
      type: 'dashed',
      color: CHART_OPTIONS.grid,
    },
  }
}

export const SPLIT_AREA: {} = {
  interval: 'auto',
  show: false,
  areaStyle: {
    ...SHADOW_STYLE,
    opacity: 1,
  },
}

export const AXIS = (
  theme: Theme
): XAXisComponentOption | YAXisComponentOption => ({
  id: '',
  show: true,
  position: 'bottom',
  type: 'category',
  offset: 0,
  name: 'Name Axis',
  nameLocation: 'middle',
  nameTextStyle: {
    ...NAME_TEXT_STYLE(theme),
  },
  nameGap: 25,
  nameRotate: 0,
  inverse: false,
  boundaryGap: false,
  min: undefined,
  max: undefined,
  scale: false,
  axisLine: {
    ...AXIS_LINE(theme),
  },
  axisTick: {
    ...AXIS_TICK(theme),
  },
  axisLabel: {
    ...AXIS_LABEL(theme),
  },
  splitLine: {
    ...SPLIT_LINE(theme),
  },
  splitArea: {
    ...SPLIT_AREA,
  },
  //@ts-ignore
  realtimeSort: false,
})

export const ICON_STYLE = (theme: Theme) => {
  const CHART_OPTIONS = theme.custom.chart.options
  return {
    color: 'none',
    borderColor: CHART_OPTIONS.text,
    ...SHADOW_STYLE,
  }
}
