import formatReports from 'contexts/reports/formatReports/'

interface handleUpdateReportParams {
  setReports: React.Dispatch<React.SetStateAction<Report[]>>
  rawReport: Report
}
const handleUpdateReport = (params: handleUpdateReportParams) => {
  const { setReports, rawReport } = params

  setReports((prevReports) => {
    const preUpdatedReports = prevReports.map((report) => {
      if (report.id === rawReport.id) {
        return rawReport
      }
      return report
    })
    const updatedReports = formatReports({
      reports: preUpdatedReports,
    })
    return updatedReports
  })
}

export default handleUpdateReport
