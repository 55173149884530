import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
  ReactNode,
} from 'react'
import { HistoryStack, Crumb } from './types'

interface NavigationContextValue {
  historyStack: HistoryStack
  setHistoryStack: Dispatch<SetStateAction<HistoryStack>>
  breadcrumb: Crumb[]
  setBreadcrumb: Dispatch<SetStateAction<Crumb[]>>
}

const NavigationContext = createContext<NavigationContextValue | undefined>(
  undefined
)

interface NavigationProviderProps {
  children: ReactNode
}

const NavigationProvider = (props: NavigationProviderProps) => {
  const { children } = props
  const defaultHistoryStack: HistoryStack = {
    historyItems: [],
    position: null,
  }
  const [historyStack, setHistoryStack] =
    useState<HistoryStack>(defaultHistoryStack)
  const [breadcrumb, setBreadcrumb] = useState<Crumb[]>([])

  const value = { historyStack, setHistoryStack, breadcrumb, setBreadcrumb }

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  )
}

const useNavigation = () => {
  const context = useContext(NavigationContext)

  if (context === undefined) {
    throw new Error('useNavigation must be used within a HistoryProvider')
  }

  return context
}

export { NavigationProvider, useNavigation }
