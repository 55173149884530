import eventFragment from 'gql/fragments/event'

const playgroundOnEventCreated = /* GraphQL */ `
  ${eventFragment}
  subscription onEventCreated {
    onEventCreated {
      ...eventFields
    }
  }
`

const onEventCreated = /* GraphQL */ `
  ${eventFragment}
  subscription onEventCreated(
    $authResourceType: String!
    $mainAuthResourceId: ID!
  ) {
    onEventCreated(
      authResourceType: $authResourceType
      mainAuthResourceId: $mainAuthResourceId
    ) {
      ...eventFields
    }
  }
`

export { playgroundOnEventCreated }
export default onEventCreated
