import React, { FC } from 'react'
import { Dialog, Typography, Grow, Box } from '@mui/material'
import { formatMessage } from 'i18n/ShimokuIntl'
import Button from 'components/Button'
import { LinkA } from 'icons/Line/link-a/link-a'
import Hexagon from 'components/Shapes/Hexagon'
import { TextInput } from 'components/Input'
import { useSnackbar } from 'notistack'

interface ShareDashboardModalProps {
  onClose: () => void
  open: boolean
  url: string
  dashboardName: string
}

const ShareDashboardModal: FC<ShareDashboardModalProps> = ({
  dashboardName,
  open,
  onClose,
  url,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const generateIframeCode = () => {
    return `<iframe title="${dashboardName}" src="${url}" width="100%" height="100%" style="border: 0; height: 100%;" allowfullscreen></iframe>`
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url)
    enqueueSnackbar(formatMessage('info.linkCopied'), { variant: 'info' })
  }
  const handleCopyEmbedCode = () => {
    const iframeCode = generateIframeCode()
    navigator.clipboard.writeText(iframeCode)
    enqueueSnackbar(formatMessage('info.embedCodeCopied'), { variant: 'info' })
  }
  return (
    <Dialog open={open} TransitionComponent={Grow} onClose={onClose}>
      <Box padding="1.5rem">
        <Box
          display="flex"
          justifyContent="center"
          marginTop="0.25rem"
          marginBottom="1.25rem"
        >
          <Hexagon>
            <LinkA width="2rem" height="2rem" />
          </Hexagon>
        </Box>
        <Typography align="center" component="h5" variant="bodyM600">
          {formatMessage('component.shareDashboardModal.title')}
        </Typography>
        <Typography
          align="center"
          variant="bodyS400"
          marginTop="0.5rem"
          marginBottom="1.5rem"
          component="h6"
        >
          {formatMessage('component.shareDashboardModal.subtitle')}
        </Typography>
        <TextInput fullWidth disabled value={url} />
        <Box
          display="flex"
          sx={{ justifyContent: 'space-between', gap: '0.5rem', marginTop: 2 }}
        >
          <Button onClick={handleCopyEmbedCode}>
            {formatMessage('component.shareDashboardModal.getEmbedCode')}
          </Button>
          <Button onClick={handleCopyLink}>
            {formatMessage('component.shareDashboardModal.copyLink')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default ShareDashboardModal
