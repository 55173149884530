import { ChipProperties } from '.'
import { styled } from '@mui/material/styles'

const getCompatibleColor = (color: string): string => {
  switch (color) {
    case 'active':
      return 'var(--chart-C2)'
    case 'caution':
      return 'var(--chart-C3)'
    case 'warning':
      return 'var(--chart-C4)'
    case 'error':
      return 'var(--color-status-error)'
    case 'neutral':
      return 'var(--color-grey-500)'
    case 'main':
      return 'var(--chart-C1)'
    default:
      return 'var(--color-grey-500)'
  }
}

const setColor = (color: string): string => {
  if (color.includes('#')) {
    return color
  }
  return getCompatibleColor(color)
}

const getColorText = (color: string, variant: string | undefined): string => {
  if (
    variant === 'filled' &&
    (color === 'active' || color === 'caution' || color === 'warning')
  ) {
    return 'var(--color-black)'
  }
  if (variant === 'filled') {
    return 'var(--color-white)'
  }
  return setColor(color)
}

export const Chip = styled('div')<ChipProperties>(
  ({ color, radius, variant }) => ({
    fontSize: 'var(--note400-font-size)',
    display: 'inline-block',
    borderRadius:
      radius === 'rectangle'
        ? 'var(--border-radius-xs)'
        : 'var(--border-radius-xxl)',
    padding: '2px 8px',
    minWidth: '90px',
    color: getColorText(color, variant),
    backgroundColor:
      variant === 'filled' ? setColor(color) : 'var(--color-white)',
    border: variant === 'outlined' ? `1px solid ${setColor(color)}` : 'none',
    textAlign: 'center',
  })
)
