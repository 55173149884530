import { useSelector, useDispatch } from 'react-redux'
import { setAccount as setAccountRedux } from 'redux/account'
import { setDashboards } from 'redux/dashboards'
import { setApps } from 'redux/apps'
import { selectAvailableModules } from 'redux/modules'
import { setPlan } from 'redux/plan'
import { setLoadingAccount } from 'redux/loading'
import { setUser } from 'redux/user'
import { useSnackbar } from 'notistack'
import updateUser from 'gql/mutations/updateUser'
import useGraphQLOperation from 'hooks/useGraphQLOperation'
import {
  GraphQLOperationTypeEnum,
  FetchPolicyEnum,
} from 'hooks/useGraphQLOperation/types'
import useSetTheme from 'hooks/useSetTheme'
import getAccount from 'lib/account/getAccount'
import getDashboardsAppsWithReports from 'lib/dashboards/getDashboardsAppsWithReports'
import getApps from 'lib/apps/getApps'
import getPlan from 'lib/plan/getPlan'
import { formatMessage } from 'i18n/ShimokuIntl'

interface SetAccountParams {
  user: User
}

const { MUTATION } = GraphQLOperationTypeEnum
const { NETWORK_ONLY } = FetchPolicyEnum

const useAccount = () => {
  const graphQLOperation = useGraphQLOperation()
  const { setThemeByAccount } = useSetTheme()
  const { haveInstallRemoveApps } = useSelector(selectAvailableModules)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const setAccount = async (params: SetAccountParams) => {
    const { user } = params
    dispatch(setLoadingAccount(true))

    const account = (await getAccount({
      user,
      graphQLOperation,
      enqueueSnackbar,
      doListAccounts: true,
    })) as Account

    dispatch(setAccountRedux(account))

    try {
      const accountInUseId = account.id

      const updateUserVaribles = {
        input: {
          accountInUseId: accountInUseId,
          id: user.id,
        },
      }

      const updateUserOptions = { variables: updateUserVaribles }

      await graphQLOperation({
        operationType: MUTATION,
        operation: updateUser,
        options: updateUserOptions,
        fetchPolicy: NETWORK_ONLY,
      })

      dispatch(setUser({ accountInUseId }))
    } catch (error) {
      enqueueSnackbar(formatMessage('errors.updateUserAccountInUseId'), {
        variant: 'error',
      })
    }

    await setThemeByAccount({ user, account })

    const dashboardsAppsWithoutReports =
      account.business?.dashboards?.items || []
    const dashboards = await getDashboardsAppsWithReports({
      dashboards: dashboardsAppsWithoutReports,
      graphQLOperation,
    })
    dispatch(setDashboards(dashboards))

    const apps = getApps({ dashboards, haveInstallRemoveApps })
    dispatch(setApps(apps))

    const plan = getPlan({ account })
    dispatch(setPlan(plan))

    dispatch(setLoadingAccount(false))
  }

  return { setAccount }
}

export default useAccount
