import {
  JS_ENVIRONMENT,
  JS_ACCESS_TOKEN,
  JS_UNIVERSE_ID,
  JS_WORKSPACE_ID,
  JS_SNACKBAR,
} from 'hooks/useRunEvents/actionEvents/useRunAction/types'

interface ActionExecutionWrapperParams {
  codeFragment: string
}

const actionExecutionWrapper = (params: ActionExecutionWrapperParams) => {
  const { codeFragment } = params

  return `
from shimoku.actions_execution.execute_action import execute_action_code
    
execute_action_code(
    code="""${codeFragment}""",
    js_environment=${JS_ENVIRONMENT},
    js_access_token=${JS_ACCESS_TOKEN},
    js_universe_id=${JS_UNIVERSE_ID},
    js_workspace_id=${JS_WORKSPACE_ID},
    js_snackbar=${JS_SNACKBAR}
)
  `
}

export default actionExecutionWrapper
