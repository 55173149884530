import React, { FC } from 'react'
import { IconButton } from '@mui/material'
import { Github } from 'icons/social/github/github'
import { Linkedin } from 'icons/social/linkedin/linkedin'
import { Youtube } from 'icons/social/youtube/youtube'
import { Discord } from 'icons/social/discord/discord'
import URLs from 'lib/URLs'
import * as S from './SocialIcons.styled'

const SocialIcons: FC = () => {
  return (
    <S.Container>
      <IconButton
        aria-label="Github"
        href={URLs.github}
        target="_blank"
        sx={{ marginRight: '8px' }}
      >
        <Github />
      </IconButton>
      <IconButton
        aria-label="Linkedin"
        href={URLs.linkedin}
        target="_blank"
        sx={{ marginRight: '8px' }}
      >
        <Linkedin />
      </IconButton>
      <IconButton
        aria-label="Youtube"
        href={URLs.youtube}
        target="_blank"
        sx={{ marginRight: '8px' }}
      >
        <Youtube />
      </IconButton>
      <IconButton aria-label="Discord" href={URLs.discord} target="_blank">
        <Discord />
      </IconButton>
    </S.Container>
  )
}

export default SocialIcons
