import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import { PostHogProvider } from 'posthog-js/react'
import Helmet from 'components/Helmet'
import { ThemeProvider } from 'theme'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { SnackbarProvider } from 'notistack'
import configureAppStore from 'redux/store'
import { NavigationProvider } from 'contexts/navigation'
import WithGraphQL from 'hocs/WithGraphQL'
import { RealTimeProvider } from 'contexts/realTime'
import AppRoutes from 'components/AppRoutes'
import getPostHogClient from 'lib/post-hog'

const Root = () => {
  return (
    <ErrorBoundary>
      <Provider store={configureAppStore({})}>
        <ThemeProvider>
          <HelmetProvider>
            <SnackbarProvider
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              preventDuplicate={true}
              style={{ borderRadius: 'var(--border-radius-m)' }}
            >
              <CssBaseline />
              <Helmet />
              <PostHogProvider client={getPostHogClient()}>
                <NavigationProvider>
                  <BrowserRouter>
                    <WithGraphQL>
                      <RealTimeProvider>
                        <AppRoutes />
                      </RealTimeProvider>
                    </WithGraphQL>
                  </BrowserRouter>
                </NavigationProvider>
              </PostHogProvider>
            </SnackbarProvider>
          </HelmetProvider>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  )
}

export default Root
