import { Theme } from '@mui/material/styles'

interface line {
  data: string[][]
  type: string
  typeChartData: string
  symbol: string
  smooth: boolean
  animation: boolean
  lineStyle: {
    color: string
    width: number
  }
}

interface TooltipLabel {
  formatter: string | Function
}

const DEFAULT_OPTION = (
  id: string,
  xAxisLabel: string,
  yAxisLabel: string,
  xTypeAxis: string,
  yTypeAxis: string,
  dataZoomX: boolean,
  tooltipLabel: TooltipLabel,
  theme: Theme
) => {
  const PALLETE = theme.palette
  const CHART_OPTIONS = theme.custom.chart.options

  return {
    id,
    tooltip: {
      show: true,
      trigger: 'axis',
      axisPointer: {
        label: tooltipLabel,
      },
      backgroundColor: PALLETE.background.paper,
      borderColor: PALLETE.divider,
      textStyle: {
        color: PALLETE.text.primary,
      },
    },
    grid: {
      show: true,
      top: 8,
      right: 18,
      bottom: dataZoomX ? 90 : 42,
      left: 66,
      borderWidth: 0,
    },
    xAxis: {
      type: `${xTypeAxis}`,
      name: `${xAxisLabel}`,
      nameLocation: 'middle',
      boundaryGap: false,
      nameTextStyle: {
        color: CHART_OPTIONS.text,
        fontFamily: `'Roboto', sans-serif`,
        fontSize: 14,
        fontWeight: '400',
        padding: [10, 0, 0, 0],
      },
      axisLine: {
        show: true,
        lineStyle: {
          width: 1,
          color: CHART_OPTIONS.axisline,
          opacity: 1,
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          width: 1,
          color: CHART_OPTIONS.axisline,
          opacity: 1,
        },
      },
      axisLabel: {
        color: CHART_OPTIONS.axislabel,
        fontFamily: `'Roboto', sans-serif`,
        fontSize: 10,
        fontWeight: 400,
      },
      splitLine: {
        show: true,
        lineStyle: {
          width: 1,
          type: 'dashed',
          color: CHART_OPTIONS.grid,
          opacity: 1,
        },
      },
    },
    yAxis: {
      type: `${yTypeAxis}`,
      name: `${yAxisLabel}`,
      nameLocation: 'middle',
      max: 'dataMax',
      min: 'dataMin',
      nameTextStyle: {
        color: CHART_OPTIONS.text,
        fontFamily: `'Roboto', sans-serif`,
        fontSize: 14,
        fontWeight: '400',
        padding: [0, 0, 30, 0],
      },
      boundaryGap: ['0.2', '0.2'],
      axisLine: {
        show: true,
        lineStyle: {
          width: 1,
          color: CHART_OPTIONS.axisline,
          opacity: 1,
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          width: 1,
          color: CHART_OPTIONS.axisline,
          opacity: 1,
        },
      },
      axisLabel: {
        color: CHART_OPTIONS.axislabel,
        fontFamily: `'Roboto', sans-serif`,
        fontSize: 10,
        fontWeight: 400,
      },
      splitLine: {
        show: true,
        lineStyle: {
          width: 1,
          type: 'dashed',
          color: CHART_OPTIONS.grid,
          opacity: 1,
        },
      },
    },
    dataZoom: [
      {
        type: 'slider',
        xAxisIndex: [0],
        filterMode: 'filter',
        show: dataZoomX,
        textStyle: {
          color: CHART_OPTIONS.text,
        },
        dataBackground: {
          lineStyle: {
            color: CHART_OPTIONS.dataBackground,
          },
          areaStyle: {
            color: CHART_OPTIONS.dataBackground,
            opacity: 1,
          },
        },
        selectedDataBackground: {
          lineStyle: {
            color: CHART_OPTIONS.dataBackground,
          },
          areaStyle: {
            color: CHART_OPTIONS.dataBackground,
            opacity: 1,
          },
        },
        fillerColor: CHART_OPTIONS.generalColor['200'],
        borderColor: CHART_OPTIONS.generalColor['200'],
        handleStyle: {
          color: 'rgba(255,255,255,1)',
          borderColor: CHART_OPTIONS.generalColor['700'],
        },
        moveHandleSize: 4,
        moveHandleStyle: {
          color: CHART_OPTIONS.generalColor['400'],
        },
        emphasis: {
          moveHandleStyle: {
            color: CHART_OPTIONS.generalColor['400'],
          },
        },
      },
    ],
    series: [] as line[],
    animation: true,
  }
}

export default DEFAULT_OPTION
