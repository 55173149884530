import MIME_Types from 'lib/fileTypes/MIME_Types'

interface GetAcceptedFileTypesParams {
  acceptFileTypes?: string[]
}

const getAcceptedFileTypes = (params: GetAcceptedFileTypesParams) => {
  const { acceptFileTypes = [] } = params

  const acceptedFileTypes = acceptFileTypes.reduce(
    (acceptedFileTypes, fileType) => {
      const extension = MIME_Types[fileType]

      if (Boolean(extension)) {
        return { ...acceptedFileTypes, [fileType]: extension }
      }

      return acceptedFileTypes
    },
    {}
  )

  return acceptedFileTypes
}

export default getAcceptedFileTypes
