export enum ModalTypes {
  PRICING_TABLE = 'PRICING_TABLE',
}

export type ModalType = keyof typeof ModalTypes

export type Modal = {
  open: boolean
  type?: ModalType
}
