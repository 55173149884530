import React, { ReactNode, CSSProperties } from 'react'
import HexagonSVG from './HexagonSVG'
import * as S from './Hexagon.styled'

interface HexagonProps {
  children: ReactNode
  width?: string
  color?: string
  style?: CSSProperties
}

const Hexagon = (props: HexagonProps) => {
  const { children, width, color, style } = props
  return (
    <S.Container style={style}>
      <HexagonSVG width={width} color={color} />
      <S.IconContainer>{children}</S.IconContainer>
    </S.Container>
  )
}

export default Hexagon
