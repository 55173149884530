import React, { useState, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { useSnackbar } from 'notistack'
import FilterComponent, { ItemFilter } from 'components/ReportsFilter'
import LoadingSection from 'components/LoadingSection/LoadingSection'
import ReportWrapper from 'components/ReportWrapper'
import getReportById from 'gql/queries/getReportById'
import Sentry from 'tracking/Sentry'
import { useLayoutState } from 'hooks/components'
import { formatMessage } from 'i18n/ShimokuIntl'
import getReportRows from 'components/Reports/GridReports/GridReport/getReportRows'

const DEFAULT_MIN_HEIGHT = 400

interface FilterProps {
  report: Report
  columns: number
}

const Filter = (props: FilterProps) => {
  const { report: reportFilter, columns } = props
  const { chartData, dataFields } = reportFilter
  const data: ItemFilter[] = chartData ? JSON.parse(chartData) : {}
  const fields: ChartFields = dataFields ? JSON.parse(dataFields) : {}
  const { label = '', default: defaultValue, width } = fields
  const localDefaultValue = localStorage.getItem(
    `valueFilter${reportFilter.id}`
  )
  const defaultReportId = localDefaultValue
    ? localDefaultValue
    : defaultValue
    ? defaultValue
    : data[0].id

  const [reportComponent, setReportComponent] = useState<Report | undefined>(
    undefined
  )
  const [state, setState] = useState({ loading: false })
  const [minHeight, setMinHeight] = useState(DEFAULT_MIN_HEIGHT)
  const { matches: isTablet } = useLayoutState('sm')
  const { enqueueSnackbar } = useSnackbar()

  const getReport = async ({ id }: { id: string }) => {
    try {
      setState({ loading: true })
      const request: any = await API.graphql(
        graphqlOperation(getReportById, { id })
      )
      const response: Report = request.data.getReport
      const subReport = reportFilter.subReports?.find(
        (report: Report) => report.id === id
      )
      if (subReport) {
        const { dataFields: subReportDataFields } = subReport
        const minHeight = subReportDataFields
          ? JSON.parse(subReportDataFields)?.height
          : DEFAULT_MIN_HEIGHT
        setMinHeight(minHeight)
      }

      setReportComponent(response)
      setState({ loading: false })
    } catch (error) {
      enqueueSnackbar(formatMessage('errors.getReportFilter'), {
        variant: 'error',
      })
      Sentry.captureException(error)
    }
  }

  const handleChange = ({ id }: { id: string }) => {
    getReport({ id })
    localStorage.setItem(`valueFilter${reportFilter.id}`, id)
  }

  useEffect(() => {
    getReport({ id: defaultReportId })
  }, []) // eslint-disable-line

  return (
    <FilterComponent
      placeholder={<LoadingSection minHeight={minHeight ? minHeight : 400} />}
      loading={state.loading}
      defaultOption={defaultReportId}
      callbackHandleChange={handleChange}
      selectWidth={width}
      label={label}
      isTablet={isTablet}
      data={data}
    >
      {reportComponent && (
        <ReportWrapper
          report={reportComponent}
          rows={getReportRows({ report: reportComponent })}
          columns={columns}
        />
      )}
    </FilterComponent>
  )
}

export default Filter
