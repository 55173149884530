import { API } from 'aws-amplify'
import { PublicPermissions } from 'contexts/ReportDataSets/getReportDataSetsListData'
import { DataParams } from 'containers/Table/utils/getTableData/getData'
import listData from 'gql/queries/listData'

interface GetPublicDataParams extends DataParams {
  publicPermissions: PublicPermissions
}

const getPublicData = async (params: GetPublicDataParams) => {
  const { dataSetId, limit, nextToken, sort, filter, publicPermissions } =
    params
  const { token, dashboardId } = publicPermissions
  const variables = { dataSetId, limit, nextToken, sort, filter }

  const {
    data: { listData: res },
  }: any = await API.graphql({
    query: listData,
    variables: {
      ...variables,
      publicPermissions: {
        resourceId: dashboardId,
        token,
      },
    },
    authMode: 'API_KEY',
  })

  return res
}

export default getPublicData
