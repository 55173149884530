import { RealTimeEvent } from '../types'
import {
  RealTimeEventsReducerAction,
  RealTimeEventsReducerActionType,
} from './types'

const {
  ADD_REAL_TIME_EVENT,
  BUSINESS_CONTENTS_UPDATED,
  NOTIFICATION,
  REPORT_UPDATED,
  REPORT_CREATED,
  REPORT_DELETED,
  DATASET_CONTENTS_UPDATED,
  DASHBOARD_CREATED,
} = RealTimeEventsReducerActionType

const realTimeEventsReducer = (
  state: RealTimeEvent,
  action: RealTimeEventsReducerAction
) => {
  switch (action.type) {
    case DATASET_CONTENTS_UPDATED:
    case REPORT_DELETED:
    case REPORT_CREATED:
    case REPORT_UPDATED:
    case NOTIFICATION:
    case BUSINESS_CONTENTS_UPDATED:
    case ADD_REAL_TIME_EVENT:
    case DASHBOARD_CREATED:
      return action.payload
    default:
      return state
  }
}

export default realTimeEventsReducer
