import { styled } from '@mui/material/styles'
import { IconButton, IconButtonProps } from '@mui/material'

export const HistoryNavigationContainer = styled('div')`
  display: flex;
  gap: 1rem;
`

export const HistoryNavigationButton = styled(IconButton)<IconButtonProps>(
  ({ disabled }) => ({
    backgroundColor: 'var(--color-grey-200)',
    color: 'var(--color-base-icon)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '2.25rem',
    minWidth: '0',
    height: '2.25rem',
    padding: '0',
    borderRadius: 'var(--border-radius-s)',
    ...(disabled && {
      backgroundColor: 'transparent',
      opacity: '0.3',
    }),
  })
)
