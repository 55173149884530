import { RealTimeEvent } from '../types'

export enum RealTimeEventsReducerActionType {
  ADD_REAL_TIME_EVENT = 'ADD_REAL_TIME_EVENT',
  BUSINESS_CONTENTS_UPDATED = 'BUSINESS_CONTENTS_UPDATED',
  NOTIFICATION = 'NOTIFICATION',
  DASHBOARD_CREATED = 'DASHBOARD_CREATED',
  REPORT_UPDATED = 'REPORT_UPDATED',
  REPORT_CREATED = 'REPORT_CREATED',
  REPORT_DELETED = 'REPORT_DELETED',
  DATASET_CONTENTS_UPDATED = 'DATASET_CONTENTS_UPDATED',
}

export interface RealTimeEventsReducerAction {
  type: RealTimeEventsReducerActionType
  payload: RealTimeEvent
}
