import { API, graphqlOperation } from 'aws-amplify'
import { SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack'
import createStripePaymentLink from 'gql/mutations/createStripePaymentLink'
import { getErrorMessage } from 'lib/errors'
import Sentry from 'tracking/Sentry'

interface HandleSuscribeParams {
  universeId: string
  universePlanTypeId: string
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey
}

const handleSuscribe = async (params: HandleSuscribeParams) => {
  const { universeId, universePlanTypeId, enqueueSnackbar } = params

  try {
    const res: any = await API.graphql(
      graphqlOperation(createStripePaymentLink, {
        universeId,
        universePlanTypeId,
      })
    )
    window.location.replace(res.data.createStripePaymentLink.url)
  } catch (error) {
    enqueueSnackbar(getErrorMessage(error, 'errors.createStripePaymentLink'), {
      variant: 'error',
    })
    Sentry.captureException(error)
  }
}

export default handleSuscribe
