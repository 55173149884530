import { useLocation } from 'react-router-dom'
import { PLAYGROUND_PATH } from 'components/Routes/routes/getDefaultRoutes'

const usePlaygroundIsRunning = () => {
  const { pathname } = useLocation()
  const playgroundIsRunning = pathname.startsWith(PLAYGROUND_PATH)

  return playgroundIsRunning
}

export default usePlaygroundIsRunning
