import { ProviderContext } from 'notistack'
import {
  GraphQLOperationTypeEnum,
  FetchPolicyEnum,
} from 'hooks/useGraphQLOperation/types'
import useGraphQLOperation from 'hooks/useGraphQLOperation'
import getReportByIdOperation from 'gql/queries/getReportById'
import { formatMessage } from 'i18n/ShimokuIntl'

export interface getReportParams {
  reportId: string
  enqueueSnackbar: ProviderContext['enqueueSnackbar']
  graphQLOperation: ReturnType<typeof useGraphQLOperation>
}

// eslint-disable-next-line consistent-return
const getReportById = async (params: getReportParams) => {
  const { NETWORK_ONLY } = FetchPolicyEnum
  const { QUERY } = GraphQLOperationTypeEnum

  const { graphQLOperation, enqueueSnackbar, reportId } = params
  try {
    const response = await graphQLOperation({
      operationType: QUERY,
      operation: getReportByIdOperation,
      options: { variables: { id: reportId } },
      fetchPolicy: NETWORK_ONLY,
    })
    const report: Report = response.data.getReport
    return report
  } catch (error) {
    enqueueSnackbar(formatMessage('errors.fetchReports'), {
      variant: 'error',
    })
  }
}

export default getReportById
