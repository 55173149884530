import React, { ReactNode } from 'react'
import * as S from './Tabs.styled'

interface TabPanelProps {
  children: ReactNode
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, index, value } = props

  return (
    <S.TabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <>{children}</>}
    </S.TabPanel>
  )
}

export default TabPanel
