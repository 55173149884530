import { EChartsOption, SeriesOption } from 'echarts'
import addOptionModifications from '../../addOptionModifications'
import defaultOptionModifications from './defaultOptionModifications'
import titleOption from '../../defaults/title'
import tooltipOption from '../../defaults/tooltip'
import toolboxOption, { getDefaultToolbox } from '../../defaults/toolbox'
import singleAxisComponentOption, {
  getDefaultSingleAxis,
} from '../../defaults/singleAxis'
import seriesOption from './series'
import { Option, ChartParams } from '../../types'
import validObject from '../validObject'

const singleAxis = (params: ChartParams): EChartsOption => {
  const {
    theme,
    title = '',
    subtitle = '',
    tooltip = true,
    toolbox = getDefaultToolbox(theme),
    singleAxis = getDefaultSingleAxis(theme),
    data = [{}],
    optionModifications = {},
  } = params

  const axisName = 'xAxis'
  const seriesModifications = optionModifications?.series as SeriesOption

  const defaultOption: Option = {
    title: titleOption({ title, subtitle, theme }),
    tooltip: tooltipOption({ tooltip, theme }),
    toolbox: toolboxOption({ ...toolbox, theme }),
    singleAxis: singleAxisComponentOption({ ...singleAxis, theme }),
    series: seriesOption({ data, seriesModifications, axisName, theme }),
  }

  const paddingSingleAxis = validObject({
    object: {
      title: Boolean(title),
      subtitle: Boolean(subtitle),
      toolbox: validObject({ object: toolbox }),
    },
  })

  const singleAxisOption = addOptionModifications({
    defaultOption,
    defaultOptionModifications: defaultOptionModifications({
      data,
      paddingSingleAxis,
      axisName,
    }),
    optionModifications,
  })

  return singleAxisOption as EChartsOption
}

export default singleAxis
