import { PaddingGrid } from '../../types'

export const PADDING_GRID_TOP: PaddingGrid = {
  default: 14,
  legend: 32,
  toolbox: 40,
  title: 44,
  subtitle: 64,
}

export const PADDING_GRID_RIGHT: PaddingGrid = {
  default: 64,
}

export const PADDING_GRID_BOTTOM: PaddingGrid = {
  default: 12,
  xAxisName: 26,
  dataZoom: 64,
}

export const PADDING_GRID_LEFT: PaddingGrid = {
  default: 64,
}
