const trasformOldColor = (color: string): string => {
  switch (color) {
    case 'ok':
      return 'active'
    case 'ko':
      return 'error'
    default:
      return color
  }
}

export const setCompatibleLabelProperties = (
  dataCell: any,
  rowCell: any
): any => {
  const defaultValues = {
    variant: 'outlined',
    radius: 'rectangle',
  }
  if (typeof dataCell.isLabel === 'string' && Boolean(dataCell.isLabel)) {
    return {
      [rowCell]: {
        color: trasformOldColor(rowCell),
        ...defaultValues,
      },
    }
  }
  return Object.keys(dataCell.isLabel).reduce((acc: any, key) => {
    const label = dataCell.isLabel[key]
    if (typeof label === 'string') {
      acc[key] = {
        color: trasformOldColor(label),
        ...defaultValues,
      }
    } else {
      acc[key] = label
    }
    return acc
  }, {})
}
