import { useRef, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { useSnackbar } from 'notistack'
import { useReports } from '../../index'
import formatReports from '../../formatReports'
import { formatMessage } from 'i18n/ShimokuIntl'
import listReports from 'gql/queries/listReports'

interface UseUpdateReportsPeriodicallyProps {
  interval?: number
  times?: number
  appId: string
}

const useUpdateReportsPeriodically = (
  props: UseUpdateReportsPeriodicallyProps
) => {
  const { interval = 10000, times = 6, appId } = props
  const countTimes = useRef<number>(0)
  const intervalId = useRef<NodeJS.Timer | null>(null)
  const { setReports } = useReports()
  const { enqueueSnackbar } = useSnackbar()

  const getReports = async () => {
    try {
      const params = { appId }
      const res: any = await API.graphql(graphqlOperation(listReports, params))
      const newReports: Report[] = res?.data?.listReports?.items
      setReports(formatReports({ reports: newReports }))
    } catch (error) {
      enqueueSnackbar(formatMessage('errors.fetchReports'), {
        variant: 'error',
      })
    }
  }

  const updateReportsPeriodically = () => {
    intervalId.current = setInterval(() => {
      if (countTimes.current < times) {
        getReports()
        countTimes.current += 1
      } else {
        intervalId.current && clearInterval(intervalId.current)
      }
    }, interval)
  }

  useEffect(() => {
    return () => {
      intervalId.current && clearInterval(intervalId.current)
    }
  }, []) // eslint-disable-line

  return { updateReportsPeriodically }
}

export default useUpdateReportsPeriodically
