import React, { FC } from 'react'
import Sentry from 'tracking/Sentry'

/**
 * TODO: support "Component and PureComponent" as Component param typing
 */
const withErrorWrapper = <T extends any>(
  Component: FC<T>,
  PlaceHolder: FC<ErrorWrapperPlaceholderProps>
): any => {
  return class ErrorWrapper extends React.Component<T, ErrorWrapperState> {
    state = { error: undefined }

    static getDerivedStateFromError = (error: Error) => ({ error })

    componentDidCatch = (error: Error) =>
      Sentry && Sentry.captureException(error)

    render() {
      const { error } = this.state
      return Boolean(error) ? (
        <PlaceHolder error={error} />
      ) : (
        <Component {...this.props} />
      )
    }
  }
}

export default withErrorWrapper
