import replaceArray from './replaceArray'
import replaceObject from './replaceObject'
import replaceString from './replaceString'
import { isArray, isObject, isString } from 'lib/variableType'
import { Mapping, MappingArray, MappingObject } from 'lib/reports/types'

interface MappingDataItemParams {
  mapping: Mapping
  dataItem: Data
  aggregation?: Aggregation[]
}

const mappingDataItem = (
  params: MappingDataItemParams
): Mapping | undefined => {
  const { mapping, dataItem, aggregation = [] as Aggregation[] } = params
  let mappedDataItem

  if (isArray(mapping)) {
    mappedDataItem = replaceArray({
      arrayToReplace: mapping as MappingArray,
      itemsToPlace: dataItem,
      aggregation,
    })
  } else if (isObject(mapping)) {
    mappedDataItem = replaceObject({
      objectToReplace: mapping as MappingObject,
      itemsToPlace: dataItem,
      aggregation,
    })
  } else if (isString(mapping)) {
    mappedDataItem = replaceString({
      stringToReplace: mapping as string,
      itemsToPlace: dataItem,
      aggregation,
    })
  }
  if (isObject(mappedDataItem)) {
    return {
      ...mappedDataItem,
      dataId: dataItem?.id,
    }
  }

  return mappedDataItem
}

export default mappingDataItem
