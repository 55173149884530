import { useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { setUser } from 'redux/user'
import { useSnackbar } from 'notistack'
import { getUserId, isCognitoUserAdmin } from 'redux/user'
import useSetAccount from 'hooks/useAccount'
import { getErrorMessage } from 'lib/errors'
import { formatMessage } from 'i18n/ShimokuIntl'
import { getUserByCognitoIdQuery } from 'gql/queries/getUserByCognitoId'
import Sentry, { configureUserScope } from 'tracking/Sentry'

const useUser = () => {
  const [cognitoUser, setCognitoUser] = useState<any>(undefined)
  const history = useHistory()
  const { setAccount } = useSetAccount()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const routeMatch = useRouteMatch<{
    dashboardIdFromUrl: string
    menupath: string
  }>('/:dashboardIdFromUrl/:menupath')

  const fetchCognitoUser = async () => {
    const resCognitoUser = await Auth.currentAuthenticatedUser()
    setCognitoUser(resCognitoUser)
  }

  const setAppUser = (user: User) => {
    dispatch(setUser(user))
    setAccount({ user })
    configureUserScope(user)
  }

  const saveData = async (data?: any, cognitoUser?: any) => {
    if (!cognitoUser || !data?.user) {
      throw new Error(formatMessage('errors.fetchUser'))
    }
    if (!data.user.accounts?.items.length) {
      throw new Error(formatMessage('errors.userWithoutBusinessAccount'))
    }

    const dashboardIdFromUrl = routeMatch?.params.dashboardIdFromUrl
    const dashboardAccount = data.user.accounts?.items.find(
      (account: Account) =>
        account.business.dashboards.items.some(
          (dashboard) => dashboard.id === dashboardIdFromUrl
        )
    )

    const isAdmin = await isCognitoUserAdmin(cognitoUser)

    const user: User = {
      ...data.user,
      ...(dashboardAccount?.id &&
        dashboardAccount.id !== data.user.accountInUseId && {
          accountInUseId: dashboardAccount.id,
        }),
      currentDateTime: data.currentDateTime,
      isAdmin,
    }

    setAppUser(user)
  }

  const fetchUser = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      const params = { id: getUserId(cognitoUser.attributes) }
      const res: any = await API.graphql(
        graphqlOperation(getUserByCognitoIdQuery, params)
      )
      await saveData(res.data, cognitoUser)
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error, 'errors.fetchUser'), {
        variant: 'error',
      })
      Sentry.captureException(error)
      history.push('/sign-in')
    }
  }

  useEffect(() => {
    fetchCognitoUser()
  }, []) // eslint-disable-line

  return { cognitoUser, fetchUser }
}

export default useUser
