import { SankeySeriesOption } from 'echarts'
import { TEXT_STYLE, BORDER_STYLE, SHADOW_STYLE } from '../../defaults/general'
import { TypeSeriesParams } from '../../types'

const sankeySerie = (
  params: TypeSeriesParams<SankeySeriesOption>
): SankeySeriesOption => {
  const {
    typeSeriesModifications: sankeySeriesModifications = {},
    paddingSankey = false,
    theme,
  } = params
  const PALLETE = theme.palette

  return {
    type: 'sankey',
    left: 32,
    top: `${paddingSankey ? 64 : 10}`,
    right: 64,
    bottom: 10,
    nodeWidth: 20,
    nodeGap: 8,
    nodeAlign: 'justify',
    layoutIterations: 32,
    orient: 'horizontal',
    draggable: true,
    selectedMode: true,
    ...sankeySeriesModifications,
    label: {
      show: true,
      position: 'right',
      distance: 4,
      rotate: 0,
      ...TEXT_STYLE(theme),
      fontSize: 12,
      backgroundColor: 'transparent',
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      ...sankeySeriesModifications?.label,
    },
    labelLayout: {
      hideOverlap: false,
      ...sankeySeriesModifications?.labelLayout,
    },
    itemStyle: {
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      ...sankeySeriesModifications?.itemStyle,
    },
    lineStyle: {
      color: PALLETE.grey['600'],
      opacity: 0.2,
      curveness: 0.5,
      ...SHADOW_STYLE,
      ...sankeySeriesModifications?.lineStyle,
    },
  }
}

export default sankeySerie
