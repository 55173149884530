import React from 'react'
import { useHistory } from 'react-router-dom'
import ArrowLeft from 'icons/Line/arrow-left'
import ArrowRight from 'icons/Line/arrow-right'
import { useNavigation as useNavigationContext } from 'contexts/navigation'
import * as S from './HistoryNavigation.styled'

export interface HistoryNavigationProps {
  navigateBefore?: boolean
  navigateNext?: boolean
}

const HistoryNavigation = (props: HistoryNavigationProps) => {
  const { navigateBefore = true, navigateNext = true } = props
  const { goBack, goForward } = useHistory()
  const { historyStack } = useNavigationContext()

  const disabledHistoryBack = (historyStack.position as number) < 1

  const disableHistoryForward =
    historyStack.historyItems.length - 1 <= (historyStack.position as number)

  return (
    <S.HistoryNavigationContainer>
      {navigateBefore && (
        <S.HistoryNavigationButton
          disabled={disabledHistoryBack}
          onClick={goBack}
          data-testid="button-back-history"
        >
          <ArrowLeft />
        </S.HistoryNavigationButton>
      )}
      {navigateNext && (
        <S.HistoryNavigationButton
          disabled={disableHistoryForward}
          onClick={goForward}
          data-testid="button-forward-history"
        >
          <ArrowRight />
        </S.HistoryNavigationButton>
      )}
    </S.HistoryNavigationContainer>
  )
}

export default HistoryNavigation
