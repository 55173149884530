import { SeriesOption, CandlestickSeriesOption } from 'echarts'
import candlestickSerie from '../../defaults/series/candlestick'
import { SeriesParams } from '../../types'

const series = (params: SeriesParams): SeriesOption => {
  const { seriesModifications = {}, theme } = params

  const seriesOption = candlestickSerie({
    typeSeriesModifications: seriesModifications as CandlestickSeriesOption,
    theme,
  })

  const series = [
    {
      ...seriesOption,
    },
  ]

  return series as SeriesOption
}

export default series
