import { FieldGroup } from 'components/ReportForm/types'

const getReferencedFields = (data: FieldGroup[]) => {
  const referencedFields = data.map(({ fields }) => {
    const referenced = fields.map((field) =>
      field.reference
        ? {
            [field.reference]: field.mapping,
          }
        : field
    )
    return Object.assign({}, ...referenced)
  })

  return Object.assign({}, ...referencedFields)
}

export default getReferencedFields
