import { API, graphqlOperation } from 'aws-amplify'
import updateDataMutation from 'gql/mutations/updateData'
import createDataMutation from 'gql/mutations/createData'
import deleteDataMutation from 'gql/mutations/deleteData'
import { getErrorMessage } from 'lib/errors'
import Sentry from 'tracking/Sentry'
import { FieldError } from 'react-hook-form'

const CODE_LENGTH = 6

/**
- At least 8 characters
- At least one upper and lower case letter
- At least one number
 */
export const haveUpperLowerAndEightLength: RegExp = new RegExp(
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
)

/**
- At least 12 characters
- At least one upper and lower case letter
- At least one number
- At least one "especial symbol", excludes withespaces
 */
export const haveUpperLowerNonWordAndTwelveLength: RegExp = new RegExp(
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\w\s]).{12,}$/
)

export const hasValues = (obj: any) => {
  return Boolean(Object.values(obj).filter((value) => Boolean(value)).length)
}

export const isValidEmail = (email: string): boolean =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

export const isValidPassword = (password: string) =>
  haveUpperLowerNonWordAndTwelveLength.test(password)

export const areValidPasswords = (
  password: string,
  repassword: string
): boolean => {
  return isValidPassword(password) && password === repassword
}

export const isValidCode = (code: string): boolean =>
  code.length === CODE_LENGTH

export const objectsAreEqual = (obj1: any, obj2: any): boolean => {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false
  }
  for (const key in obj1) {
    if (
      !obj1.hasOwnProperty(key) ||
      !obj2.hasOwnProperty(key) ||
      obj1[key] !== obj2[key]
    ) {
      return false
    }
  }
  return true
}

export const differentObjectProperty =
  (defaultObject: any, targetObject: any) => (property: string) => {
    return defaultObject[property] !== targetObject[property]
  }

/**
 * TODO: this could be coded better, review it.
 */
export const defaultFalsyValues = (
  givenObject: any,
  defaultValue: any = ''
) => {
  const parsedObject: any = {}
  for (const key in givenObject) {
    if (givenObject.hasOwnProperty(key)) {
      parsedObject[key] = givenObject[key] || defaultValue
    }
  }
  return parsedObject
}

export const updateData = async (dataToSend: any) => {
  try {
    await API.graphql(
      graphqlOperation(updateDataMutation, { input: dataToSend })
    )
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(getErrorMessage(error, 'errors.updateFormReport'))
  }
}

export const deleteData = async (id: string) => {
  try {
    await API.graphql(graphqlOperation(deleteDataMutation, { id }))
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(getErrorMessage(error, 'errors.deleteData'))
  }
}

export const createData = async (dataToSend: any) => {
  try {
    const res: any = await API.graphql(
      graphqlOperation(createDataMutation, { input: dataToSend })
    )
    return res.data.createData
  } catch (error) {
    Sentry.captureException(error)
    throw new Error(getErrorMessage(error, 'errors.createData'))
  }
}

export function hasError(
  errors: Record<string, FieldError | undefined>,
  key: string
): key is keyof typeof errors {
  return key in errors
}
