import React, { useState, Dispatch, SetStateAction } from 'react'
import { useSelector } from 'react-redux'
import { selectAccount } from 'redux/account'
import { useSnackbar } from 'notistack'
import { Box, Typography } from '@mui/material'
import Button from 'components/Button'
import { CheckCercle } from 'icons/Line/check-cercle/check-cercle'
import { formatCredits } from 'lib/plan'
import handleSuscribe from 'lib/stripe/handleSuscribe'
import handleManageSubscription from 'lib/stripe/handleManageSubscription'
import { formatMessage } from 'i18n/ShimokuIntl'
import * as S from './Plan.styled'
import { usePostHog } from 'posthog-js/react'
import { SUSCRIPTIONS_EVENTS } from 'lib/postHogEvents'

export enum PlanTypeEnum {
  CORPORATE = 'Corporate',
  FREE = 'Free',
}

export type PlanType = PlanTypeEnum | string

type PlanProps = {
  id: string
  activePlanType: PlanType
  description?: string
  setAllPlansDisabled: Dispatch<SetStateAction<boolean>>
  disabled?: boolean
  featured?: boolean
  features?: string
  image?: string
  limits: string
  price: number
  type: PlanType
}

const Plan = ({
  id: universePlanTypeId,
  activePlanType,
  description,
  setAllPlansDisabled,
  disabled = false,
  featured = false,
  features,
  image,
  limits,
  price,
  type,
}: PlanProps) => {
  const account = useSelector(selectAccount)
  const universeId = account.business.universe.id
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const posthog = usePostHog()
  const parsedLimits = JSON.parse(limits)
  const featuresObject = features
    ? Object.entries(JSON.parse(features)).sort(([keyA], [keyB]) => {
        const aNum = parseInt(keyA.replace('feature-', ''), 10)
        const bNum = parseInt(keyB.replace('feature-', ''), 10)
        return aNum - bNum
      })
    : []
  const isDowngrade =
    activePlanType !== PlanTypeEnum.FREE && type === PlanTypeEnum.FREE

  const handleButtonClick = () => {
    setIsLoading(true)
    setAllPlansDisabled(true)
    if (activePlanType === PlanTypeEnum.FREE) {
      handleSuscribe({ universeId, universePlanTypeId, enqueueSnackbar })
      posthog?.capture(SUSCRIPTIONS_EVENTS.UPGRADE_PLAN)
    } else {
      if (isDowngrade) {
        posthog?.capture(SUSCRIPTIONS_EVENTS.DOWNGRADE_PLAN)
      } else {
        posthog?.capture(SUSCRIPTIONS_EVENTS.UPGRADE_PLAN)
      }
      handleManageSubscription({ universeId, enqueueSnackbar })
    }
  }

  return (
    <S.PlanItemContainer highlited={featured}>
      <S.PlanContent>
        <Box
          display="flex"
          marginBottom={2}
          width="60px"
          height="70px"
          alignSelf="center"
        >
          <img alt="Plan icon" src={image} width="100%" />
        </Box>
        <Typography
          variant="h5"
          marginBottom={1}
          sx={{ textTransform: 'capitalize' }}
        >
          {type.toLowerCase()}
        </Typography>
        <Typography variant="bodyS400" marginBottom={3}>
          {description}
        </Typography>
        <Box flex={1} marginBottom={4}>
          {type !== PlanTypeEnum.CORPORATE && (
            <Typography variant="h1">
              {`${price}€`}
              <Typography variant="bodyS400">
                / {formatMessage('generic.month')}
              </Typography>
            </Typography>
          )}
        </Box>
        <Box flex={1}>
          <Typography variant="bodyS600" marginBottom={1}>
            {formatMessage('component.plan.includes')}
          </Typography>
          <S.PlanDetailsList>
            <S.PlanDetailsListItem>
              <CheckCercle width="18px" height="18px" />{' '}
              <Typography variant="bodyS400" marginLeft={2}>
                {formatCredits(parsedLimits.totalCredits)}{' '}
                {formatMessage('component.plan.credits')}
              </Typography>
            </S.PlanDetailsListItem>
            {featuresObject.map(([key, value]) => (
              <S.PlanDetailsListItem key={key}>
                <CheckCercle width="18px" height="18px" />{' '}
                <Typography variant="bodyS400" marginLeft={2}>
                  {formatMessage(value as string)}
                </Typography>
              </S.PlanDetailsListItem>
            ))}
          </S.PlanDetailsList>
        </Box>
        <S.ButtonContainer className="ph-no-capture">
          {activePlanType === type ? (
            <Button variant="text" inverted fullWidth>
              {formatMessage('generic.active')}
            </Button>
          ) : type === PlanTypeEnum.CORPORATE ? (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={disabled}
              href="mailto:contact@shimoku.com"
              onClick={() => posthog?.capture(SUSCRIPTIONS_EVENTS.CONTACT_US)}
            >
              {formatMessage('component.plan.talkToSales')}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={disabled}
              loading={isLoading}
              onClick={handleButtonClick}
            >
              {isDowngrade
                ? formatMessage('generic.downgrade')
                : formatMessage('generic.upgrade')}
            </Button>
          )}
        </S.ButtonContainer>
      </S.PlanContent>
    </S.PlanItemContainer>
  )
}

export default Plan
