import { useReportDataSets } from '../index'

interface UseGetReportDataSetsParams {
  reportDataSets: ReportDataSet[]
}

const useReportDataSetsByReportDataSets = (
  params: UseGetReportDataSetsParams
) => {
  const { reportDataSets: defaultReportDataSets } = params
  const { reportDataSets: allReportDataSets } = useReportDataSets()

  const reportDataSetIds = new Set(
    defaultReportDataSets.map((defaultReportDataSet) => defaultReportDataSet.id)
  )

  const reportDataSets = allReportDataSets.filter((reportDataSet) =>
    reportDataSetIds.has(reportDataSet.id)
  )

  return { reportDataSets }
}

export default useReportDataSetsByReportDataSets
