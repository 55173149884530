import React, {
  createContext,
  ReactNode,
  useContext,
  useReducer,
  useEffect,
} from 'react'
import { OnDataOptions } from '@apollo/client'
import useGraphQLOperation from 'hooks/useGraphQLOperation'
import {
  GraphQLOperationTypeEnum,
  GraphQLOperationOptions,
} from 'hooks/useGraphQLOperation/types'
import usePlaygroundIsRunning from 'hooks/usePlaygroundIsRunning'
import { useRealTimeComponents } from './useRealTimeComponents'
import realTimeEventReducer from './realTimeEventsReducer'
import onEventCreatedSubscription, {
  playgroundOnEventCreated,
} from 'gql/subscriptions/onEventCreated'
import {
  RealTimeEvent,
  RegisterRealTimeComponentParams,
  UnregisterRealTimeComponentParams,
  AuthResourceType,
} from './types'
import { RealTimeEventsReducerActionType } from './realTimeEventsReducer/types'
import { useSelector } from 'react-redux'
import { selectUser } from 'redux/user'
import { selectAccount } from 'redux/account'

const { ADD_REAL_TIME_EVENT } = RealTimeEventsReducerActionType
const { SUBSCRIPTION } = GraphQLOperationTypeEnum

interface RealTimeContextValue {
  registerRealTimeComponent: (params: RegisterRealTimeComponentParams) => void
  unregisterRealTimeComponent: (
    params: UnregisterRealTimeComponentParams
  ) => void
}

const RealTimeContext = createContext<RealTimeContextValue | undefined>(
  undefined
)

interface RealTimeProviderProps {
  children: ReactNode
}

const RealTimeProvider = (props: RealTimeProviderProps) => {
  const { children } = props
  const graphQLOperation = useGraphQLOperation()
  const user = useSelector(selectUser)
  const account: Account = useSelector(selectAccount)
  const universeId = account.business?.universe?.id || ''
  const currentBusinessId = account.business?.id
  const playgroundIsRunning = usePlaygroundIsRunning()
  const realTimeEventInitialState = {} as RealTimeEvent
  const [realTimeEvent, dispatchRealTimeEvent] = useReducer(
    realTimeEventReducer,
    realTimeEventInitialState
  )
  const {
    realTimeComponents,
    registerRealTimeComponent,
    unregisterRealTimeComponent,
  } = useRealTimeComponents()

  const value: RealTimeContextValue = {
    registerRealTimeComponent,
    unregisterRealTimeComponent,
  }

  useEffect(() => {
    if (Boolean(Object.keys(realTimeEvent).length) && realTimeComponents) {
      const callback = realTimeComponents[realTimeEvent.type]

      if (typeof callback === 'object') {
        const id = realTimeEvent.resourceId
        if (callback[id]) {
          callback[id](realTimeEvent)
        }
      } else {
        callback(realTimeEvent)
      }
    }
  }, [realTimeEvent]) // eslint-disable-line

  useEffect(() => {
    if (Boolean(playgroundIsRunning)) {
      const onData = (options: OnDataOptions) => {
        dispatchRealTimeEvent({
          type: ADD_REAL_TIME_EVENT,
          //@ts-ignore
          payload: options.data.onEventCreated,
        })
      }

      const options = { onData }
      graphQLOperation({
        operationType: SUBSCRIPTION,
        operation: playgroundOnEventCreated,
        options,
      })
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    const onData = (options: any) => {
      dispatchRealTimeEvent({
        type: options.value.data.onEventCreated.type,
        payload: options.value.data.onEventCreated,
      })
    }

    if (
      user &&
      user.apiToken &&
      universeId &&
      !playgroundIsRunning &&
      currentBusinessId
    ) {
      const AWS_LAMBDA = 'AWS_LAMBDA'

      const { BUSINESS_ADMIN, BUSINESS_USER, UNIVERSE_ADMIN, UNIVERSE_USER } =
        AuthResourceType
      type AuthResourceTypeVariablesType = {
        authResourceType: AuthResourceType
        mainAuthResourceId: string
      }
      const AuthResourceTypeVariables: AuthResourceTypeVariablesType[] = [
        { authResourceType: UNIVERSE_ADMIN, mainAuthResourceId: universeId },
        {
          authResourceType: BUSINESS_ADMIN,
          mainAuthResourceId: currentBusinessId,
        },
        { authResourceType: UNIVERSE_USER, mainAuthResourceId: universeId },
        {
          authResourceType: BUSINESS_USER,
          mainAuthResourceId: currentBusinessId,
        },
      ]
      AuthResourceTypeVariables.forEach((authResourceTypeVariable) => {
        const options: GraphQLOperationOptions = {
          onData,
          variables: authResourceTypeVariable,
          authToken: user?.apiToken ?? undefined,
          authMode: AWS_LAMBDA,
        }

        graphQLOperation({
          operationType: SUBSCRIPTION,
          operation: onEventCreatedSubscription,
          options,
          user,
        })
      })
    }
  }, [user.id, currentBusinessId, universeId, playgroundIsRunning]) // eslint-disable-line

  return (
    <RealTimeContext.Provider value={value}>
      {children}
    </RealTimeContext.Provider>
  )
}

const useRealTime = () => {
  const context = useContext(RealTimeContext)

  if (context === undefined) {
    throw new Error('useRealTime must be used within a RealTimeProvider')
  }

  return context
}

export { RealTimeProvider, useRealTime }
