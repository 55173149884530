const Messages: StringObject = require('./messages.json')

type TranslationValue = number | string | undefined
type TranslationValues = {
  [key: string]: TranslationValue
}

const replaceTermValues = (
  term: string,
  values: TranslationValues = {}
): string => {
  const keys: string[] = Object.keys(values)
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = values[key]
    if (value !== undefined) {
      term = term.replace(new RegExp(`{${key}}`, 'g'), value.toString())
    }
  }
  return term
}

export const formatMessage = (
  key: string,
  values?: TranslationValues
): string => {
  const term = Messages[key]
  if (!term) {
    return key
  }
  return replaceTermValues(term, values)
}
