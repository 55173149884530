import React, { useRef, useEffect, useState, ReactElement } from 'react'
import { Tooltip } from '@mui/material'
import * as S from './OverflowTooltip.styled'

interface OverflowTooltipProps {
  title: string
  children: string | ReactElement
  margin?: string
  arrow?: boolean
  color?: string
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}

const OverflowTooltip = ({
  title,
  placement = 'top',
  children,
  arrow,
  margin,
}: OverflowTooltipProps) => {
  const textElementRef = useRef<HTMLDivElement>(null)
  const [hoverStatus, setHover] = useState<boolean>(false)

  useEffect(() => {
    compareSize()
  }, [])

  const compareSize = () => {
    const { current } = textElementRef
    const haveChildren = current!.children.length > 0
    const haveOverlow: boolean = haveChildren
      ? current!.children[0].scrollWidth > current!.children[0].clientWidth
      : current!.scrollWidth > current!.clientWidth
    setHover(haveOverlow)
  }

  return (
    <Tooltip
      arrow={arrow}
      placement={placement}
      title={title}
      disableHoverListener={!hoverStatus}
    >
      <S.Text margin={margin} ref={textElementRef}>
        {children}
      </S.Text>
    </Tooltip>
  )
}

export default OverflowTooltip
