const listDataTotal = /* GraphQL */ `
  query listDataTotal(
    $dataSetId: ID!
    $filter: SearchableDataFilterInput
    $publicPermissions: DashboardPublicPermissionInputExposed
  ) {
    listData(
      dataSetId: $dataSetId
      filter: $filter
      publicPermissions: $publicPermissions
    ) {
      total
    }
  }
`

export default listDataTotal
