import getAppRoutes from 'lib/apps/getAppRoutes'

const deepClone = (array: any) => {
  return JSON.parse(JSON.stringify(array))
}

export const toogleOpenedStatus = (
  menuItemList: MenuItem[],
  index: number
): MenuItem[] => {
  const newMenuItemList = [...menuItemList]
  newMenuItemList[index].opened = !newMenuItemList[index].opened
  return newMenuItemList
}

export const checkDashboardOpenAndActiveStatus = (
  list: MenuItem[],
  pathname: string
): MenuItem[] =>
  list.map((app: MenuItem): MenuItem => {
    const { rootApp, subApps } = getAppRoutes(app)
    const pathToUpperCase = pathname?.toUpperCase()
    const childrenActive = subApps.some(
      ({ path }) => pathToUpperCase === path.toUpperCase()
    )
    app.active =
      pathToUpperCase === rootApp.path.toUpperCase() || childrenActive
    if (childrenActive) {
      app.opened = true
    }
    return app
  })

interface CheckOpenAndActiveStatusParams {
  list: MenuItem[]
  pathname: string
  dashboard: Dashboard
  prefix?: string
}

export const checkOpenAndActiveStatus = (
  params: CheckOpenAndActiveStatusParams
): MenuItem[] => {
  const { list, pathname, dashboard, prefix } = params

  return list.map((app: MenuItem): MenuItem => {
    const { rootApp, subApps } = getAppRoutes({ app, dashboard, prefix })
    const pathToUpperCase = pathname?.toUpperCase()
    const childrenActive = subApps.some(
      ({ path }) => pathToUpperCase === path.toUpperCase()
    )
    app.active =
      pathToUpperCase === rootApp.path.toUpperCase() || childrenActive
    if (childrenActive) {
      app.opened = true
    }
    return app
  })
}

export const addDashboardActiveStatus = (
  items: Dashboard[],
  pathname: string
): MenuItem[] => {
  return items.map((dashboard) => {
    const urlActive = pathname.includes(dashboard.id)
    return {
      ...dashboard,
      opened: urlActive,
      active: urlActive,
    }
  })
}
export const addOpenAndActiveStatus = (
  items: App[],
  pathname: string,
  dashboard: Dashboard
): MenuItem[] => {
  const cloneApps: App[] = deepClone(items)
  return cloneApps.map((app: App): MenuItem => {
    const { rootApp, subApps } = getAppRoutes({
      app,
      dashboard,
    })
    const pathToUpperCase = pathname?.toUpperCase()
    const rootRouteToUpperCase = rootApp.path.toUpperCase()
    const childrenActive = subApps.some(
      ({ path }) => pathToUpperCase === path.toUpperCase()
    )
    const newItem: MenuItem = {
      ...app,
      opened: pathToUpperCase === rootRouteToUpperCase || childrenActive,
      active: pathToUpperCase === rootRouteToUpperCase || childrenActive,
    }
    return newItem
  })
}

export const getAllApps = (appDashboards: AppDashboard[]): App[] =>
  appDashboards.map((appDashboard: AppDashboard) => appDashboard.app)
