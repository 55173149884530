type StoredValue<T> = T | null

function safelyExecute<T>(operation: () => T): T | null {
  try {
    return operation()
  } catch (error) {
    console.error(`Error occurred during local storage operation: ${error}`)
    return null
  }
}

export function getItem<T>(key: string): StoredValue<T> {
  return safelyExecute(() => {
    const item = window.localStorage.getItem(key)
    return item ? (JSON.parse(item) as T) : null
  })
}

export function setItem<T>(key: string, value: T): void {
  safelyExecute(() => {
    const jsonValue = JSON.stringify(value)
    window.localStorage.setItem(key, jsonValue)
  })
}

export function removeItem(key: string): void {
  safelyExecute(() => {
    window.localStorage.removeItem(key)
  })
}
