import { getItem, setItem } from 'lib/localStorage'
import { STARTUP_APP_KEY } from '../index'
import { StartupApps } from '../types'

interface SetStartupAppParams {
  account: Account
  app: App
}

const setStartupApp = (params: SetStartupAppParams) => {
  const {
    account: { id: accountId },
    app,
  } = params
  if (!app || !app.id) return

  const appId = app.id
  const startupApps = getItem<StartupApps>(STARTUP_APP_KEY) || {}
  const startupApp = { ...startupApps, [accountId]: appId }

  setItem(STARTUP_APP_KEY, startupApp)
}

export default setStartupApp
