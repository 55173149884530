import { Theme } from '@mui/material'
import {
  Annotation,
  DefaultAnnotationFormValues,
} from 'components/AnnotationModal/annotationModal'
import { EChartsOption, SeriesOption } from 'echarts'
import getFontSize from 'lib/reports/getFontSize'
import { SliderProperties } from 'lib/reports/types'
import { GRID_TOP } from '.'

type GetLabelParams = {
  text: string
  theme: Theme
  serieIndex: number
}

type GetSerieWithAnnotationParams = {
  annotation: Annotation
  option: EChartsOption
  theme: Theme
  slider?: SliderProperties
  isEditing?: boolean
  selectedDataPoint?: DefaultAnnotationFormValues
  index?: number
}

type GenerateDataToSendProps = {
  annotation: Annotation
  index: number
  dataSetId?: string
  isSecondDate?: boolean
}

const getLabel = ({ text, theme, serieIndex }: GetLabelParams) => {
  // @ts-ignore
  const fontSize = theme.typography.bodyS400.fontSize || 14
  const fontSizePx = getFontSize(fontSize, theme.typography.fontSize)
  const availableColors = Object.values(theme.custom.chart.colors)
  return {
    label: {
      show: true,
      color: theme.palette.common.black,
      fontSize: fontSizePx,
      fontFamily: theme.typography.fontFamily,
      backgroundColor: theme.palette.common.white,
      padding: [8, 12],
      position: 'top',
      borderWidth: '3px',
      borderColor: availableColors[serieIndex],
      borderRadius: 4,
      distance: GRID_TOP,
      overflow: 'truncate',
      ellipsis: '...',
      width: 120,
      formatter: () => text,
    },
    labelLine: {
      show: true,
      lineStyle: {
        width: 2,
        cap: 'round',
        join: 'round',
        type: 'dashed',
      },
    },
  }
}

const setOptionsWithAnnotations = ({
  annotation,
  option,
  theme,
  isEditing = false,
  selectedDataPoint,
  index = 0,
}: GetSerieWithAnnotationParams) => {
  if (!option.series || !(option.series as Array<SeriesOption>).length) {
    throw new Error('No series found')
  }
  const { category, text, date, intensity, dataId } = annotation
  const serieIndex = (option.series as Array<SeriesOption>).findIndex(
    (serie: SeriesOption) => serie.name === category
  )
  if (serieIndex === -1) {
    throw new Error('No serie found')
  }
  const serie = (option.series as Array<any>)[serieIndex]
  const serieData = serie.data as Array<any>
  if (!serieData) {
    throw new Error('No serie data found')
  }
  const dataWithLabel =
    index === 0
      ? {
          dataId,
          value: [date[index], Number(intensity)],
          ...getLabel({
            text,
            theme,
            serieIndex,
          }),
        }
      : {
          dataId,
          value: [date[index], Number(intensity)],
        }
  if (isEditing && selectedDataPoint) {
    const dataIndex = serieData.findIndex(
      (data: any) => data.dataId === selectedDataPoint.dataId
    )

    if (dataIndex !== -1) {
      serieData[dataIndex] = dataWithLabel
    } else {
      serieData.push(dataWithLabel)
    }
  } else {
    serieData.push(dataWithLabel)
  }
  const sortedData = sortSeriesByDate(serieData)
  const updatedSeries = [...(option.series as Array<any>)]
  updatedSeries[serieIndex] = {
    ...serie,
    data: sortedData,
  }
  const updatedOption = {
    ...option,
    series: updatedSeries,
  }

  return updatedOption
}

export const getFormatedSeriesData = (option: any, theme: Theme) => {
  return option.current.series.map((serie: any, i: number) => {
    if (!serie.data) return serie
    const value = serie.data.map((data: any) => {
      if (!data.label) {
        return {
          dataId: data.dataId,
          value: [data.values[0], data.values[1]],
        }
      }
      return {
        value: data.values,
        dataId: data.dataId,
        ...getLabel({
          text: data.label,
          theme,
          serieIndex: i,
        }),
      }
    })
    const sortedData = sortSeriesByDate(value)
    return {
      ...serie,
      areaStyle: { origin: 'auto' },
      smooth: 0.5,
      smoothMonotone: 'y',
      data: sortedData,
    }
  })
}

export const formatXAxis = (option: any) => {
  return {
    ...option.current.xAxis,
    type: 'time',
    position: 'bottom',
    offset: 10,
    axisLine: {
      onZero: false,
      lineStyle: {
        width: 20,
        color: 'var(--color-grey-800)',
        cap: 'square',
      },
    },
    axisTick: {
      inside: false,
      show: true,
      length: 40,
      lineStyle: {
        color: 'white',
        dashOffset: 300,
        width: 2,
        miterLimit: 2,
        cap: 'square',
      },
    },
    axisLabel: {
      showMaxLabel: false,
      showMinLabel: false,
      color: 'var(--color-white)',
      align: 'left',
      padding: [0, 0, 0, 20],
      margin: -5,
      fontSize: 14,
      fontFamily: 'Rubik,sans-serif',
    },
  }
}

export const generateDataToSend = ({
  annotation,
  index,
  dataSetId,
  isSecondDate = false,
}: GenerateDataToSendProps) => {
  return {
    ...(dataSetId !== undefined ? { dataSetId } : {}),
    dateField1: annotation.date[index],
    stringField1: !isSecondDate ? annotation.text : null,
    intField1: Number(annotation.intensity),
  }
}

export const deleteDataPointFromOption = ({
  option,
  dataId,
  category,
}: {
  option: any
  dataId: string
  category: string
}) => {
  const serieIndex = option.current.series.findIndex(
    (serie: any) => serie.name === category
  )

  const dataIndex = option.current.series[serieIndex].data.findIndex(
    (data: any) => data.dataId === dataId
  )

  if (dataIndex === -1) {
    return option
  }

  return {
    ...option,
    current: {
      ...option.current,
      series: option.current.series.map((serie: any, index: number) =>
        index === serieIndex
          ? {
              ...serie,
              data: [
                ...serie.data.slice(0, dataIndex),
                ...serie.data.slice(dataIndex + 1),
              ],
            }
          : serie
      ),
    },
  }
}

export const sortSeriesByDate = (value: any) => {
  if (!Array.isArray(value)) {
    return undefined
  }

  return value.sort(
    (a: any, b: any) => Date.parse(a.value[0]) - Date.parse(b.value[0])
  )
}

export default setOptionsWithAnnotations
