import { Theme } from '@mui/material/styles'
import { SingleAxisComponentOption } from 'echarts'
import { TEXT_STYLE } from './general'
import {
  AXIS_LINE,
  AXIS_TICK,
  AXIS_LABEL,
  SPLIT_LINE,
  SPLIT_AREA,
} from './general'
import { AxisParams } from '../types'

export const getDefaultSingleAxis = (theme: Theme): AxisParams => ({
  name: '',
  type: 'category',
  theme,
})

const singleAxis = (params: AxisParams): SingleAxisComponentOption => {
  const { name = '', type = 'category', theme } = params

  return {
    id: undefined,
    orient: 'horizontal',
    type,
    right: '8%',
    bottom: 0,
    left: '8%',
    name,
    nameLocation: 'start',
    nameGap: 16,
    boundaryGap: false,
    scale: false,
    nameTextStyle: {
      ...TEXT_STYLE(theme),
    },
    axisLine: {
      ...AXIS_LINE(theme),
    },
    axisTick: {
      ...AXIS_TICK(theme),
    },
    axisLabel: {
      ...AXIS_LABEL(theme),
    },
    splitLine: {
      ...SPLIT_LINE(theme),
      show: false,
    },
    splitArea: {
      ...SPLIT_AREA,
    },
  }
}

export default singleAxis
