import { TableProperties, DATA_FIELD_TYPES } from 'lib/reports/types'
import { MATCH_PHRASE_PREFIX } from 'containers/Table/utils/getFilter/getOperator'

const { STRING_FIELD } = DATA_FIELD_TYPES

interface GetSearchFilterParams {
  quickFilterValues: string[]
  tableProperties: TableProperties
}

const getSearchFilter = (params: GetSearchFilterParams) => {
  const { quickFilterValues, tableProperties } = params

  if (!quickFilterValues.length) {
    return null
  }

  const value = quickFilterValues.join(' ')
  const {
    rows: { mapping: rowsMapping },
  } = tableProperties

  const fields = Object.values(rowsMapping).filter((field: string) =>
    field.startsWith(STRING_FIELD)
  )

  const searchFilter = {
    or: fields.map((field: string) => ({
      [field]: { [MATCH_PHRASE_PREFIX]: value },
    })),
  }

  return searchFilter
}

export default getSearchFilter
