import { DataZoomComponentOption } from 'echarts'
import { Theme } from '@mui/material/styles'
import { SHADOW_STYLE, BORDER_STYLE, TEXT_STYLE } from './general'

interface DataZoomParams {
  dataZoom?: boolean
  theme: Theme
}

const dataZoom = (params: DataZoomParams): DataZoomComponentOption => {
  const { dataZoom: show, theme } = params
  const CHART_OPTIONS = theme.custom.chart.options

  return {
    type: 'slider',
    id: '',
    show,
    backgroundColor: 'transparent',
    fillerColor: CHART_OPTIONS.generalColor['200'],
    borderColor: CHART_OPTIONS.generalColor['200'],
    filterMode: 'filter',
    start: 0,
    end: 100,
    startValue: undefined,
    endValue: 60,
    orient: 'horizontal',
    moveHandleSize: 4,
    showDetail: true,
    realtime: true,
    bottom: 10,
    dataBackground: {
      lineStyle: {
        color: CHART_OPTIONS.dataBackground,
        width: 1,
        type: 'solid',
        ...SHADOW_STYLE,
        opacity: 1,
      },
      areaStyle: {
        color: CHART_OPTIONS.dataBackground,
        ...SHADOW_STYLE,
        opacity: 1,
      },
    },
    selectedDataBackground: {
      lineStyle: {
        color: CHART_OPTIONS.dataBackground,
        width: 1,
        type: 'solid',
        ...SHADOW_STYLE,
        opacity: 1,
      },
      areaStyle: {
        color: CHART_OPTIONS.dataBackground,
        ...SHADOW_STYLE,
        opacity: 1,
      },
    },
    handleStyle: {
      color: 'rgba(255,255,255,1)',
      borderColor: CHART_OPTIONS.generalColor['700'],
      borderWidth: 1,
      borderType: 'solid',
      ...SHADOW_STYLE,
    },
    moveHandleStyle: {
      color: CHART_OPTIONS.generalColor['400'],
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
    },
    textStyle: {
      ...TEXT_STYLE(theme),
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.54)',
    },
    emphasis: {
      moveHandleStyle: {
        color: CHART_OPTIONS.generalColor['400'],
      },
    },
  }
}

export default dataZoom
