import React, { SVGProps } from 'react'

export const CloudDownload = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
    >
      <title>cloud-download-icon</title>
      <path
        d="M18 14.8675C19.8027 13.7287 21 11.7185 21 9.42857C21 5.87817 18.1218 3 14.5714 3C11.3736 3 8.7212 5.33485 8.22586 8.39276C7.80049 8.23127 7.33914 8.14285 6.85714 8.14285C4.7269 8.14285 3 9.86975 3 12C3 13.1383 3.49311 14.1615 4.27738 14.8675C4.49692 15.0651 4.73927 15.2379 5 15.3814"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 21L12 19L12 17L12 15L12 13"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M15 18L12 21L9 18"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
