import { styled } from '@mui/material/styles'
import { Paper } from '@mui/material'

interface ModalContainerProps {
  width?: number
  height?: number
}

export const ModalContainer = styled(Paper)<ModalContainerProps>(
  ({ width = 50, height = 80, theme: { breakpoints } }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: `${width}%`,
    height: 'auto',
    maxHeight: `${height}vh`,
    padding: '1.25rem',
    overflowY: 'auto',
    [breakpoints.down('md')]: {
      width: '80%',
    },
    [breakpoints.down('sm')]: {
      width: '90%',
    },
  })
)
