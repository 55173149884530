export const isArray = (variable: any) => Array.isArray(variable)

const OBJECT = '[object Object]'
export const isObject = (variable: any) =>
  Object.prototype.toString.call(variable) === OBJECT

export const isString = (variable: any) => typeof variable === 'string'

export const isNumber = (variable: any) => typeof variable === 'number'

export const isNullOrUndefined = (variable: any) =>
  variable === null || variable === undefined

export const isFile = (variable: any) => variable instanceof File
