import React from 'react'
import { Controller, Control } from 'react-hook-form'
import { PasswordInput, TextInput } from 'components/Input'
import { Checkbox, FormControlLabel } from '@mui/material'
import { formatMessage } from 'i18n/ShimokuIntl'
import * as GS from '../../material/global.styled'

export interface AuthFormField {
  type: string
  name: string
  required?: boolean
  label?: string
  placeholder?: string
  helperText?: string
  valueToGet?: string
  defaultValue?: string
}
export interface AuthFormInputProps extends AuthFormField {
  control: Control<any, any>
  errors: any
  getValues?: (value: string) => any
}

export interface AuthPasswordFormField extends AuthFormField {
  passwordRegex: RegExp
}
export interface AuthFormPasswordInputProps
  extends AuthFormInputProps,
    AuthPasswordFormField {}

const AuthFormInput = (
  props: AuthFormPasswordInputProps | AuthFormInputProps
) => {
  const {
    control,
    errors,
    getValues,
    helperText,
    label = '',
    name,
    placeholder,
    required = false,
    type,
    valueToGet = '',
  } = props
  switch (type) {
    case 'password':
      const { passwordRegex } = props as AuthFormPasswordInputProps
      return (
        <Controller
          name={name}
          control={control}
          rules={{ required, pattern: passwordRegex }}
          render={({ field }) => (
            <PasswordInput
              {...field}
              defaultValue={field.value}
              required={required}
              fullWidth
              error={Boolean(errors[name])}
              label={label}
              placeholder={placeholder}
              helperText={helperText}
            />
          )}
        />
      )
    case 'email':
      return (
        <Controller
          name={name}
          control={control}
          rules={{ required, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ }}
          render={({ field }) => (
            <TextInput
              {...field}
              fullWidth
              required={required}
              defaultValue={field.value}
              label={label}
              placeholder={placeholder}
              error={Boolean(errors[name])}
              helperText={errors[name] && helperText}
            />
          )}
        />
      )

    case 'repassword':
      return (
        <Controller
          name={name}
          control={control}
          rules={{
            required: true,
            validate:
              getValues &&
              ((value) =>
                value === getValues(valueToGet) ||
                formatMessage('errors.passwordsDontMatch')),
          }}
          render={({ field }) => (
            <PasswordInput
              {...field}
              defaultValue={field.value}
              required={true}
              fullWidth
              error={Boolean(errors[name])}
              label={label}
              placeholder={placeholder}
              helperText={errors[name]?.message || helperText}
            />
          )}
        />
      )
    case 'text':
      return (
        <Controller
          name={name}
          control={control}
          rules={{ required }}
          render={({ field }) => (
            <TextInput
              {...field}
              fullWidth
              required={required}
              defaultValue={field.value}
              error={Boolean(errors[name])}
              label={label}
              placeholder={placeholder}
            />
          )}
        />
      )
    case 'checkbox':
      return (
        <FormControlLabel
          sx={{ alignItems: 'flex-start' }}
          control={
            <Controller
              name={name}
              control={control}
              rules={{ required }}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  defaultChecked={field.value}
                  sx={{ padding: '0 0.5rem 0 0' }}
                />
              )}
            />
          }
          label={
            <GS.TextWithLink
              dangerouslySetInnerHTML={{
                __html: label,
              }}
            />
          }
        />
      )
    default:
      return <></>
  }
}

export default AuthFormInput
