import React, { useState } from 'react'
import { Box } from '@mui/material'
import {
  DateRangePicker as DateRangePickerPro,
  DateRange,
} from '@mui/x-date-pickers-pro/DateRangePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TextInput } from 'components/Input'

type DateRangePickerProps = {
  dateRange?: DateRange<Date>
  startText?: string
  endText?: string
  rangeText?: string
  disabled?: boolean
  disablePast?: boolean
  disableFuture?: boolean
  calendars?: 1 | 2 | 3
  getCurrentRange?: (value: DateRange<Date>) => void
}

const DateRangePicker = ({
  dateRange = [null, null],
  startText = 'Start',
  endText = 'End',
  rangeText = 'to',
  disabled = false,
  disablePast = false,
  disableFuture = false,
  calendars = 2,
  getCurrentRange,
}: DateRangePickerProps) => {
  const [value, setValue] = useState<DateRange<Date> | null>(null)
  const handleChange = (value: DateRange<Date>) => {
    setValue(value)
    getCurrentRange && getCurrentRange(value)
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePickerPro
        startText={startText}
        disabled={disabled}
        endText={endText}
        calendars={calendars}
        disablePast={disablePast}
        disableFuture={disableFuture}
        value={value ?? [dateRange[0], dateRange[1]]}
        onChange={(newValue) => handleChange(newValue)}
        renderInput={(startProps, endProps) => (
          <Box display="flex" alignItems="center" data-testid="dateRangePicker">
            <TextInput
              {...startProps}
              // @ts-ignore */
              label={null}
              inputProps={{
                ...startProps.inputProps,
                placeholder: startText,
              }}
            />
            <Box sx={{ mx: 2 }}> {rangeText} </Box>
            <TextInput
              {...endProps}
              // @ts-ignore */
              label={null}
              inputProps={{
                ...endProps.inputProps,
                placeholder: endText,
              }}
            />
          </Box>
        )}
      />
    </LocalizationProvider>
  )
}

export default DateRangePicker
