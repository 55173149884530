import { EChartsOption, SeriesOption } from 'echarts'
import addOptionModifications from '../../addOptionModifications'
import defaultOptionModifications from './defaultOptionModifications'
import titleOption from '../../defaults/title'
import legendOption from '../../defaults/legend'
import tooltipOption from '../../defaults/tooltip'
import toolboxOption, { getDefaultToolbox } from '../../defaults/toolbox'
import generateDataset from '../../formatData/generateDataset'
import seriesOption from './series'
import { ChartParams, Option } from '../../types'
import validObject from '../validObject'

const Pie = (params: ChartParams): EChartsOption => {
  const {
    theme,
    title = '',
    subtitle = '',
    legend = false,
    tooltip = false,
    toolbox = getDefaultToolbox(theme),
    data = [{}],
    optionModifications = {},
  } = params

  const paddingPie = validObject({
    object: {
      title: Boolean(title),
      subtitle: Boolean(subtitle),
      legend,
      toolbox: validObject({ object: toolbox }),
    },
  })

  const axisName = 'name'
  const seriesModifications = optionModifications?.series as SeriesOption

  const defaultOption: Option = {
    title: titleOption({ title, subtitle, theme }),
    legend: legendOption({ legend, theme }),
    tooltip: tooltipOption({ tooltip, theme }),
    toolbox: toolboxOption({ ...toolbox, theme }),
    dataset: generateDataset({ data, axisName }),
    series: seriesOption({
      data,
      seriesModifications,
      axisName,
      paddingPie,
      theme,
    }),
  }

  const pieOption = addOptionModifications({
    defaultOption,
    defaultOptionModifications: defaultOptionModifications(),
    optionModifications,
  })

  return pieOption
}

export default Pie
