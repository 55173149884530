import React from 'react'
import NewTable from './Table'
import OldTable from 'components/ReportTable'
import { TableProperties, OldTableProperties } from 'lib/reports/types'

interface TableProps {
  report: Report
  reportProperties: TableProperties | OldTableProperties
  rows: number
}

const Table = (props: TableProps) => {
  const { report, reportProperties, rows } = props
  const reportDataSets = report.reportDataSets?.items || []

  return Boolean(reportDataSets.length) ? (
    <NewTable
      report={report}
      reportProperties={reportProperties as TableProperties}
      rows={rows}
    />
  ) : (
    <OldTable
      report={report}
      reportProperties={reportProperties as OldTableProperties}
    />
  )
}

export default Table
