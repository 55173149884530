import { RadiusSizes } from 'theme/types'

const COMMON_RADIUS: RadiusSizes = {
  xs: '4px',
  s: '8px',
  m: '16px',
  l: '24px',
  xl: '48px',
  xxl: '100px',
}

export default COMMON_RADIUS
