import React, { ReactNode, lazy, Suspense, useState, useEffect } from 'react'
import { Breakpoint } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { usePostHog } from 'posthog-js/react'
import { selectAccount } from 'redux/account'
import { selectDashboards } from 'redux/dashboards'
import { selectApps } from 'redux/apps'
import { selectUniverseModules } from 'redux/universeModules'
import { selectModalState, setModalState } from 'redux/modal'
import { setRoles } from 'redux/roles'
import { useRealTime } from 'contexts/realTime'
import Header from 'containers/Header'
import DrawerMenu from 'components/Menu/DrawerMenu'
import DashboardMenu from 'components/Menu/DashboardMenu'
import LoadingPage from 'components/LoadingPage/LoadingPage'
import Footer from 'components/Footer'
import LoadingSection from 'components/LoadingSection/LoadingSection'
import ModalSwitch from 'components/ModalSwitch'
import { useLayoutState } from 'hooks/components'
import { useFeedback, setMaxFeedbackDisplayCount } from 'hooks/useFeedback'
import usePlaygroundIsRunning from 'hooks/usePlaygroundIsRunning'
import { useDashboardParams } from 'hooks/useDashboardParams'
import useWorkspaceUpdate from 'contexts/realTime/realTimeEvents/useWorkspaceUpdate'
import { reloadPageOnChunkError } from 'lib/lazy'
import { NAVIGATION_EVENTS } from 'lib/postHogEvents'
import { AlignText } from 'material/types'
import { selectLoading } from 'redux/loading'
import * as S from './Layout.styled'
import { RealTimeEventsReducerActionType } from 'contexts/realTime/realTimeEventsReducer/types'
import useRealTimeEventsDashboard from 'contexts/realTime/realTimeEvents/useRealTimeEventsDashboard'
const Feedback = lazy(() =>
  import('components/Feedback').catch(reloadPageOnChunkError)
)

interface LayoutProps {
  children: ReactNode
  sideMenu?: boolean
  initialStateSideMenu?: boolean
  maxWidthContent?: false | Breakpoint | undefined
  loading?: boolean
  footerAlign?: AlignText
}

const Layout = (props: LayoutProps) => {
  const {
    children,
    sideMenu = true,
    initialStateSideMenu = true,
    maxWidthContent = false,
    footerAlign,
  } = props
  const dispatch = useDispatch()
  const account = useSelector(selectAccount)
  const dashboards = useSelector(selectDashboards)
  const apps = useSelector(selectApps)
  const { handleWorkspaceUpdate } = useWorkspaceUpdate()
  const { handleDashboardEvents } = useRealTimeEventsDashboard()
  const { loadingAccount } = useSelector(selectLoading)
  const universeModules = useSelector(selectUniverseModules)
  const { SUBSCRIPTION_PLANS } = universeModules
  const { open, type } = useSelector(selectModalState)
  const { key: locationKey, pathname } = useLocation()
  const { matches: isDesktop } = useLayoutState()
  const { showFeedback, setShowFeedback } = useFeedback(Boolean(apps.length))
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(
    isDesktop ? initialStateSideMenu : false
  )
  const playgroundIsRunning = usePlaygroundIsRunning()
  const { shared } = useDashboardParams()
  const posthog = usePostHog()
  const { registerRealTimeComponent, unregisterRealTimeComponent } =
    useRealTime()

  const closeSideMenu = () => {
    setIsSideMenuOpen(false)
    posthog?.capture(NAVIGATION_EVENTS.CLOSE_DRAWER)
  }
  const toggleSideMenu = () => {
    if (isSideMenuOpen) posthog?.capture(NAVIGATION_EVENTS.CLOSE_DRAWER)
    if (!isSideMenuOpen) posthog?.capture(NAVIGATION_EVENTS.OPEN_DRAWER)
    setIsSideMenuOpen((prevIsSideMenuOpen) => !prevIsSideMenuOpen)
  }

  const handleCloseFeedback = () => setShowFeedback(false)

  const handleCloseModal = () => dispatch(setModalState({ open: false }))

  // RealTime Playground ⬇️

  useEffect(() => {
    const { BUSINESS_CONTENTS_UPDATED: event, DASHBOARD_CREATED } =
      RealTimeEventsReducerActionType
    registerRealTimeComponent({
      eventType: event,
      callback: handleWorkspaceUpdate,
    })
    registerRealTimeComponent({
      eventType: DASHBOARD_CREATED,
      callback: (event) => {
        handleDashboardEvents(event)
      },
    })

    return () => {
      unregisterRealTimeComponent({ eventType: event })
    }
  }, []) // eslint-disable-line

  // RealTime Playground ⬆️

  useEffect(() => {
    if (!isDesktop) {
      closeSideMenu()
    }
  }, [locationKey]) // eslint-disable-line

  useEffect(() => {
    if (account.userType === 'ADMIN') return

    const pathParts = pathname.split('/')
    const [, dashboardId, normalizedAppName] = pathParts
    const dashboard = dashboards.find(
      (dashboard) => dashboard.id === dashboardId
    )
    const app = apps.find((app) => app.normalizedName === normalizedAppName)

    dispatch(
      setRoles({
        app,
        dashboard,
        business: account.business,
        accountRoles: account.roles,
      })
    )
  }, [pathname, dashboards, apps]) // eslint-disable-line

  if (shared) {
    return <>{children}</>
  }

  return (
    <S.LayoutContainer>
      <Header
        sideMenu={sideMenu}
        onMenuToggle={toggleSideMenu}
        playground={playgroundIsRunning}
      />

      {Boolean(sideMenu) && (
        <DrawerMenu
          open={isSideMenuOpen}
          onClose={closeSideMenu}
          variant={isDesktop ? 'persistent' : 'temporary'}
          ModalProps={{ keepMounted: true }}
        >
          <DashboardMenu
            items={dashboards}
            pathname={pathname}
            loadingAccount={loadingAccount}
            playground={playgroundIsRunning}
          />
        </DrawerMenu>
      )}

      <S.ContentContainer isSideMenuOpen={sideMenu ? isSideMenuOpen : false}>
        <S.Content maxWidth={maxWidthContent}>
          {loadingAccount ? <LoadingPage /> : children}
          <Footer align={footerAlign} />
        </S.Content>
      </S.ContentContainer>

      {showFeedback && SUBSCRIPTION_PLANS && (
        <Suspense fallback={<LoadingSection />}>
          <Feedback
            handleClose={handleCloseFeedback}
            onSubmitFeedback={setMaxFeedbackDisplayCount}
          />
        </Suspense>
      )}

      <ModalSwitch
        open={open}
        onClose={handleCloseModal}
        type={type}
        universeModules={universeModules}
      />
    </S.LayoutContainer>
  )
}

export default Layout
