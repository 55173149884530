import {
  IconButtonProps,
  MenuItemProps,
  MenuItem as MenuComponent,
  Drawer as DrawerComponent,
  DrawerProps as DrawerComponentProps,
  List as ListComponent,
  Divider as DividerComponent,
  IconButton,
  Skeleton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
interface CustomIconButton extends IconButtonProps {
  active?: boolean
}

interface CustomMenuItem extends MenuItemProps {
  haveArrow?: boolean
  isSkeleton?: boolean
}

interface CustomSubMenuItem {
  active?: boolean
}

export const MenuItem = styled(MenuComponent)<CustomMenuItem>(
  ({
    theme: {
      typography,
      palette: { mode, primary },
    },
    haveArrow,
    selected,
  }) => ({
    ...(!selected && {
      ...typography.bodyS400,
    }),
    ...(selected && {
      ...typography.bodyS600,
    }),
    ...(!haveArrow && {
      padding: '14px 32px 14px 36px',
    }),
    ...(haveArrow && {
      padding: '7px 32px 7px 5px',
    }),
    display: 'flex',
    borderRadius: 'var(--border-radius-s)',
    marginBottom: '6px',
    color: !selected ? 'var(--color-grey-700)' : '',
    '&:hover': {
      backgroundColor: `${primary.main}0A`,
      ...(mode === 'dark' && {
        backgroundColor: 'var(--color-grey-700)',
      }),
    },
    '&.Mui-selected': {
      backgroundColor: `${primary.main}0A`,
      '&:hover': {
        backgroundColor: `${primary.main}0A`,
      },
    },
  })
)

export const SubMenuItem = styled(MenuComponent)<CustomSubMenuItem>(
  ({
    theme: {
      typography,
      palette: { primary },
    },
    selected,
  }) => ({
    ...(!selected && {
      ...typography.bodyS400,
    }),
    ...(selected && {
      ...typography.bodyS600,
    }),
    borderRadius: 'var(--border-radius-s)',
    color: !selected ? 'var(--color-grey-700)' : '',
    display: 'inline-flex',
    padding: '8px 23px 8px 16px',
    margin: '8px 0',
    width: '100%',
    '&.Mui-selected': {
      backgroundColor: `${primary.main}0A`,
      '&:hover': {
        backgroundColor: `${primary.main}0A`,
      },
    },
    '> svg': {
      color: selected ? 'var(--color-primary)' : 'var(--color-grey-700)',
    },
  })
)

export const DashboardMenuItem = styled(MenuItem)(({ selected }) => ({
  color: selected ? 'var(--color-white)' : '',
  padding: '10px 8px',
  justifyContent: 'space-between',
  '&.Mui-selected': {
    backgroundColor: 'var(--color-primary)',
    '&:hover': {
      backgroundColor: 'var(--color-primary)',
    },
  },
}))

export const IconAndTextAgruppation = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

interface LogoContainerProps {
  menuHeader: boolean
}

export const LogoContainer = styled('div')<LogoContainerProps>(
  ({ theme, menuHeader }) => ({
    width: '100%',
    height: 'var(--header-height)',
    minHeight: 'var(--header-height)',
    backgroundColor: 'var(--background-default)',
    padding: '0.5rem 1.5rem',
    display: menuHeader ? 'flex' : 'none',
    alignItems: 'center',
    borderBottom: '1px solid var(--color-grey-200)',

    '& > a': {
      display: 'inline-flex',
    },

    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  })
)

interface DrawerProps extends DrawerComponentProps {
  menuHeader: boolean
}

export const Drawer = styled(DrawerComponent)<DrawerProps>(
  ({ theme, menuHeader }) => ({
    border: 'none',

    '.MuiPaper-root': {
      zIndex: theme.zIndex.appBar - 1,
      height: menuHeader ? '100vh' : 'calc(100vh - var(--header-height))',
      marginTop: menuHeader ? 0 : 'var(--header-height)',
      borderRadius: 0,
      boxShadow: 'none',
    },

    [theme.breakpoints.down('md')]: {
      '.MuiPaper-root': {
        height: '100vh',
        marginTop: 0,
      },
    },
  })
)

export const List = styled(ListComponent)(() => ({
  padding: '20px 16px 4px 16px',
  overflow: 'auto',
  height: '100vh',
  border: 'none',
  borderRight: '1px solid var(--color-grey-200)',
}))

export const MenuContainer = styled('div')(() => ({
  padding: '5px',
}))

export const SubMenuContainer = styled('div')(() => ({
  position: 'relative',
  paddingLeft: '32px',
  margin: '8px 0',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '20px',
    top: '0',
    bottom: '0',
    width: '2px',
    backgroundColor: 'var(--color-grey-300)',
  },
}))

export const Icon = styled(IconButton)<CustomIconButton>(({ active }) => {
  return {
    color: active ? 'var(--color-white)' : '',
    borderRadius: 'var(--border-radius-s)',
    width: '32px',
    height: '32px',
    padding: '0',
  }
})

export const Divider = styled(DividerComponent)(
  ({
    theme: {
      palette: { mode },
    },
  }) => ({
    borderColor: 'var(--color-grey-100)',
    ...(mode === 'dark' && {
      borderColor: 'var(--color-grey-700)',
    }),
  })
)

interface MenuSkeletonProps {
  submenu?: boolean
}

export const MenuSkeleton = styled(Skeleton)<MenuSkeletonProps>(
  ({ submenu }) => ({
    width: '100%',
    maxWidth: 'none',
    height: '28px',
    borderRadius: 'var(--border-radius-s)',
    marginRight: submenu ? '50px' : '30px',
  })
)

export const PaperStyles = {
  width: 'var(--drawer-width)',
  borderRight: 0,
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
  overflow: 'hidden',
}
