import React from 'react'
import { SelectProps } from '@mui/material/Select'
import ChevronDown from 'icons/Line/chevron-down'
import { LabelInput } from './common'
import { TextInputProps } from './text-input'
import * as S from './input.styled'

export type SelectInputProps = TextInputProps &
  SelectProps & {
    minWidth?: string
    textAlign?: 'left' | 'center' | 'right'
    hideArrow?: boolean
  }

const SelectInput = ({
  label,
  children,
  defaultValue,
  disabled,
  id,
  value,
  minWidth,
  textAlign,
  hideArrow,
  ...props
}: SelectInputProps) => {
  const StyledChevron = () => (
    <ChevronDown
      style={{
        position: 'absolute',
        right: 12,
        top: 12,
        pointerEvents: 'none',
      }}
    />
  )
  return (
    <>
      <LabelInput {...props} id={id} label={label} />
      <S.Select
        {...props}
        data-testid={`${label}-input`}
        defaultValue={defaultValue}
        disabled={disabled}
        minWidth={minWidth}
        fullWidth
        input={
          <S.InputBase
            {...props}
            id={id}
            value={value}
            fullWidth
            textAlign={textAlign}
          />
        }
        /* @ts-ignore */
        IconComponent={!disabled && !hideArrow ? StyledChevron : null}
      >
        {children}
      </S.Select>
    </>
  )
}

export default SelectInput
