import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'redux/store/types'

const initialState: App[] = []

const appsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    setApps: (state: App[], action) => action.payload,
    updateApp: (state: App[], { payload }) =>
      state.map((app: App) =>
        app.id === payload.id ? { ...app, ...payload } : app
      ),
    addApps: (state: App[], action) => state.concat(action.payload),
  },
})

export const selectApps = (state: RootState) => state.apps

export const { setApps, updateApp, addApps } = appsSlice.actions

export default appsSlice.reducer
