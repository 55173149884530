import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'redux/store/types'

type setAvailableRolesParams = {
  business?: Business
  app?: App
  dashboard?: Dashboard
  accountRoles: string[]
}

export interface UserRolePermission {
  [resource: string]: string
}

const initialState: UserRolePermission = {
  DATA: 'WRITE',
  USER_MANAGEMENT: 'WRITE',
  DATA_EXECUTION: 'WRITE',
  BUSINESS_INFO: 'WRITE',
}

const groupPermissionsByResource = (rolePermissions: RolePermissions[]) =>
  rolePermissions.reduce((grouped, rolePermission) => {
    if (!grouped[rolePermission.resource]) {
      return {
        ...grouped,
        [rolePermission.resource]: [rolePermission.permission],
      }
    }

    return {
      ...grouped,
      [rolePermission.resource]: [
        ...grouped[rolePermission.resource],
        rolePermission.permission,
      ],
    }
  }, {} as { [resource: string]: string[] })

const highestPermission = (permissions: string[]): string => {
  return permissions.includes('WRITE') ? 'WRITE' : 'READ'
}

const getUserRolePermissions = (
  rolePermissions: RolePermissions[],
  roles: string[]
): UserRolePermission => {
  const userRolePermissions = rolePermissions.filter((permission) =>
    roles?.includes(permission.role)
  )

  const groupedPermissions = groupPermissionsByResource(userRolePermissions)

  return Object.entries(groupedPermissions).reduce(
    (acc: UserRolePermission, [resource, permissions]) => ({
      ...acc,
      [resource]: highestPermission(permissions),
    }),
    {}
  )
}

const availableRolesSlice = createSlice({
  name: 'resourcePermissions',
  initialState,
  reducers: {
    setRoles: (state, action) => {
      const {
        business,
        app,
        dashboard,
        accountRoles,
      }: setAvailableRolesParams = action.payload

      const businessPermissions = business
        ? getUserRolePermissions(business.rolePermissions.items, accountRoles)
        : {}

      const dashboardPermissions = dashboard
        ? getUserRolePermissions(dashboard.rolePermissions.items, accountRoles)
        : {}

      const appPermissions = app
        ? getUserRolePermissions(app.rolePermissions.items, accountRoles)
        : {}

      Object.assign(state, {
        ...appPermissions,
        ...dashboardPermissions,
        ...businessPermissions,
      })
    },
  },
})

export const selectCurrentRoles = (state: RootState) => {
  return state.resourcePermissions
}

export const { setRoles } = availableRolesSlice.actions

export default availableRolesSlice.reducer
