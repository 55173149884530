import businessFragment from './business'

const accountFragment = /* GraphQL */ `
  ${businessFragment}
  fragment AccountFields on AccountExposed {
    id
    createdAt
    startupApp {
      id
      type {
        id
        normalizedName
      }
      isDisabled
    }
    business {
      ...BusinessFields
    }
    roles
    notificationsLastRead
    userType
  }
`

export default accountFragment
