import reportFragment from '../fragments/report'

const listReports = /* GraphQL */ `
  ${reportFragment}
  query listReports(
    $appId: ID!
    $limit: Int
    $publicPermissions: DashboardPublicPermissionInputExposed
  ) {
    listReports(
      appId: $appId
      limit: $limit
      publicPermissions: $publicPermissions
    ) {
      items {
        ...ReportFields
      }
    }
  }
`

export default listReports
