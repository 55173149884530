import { styled } from '@mui/material/styles'
import { GridColumnsPanel } from '@mui/x-data-grid-pro'
import { panelTextStyles, inputStyles } from '../DataGrid.styled'

export const ColumnsPanel = styled(GridColumnsPanel)(() => ({
  padding: '1rem',

  '& .MuiDataGrid-panelHeader': {
    padding: '0.375rem 0',

    '& .MuiInput-root': {
      ...inputStyles,
      '& .MuiInput-input::placeholder': {
        opacity: '0.42 !important',
      },
    },
  },

  '& .MuiDataGrid-columnsPanel': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
    margin: '1rem 0',
    padding: 0,

    '& .MuiDataGrid-columnsPanelRow': {
      padding: '0',

      '& .MuiFormControlLabel-root': {
        margin: 0,
      },
    },

    '& .MuiTypography-root': {
      ...panelTextStyles,
    },

    '& .MuiSwitch-root': {
      width: '2.625rem',
      height: '1.625rem',
      padding: '0',
      marginRight: '0.5rem',

      '& .MuiSwitch-switchBase': {
        padding: '0',
        margin: '0.125rem',
        color: 'var(--color-grey-800)',

        '& + .MuiSwitch-track': {
          backgroundColor: 'var(--color-grey-200)',
          opacity: 1,
          border: '0.125rem solid var(--color-grey-700)',
          borderRadius: 'var(--border-radius-m)',
        },

        '& .MuiSwitch-thumb': {
          width: '1.375rem',
          height: '1.375rem',
          boxShadow: 'none',
        },

        '&.Mui-checked': {
          color: 'var(--color-white)',
          '& + .MuiSwitch-track': {
            backgroundColor: 'var(--color-primary)',
            border: '0.125rem solid var(--color-primary)',
            opacity: 1,
          },
        },
      },
    },
  },

  '& .MuiDataGrid-panelFooter': {
    padding: 0,

    '& .MuiButton-root': {
      ...panelTextStyles,
      color: 'var(--color-primary)',
    },
  },
}))
