import { UseFormSetValue, UseFormUnregister } from 'react-hook-form'
import resetValuesOnChange from '../resetValuesOnChange'
import { InputType, Options, FormValues } from 'components/ReportForm/types'
import { isArray, isObject } from 'lib/variableType'

interface getOptionsParams {
  options?: Options
  formValues: FormValues
  dependsOn?: string
  inputType: InputType
  unregister: UseFormUnregister<any>
  setValue: UseFormSetValue<any>
  fieldName: string
}

export const getOptions = (params: getOptionsParams) => {
  const {
    options: defaultOptions,
    dependsOn = '',
    formValues,
    inputType,
    unregister,
    setValue,
    fieldName,
  } = params

  if (isArray(defaultOptions)) {
    return defaultOptions as string[]
  }

  if (Boolean(dependsOn) && isObject(defaultOptions)) {
    const dependsOnValue = formValues[dependsOn]
    const options =
      //@ts-ignore
      (defaultOptions[dependsOnValue] as string[]) || ([] as string[])

    resetValuesOnChange({
      inputType,
      formValues,
      fieldName,
      options,
      setValue,
      defaultOptions: defaultOptions as Options,
      unregister,
    })

    return options
  }

  return [] as string[]
}

export default getOptions
