import getDefaultValues from '../getDefaultValues'
import { FieldGroup } from 'components/ReportForm/types'

const getComponentData = (currentFieldGroups: FieldGroup[]) => {
  const allValues: any = currentFieldGroups.map(({ fields }: any) =>
    getDefaultValues(fields)
  )
  const defaultFormValues = Object.assign({}, ...allValues)
  return {
    defaultFormValues,
    currentFieldGroups,
  }
}

export default getComponentData
