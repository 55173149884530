import { createSlice } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'
import { RootState } from 'redux/store/types'
const { REACT_APP_ADMIN_GROUP_NAME } = process.env

const initialState: User = {
  id: '',
  name: '',
  email: '',
  companyName: '',
  companyRole: '',
  phoneNumber: '',
  birthDate: '',
  isAdmin: false,
  currentDateTime: '',
  accounts: {
    items: [],
  },
  accountInUseId: '',
  apiToken: null,
  kindOfUser: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => ({ ...state, ...action.payload }),
  },
})

export const selectUser = (state: RootState) => state.user

export const isCognitoUserAdmin = async (cognitoUser?: any) => {
  const user = cognitoUser ? cognitoUser : await Auth.currentAuthenticatedUser()
  return Boolean(
    user?.signInUserSession?.accessToken?.payload['cognito:groups']?.includes(
      REACT_APP_ADMIN_GROUP_NAME
    )
  )
}

export const { setUser } = userSlice.actions

/**
 * TODO: pending to unify all indentities in one
 * Retrieves the userId given a logged user.
 */

export const getUserId = (user: any): string => {
  if (user.identities) {
    const [{ providerName, userId }] = JSON.parse(user.identities)
    return `${providerName}_${userId}`
  }
  return user.sub
}

export default userSlice.reducer
