import { OnDataOptions, FetchPolicy } from '@apollo/client'
import { GraphQLOptions } from '@aws-amplify/api-graphql'

export enum GraphQLClient {
  APP_SYNC = 'APP_SYNC',
  APOLLO = 'APOLLO',
}

export enum GraphQLOperationTypeEnum {
  QUERY = 'QUERY',
  MUTATION = 'MUTATION',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export type GraphQLOperationType = keyof typeof GraphQLOperationTypeEnum

export interface GraphQLOperationOptions {
  variables?: Record<string, any>
  onData?: (options: OnDataOptions) => any
  authToken?: GraphQLOptions['authToken']
  authMode?: GraphQLOptions['authMode']
}

export interface GraphQLOperationParams {
  operationType: GraphQLOperationType
  operation: string
  options?: GraphQLOperationOptions
  fetchPolicy?: FetchPolicy
  user?: User
}

export enum FetchPolicyEnum {
  CACHE_FIRST = 'cache-first',
  NETWORK_ONLY = 'network-only',
  CACHE_ONLY = 'cache-only',
  NO_CACHE = 'no-cache',
  STANDBY = 'standby',
}
