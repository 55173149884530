import appDashboardFragment from './appDashboards'
import rolePermissionsFragment from './rolePermissions'

const ROLE_PERMISSIONS_FRAGMENT_NAME = 'Dashboard'

const dashboardFragment = /* GraphQL */ `
  ${appDashboardFragment}
  ${rolePermissionsFragment({ name: ROLE_PERMISSIONS_FRAGMENT_NAME })}
  fragment dashboardFields on DashboardExposed {
    id
    name
    order
    isDisabled
    theme
    publicPermission {
      isPublic
      permission
      token
    }
    appDashboards {
      items {
        ...appDashboardFields
      }
    }
    rolePermissions {
      items {
        ...RolePermissionsFields${ROLE_PERMISSIONS_FRAGMENT_NAME}
      }
    }
  }
`

export default dashboardFragment
