import { GRID_ROW_GAP } from 'components/Grid/utils/defaultValues'

interface GetRowGapParams {
  isBentoBox?: boolean
}

const getRowGap = ({ isBentoBox }: GetRowGapParams) =>
  isBentoBox ? 0 : GRID_ROW_GAP

export default getRowGap
