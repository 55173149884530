import React, { FC } from 'react'
import { Alert } from '@mui/material'
import withErrorWrapper from 'hocs/withErrorWrapper'
import { formatMessage } from 'i18n/ShimokuIntl'
import alignIndicators from './alignIndicators'
import { replaceRawTimestampInString } from 'lib/dates'
import * as S from './ReportIndicators.styled'
import Indicator, { IndicatorProps } from 'components/Indicator'
import {
  getCompatibleColor,
  getCompatibleVariant,
} from 'components/Indicator/compatibility-helper'

export const ReportIndicators: FC<WithReport> = (props) => {
  const { report } = props
  const { chartData = '[]', dataFields } = report
  const data = JSON.parse(chartData)
  const fields = dataFields ? JSON.parse(dataFields) : {}
  const columns = fields?.columns ? fields?.columns : 4
  const align = fields?.align ? alignIndicators(fields?.align) : 'flex-start'

  return (
    <S.Wrapper align={align} data-testid="reportIndicator">
      {data.map((indicator: IndicatorProps, i: number) => {
        return (
          <S.Container key={i} columns={columns} className="bentobox-reset">
            <Indicator
              title={indicator.title}
              value={indicator.value}
              description={replaceRawTimestampInString(indicator.description)}
              color={getCompatibleColor(indicator.color)}
              align={indicator.align}
              variant={getCompatibleVariant({
                color: indicator.color,
                variant: indicator.variant,
              })}
              icon={indicator.icon}
              targetPath={indicator.targetPath}
              bigIcon={indicator.bigIcon}
              hideIcons={indicator.hideIcons}
              backgroundImage={indicator.backgroundImage}
              info={indicator.info}
            />
          </S.Container>
        )
      })}
    </S.Wrapper>
  )
}

const Placeholder = () => (
  <Alert severity="error">
    {formatMessage('errors.inconsistentReportData')}
  </Alert>
)

export default withErrorWrapper<WithReport>(ReportIndicators, Placeholder)
