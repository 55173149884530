import React, { SVGProps } from 'react'

export const LinkA = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
    >
      <title>link-a-icon</title>
      <path
        d="M12.1387 8.81413L15.2101 5.74279C16.4601 4.49281 18.4867 4.4928 19.7367 5.74279V5.74279C20.9867 6.99277 20.9867 9.0194 19.7367 10.2694L15.0536 14.9525C14.3067 15.6994 13.0957 15.6994 12.3488 14.9524V14.9524"
        stroke={color}
        stroke-width="2"
      />
      <rect
        x="10.6835"
        y="7.25146"
        width="6.40157"
        height="11.7362"
        rx="3.20078"
        transform="rotate(45 10.6835 7.25146)"
        stroke={color}
        stroke-width="2"
      />
    </svg>
  )
}
