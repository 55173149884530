import { styled } from '@mui/material/styles'
import TextInput from '../text-input'
import { MenuItem as MenuItemComponent } from '@mui/material'

type InputWithoutBordersProps = {
  top?: boolean
  bottom?: boolean
  withoutPadding?: boolean
}

export const SELECT_OPTION_HEIGHT = 52
export const SELECT_OPTION_LINE_HEIGHT = 21
export const SELECT_OPTION_PADDING = 31

export const MenuItem = styled(MenuItemComponent)(({ theme }) => ({
  whiteSpace: 'normal',
  [theme.breakpoints.up('sm')]: {
    minHeight: SELECT_OPTION_HEIGHT,
  },
}))

export const InputWithoutBorders = styled(TextInput)(
  ({ top, bottom, withoutPadding }: InputWithoutBordersProps) => ({
    position: 'sticky',
    left: 0,
    right: 0,
    backgroundColor: 'var(--color-white)',
    zIndex: 'var(--zIndex-xs)',
    height: '52px',
    display: 'flex',
    paddingLeft: withoutPadding ? 0 : '12px',
    justifyContent: 'center',
    ...(top && { top: 0 }),
    ...(bottom && { bottom: 0 }),
    '& .MuiInputBase-input': {
      backgroundColor: 'var(--color-white)',
      fontSize: 'var(--bodyS400-font-size)',
      paddingLeft: withoutPadding ? '24px' : '4px',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'transparent',
      '&.Mui-hover .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  })
)
