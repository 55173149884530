import getAppName from 'lib/apps/getAppName'

// We can optimice this with a Set
const groupByAppName = (apps: App[]) => {
  const groupedApps = apps.reduce((acc: { [key: string]: App[] }, app: App) => {
    const appName = getAppName(app)
    if (acc[appName]) {
      acc[appName].push(app)
    } else {
      acc[appName] = [app]
    }
    return acc
  }, {})

  return groupedApps
}
interface AddIncrementToDuplicatedAppNamesParams {
  apps: App[]
}

const addIncrementToDuplicatedAppNames = (
  params: AddIncrementToDuplicatedAppNamesParams
) => {
  const { apps } = params
  const groupedApps = groupByAppName(apps)

  return Object.keys(groupedApps).reduce((acc: App[], groupName: string) => {
    const appsWithSameName = groupedApps[groupName]

    if (appsWithSameName.length > 1) {
      const appsWithSameNameWithIncrement = appsWithSameName.map(
        (app: App, index: number) => {
          const appName = getAppName(app)
          const appNameWithIncrement = `${appName}${
            index > 0 ? ` (${index + 1})` : ''
          }`
          return { ...app, name: appNameWithIncrement }
        }
      )

      return acc.concat(appsWithSameNameWithIncrement)
    } else {
      return acc.concat(appsWithSameName)
    }
  }, [])
}

export default addIncrementToDuplicatedAppNames
