import React, { SVGProps } from 'react'

export const Discord = ({
  width = '32',
  height = '32',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>discord-icon</title>
      <g clip-path="url(#clip0_1989_3317)">
        <mask
          id="mask0_1989_3317"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="5"
          width="28"
          height="22"
        >
          <path d="M30 5H2V27H30V5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1989_3317)">
          <path
            d="M25.7032 6.9591C23.9186 6.12853 22.0048 5.51662 20.0039 5.16614C19.9674 5.15938 19.931 5.17628 19.9123 5.21009C19.6661 5.6541 19.3935 6.23334 19.2026 6.68862C17.0505 6.36182 14.9094 6.36182 12.8014 6.68862C12.6104 6.22322 12.3279 5.6541 12.0807 5.21009C12.0619 5.17741 12.0255 5.16051 11.9891 5.16614C9.98923 5.5155 8.07549 6.12742 6.28976 6.9591C6.2743 6.96586 6.26105 6.97713 6.25225 6.99177C2.62229 12.4923 1.62789 17.8577 2.11571 23.1565C2.11792 23.1825 2.13226 23.2073 2.15213 23.223C4.54708 25.0069 6.86699 26.0899 9.14383 26.8078C9.18026 26.8191 9.21887 26.8055 9.24206 26.7751C9.78065 26.0291 10.2607 25.2425 10.6724 24.4153C10.6967 24.3669 10.6735 24.3094 10.6238 24.2902C9.86232 23.9972 9.1372 23.64 8.43968 23.2343C8.38451 23.2016 8.3801 23.1216 8.43085 23.0833C8.57763 22.9717 8.72446 22.8556 8.86461 22.7384C8.88997 22.717 8.92531 22.7125 8.95512 22.726C13.5375 24.8481 18.4985 24.8481 23.0268 22.726C23.0566 22.7114 23.0919 22.7159 23.1184 22.7373C23.2586 22.8545 23.4054 22.9717 23.5533 23.0833C23.604 23.1216 23.6007 23.2016 23.5455 23.2343C22.848 23.6479 22.1229 23.9972 21.3603 24.2891C21.3106 24.3083 21.2885 24.3669 21.3128 24.4153C21.7333 25.2413 22.2134 26.0279 22.742 26.774C22.7641 26.8055 22.8038 26.8191 22.8403 26.8078C25.1282 26.0899 27.4481 25.0069 29.843 23.223C29.864 23.2073 29.8772 23.1836 29.8794 23.1577C30.4633 17.0316 28.9016 11.7103 25.7396 6.9929C25.7319 6.97713 25.7186 6.96586 25.7032 6.9591ZM11.3567 19.9301C9.97708 19.9301 8.84032 18.6454 8.84032 17.0677C8.84032 15.49 9.95504 14.2053 11.3567 14.2053C12.7694 14.2053 13.8951 15.5013 13.873 17.0677C13.873 18.6454 12.7583 19.9301 11.3567 19.9301ZM20.6605 19.9301C19.281 19.9301 18.1442 18.6454 18.1442 17.0677C18.1442 15.49 19.2589 14.2053 20.6605 14.2053C22.0732 14.2053 23.199 15.5013 23.1769 17.0677C23.1769 18.6454 22.0732 19.9301 20.6605 19.9301Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1989_3317">
          <rect
            width="28"
            height="22"
            fill="white"
            transform="translate(2 5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
