import React, { SVGProps } from 'react'

export const UserMultiple = ({
  width = '24px',
  height = '24px',
  color = 'var(--color-base-icon)',
}: SVGProps<SVGSVGElementEventMap>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        width,
        height,
      }}
      fill="none"
    >
      <title>user-multiple-icon</title>
      <path
        d="M15 3C17.2091 3 19 4.79086 19 7C19 9.20914 17.2091 11 15 11"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M23 20.786L22.6741 18.2718C22.4736 16.7253 21.4068 15.4805 20 15"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="4"
        cy="4"
        r="4"
        transform="matrix(1 0 0 -1 5 11)"
        stroke={color}
        stroke-width="2"
      />
      <path
        d="M17 21L16.6169 18.4138C16.3263 16.4525 14.6428 15 12.66 15H5.33995C3.35722 15 1.67371 16.4525 1.38314 18.4138L1 21"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
