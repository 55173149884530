import Sentry from 'tracking/Sentry'
import dayjs from 'dayjs'

export const checkIsValidDate = (date: Date) => {
  if (isNaN(date.getTime())) throw new Error('Invalid date')
}

/**
 * December 11, 2020
 **/
export const getNotificationDateFormat = (date: Date): string | undefined => {
  try {
    checkIsValidDate(date)
    return date.toLocaleDateString('en-EN', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })
  } catch (error) {
    Sentry.captureException(error)
    return
  }
}

/**
 * Returns a text replacing raw timestamp inside #{} if exist
 * @param text "Trade time: #{2021-03-16T12:32:00.021Z}"
 * @returns string like "Trade time: 16/3/2021, 13:32:00"
 */
export const replaceRawTimestampInString = (
  text: string | undefined,
  showOnlyTime = false
) => {
  let textReplaced = text
  if (text?.substring(text.lastIndexOf('#{'), text.lastIndexOf('}'))) {
    const rawTimestamp = text
      ?.substring(text.lastIndexOf('#{') + 2, text.lastIndexOf('}'))
      .replace(' ', 'T') as string
    if (rawTimestamp) {
      const timestamp = dayjs(rawTimestamp).toDate()
      try {
        checkIsValidDate(timestamp)
        const formattedTimestamp = showOnlyTime
          ? timestamp.toLocaleTimeString()
          : timestamp.toLocaleString()
        textReplaced = text?.replace(
          text?.substring(
            text.lastIndexOf('#{'),
            text.lastIndexOf('}') + 1
          ) as string,
          formattedTimestamp
        )
      } catch (error) {
        Sentry.captureException(error)
      }
    }
  }
  return textReplaced
}
