import { REPORT_TYPES } from 'lib/reports'
import { ModalProperties } from 'lib/reports/types'

const { MODAL } = REPORT_TYPES

interface LinkReportsToModalsParams {
  reports: Report[]
}

const linkReportsToModals = (params: LinkReportsToModalsParams) => {
  const { reports } = params

  const modalsReports = reports.filter(({ reportType }) => reportType === MODAL)

  if (!modalsReports.length) {
    return reports
  }

  const reportIdsLinkToModals = new Set(
    modalsReports.flatMap((report) => {
      const { reportProperties } = report
      const { reportIds = [] }: ModalProperties = reportProperties

      return reportIds
    })
  )

  const reportsLinkedToModals = reports.map((report) => ({
    ...report,
    linkedToModal: reportIdsLinkToModals.has(report.id),
  }))

  return reportsLinkedToModals
}

export default linkReportsToModals
