import {
  EventActions,
  EventAction,
  EventActionParams,
  EventParams,
} from 'hooks/useRunEvents/types'
import useRunActivity, {
  RunActivityParams,
} from 'hooks/useRunEvents/actionEvents/useRunActivity'
import useRunAction, {
  RunActionParams,
} from 'hooks/useRunEvents/actionEvents/useRunAction'
import useHandleModal, {
  HandleModalParams,
} from 'hooks/useRunEvents/actionEvents/useHandleModal'

const {
  runActivity: runActivityName,
  runAction: runActionName,
  openModal: openModalName,
  closeModal: closeModalName,
} = EventActions

interface UseGetActionEventParams {
  appId: string
}

export interface GetActionEventParams {
  action: EventAction
  actionParams: EventActionParams
  eventParams?: EventParams
}

const useGetActionEvent = (params: UseGetActionEventParams) => {
  const { appId } = params
  const { runActivity } = useRunActivity({ appId })
  const { runAction } = useRunAction()
  const { handleModal } = useHandleModal()

  const getActionEvent = (params: GetActionEventParams) => {
    const { action, actionParams, eventParams } = params

    switch (action) {
      case runActivityName:
        const { activityId } = actionParams as RunActivityParams
        runActivity({ activityId, eventParams })
        break
      case runActionName:
        const { actionId } = actionParams as RunActionParams
        runAction({ actionId })
        break
      case openModalName:
        const { modalId: modalIdOpen } = actionParams as HandleModalParams
        handleModal({ modalId: modalIdOpen, modalState: true })
        break
      case closeModalName:
        const { modalId: modalIdClose } = actionParams as HandleModalParams
        handleModal({ modalId: modalIdClose, modalState: false })
        break
      default:
        break
    }
  }

  return { getActionEvent }
}

export default useGetActionEvent
