import { styled } from '@mui/material/styles'
import {
  FormControl as FormControlComponent,
  Select as SelectComponent,
} from '@mui/material'
import { FormControlProps } from '@mui/material/FormControl'
import { SelectProps } from '@mui/material/Select'

export const FormControl = styled(FormControlComponent)<FormControlProps>(
  () => ({
    margin: '0 0 20px 0',
  })
)

export const Select = styled(SelectComponent)<SelectProps>(
  ({
    theme: {
      palette: { mode },
    },
  }) => ({
    boxShadow: 'var(--box-shadow-l)',
    borderRadius: 'var(--border-radius-m)',
    '&:before': {
      border: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    ...(mode === 'dark' && {
      boxShadow: 'var(--box-shadow-m)',
    }),
  })
)
