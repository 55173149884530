export enum KindsOfUser {
  'Founders' = 'Founders',
  'MarketingAndSales' = 'Marketing&Sales',
  'PythonDev' = 'Python Dev',
}

export type KindOUser = keyof typeof KindsOfUser

export type KindOfWorkFormInputs = {
  kindOfUser: KindOUser
}

export type CustomizeExperienceFormInputs = {
  nameOrAlias?: string
  position?: string
  interest?: string
  sector?: string
  pythonLevel?: string
}
