import { Dispatch, SetStateAction, MutableRefObject } from 'react'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { useSnackbar } from 'notistack'
import useGraphQLOperation from 'hooks/useGraphQLOperation'
import getAllTableData from 'containers/Table/utils/getAllTableData'
import generateTableRows from 'containers/Table/utils/generateTableRows'
import { TableState } from 'containers/Table/types'
import { downloadCSV } from 'lib/files'
import { MappingObject } from 'lib/reports/types'

interface UseExportTableDataToCSVProps {
  apiRef: MutableRefObject<GridApiPro>
  dataSetId: string
  rowsMapping: MappingObject
  reportId: string
  tableState: TableState
  setTableState: Dispatch<SetStateAction<TableState>>
}

const useExportTableDataToCSV = (props: UseExportTableDataToCSVProps) => {
  const {
    apiRef,
    dataSetId,
    rowsMapping,
    reportId,
    tableState,
    setTableState,
  } = props
  const { enqueueSnackbar } = useSnackbar()
  const graphQLOperation = useGraphQLOperation()

  const exportOnClick = async () => {
    setTableState((prevTableState) => ({ ...prevTableState, loading: true }))
    const dataItems = await getAllTableData({
      dataSetId,
      tableState,
      graphQLOperation,
      enqueueSnackbar,
    })
    const visibleColumns = apiRef.current.getVisibleColumns()
    const headers = visibleColumns.map(({ headerName }) => headerName as string)
    const mapping = visibleColumns.reduce(
      (mapping, { field, headerName }) => ({
        ...mapping,
        [headerName as string]: rowsMapping[field],
      }),
      {} as MappingObject
    )
    const rows = generateTableRows({
      dataItems,
      rowsMapping: mapping,
      CSV: true,
    })
    const title = `Table-${reportId}-${new Date()}`
    downloadCSV({ headers, rows, title })
    setTableState((prevTableState) => ({ ...prevTableState, loading: false }))
  }

  return { exportOnClick }
}

export default useExportTableDataToCSV
