import handleCreateDashboard from './handleCreateDashboard'
import useGraphQLOperation from 'hooks/useGraphQLOperation'
import { RealTimeEvent } from 'contexts/realTime/types'
import { RealTimeEventsReducerActionType } from 'contexts/realTime/realTimeEventsReducer/types'
import { selectDashboards } from 'redux/dashboards'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'

const useDashboardEventsHandler = () => {
  const graphQLOperation = useGraphQLOperation()
  const { DASHBOARD_CREATED } = RealTimeEventsReducerActionType
  const dispatch = useDispatch()
  const dashboards = useSelector(selectDashboards)
  const { enqueueSnackbar } = useSnackbar()

  const handleDashboardEvents = async (realTimeEvent: RealTimeEvent) => {
    const dashboardId = realTimeEvent.resourceId
    if (realTimeEvent.type === DASHBOARD_CREATED) {
      const dashboardFound = dashboards.some(
        (dashboard) => dashboard.id === dashboardId
      )

      if (!Boolean(dashboardFound)) {
        handleCreateDashboard({
          id: dashboardId,
          graphQLOperation,
          dispatch,
          dashboards,
          enqueueSnackbar,
        })
      }
    }
  }
  return { handleDashboardEvents }
}

export default useDashboardEventsHandler
