interface GetReportsPropertiesParams {
  reports: Report[]
}

const getReportsProperties = (params: GetReportsPropertiesParams) => {
  const { reports } = params

  return reports.map((report) => {
    const { properties } = report
    const reportProperties = properties ? JSON.parse(properties) : {}

    return {
      ...report,
      reportProperties,
    } as Report
  })
}

export default getReportsProperties
