import { Theme } from '@mui/material/styles'
import { YAXisComponentOption } from 'echarts'
import { AXIS } from './general'
import { AxisParams } from '../types'

export const getDefaultYAxis = (theme: Theme): AxisParams => ({
  name: '',
  type: 'value',
  theme,
})

const yAxis = (params: AxisParams): YAXisComponentOption => {
  const { name = '', type = 'value', theme } = params

  return {
    ...AXIS(theme),
    name,
    type,
    nameRotate: 90,
    nameGap: 30,
    nameTextStyle: {
      ...AXIS(theme)?.nameTextStyle,
    },
    axisLine: {
      ...AXIS(theme)?.axisLine,
      lineStyle: {
        ...AXIS(theme)?.axisLine?.lineStyle,
      },
    },
    axisTick: {
      ...AXIS(theme)?.axisTick,
      lineStyle: {
        ...AXIS(theme)?.axisTick?.lineStyle,
      },
    },
    axisLabel: {
      ...AXIS(theme)?.axisLabel,
      align: 'right',
      margin: 16,
    },
    splitLine: {
      ...AXIS(theme)?.splitLine,
      lineStyle: {
        ...AXIS(theme)?.splitLine?.lineStyle,
      },
    },
    splitArea: {
      ...AXIS(theme)?.splitArea,
      areaStyle: {
        ...AXIS(theme)?.splitArea?.areaStyle,
      },
    },
  } as YAXisComponentOption
}
export default yAxis
