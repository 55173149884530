import { formatMessage } from 'i18n/ShimokuIntl'
import {
  haveUpperLowerAndEightLength,
  haveUpperLowerNonWordAndTwelveLength,
} from 'lib/forms/forms'
import {
  AuthFormField,
  AuthPasswordFormField,
} from 'components/AuthForm/AuthFormInput'

export const defaultEmail: AuthFormField = {
  type: 'email',
  name: 'email',
  required: true,
  label: formatMessage('form.email'),
  placeholder: formatMessage('form.email'),
}

export const defaultPassword: AuthPasswordFormField = {
  type: 'password',
  name: 'password',
  required: true,
  label: formatMessage('form.password'),
  placeholder: formatMessage('form.password'),
  helperText: formatMessage('form.passwordRules'),
  passwordRegex: haveUpperLowerNonWordAndTwelveLength,
}

export const defaultRepassword = {
  type: 'repassword',
  name: 'repassword',
  required: true,
  label: formatMessage('form.rePassword'),
  placeholder: formatMessage('form.rePassword'),
  helperText: formatMessage('form.passwordRules'),
  valueToGet: 'password',
  passwordRegex: haveUpperLowerNonWordAndTwelveLength,
}

export const defaultCode = {
  type: 'text',
  name: 'code',
  required: true,
  label: formatMessage('form.code'),
  placeholder: formatMessage('form.code'),
}

export const signUpStepOneFields = {
  email: defaultEmail,
}

export const signUpStepTwoFields: {
  password: AuthPasswordFormField
  repassword: AuthPasswordFormField
} = {
  password: defaultPassword,
  repassword: defaultRepassword,
}

export const signInFields = {
  email: defaultEmail,
  password: {
    ...defaultPassword,
    passwordRegex: haveUpperLowerAndEightLength,
    helperText: undefined,
  },
}

export const forgotPasswordFields = {
  email: defaultEmail,
}
