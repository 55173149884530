import { useReports } from 'contexts/reports'

export interface HandleModalParams {
  modalId: string
  modalState: boolean
}

const useHandleModal = () => {
  const { setReports } = useReports()

  const handleModal = (params: HandleModalParams) => {
    const { modalId, modalState } = params

    setReports((reports) =>
      reports.map((report) => {
        const { id: reportId, reportProperties } = report

        if (reportId === modalId) {
          return {
            ...report,
            reportProperties: {
              ...reportProperties,
              open: modalState,
            },
          }
        }

        return report
      })
    )
  }

  return { handleModal }
}

export default useHandleModal
