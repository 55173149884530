import disableReportsLinkedToMultifilters from './disableReportsLinkedToMultifilters'
import linkReportsToTabs from './linkReportsToTabs'
import linkReportsToModals from './linkReportsToModals'
import { sortByOrder } from 'lib/reports'
import getReportsProperties from 'lib/reports/getReportsProperties'

interface formatReportsParams {
  reports: Report[]
}

const formatReports = (params: formatReportsParams) => {
  const { reports } = params

  const reportsWithReportProperties = getReportsProperties({
    reports,
  })

  const orderedReports = reportsWithReportProperties.sort(sortByOrder)

  const disabledReportsLinkedToMultifilters =
    disableReportsLinkedToMultifilters({ reports: orderedReports })

  const reportsLinkedToTabs = linkReportsToTabs({
    reports: disabledReportsLinkedToMultifilters,
  })

  const reportsLikedToModals = linkReportsToModals({
    reports: reportsLinkedToTabs,
  })

  return reportsLikedToModals
}

export default formatReports
