import React from 'react'
import { InputAdornment } from '@mui/material'
import Search from 'icons/Line/search'
import debounce from 'lib/debounce'
import { TextInput } from 'components/Input'

interface SelectFilterProps extends React.HTMLAttributes<HTMLDivElement> {
  delay?: number
  field: ReportTableDataField
  loading?: boolean
  onFilter: (field: string, type?: string) => (event: any) => void
  reportId: string
  cell: string
  type: 'search'
}

const SearchFilter = ({
  cell,
  delay = 500,
  field,
  loading,
  onFilter,
  reportId,
  type,
}: SelectFilterProps) => (
  <TextInput
    id={`report-${reportId}-filter-${field.field}`}
    disabled={loading}
    label={cell}
    onChange={debounce(onFilter(field.field, type), delay)}
    type={type}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Search width="18px" height="18px" />
        </InputAdornment>
      ),
    }}
  />
)

export default SearchFilter
