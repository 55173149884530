import { SeriesOption, HeatmapSeriesOption } from 'echarts'
import heatmapSerie from '../../defaults/series/heatmap'
import { SeriesParams, DataElement } from '../../types'

const series = (params: SeriesParams): SeriesOption => {
  const { data, seriesModifications = {}, theme } = params

  const xAxisData = Array.from(
    new Set(data.map((element: DataElement) => element?.xAxis))
  )
  const yAxisData = Array.from(
    new Set(data.map((element: DataElement) => element?.yAxis))
  )
  const dataHeatmap = data.map((element: DataElement) => {
    const xPosition = xAxisData.indexOf(element?.xAxis)
    const yPosition = yAxisData.indexOf(element?.yAxis)

    return [xPosition, yPosition, element?.value]
  })
  const seriesOption = heatmapSerie({
    typeSeriesModifications: seriesModifications as HeatmapSeriesOption,
    theme,
  })

  const series = [
    {
      ...seriesOption,
      data: dataHeatmap,
    },
  ] as SeriesOption

  return series
}

export default series
