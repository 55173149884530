import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLOperationParams, GraphQLOperationTypeEnum } from '../types'

const { QUERY, MUTATION, SUBSCRIPTION } = GraphQLOperationTypeEnum

const executeAppSyncOperation = (params: GraphQLOperationParams) => {
  const { operationType, operation, options = {} } = params
  const { variables = {}, onData, authToken, authMode } = options

  switch (operationType) {
    case QUERY:
    case MUTATION:
      return API.graphql(graphqlOperation(operation, variables))

    case SUBSCRIPTION:
      return (
        API.graphql({
          query: operation,
          variables,
          authMode: authMode,
          authToken: authToken,
        })
          //@ts-ignore
          .subscribe({
            next: onData,
          })
      )
    default:
      throw new Error(
        `Unsupported operation type for AppSync: ${operationType}`
      )
  }
}

export default executeAppSyncOperation
