import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'redux/store/types'
import { Plan, PlanLimits } from './types'

const initialState: Plan = {
  id: '',
  limits: {} as PlanLimits,
  price: 0,
  type: 'FREEMIUM',
}

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setPlan: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
})

export const selectPlan = (state: RootState) => state.plan

export const { setPlan } = planSlice.actions

export default planSlice.reducer
