import { Theme } from '@mui/material/styles'
import { RadarComponentOption } from 'echarts'
import defaultRadar from '../../defaults/radar'
import { DefaultData, DataElement } from '../../types'
import { capitalize } from 'lib/strings'

interface RadarParams {
  data: DefaultData
  paddingRadar?: boolean
  theme: Theme
}

const radar = (params: RadarParams): RadarComponentOption => {
  const { data, paddingRadar, theme } = params
  const dataWithoutName = Object.keys(data?.[0]).filter(
    (name: string) => name !== 'name'
  )
  const indicator = dataWithoutName.map((name: string) => ({
    name: capitalize(name),
    max: Math.max(...data.map((value: DataElement) => value[name] as number)),
  }))

  const radarModifications: RadarComponentOption = {
    radius: ['0%', `${paddingRadar ? 65 : 80}%`],
    center: ['50%', `${paddingRadar ? 55 : 50}%`],
    indicator,
  }

  const radarOption = defaultRadar({
    typeSeriesModifications: radarModifications,
    theme,
  })

  return radarOption
}

export default radar
