import { BarSeriesOption } from 'echarts'
import { BORDER_STYLE, SHADOW_STYLE, TEXT_STYLE } from '../../defaults/general'
import { TypeSeriesParams } from '../../types'

const BarSerie = (
  params: TypeSeriesParams<BarSeriesOption>
): BarSeriesOption => {
  const { typeSeriesModifications: barSeriesModifications = {}, theme } = params

  return {
    type: 'bar',
    legendHoverLink: true,
    coordinateSystem: 'cartesian2d',
    showBackground: false,
    selectedMode: false,
    stack: '',
    cursor: 'pointer',
    barWidth: 'auto',
    barGap: '30%',
    barCategoryGap: '20%',
    ...barSeriesModifications,
    backgroundStyle: {
      color: 'rgba( 180, 180, 180, 0.2)',
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      ...barSeriesModifications?.backgroundStyle,
    },
    label: {
      show: false,
      rotate: 0,
      offset: [0, 0],
      ...TEXT_STYLE(theme),
      fontSize: 12,
      color: '#fff',
      backgroundColor: 'transparent',
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      position: 'inside',
      ...barSeriesModifications?.label,
    },
    itemStyle: {
      ...BORDER_STYLE,
      ...SHADOW_STYLE,
      ...barSeriesModifications?.itemStyle,
    },
    labelLayout: {
      hideOverlap: false,
      ...barSeriesModifications?.labelLayout,
    },
    emphasis: {
      focus: 'none',
      ...barSeriesModifications?.emphasis,
    },
  }
}

export default BarSerie
