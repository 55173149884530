import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'

type State = Account

const setAccount: CaseReducer<State, PayloadAction<Account>> = (
  state,
  action
) => ({ ...state, ...action.payload })

export default setAccount
