import {
  GraphQLOperationParams,
  GraphQLOperationTypeEnum,
  FetchPolicyEnum,
} from 'hooks/useGraphQLOperation/types'
import getAccountQuery from 'gql/queries/getAccount'
import listAccounts from 'gql/queries/listAccounts'
import { formatMessage } from 'i18n/ShimokuIntl'
import { ProviderContext } from 'notistack'

const { QUERY } = GraphQLOperationTypeEnum
const { NETWORK_ONLY } = FetchPolicyEnum

interface SearchUserAccountByIdParams {
  user: User
  accountInUseId: string
}

const searchUserAccountById = ({
  user,
  accountInUseId,
}: SearchUserAccountByIdParams) =>
  user.accounts?.items.find((account) => account.id === accountInUseId)

interface GetAccountParams {
  user: User
  graphQLOperation: (params: GraphQLOperationParams) => Promise<any>
  enqueueSnackbar: ProviderContext['enqueueSnackbar']
  doListAccounts?: boolean
}

const getAccount = async (params: GetAccountParams) => {
  const {
    user,
    graphQLOperation,
    enqueueSnackbar,
    doListAccounts = false,
  } = params

  let res: any

  try {
    const variables = { id: user.accountInUseId }
    const options = { variables }
    res = await graphQLOperation({
      operationType: QUERY,
      operation: getAccountQuery,
      options,
      fetchPolicy: NETWORK_ONLY,
    })
  } catch (error) {
    if (!doListAccounts) {
      const searchedAccount = searchUserAccountById({
        user,
        accountInUseId: user.accountInUseId as string,
      }) as Account

      enqueueSnackbar(
        formatMessage('errors.getAccount', {
          workspaceName: searchedAccount.business.name,
        }),
        { variant: 'error' }
      )
      return undefined
    }

    const listAccountsVaribles = { userId: user.id }
    const listAccountsOptions = { variables: listAccountsVaribles }
    const {
      data: {
        listAccounts: { items },
      },
    }: any = await graphQLOperation({
      operationType: QUERY,
      operation: listAccounts,
      options: listAccountsOptions,
      fetchPolicy: NETWORK_ONLY,
    })

    const accountItem = items[0] as Pick<Account, 'id'>
    const accountInUseId = accountItem?.id
    const getAccountVaribles = { id: accountInUseId }
    const getAccountOptions = { variables: getAccountVaribles }

    res = await graphQLOperation({
      operationType: QUERY,
      operation: getAccountQuery,
      options: getAccountOptions,
      fetchPolicy: NETWORK_ONLY,
    })

    const account = res.data.getAccount as Account

    const previousAccount = searchUserAccountById({
      user,
      accountInUseId: user.accountInUseId as string,
    })

    enqueueSnackbar(
      formatMessage('errors.loadAccount', {
        workspaceName: previousAccount?.business.name || '',
        theFirstWorspaceName: account.business.name,
      }),
      { variant: 'error' }
    )
  }

  return res.data.getAccount as Account
}

export default getAccount
