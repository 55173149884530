import React, { useState, useCallback } from 'react'
import { useSnackbar } from 'notistack'
import { useDropzone, FileRejection } from 'react-dropzone'
import { LabelInput, LabelInputProps } from '../common'
import UploadCloud from 'icons/Line/cloud'
import * as S from '../input.styled'
import FileList from '../FileList/file-list'
import getAcceptFileTypes from './getAcceptedFileTypes'
import { InputType } from 'components/ReportForm/types'
import { FileData, handleChangeParams } from '../types'

const { file } = InputType

type FileInputProps = LabelInputProps & {
  name: string
  fieldName: string
  acceptFileTypes?: string[]
  fullWidth?: boolean
  helperText?: string
  value?: (File | FileData)[]
  multiple?: boolean
  paginateBy?: number
  disabled?: boolean
  dragAndDrop?: boolean
  handleChange: ({ files, eventName }: handleChangeParams) => void
}

const FileInput = ({
  id,
  name,
  fieldName,
  label,
  placeholder,
  acceptFileTypes,
  multiple,
  value = [],
  handleChange,
  dragAndDrop = false,
  fullWidth,
  required,
  disabled = false,
  paginateBy,
  ...props
}: FileInputProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [listFiles, setListFiles] = useState<(File | FileData)[]>(value)

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (Boolean(acceptedFiles.length)) {
        setListFiles((prevListFiles) => {
          const files = [...prevListFiles, ...acceptedFiles]
          handleChange({ files, eventName: name })

          return files
        })
      }

      if (Boolean(fileRejections.length)) {
        fileRejections.forEach((fileRejection) => {
          const { errors } = fileRejection
          errors.forEach((error) => {
            enqueueSnackbar(error.message, { variant: 'error' })
          })
        })
      }
    },
    [] // eslint-disable-line
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: getAcceptFileTypes({ acceptFileTypes }),
    noDrag: !dragAndDrop,
    disabled,
  })

  return (
    <>
      <div {...getRootProps()}>
        <LabelInput {...props} id={id} label={label} required={required} />
        <S.InputFileButton fullWidth={fullWidth} endIcon={<UploadCloud />}>
          {placeholder}
        </S.InputFileButton>
        <input
          {...getInputProps({
            id,
            disabled,
            multiple,
            name,
            'data-inputType': `${fieldName}-${file}`,
          })}
        />
      </div>

      {Boolean(value?.length) && (
        <FileList
          disabled={disabled}
          handleChange={handleChange}
          fieldName={name}
          paginateBy={paginateBy}
          listFiles={listFiles}
          setListFiles={setListFiles}
        />
      )}
    </>
  )
}

export default FileInput
