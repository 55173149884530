import React from 'react'
import { useReports } from 'contexts/reports'
import useHandleModal from 'hooks/useRunEvents/actionEvents/useHandleModal'
import ModalComponent from 'components/Modal'
import { ModalProperties } from 'lib/reports/types'

interface ModalsProps {
  modals: Report[]
}

const Modals = (props: ModalsProps) => {
  const { modals } = props
  const { reports } = useReports()
  const { handleModal } = useHandleModal()

  return (
    <>
      {modals.map((modal) => {
        const { id: modalId, reportProperties } = modal
        const {
          open = false,
          reportIds = [],
          width,
          height,
        }: ModalProperties = reportProperties
        const handleClose = () => handleModal({ modalId, modalState: false })
        const modalReportsIds = new Set(reportIds)
        const modalReports = reports.filter((report) =>
          modalReportsIds.has(report.id)
        )

        return (
          <ModalComponent
            reports={modalReports}
            open={open}
            handleClose={handleClose}
            width={width}
            height={height}
          />
        )
      })}
    </>
  )
}

export default Modals
