import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { selectUser, setUser } from 'redux/user'
import useSetAccount from 'hooks/useAccount'
import ChangeAccountComponent from 'components/ChangeAccount'
import { USER_MENUS } from 'components/UserMenu'
import { ROOT_PATH } from 'components/Routes/routes/getDefaultRoutes'
import { formatMessage } from 'i18n/ShimokuIntl'
import { orderAlphabetically } from 'lib/strings'

export interface BusinessChangeAccount {
  id: string
  business: {
    id: string
    name?: string
  }
}

interface ChangeAccountProps {
  setActiveMenu: React.Dispatch<React.SetStateAction<string>>
  filteredAccountsNames: string[]
}

const ChangeAccount = (props: ChangeAccountProps) => {
  const { setActiveMenu, filteredAccountsNames } = props
  const { MAIN } = USER_MENUS
  const history = useHistory()
  const { setAccount } = useSetAccount()
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const accounts: BusinessChangeAccount[] = user.accounts
    ?.items as BusinessChangeAccount[]

  const filteredAccounts = accounts.filter((account) =>
    filteredAccountsNames.includes(account.business.name || '')
  )

  const sortedAccounts = [...filteredAccounts].sort((a, b) =>
    orderAlphabetically(a.business.name!, b.business.name!)
  )

  const [activeAccount, setActiveAccount] = useState(user.accountInUseId || '')
  const { enqueueSnackbar } = useSnackbar()

  const updateAccountInUseId = async (
    accountInUseId: string,
    userId: string
  ) => {
    if (user.accountInUseId !== accountInUseId) {
      try {
        setActiveAccount(accountInUseId)
        setActiveMenu(MAIN)
        const newUser = { ...user, accountInUseId }
        dispatch(setUser(newUser))
        await setAccount({ user: newUser })
        history.push(ROOT_PATH)
      } catch (error) {
        enqueueSnackbar(formatMessage('errors.changeAccount'), {
          variant: 'error',
        })
      }
    }
  }

  return (
    <ChangeAccountComponent
      userId={user.id}
      accounts={sortedAccounts}
      activeAccount={activeAccount}
      updateAccountInUseId={updateAccountInUseId}
    />
  )
}

export default ChangeAccount
