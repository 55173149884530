import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

type PlanItemContainerProps = {
  highlited: boolean
}

export const PlanItemContainer = styled('section')<PlanItemContainerProps>(
  ({ highlited }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    padding: '16px',
    height: '100%',
    borderRadius: 'var(--border-radius-m)',
    backgroundColor: highlited ? 'var(--color-grey-100)' : 'transparent',
    border: highlited ? '1px solid var(--color-grey-200)' : 'none',
  })
)

export const PlanContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}))

export const PlanDetailsList = styled('ul')(() => ({
  padding: 0,
}))

export const PlanDetailsListItem = styled('li')(() => ({
  listStyle: 'none',
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  marginBottom: '16px',
}))

export const DescriptionTypography = styled(Typography)(() => ({
  minHeight: '50px',
}))

export const ButtonContainer = styled('div')(() => ({
  marginTop: '24px',
}))
