import { ReportDataSetsByDataSetIds } from 'contexts/ReportDataSets/types'

interface GetReportDataSetsByDataSetIdsParams {
  reportDataSets: ReportDataSet[]
}

const getReportDataSetsByDataSetIds = (
  params: GetReportDataSetsByDataSetIdsParams
) => {
  const { reportDataSets } = params

  return reportDataSets.reduce((reportDataSetsByDataSetIds, reportDataSet) => {
    const {
      dataSet: { id: dataSetId },
    } = reportDataSet

    if (Boolean(reportDataSetsByDataSetIds[dataSetId])) {
      return {
        ...reportDataSetsByDataSetIds,
        [dataSetId]: [...reportDataSetsByDataSetIds[dataSetId], reportDataSet],
      }
    }

    return {
      ...reportDataSetsByDataSetIds,
      [dataSetId]: [reportDataSet],
    }
  }, {} as ReportDataSetsByDataSetIds)
}

export default getReportDataSetsByDataSetIds
