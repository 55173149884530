import React from 'react'
import { Skeleton as DefaultSkeleton } from '@mui/material'
import { ROW_HEIGHT } from 'components/Grid/utils/defaultValues'
import * as S from './ReportFormLoading.styled'

const GAP = ROW_HEIGHT / 10
const TOTAL_GAP = GAP * 3
const TOTAL_SKELETON_HEIGHT = ROW_HEIGHT - TOTAL_GAP
const SKELETON_HEIGHT = TOTAL_SKELETON_HEIGHT / 3

interface SkeletonProps {
  index: number
}

const Skeleton = ({ index }: SkeletonProps) => {
  const isOddIndex = index % 2 === 1
  const placeholderCount = isOddIndex ? 1 : 2
  const skeletonWidth = isOddIndex ? '100%' : `calc(50% - ${GAP / 2}px)`
  const skeletons = Array.from({ length: placeholderCount }, (_, i) => (
    <DefaultSkeleton
      key={i}
      role="progressbar"
      variant="rectangular"
      width={skeletonWidth}
      height={SKELETON_HEIGHT}
    />
  ))

  return <>{skeletons}</>
}

interface ReportFormLoadingProps {
  gridRows: number
  isBentoBox?: boolean
}

const ReportFormLoading = (props: ReportFormLoadingProps) => {
  const { gridRows, isBentoBox } = props
  const placeholderItems = isBentoBox ? gridRows / 3 : gridRows * 3
  const loading = Array.from({ length: placeholderItems }, (_, i) => (
    <Skeleton key={i + 1} index={i + 1} />
  ))

  return <S.LoadingContainer gap={GAP}>{loading}</S.LoadingContainer>
}

export default ReportFormLoading
