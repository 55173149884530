import { ReactNode, ReactElement } from 'react'

export type ConditionalwrapperProps = {
  condition: boolean
  wrapper: (children: ReactNode) => ReactElement
  children: ReactElement
}

export const ConditionalWrapper = (props: ConditionalwrapperProps) => {
  const { condition, wrapper, children } = props

  return condition ? wrapper(children) : children
}
