import { loadPyodide as defaultLoadPyodide } from 'pyodide'
import {
  SnackbarMessage,
  OptionsObject,
  SnackbarKey,
  VariantType,
} from 'notistack'
import {
  JS_ENVIRONMENT,
  JS_ACCESS_TOKEN,
  JS_UNIVERSE_ID,
  JS_WORKSPACE_ID,
  JS_SNACKBAR,
} from 'hooks/useRunEvents/actionEvents/useRunAction/types'

const PYODIDE_INDEX_URL = 'https://cdn.jsdelivr.net/pyodide/v0.23.4/full'
const MICROPIP = 'micropip'
const SHIMOKU_BROWSER = 'shimoku-browser'
const { REACT_APP_ENVIRONMENT: environment } = process.env

interface LoadPyodideParams {
  user: User
  account: Account
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey
}

const loadPyodide = async (params: LoadPyodideParams) => {
  const { user, account, enqueueSnackbar } = params

  const jsEnvironment = environment
  const jsSnackbar = (text: string, variant: VariantType) => {
    enqueueSnackbar(text, { variant })
  }
  const jsAccessToken = user?.apiToken || ''
  const jsUniverseId = account?.business?.universe?.id || ''
  const jsWorkspaceId = account?.business?.id || ''

  const pyodide = await defaultLoadPyodide({ indexURL: PYODIDE_INDEX_URL })
  pyodide.globals.set(JS_SNACKBAR, jsSnackbar)
  pyodide.globals.set(JS_ACCESS_TOKEN, jsAccessToken)
  pyodide.globals.set(JS_UNIVERSE_ID, jsUniverseId)
  pyodide.globals.set(JS_WORKSPACE_ID, jsWorkspaceId)
  pyodide.globals.set(JS_ENVIRONMENT, jsEnvironment)
  await pyodide.loadPackage(MICROPIP)
  const micropip = pyodide.pyimport(MICROPIP)
  await micropip.install(SHIMOKU_BROWSER)

  return { pyodide, micropip }
}

export default loadPyodide
