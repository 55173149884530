import React, { Component } from 'react'
import Sentry from 'tracking/Sentry'
import NotFound from 'pages/NotFound'

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends Component<any, ErrorBoundaryState> {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    Sentry && Sentry.captureException(error)
  }

  render() {
    return this.state.hasError ? <NotFound /> : this.props.children
  }
}

export default ErrorBoundary
